import { Box, Stack, Typography } from '@mui/material'
import { CustomButton } from '../../../components/atom/CustomButton'
import en from '../../../locale/en.json';
import { colors } from '../../../themes/colors';
import Input from '../../../components/atom/Input';
import { feedbackReasons, typographyOptions } from '../../../constants';
import CustomSelect from '../../../components/atom/Select';
import { useState } from 'react';
import { ArrowBack } from '../../../assests/exportImages';
import { screenActionsCall } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';


interface ProfileProps {
  handleBack?: () => void;
  isMobile?: boolean;
}

const Feedback = ({ handleBack, isMobile }) => {
  const dispatch = useDispatch()
  const { userDetails } = useSelector(
    (state: RootState) => state.screenReducer
  );
  const [selectedOption, setSelectedOption] = useState(null)
  const [feedback, setFeedback] = useState("")

  const handleSubmit = () => {
    if (selectedOption && feedback) {
      const data = {
        name: userDetails?.name,
        reason: selectedOption?.name,
        comment: feedback
      }
      dispatch(screenActionsCall.sendFeedback(data));
    } else {
      console.log("Please select a reason and provide feedback.");
    }
  }
  return (<Box px={2} >
    {isMobile && <img src={ArrowBack} alt="close icon" onClick={handleBack} />}

    <Box width={isMobile ? '100%' : '75%'} mt={2} gap={2} display={'flex'} flexDirection={'column'}>
      <Stack bgcolor={colors.darkBackground} py={3} px={2} borderRadius={'20px'}><Typography variant={typographyOptions.H1} color={colors.primaryGrey} paddingLeft="10px">
        {en.feedbackDescription}
      </Typography></Stack>
      <Stack gap="10px">
        <Typography variant={typographyOptions.H1} color={colors.primaryGrey} paddingLeft="10px">
          {en.chooseReason}
        </Typography><CustomSelect
          labelId="trip-select-label"
          id="trip-select"
          value={selectedOption?.displayName}
          handleChange={(e: any) => {
            setSelectedOption(e);
          }}
          // adornmentText={!selectedOption && en.choose}
          options={feedbackReasons}
          placeholder={en.choose}
          sx={{ width: { xs: "100%" } }}
          defaultKey={"displayName"}
          andormentTextSx={{
            "& .MuiTypography-root": {
              fontSize: "14px"
            }
          }}
        /></Stack>
      <Stack gap="10px">
        <Typography variant={typographyOptions.H1} color={colors.primaryGrey} paddingLeft="10px">
          {en.writeFeedback}
        </Typography>
        <Input
          placeholder={en.feedbackPlaceholder}
          maxLength={500}
          showLength
          customStyle={{ minHeight: '20vh', height: '20vh', maxHeight: '20vh', overflow: 'auto', display: 'flex', alignItems: 'flex-start', fontSize: '15px' }}
          multiline
          maxWidth='100%'
          value={feedback}
          onChange={(e) => {
            setFeedback(e.target.value)
          }}
        />
      </Stack>
      <Box width='100%' display={'flex'} alignItems={'flex-end'} flexDirection={'column'}>
        <CustomButton
          title={en.submit}
          customStyle={{ borderRadius: '20px', backgroundColor: colors.secondaryBlack, marginY: '10px' }}
          customTypography={{ color: colors.primaryWhite }}
          onClick={handleSubmit}
        />
      </Box>
    </Box>
  </Box>
  )
}

export default Feedback