import {
  CafeIcon,
  FlightIcon,
  LodgingIcon,
  SleepIcon,
  TrainIcon,
  UserCirlce,
  Bell,
  PencilAlt,
  Trash,
  Collab,
  Recommendation,
  AiChecklist,
  HiddenGem
} from "../assests/exportImages";

export enum AuthKeys {
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  REGISTER_WITH_EMAIL = "REGISTER_WITH_EMAIL",
  TELL_US_MORE = "TELL_US_MORE",
  EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
}

export enum typographyOptions {
  H1 = "h1",
  H2 = "h2",
  BODY1 = "body1",
  EXTRA_LARGE = "extraLarge",
  CARDS_HEADING = "cardsHeading",
  CARDS_DESCRIPTION = "cardsDescription",
  SUB_HEADING = "subHeading",
  HEADING = "heading",
  BACKGROUNDTEXT = "backgroundText",
  AUTH_HEADING = "authHeading",
  ERROR = "error",
}

export const PlacesTypeIcons = {
  ATTRACTION: LodgingIcon,
  HOTEL: SleepIcon,
  CAFE: CafeIcon,
  SHOOPINGMALL: LodgingIcon,
  DINING: CafeIcon,
  FLIGHT: FlightIcon,
  TRAIN: TrainIcon,
};

export const PlacesType = {
  PLACE: "Attraction",
  CAFE: "Cafe",
  SHOOPINGMALL: "Shopping Mall",
  ATTRACTION: "Attraction",
  TRAIN: "Train",
  FLIGHT: "Flight",
  HOTEL: "Hotel",
};

// travel guide types of trip dropdown
export const typesOfTrip = [
  { name: "dayTrips", displayName: "Day trips" },
  { name: "weekendGetaways", displayName: "Weekend Getaways" },
  { name: "shortTrips", displayName: "Short trips" },
  { name: "weekLongTrips", displayName: "WeekLong  trips" },
  { name: "longTrip", displayName: "Long trips" },
];

// tarvel guide dropdown types
export const sortByTypes = ["Recent", "Popular"];

export enum SearchTypes {
  Destination = "Destination",
  Itinerary = "Itinerary",
  Users = "Itinerary",
}

export enum DestinationDetailTypes {
  // HOTEL = "Hotel",
  // CAFE = "Cafe",
  // SHOOPINGMALL = "Shopping Mall",
  // Attraction: "Attraction",
  City = "City",
  country = "country",
  State = "State",
}

// Rating categories and labels
export const ratingCategories = [
  { label: "Excellent", rating: 5 },
  { label: "Very Good", rating: 4 },
  { label: "Average", rating: 3 },
  { label: "Poor", rating: 2 },
  { label: "Terrible", rating: 1 },
];

export enum SettingKeys {
  PROFILE = "PROFILE",
  SHARE_FEEDBACK = "SHARE_FEEDBACK",
  NOTIFICATION = "NOTIFICATION",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
}

export const SettingComponentIcons = {
  Profile: UserCirlce,
  Feedback: PencilAlt,
  Notifications: Bell,
  Delete: Trash
};

export const feedbackReasons = [
  { name: "APP_FEEDBACK", displayName: "App Feedback" },
  { name: "MISSING_PLACE", displayName: "Missing Place" },
  { name: "SUGGESTIONS", displayName: "Suggestions" },
  { name: "WRONG_INFORMATION", displayName: "Wrong Information" },
  { name: "APPRECIATION", displayName: "Appreciation" },
  { name: "OTHER", displayName: "Other" },
];

export const platforms = [
  { name: "youtube", displayName: "Youtube" },
  { name: "instagram", displayName: "Instagram" },
  { name: "tikTok", displayName: "TikTok" },
  { name: "facebook", displayName: "Facebook" },
  { name: "twitter", displayName: "Twitter" },
];

export const followersRange = [
  { name: "under5K", displayName: "1K-5K" },
  { name: "fiveToFiftyK", displayName: "5K-50K" },
  { name: "fiftyToHundredK", displayName: "50K-100K" },
  { name: "hundredToFiveHundredK", displayName: "100K-500K" },
  { name: "fiveHundredKToOneM", displayName: "500K-1M" },
  { name: "overOneM", displayName: "1M+" },
];

export const exploreImages = {
  collab: Collab,
  recommendation: Recommendation,
  aiChecklist: AiChecklist,
  hiddenGem: HiddenGem,
};

export interface MenuItem {
  title: string;
  children?: MenuItem[];
  isChecked?: boolean;
}

export const initialMenuItems: MenuItem[] = [
  {
    title: 'Art & Culture',
    children: [
      {
        title: 'Art & Culture Classes',
        children: [
          { title: 'Art Classes' },
          { title: 'Craft Classes' },
          { title: 'Dance Lessons', isChecked: true },
        ]
      },
      { title: 'Arts & Design' },
      { title: 'Culture' },
      { title: 'Pop Culture' },
    ]
  },
  { title: 'Classes & Workshop' },
  { title: 'Food & Drink' },
  { title: 'Outdoor Activities' },
  { title: 'Seasonal & Special Occasions' },
  { title: 'Tickets & Passes' },
  { title: 'Tours, Sightseeing & Cruises' },
  { title: 'Travel & Transportation' },
];

export const Viator = {
  minPrice: 0,
  maxPrice: 999999,
  numberOfResultsPerPage: 20,

  activityTags: [
    { name: "Outdoor Activities", tag: "21909" },
    { name: "Extreme Sports", tag: "11923" },
    { name: "Fun & Games", tag: "20217" },
    { name: "In the Air", tag: "21437" },
    { name: "Motor Sports", tag: "21627" },
    { name: "Nature and Wildlife Tours", tag: "11903" },
    { name: "On the Ground", tag: "21440" },
    { name: "Art & Culture", tag: "21910" },
    { name: "Art & Culture Classes", tag: "21479" },
    { name: "Arts & Design", tag: "21509" },
    { name: "Culture", tag: "21511" },
    { name: "Pop Culture", tag: "21517" },
    { name: "Shows & Performances", tag: "21516" },
    { name: "Traditional Wellness", tag: "21759" },
    { name: "Tours, Sightseeing & Cruises", tag: "21913" },
    { name: "Cruises & Sailing", tag: "21701" },
  ],
  ratingOptions: [
    { label: "5", value: { from: 5, to: 5 } },
    { label: "4", value: { from: 4, to: 5 } },
    { label: "3", value: { from: 3, to: 5 } },
  ],
  durationOptions: [
    { label: "Up to 1 hour", value: { from: 0, to: 60 } },
    { label: "1 to 4 hours", value: { from: 60, to: 240 } },
    { label: "4 hours to 1 day", value: { from: 240, to: 1440 } },
    { label: "1 to 3 days", value: { from: 1440, to: 4320 } },
    { label: "3+ days", value: { from: 4320, to: null } },
  ],
  specialsOptions: [
    { name: "New on Viator", value: "NEW_ON_VIATOR" },
    { name: "Free Cancellation", value: "FREE_CANCELLATION" },
    { name: "Likely to sell out", value: "LIKELY_TO_SELL_OUT" },
    { name: "Skip-The-Line", value: "SKIP_THE_LINE" },
    { name: "Private Tour", value: "PRIVATE_TOUR" },
    { name: "Special Offer", value: "SPECIAL_OFFER" },
  ]
}