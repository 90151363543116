import { Skeleton, Stack, Box, Grid } from "@mui/material";

const HotelDetailsSkeleton = () => {
  return (
    <div className="flex flex-col items-center w-full bg-[#f6f6f6] md:mt-20 mt-[68px] font-Mulish md:px-20 px-5 pb-24">
    <div className="max-w-[1280px] flex flex-col w-full items-center pt-10">
      <div className="flex md:flex-col flex-col-reverse w-full">
        <div className="topBar flex flex-row justify-between items-start w-full md:pb-10 p-5 md:p-0">
          <div className="flex flex-col w-auto gap-y-3">
            <Skeleton variant="text" width={200} height={30} />
            <div className="flex flex-row gap-x-3">
              <Skeleton variant="rectangular" width={80} height={24} />
            </div>
            <Skeleton variant="text" width={150} height={20} />
          </div>
        </div>
        <div className="md:w-[1119px] md:h-[360px] h-[340px]">
          <div className="relative flex flex-wrap w-full items-center justify-center gap-[7.74px]">
            <div className="hidden md:flex flex-1 items-center justify-center gap-[7.74px]">
                <Skeleton variant="rectangular" width={1119} height={350} />
            </div>
            <div className="md:hidden flex flex-1 items-center justify-center gap-[7.74px]">
              <div className="-mt-10">
                <Skeleton variant="rectangular" width={'100vw'} height={350} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tabsHolder w-full">
        {/* <div className="TabsOption border-b-[2px] border-[#f1f1f1] my-5">
          <Skeleton variant="rectangular" width={100} height={30} style={{ marginRight: 10 }} />
          <Skeleton variant="rectangular" width={100} height={30} />
        </div> */}
        <div className="md:w-[1119px] w-full flex items-center justify-center">
        <Skeleton variant="rectangular" width={1119}height={200} />
        </div>
      </div>
    </div>
  </div>
  );
};

export default HotelDetailsSkeleton;
