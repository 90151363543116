import { Stack, Tab, Tabs } from "@mui/material";
import Helmet from "react-helmet";
import Header from "../../components/template/Header";
import Footer from "../../components/template/Footer";
import {
  BookmarkBorderOutlined,
  FavoriteBorderOutlined,
  Home,
  Hotel,

  RestaurantMenu,
  ShareOutlined,
  Star,
  StarRounded,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { convertRatingToFiveScale, isNullOrEmpty, scrollToTop } from "../../utils/helpers";
import { screenActionsCall } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { RootState } from "../../redux/reducers";
import { useParams } from 'react-router-dom';
import { colors } from "../../themes/colors";
import { Image, PopularTripsDummy } from "../../assests/exportImages";
import ImageSlider from "../../components/molecule/ImageSlider";
import AgodaBookNow from "../../components/molecule/AgodaBookNow";
import ThingsToDoNearbyPanel from "./ThingsToDoNearbyPanel";
import ThingsToDoOverviewPanel from "./ThingsToDoOverviewPanel";
import HotelDetailsSkeleton from "../HotelDetails/HotelDetailsSkeleton";
import ViatorBookNow from "./ViatorBookNow";
import { viatorSuggestionBody } from "../../config/apiBody";
import Chip from "../../components/atom/Chip";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const ThingsToDoDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const [value, setValue] = useState(0);
  const {destinations} = location.state?.card || {};  

  const { card, searchBarData } = location.state || {};
  const productId = card?.productCode || params.id;

  useEffect(() => {
    scrollToTop();
    if (productId) {
      dispatch(screenActionsCall.getThingsToDoProductDetails(productId));
    }
  }, [productId]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <div className="w-full">{children}</div>}
      </div>
    );
  };

  const a11yProps = (index: number) => {
    return {
      hotelId: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const { thingsToDoProductDetails, thingsToDoProductDetailsLoading } = useSelector((state: RootState) => state.screenReducer);

  const reviewScore = thingsToDoProductDetails?.reviews?.combinedAverageRating?.toFixed(1)
  const totalReviews = thingsToDoProductDetails?.reviews?.totalReviews
  const locationFromRef = thingsToDoProductDetails?.locationFromRef
  const firstTag = thingsToDoProductDetails?.tags?.[0];
  const secondTag = thingsToDoProductDetails?.tags?.[1];
  const destinationId = destinations?.[0]?.ref

  const fromPrice = card?.pricing?.summary?.fromPrice
  const fromPriceBeforeDiscount = card?.pricing?.summary?.fromPriceBeforeDiscount
  const flags = card?.flags

  const getHighResImage = (image) => {
    if (!image?.variants || image?.variants?.length === 0) return null;
    return image?.variants?.reduce((prev, curr) =>
      curr.height * curr.width > prev.height * prev.width ? curr : prev
    ).url;
  };

  const imageList = thingsToDoProductDetails?.images?.length
  ? thingsToDoProductDetails?.images?.slice(0, 6)?.map(getHighResImage)
  : [];


  useEffect(() => {
    if (!isNullOrEmpty(thingsToDoProductDetails)) {
          dispatch(screenActionsCall.getthingsToDoSimilarExperienceList(viatorSuggestionBody({tags: [firstTag, secondTag], destination:destinationId})));
          dispatch(screenActionsCall.getthingsToDoYouMayAlsoLikeList(viatorSuggestionBody({tags: [firstTag], destination:destinationId})));
          dispatch(screenActionsCall.getthingsToDoNearbyActivitiesList(viatorSuggestionBody({tags: [21909], destination:destinationId})));
          dispatch(screenActionsCall.getthingsToDoNearbyThingsToDoList(viatorSuggestionBody({tags: [12716], destination:destinationId})));
        }
  }, [thingsToDoProductDetails]);


  return (
    <Stack>
      <Helmet>
        <title>Escapekar: Your Ultimate Hotel Guide</title>
        <meta name="title" content="Escapekar: Your Ultimate Hotel Guide" />
        <meta
          name="description"
          content="Plan your perfect stay with Escapekar's ultimate hotel guide. Discover featured hotels, hidden gems, and popular destinations. Get personalized recommendations and explore any place you desire with our advanced search functionality."
        />
        <meta
          name="keywords"
          content="hotel guide, featured hotels, hidden gems, popular destinations, hotel itinerary, trip planner, personalized recommendations, search hotels, travel planning"
        />

        <meta name="url" content="https://escapekar.com/" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://escapekar.com/" />
        <meta
          property="og:title"
          content="Escapekar: Your Ultimate Hotel Guide"
        />
        <meta
          property="og:description"
          content="Discover featured hotels, hidden gems, and popular destinations with Escapekar's hotel guide. Get personalized trip planning recommendations and explore any location with our comprehensive search."
        />
        <meta property="og:site_name" content="Escapekar" />
        <meta property="og:locale" content="en-IN" />
        <meta property="og:country-name" content="IN" />

        <script id="data" type="application/json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "HotelGuide",
            name: "Escapekar Hotel Guide",
            description:
              "Your ultimate resource for hotel planning. Discover featured hotels, hidden gems, and popular destinations. Get personalized recommendations and search for any location.",
            url: "https://escapekar.com/",
            sameAs: [
              "https://www.facebook.com/escapekar/",
              "https://www.instagram.com/escapekar/",
              "https://www.linkedin.com/company/escapekar/",
            ],

            hasPart: [
              {
                "@type": "Hotel",
                name: "Featured Hotels",
                description:
                  "Explore our featured hotel destinations and experiences.",
              },
              {
                "@type": "Hotel",
                name: "Hidden Gems",
                description:
                  "Discover hidden gems and lesser-known hotel destinations.",
              },
              {
                "@type": "Hotel",
                name: "Popular Destinations",
                description:
                  "Browse popular hotel destinations that are favored by travelers.",
              },
            ],
            contactPoint: {
              "@type": "ContactPoint",
              areaServed: "IN",
              availableLanguage: "en",
            },
          })}
        </script>
      </Helmet>
      <Header />
      {thingsToDoProductDetailsLoading ? <HotelDetailsSkeleton /> : <div className="flex flex-col items-center w-full  bg-[#f6f6f6] md:mt-20 mt-[68px] font-Mulish md:px-20 px-5 ">
        <div className=" max-w-[1280px] flex flex-col w-full items-center pt-10">
          <div className="flex md:flex-col flex-col-reverse">
            <div className="topBar flex flex-row justify-between items-start w-full md:pb-10 p-5 md:p-0">
              <div className="flex flex-col w-auto gap-y-3">
                <div className="flex md:flex-col flex-col-reverse gap-y-1 md:gap-y-3 ">
                  <h2 className="font-bold text-2xl font-Mulish">
                    {thingsToDoProductDetails?.title}
                  </h2>
                </div>
                <div className="flex gap-2 flex-wrap">
  {flags?.map((flag) => (
    <Chip
      key={flag}
      label={flag.replace(/_/g, " ").toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())}
      type={flag === "LIKELY_TO_SELL_OUT" ? "alert" : undefined}
    />
  ))}
</div>



                <div className="flex md:flex-row flex-col-reverse font-medium text-sm text-[#999]">
                  {totalReviews && reviewScore && (<p className="text-[#000]">
                    <StarRounded className="mr-1 !w-4 !h-4 mb-1" />
                    {reviewScore}
                    <span className="text-[#999] mx-2">({totalReviews} reviews)</span>
                  </p>)}

                  {locationFromRef && <><p className="hidden md:block">•</p>
                    <p className="md:ml-1 mb-2  md:mb-0 -mt-1 md:-mt-0">{locationFromRef}</p></>}
                </div>
              </div>
              {/* <div className="flex flex-row justify-between items-start w-[286px] mt-1">
              <p className="font-bold text-base underline text-[#333] cursor-pointer">
                <FavoriteBorderOutlined className="!w-4 !h-4 text-[#333] mr-1" />
                Like
              </p>
              <p className="font-bold text-base underline text-[#333] cursor-pointer">
                <BookmarkBorderOutlined className="!w-4 !h-4 text-[#333] mr-1" />
                Add to Trip
              </p>
              <p className="font-bold text-base underline text-[#333] cursor-pointer">
                <ShareOutlined className="!w-4 !h-4 text-[#333] mr-1" />
                Share
              </p>
            </div> */}
            </div>
            <div className="md:w-[1119px] md:h-[360px] h-[320px] object-cover">

              <div className="relative flex flex-wrap w-full h-full items-center justify-center gap-[7.74px]">
                {/* Mobile View - Image Slider */}
                <div className="md:hidden block w-screen h-[320px]">
                  <ImageSlider
                    images={imageList?.map(url => ({ url, alt: "Hotel Image" }))}
                    classname="rounded-none rounded-b-3xl object-cover"
                  />
                </div>

                {/* Large Image on Desktop */}
                <div className="w-auto hidden md:block">
                  <img
                    src={imageList?.[0] || PopularTripsDummy}
                    alt="Hotel Image"
                    className="w-[555px] h-[360px] object-cover rounded-l-[20px]"
                  />
                </div>

                {/* Right Side Smaller Images */}
                <div className="hidden md:flex flex-1 gap-[7.74px]">
                  <div>
                    <img src={imageList?.[1] || PopularTripsDummy} alt="Hotel Image" className="w-[275.75px] h-[175px] object-cover mb-[7.74px]" />
                    <img src={imageList?.[2] || PopularTripsDummy} alt="Hotel Image" className="w-[275.75px] h-[175px] object-cover" />
                  </div>
                  <div>
                    <img src={imageList?.[3] || PopularTripsDummy} alt="Hotel Image" className="w-[275.75px] h-[175px] object-cover rounded-tr-[20px] mb-[7.74px]" />
                    <img src={imageList?.[4] || PopularTripsDummy} alt="Hotel Image" className="w-[275.75px] h-[175px] object-cover rounded-br-[20px]" />
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="md:hidden block">
            <ViatorBookNow thingsToDoProductDetails={thingsToDoProductDetails} card={card} />
          </div>
          <div className="tabsHolder w-full">
            <div className="TabsOption border-b-[2px] border-[#f1f1f1] my-5">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#000",
                  },
                }}
                sx={{
                  ".Mui-selected": {
                    color: `#333`,
                  },
                }}
              >
                <Tab
                  sx={{
                    color: colors.secondaryGrey,
                    "&.Mui-selected": {
                      color: colors.transparentWhite,
                    },
                  }}
                  label={
                    <span className="!normal-case font-bold text-sm">
                      Overview
                    </span>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    color: colors.secondaryGrey,
                    "&.Mui-selected": {
                      color: colors.transparentWhite,
                    }
                  }}
                  label={
                    <span className="!normal-case font-bold text-sm">
                      Nearby
                    </span>
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </div>
            <CustomTabPanel value={value} index={0}>
              <ThingsToDoOverviewPanel thingsToDoProductDetails={thingsToDoProductDetails} card={card}  />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ThingsToDoNearbyPanel />
            </CustomTabPanel>
          </div>
        </div>
      </div>}

      <Footer />
    </Stack>
  );
};

export default ThingsToDoDetails;