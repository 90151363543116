import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Box, Skeleton, Stack } from '@mui/material';
import { RootState } from '../../../redux/reducers';
import { Location } from '../../../assests/exportImages';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { isNullOrEmpty } from '../../../utils/helpers';
import { click } from '@testing-library/user-event/dist/click';

const modalStyle = {
    position: 'absolute',
    zIndex: 100,
    width: '398px',
    '@media (max-width: 768px)': {
        width: '91vw',
    },
    borderRadius: 5,
    minHeight: 'content-fit',
    maxHeight: '374px',
    backgroundColor: 'white',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.1)',
    marginTop: 1,
    overflow: 'hidden'
};

const SearchCard = ({ item, isFirst, isLast, onClick, isProduct }) => {
    return (
        <div
            className={`flex gap-[10px] items-center px-5 py-3 border-[#F1F1F1] hover:bg-[#FAFAFA] ${!isLast ? 'border-b' : ''
                } ${isFirst ? 'rounded-t-[20px]' : ''} ${isLast ? 'rounded-b-[20px]' : ''}`}
            onClick={onClick}
        >
            <div className='border-2 h-[50px] w-[50px] rounded-[10px] flex items-center justify-center overflow-hidden flex-shrink-0'>
                {isProduct ? (
                    <img src={item?.images?.[0]?.variants?.[3]?.url} alt="Product Image" className='h-full w-full object-cover' />
                ) : (
                    <img src={Location} alt="Location Image" className='h-5' />
                )}
            </div>
            <div>
                <p className='font-Mulish font-bold text-[14px] text-black'>{isProduct ? item?.title : item?.name}</p>
                <p className='font-Mulish font-medium text-[12px] text-[#999999]'>{isProduct ? item?.destination : 'City'}</p>
            </div>
        </div>
    );
};

const SearchCardSkeleton = ({ isFirst, isLast }) => {
    return (
        <Box
            className={`flex gap-[10px] items-center px-5 py-3 border-[#F1F1F1] hover:bg-[#FAFAFA] ${!isLast ? "border-b" : ""
                } ${isFirst ? "rounded-t-[20px]" : ""} ${isLast ? "rounded-b-[20px]" : ""}`}
        >
            <Skeleton
                variant="rectangular"
                width={50}
                height={50}
                animation="wave"
                sx={{ borderRadius: "10px" }}
            />
            <Stack spacing={0.5}>
                <Skeleton animation="wave" height={20} width="100px" />
                <Skeleton animation="wave" height={16} width="80px" />
            </Stack>
        </Box>
    );
};

const CityProductListPopUp = ({ handleClose, setsearchBarDataLocal, handleThingsToDoCardClick }) => {
    const modalRef = useRef(null);
    const { thingsToDoFreeTextSearchList, thingsToDoFreeTextSearchLoading, thingsToDoDestinationList, thingsToDoDestinationListLoading } = useSelector(
        (state: RootState) => state.screenReducer
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    const productList = thingsToDoFreeTextSearchList?.products?.results || [];
    const destinationList = thingsToDoDestinationList?.content || [];

    const combinedItems = [...productList, ...destinationList]
        .sort((a, b) => {
            const nameA = a?.title || a?.name || "";
            const nameB = b?.title || b?.name || "";
            return nameA.localeCompare(nameB);
        })
        .slice(0, 20);

    const handleItemClick = (item) => {
        const isProduct = !!(!isNullOrEmpty(item?.productCode))
        
        if (isProduct) {
            handleThingsToDoCardClick(item)
        } else {
            setsearchBarDataLocal((prevData) => ({
                ...prevData,
                isProduct: isProduct,
                searchText: isProduct ? item?.title : item?.name,
                destinationId: item?.destinationId,
            }
            ));
        }
        setTimeout(() => {
            handleClose();
        }, 0);
    };

    return (
        <Box sx={modalStyle} ref={modalRef}>
            <PerfectScrollbar className='max-h-[374px]'>
                {(thingsToDoFreeTextSearchLoading || thingsToDoDestinationListLoading) ? (
                    Array.from({ length: 20 }).map((_, index) => (
                        <SearchCardSkeleton key={index} isFirst={index === 0} isLast={index === 19} />
                    ))
                ) : (
                    combinedItems.map((item, index) => (
                        <SearchCard
                            key={index}
                            item={item}
                            isFirst={index === 0}
                            isLast={index === combinedItems.length - 1}
                            isProduct={!!item?.productCode}
                            onClick={() => handleItemClick(item)}
                        />
                    ))
                )}
            </PerfectScrollbar>
        </Box>
    );
};

export default CityProductListPopUp;