import React, { useEffect, useState } from 'react';

const ImageBanner = ({ text, bgColor, textColor,className }) => {
    const [bgGradient, setBgGradient] = useState('');
    const [textCol, setTextCol] = useState('');

    useEffect(() => {
        if (bgColor === 'green') {
            setBgGradient('bg-gradient-to-r from-[#5BDBBE] to-[#20B08F]');
        } else if (bgColor === 'whiteRed') {
            setBgGradient('bg-[#FEEFEF]');
        }

        if (textColor === 'white') {
            setTextCol('text-white');
        } else if (textColor === 'red') {
            setTextCol('text-[#F05D5D]');
        }
    }, [bgColor, textColor]); // Added dependencies

    return (
        <div
            className={`${bgGradient} h-[23px] flex justify-center items-center text-[12px] font-bold font-Mulish ${textCol} rounded-r-md ${className} w-fit px-3`}
        >
            <div className="text-center">{text}</div>
        </div>
    );
};

export default ImageBanner;
