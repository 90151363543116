export const colors = {
  ThemeColor: "#20B08F",
  primaryWhite: "#FFFFFF",
  primaryGrey: "#666666",
  transparentWhite: "#333333",
  secondaryWhite: "#e0efeb",
  primaryRed: "#dc004e",
  black: "#000000",
  secondaryGrey: "#999999",
  tertiaryGrey: "#EAEAEA",
  darkGray: "#2d3134",
  secondaryRed:"#eb3352",
  quatenaryGrey:"#CCCCCC",
  tertiaryRed:"#F05D5D",
  background:'#fafafa',
  darkBackground:"#f1f1f1",
  secondaryBlack:'#121212',
};
