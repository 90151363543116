import { Box, Container, Skeleton, Stack, Typography } from "@mui/material";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { SkeletonCard } from "../../../components/skeltonLoader/cardSkelton";
import CustomScroll from "../../../components/template/CustomScroll";
import { typographyOptions } from "../../../constants";
import { screenRoutes } from "../../../constants/routeConstants";
import { getStoredImages } from "../../../helper";
import styles from "./destination.module.css";
import { PopularTripsDummy } from "../../../assests/exportImages";
import { colors } from "../../../themes/colors";

const CardsWithScroll = (props: any) => {
  const { heading, description, cards, loadingDestination, loading } = props;
  const containerRef = useRef(null);

  const navigate = useNavigate();

  const handleCardClick = (card: any) => {
    navigate(`/destination-detail/${card?.name}/${card?.place_id}`, {
      state: { card },
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#FAFAFA",
        cursor: "pointer",
        margin: "50px 0px",
      }}
    >
      <Box className={styles.mainPopularTags}>
        <CustomScroll
          containerRef={containerRef}
          cards={cards}
          header={
            <Container>
              <Stack>
                <Typography variant={typographyOptions.CARDS_HEADING}>
                  {heading}
                </Typography>
                <Typography variant={typographyOptions.CARDS_DESCRIPTION}>
                  {description}
                </Typography>
              </Stack>
            </Container>
          }
          children={
            <Box sx={{ display: "flex" }}>
              {loading ? (
                Array.from({ length: 5 }, (_, i) => <SkeletonCard key={i} />)
              ) : (
                <>
                  {cards?.map((data: any, index: number) => {
                    return (
                      <Card
                        key={data.id}
                        image={getStoredImages(
                          data?.photos?.[0]?.photo_reference
                        )}
                        data={data}
                        onClick={() => handleCardClick(data)}
                        loadingDestination={props.loadingDestination}
                      />
                    );
                  })}
                </>
              )}
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default CardsWithScroll;

const Card = ({ image, data, onClick, loadingDestination }: any) => {
  return (
    <Box className={styles.destinationCard} onClick={() => onClick(data.id)}>
      {loadingDestination ? (
        <Skeleton
          variant="rectangular"
          width={240}
          height={170}
          sx={{ borderRadius: "14px" }}
        />
      ) : (
        <img
          src={image?.config?.url || PopularTripsDummy}
          alt="trips"
          style={{
            borderRadius: "14px",
            minHeight: "200px",
            width: "240px",
            objectFit: "cover",
          }}
        />
      )}

      {loadingDestination ? (
        <Skeleton
          variant="rectangular"
          width={100}
          height={118}
          sx={{ borderRadius: "14px" }}
        />
      ) : (
        <Box className={styles.CardMain}>
          <Typography
            variant={typographyOptions.H2}
            color={colors.transparentWhite}
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
            }}
          >
            {data.name}
          </Typography>
          {data?.neighborhood || data?.locality ? (
            <Typography
              variant={typographyOptions.SUB_HEADING}
              color={colors.transparentWhite}
            >
              {data?.neighborhood ?? ""}
              {data?.neighborhood && data?.locality && `, ${data.locality}`}
              {(!data?.neighborhood && data?.locality) ?? ""}
            </Typography>
          ) : (
            <></>
          )}

          <Typography
            variant={typographyOptions.H1}
            sx={{
              textAlign: "left",
              color: colors.transparentWhite,
            }}
          >
            {data?.types}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
