import { useEffect, useState } from "react";
import { CustomButton } from "../../components/atom/CustomButton";
import { AlternateEmailOutlined, Cancel, Check, CheckCircleRounded, Circle, Close, FmdGoodOutlined, OpenInNew, PhoneOutlined, Star } from "@mui/icons-material";
import { Cross, Viator } from "../../assests/exportImages";
import { formatDuration, getRatingRemark } from "../../utils/helpers";
import DownloadAppCard from "../components/DownloadAppCard";
import SimilarExperience from "../components/SimilarExperience";
import { Divider, useMediaQuery } from "@mui/material";
import ViatorBookNow from "./ViatorBookNow";
import { CrossIcon, LucideCross } from "lucide-react";
import { viatorInitialProductBody, viatorSuggestionBody } from "../../config/apiBody";
import { useDispatch, useSelector } from "react-redux";
import { screenActionsCall } from "../../redux/actions";
import ThingsToDoSuggestionContainer from "./SuggestionCards/ThingsToDoSuggestionContainer";
import { RootState } from "../../redux/reducers";

const HotelOverviewPanel = ({ thingsToDoProductDetails, card }) => {

  const {  thingsToDoSimilarExperienceListLoader } = useSelector((state: RootState) => state.screenReducer);

  const { description,inclusions, exclusions, additionalInfo, productUrl } = thingsToDoProductDetails;
  const [isReadMore, setIsReadMore] = useState(false);
  const [durationText, setdurationText] = useState("")
  const [showMoreInEx, setShowMoreInEx] = useState(false);
  const [overviewLength, setoverviewLength] = useState(0);
  const [showMoreAdditionalInfo, setShowMoreAdditionalInfo] = useState(false);

  const toggleReadMore = () => {
    setIsReadMore((prevState) => !prevState);
  };

  const isMobile = useMediaQuery("(max-width:700px)");

  useEffect(() => {
    if(isMobile){
      setoverviewLength(130)
    }else{
      setoverviewLength(500)
    }
  }, [isMobile])

  const reviewScore = thingsToDoProductDetails?.reviews?.combinedAverageRating?.toFixed(1)
  const totalReviews = thingsToDoProductDetails?.reviews?.totalReviews
  const fixedDurationInMinutes = thingsToDoProductDetails?.itinerary?.duration?.fixedDurationInMinutes
  const variableDurationFromMinutes = thingsToDoProductDetails?.itinerary?.duration?.variableDurationFromMinutes
  const variableDurationToMinutes = thingsToDoProductDetails?.itinerary?.duration?.variableDurationToMinutes
  const supplier = thingsToDoProductDetails?.supplier?.name
  const includedItems = inclusions
  ?.map((item) => item.otherDescription)
  ?.filter(Boolean);
  const excludedItems = exclusions
  ?.map((item) => item.otherDescription)
  ?.filter(Boolean);
  const visibleCountInEx = showMoreInEx ? Math.max(includedItems?.length, excludedItems?.length) : 4;

    useEffect(() => {
      fixedDurationInMinutes && setdurationText(`About ${formatDuration(fixedDurationInMinutes)}`);
      variableDurationFromMinutes && setdurationText(`About ${formatDuration(variableDurationFromMinutes)} - ${formatDuration(variableDurationToMinutes)}`);
    }, [])

  

  return (
    <div className="w-full font-Mulish mb-10">
    <div className="relative flex md:flex-row flex-col-reverse ">
      <div className="leftPanel w-full ">
        {description && <div className="w-full border-b-2 border-[#F1F1F1] pb-5">
          <p className="text-sm font-medium text-[#666] md:max-w-[90%] mt-3 leading-[28px]">
            {description?.length > overviewLength
              ? (isReadMore ? description : description.slice(0, overviewLength) + "...")
              : description}

            {description?.length > overviewLength && (
              <span
                className="text-sm ml-1 font-bold text-[#333] underline cursor-pointer"
                onClick={toggleReadMore}
              >
                {isReadMore ? "Read Less" : "Read More"}
              </span>

            )}
          </p>
        </div>}
        <div className="flex flex-col w-full border-b-2 border-[#F1F1F1] py-5 gap-y-3">
          {durationText !=="" && <p className="text-sm font-bold text-[#000]">
            Duration:
            <span className="text-[#999] ml-1"> {durationText} </span>
          </p>}
          {supplier && <p className="text-sm font-bold text-[#000]">
            Tour by:
            <span className="text-[#999] ml-1"> {supplier} </span>
          </p>}
        </div>
        <div className="flex md:flex-row flex-col justify-between w-full py-5 border-b-2  border-[#F1F1F1] gap-x-3">
          {/* <div className="flex flex-col md:w-[50%] gap-y-6">
            <div className="flex flex-col gap-y-1">
              {<p className="text-sm font-bold text-[#000]">
                <FmdGoodOutlined className="!w-[18px] !h-[18px] mr-1 mb-1" />{" "}
                Address
              </p>}
              <p className="text-sm font-medium text-[#666] pl-6">
                {`${addressline1}, ${addressline2}, ${city}, ${state}, ${country}, ${zipcode}`}
              </p>
            </div>
            <div className="flex flex-col gap-y-1">
              <p className="text-sm font-bold text-[#000]">
                <PhoneOutlined className="!w-[18px] !h-[18px] mr-1 mb-1" />
                Phone
              </p>
              <p className="text-sm font-medium text-[#666] pl-6">+65 6670 9750</p>
            </div>
          </div> */}
          <div className="flex flex-col md:w-[50%] gap-y-6 mt-5 md:mt-0">
            {productUrl && <div className="flex flex-col gap-y-1">
              <p className="text-sm font-bold text-[#000]">
                <AlternateEmailOutlined className="!w-[18px] !h-[18px] mr-1 mb-1" />
                Website
              </p>
              <a href={productUrl} target="_blank"><p className="text-sm font-medium text-[#666] pl-6 underline">{productUrl?.slice(0, 44) + "..."}</p></a>
            </div>}
            {/* <div className="flex flex-col gap-y-1">
              <p className="text-sm font-bold text-[#000]">
                <PhoneOutlined className="!w-[18px] !h-[18px] mr-1 mb-1" />
                Working Hours
              </p>
              <p className="text-sm font-bold text-[#F05D5D] pl-6">Closed</p>
              <div className="flex flex-row gap-x-5 justify-start pl-6">
                <div className="flex flex-col gap-y-1">
                  <p className="text-sm font-medium text-[#666]">Mon</p>
                  <p className="text-sm font-medium text-[#666]">Tue</p>
                  <p className="text-sm font-medium text-[#666]">Wed</p>
                  <p className="text-sm font-medium text-[#666]">Thu</p>
                  <p className="text-sm font-medium text-[#666]">Fri</p>
                  <p className="text-sm font-medium text-[#666]">Sat</p>
                  <p className="text-sm font-medium text-[#666]">Sun</p>
                </div>
                <div className="flex flex-col gap-y-1">
                  <p className="text-sm font-medium text-[#666]">Closed</p>
                  <p className="text-sm font-medium text-[#666]">Closed</p>
                  <p className="text-sm font-medium text-[#666]">9 AM – 6 PM</p>
                  <p className="text-sm font-medium text-[#666]">9 AM – 6 PM</p>
                  <p className="text-sm font-medium text-[#666]">9 AM – 6 PM</p>
                  <p className="text-sm font-medium text-[#666]">9 AM – 6 PM</p>
                  <p className="text-sm font-medium text-[#666]">9 AM – 6 PM</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {(includedItems || excludedItems) && <div className="flex flex-col w-full border-b-2 border-[#F1F1F1] py-5 gap-y-3">
    <p className="text-base font-bold text-[#000] mb-1">What’s Included</p>
    <div className="flex flex-col w-full">
    <div className="flex md:flex-row flex-col w-full justify-between">
      <div className="flex flex-col md:w-[40%] w-full gap-y-3 ">
        {includedItems?.slice(0, visibleCountInEx)?.map((item, index) => (
          <p key={index} className="font-Mulish text-xs font-medium text-[#666] !mb-1 flex items-center">
            <Check className="!w-[16px] !h-[16px] !text-[#20B08F] !mr-3" />
            {item}
          </p>
        ))}
      </div>

      <div className="flex flex-col md:w-[40%] w-full mt-3 md:mt-0 gap-y-3">
        {excludedItems?.slice(0, visibleCountInEx)?.map((item, index) => (
          <p key={index} className="font-Mulish text-xs font-medium text-[#666] !mb-1 flex items-center">
            <Close className="!w-[16px] !h-[16px] !text-[#F05D5D] !mr-3" />
            {item}
          </p>
        ))}
      </div>
    </div>

    {(includedItems?.length > 4 || excludedItems?.length > 4) && (
      <button
        onClick={() => setShowMoreInEx(!showMoreInEx)}
        className="mt-2 text-sm ml-1 font-bold text-[#333] underline cursor-pointer self-start"
      >
        {showMoreInEx ? "Read Less" : "Read More"}
      </button>
    )}
  </div>
  </div>}
        {additionalInfo && <div className="flex flex-col w-full border-b-2 border-[#F1F1F1] py-5 gap-y-3">
          <p className="text-base font-bold text-[#000] mb-1">
            Additional Info
          </p>
          <div className="flex flex-row justify-start w-full gap-x-3">
            <div className="flex flex-col w-full md:w-[50%] gap-y-2">
              {additionalInfo
                ?.slice(0, showMoreAdditionalInfo ? additionalInfo.length : 5)
                ?.map((info, index) => (
                  <div className="flex items-start">
                  <Circle className="!w-[6px] !h-[6px] !text-[#000] !mr-3 !mt-[6px]" />
                  <p
                    key={index}
                    className="font-Mulish text-xs font-medium text-[#666] !mb-1 content-center"
                  >
                    
                    {info?.description}
                  </p>
                  </div>
                ))}
              <p
        className="text-sm font-bold text-[#333] underline cursor-pointer"
        onClick={() =>
                  setShowMoreAdditionalInfo((prev) => !prev)}
              >
                {showMoreAdditionalInfo ? "Read Less" : "Read More"}
              </p>
            </div>
          </div>
        </div>}

      </div>
      <div className="hidden rightPanel md:flex justify-between w-[400px] px-5">
          <ViatorBookNow thingsToDoProductDetails={thingsToDoProductDetails} card={card} />
      </div>

    </div>
    {/* <div className="my-[60px]">
    <DownloadAppCard />
    </div> */}
    <div className="flex flex-col w-full py-5 gap-y-3">
          <p className="text-base font-bold text-[#000] mb-5">Reviews</p>
          <div className="flex flex-row justify-start w-full mb-5 gap-x-2">
            {/* <p className="font-bold text-[42px] text-[#000] leading-9">{rating}</p> */}
            <div className="flex flex-col justify-start">
              <div className="flex flex-row justify-start w-full mb-5 gap-x-2">
                {reviewScore > 0 && (
                  <>
                    <p className="font-bold text-[42px] text-[#000] leading-9">{reviewScore}</p>
                    <div className="flex flex-col justify-start">
                      {getRatingRemark(reviewScore) && (
                        <p className="text-sm font-medium">{getRatingRemark(reviewScore)}</p>
                      )}
                      <p className="text-xs font-medium">
                        <Star className="mr-1 !w-3 !h-3" />
                        {totalReviews} reviews
                      </p>
                    </div>
                  </>
                )}
              </div>
              {/* <p className="text-xs font-medium">
                <Star className="mr-1 !w-3 !h-3" />
                {numberOfReviews} reviews
              </p> */}
            </div>  
          </div>
          <a href={productUrl} target="_blank">
            <div className="w-[162px] h-[76px] border cursor-pointer gap-y-1 flex flex-col justify-center items-center rounded-2xl">
              <div className="flex flex-row gap-x-1 items-center">
                <img src={Viator} alt="Viator" className="!h-auto !w-[71px]" />
                <OpenInNew className="!w-4 !h-4" />
              </div>
              <div className="flex flex-row justify-between gap-x-2">
                <p className="text-xs font-bold text-[#333]">{reviewScore}/5</p>
                <p className="text-xs font-bold text-[#ccc]">{totalReviews} reviews</p>
              </div>
            </div>
          </a>
        </div>

        <div className="bg-[#F1F1F1] h-[1px] w-full mt-8 mb-3"></div>

        <ThingsToDoSuggestionContainer type={"SIMILAR_EXPERIENCE"} /> 

    </div>
  );
};

export default HotelOverviewPanel;
