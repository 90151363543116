import { Box, Divider, Stack, Typography } from '@mui/material'
import en from '../../../locale/en.json';
import { typographyOptions } from '../../../constants'
import { colors } from '../../../themes/colors';
import { Toggle } from '../../../components/atom/Toggle';
import { ArrowBack } from '../../../assests/exportImages';

interface ProfileProps {
    handleBack?: () => void;
    isMobile?: boolean;
  }

const Notifications = ({handleBack, isMobile}) => {
    return (
        <Box px={2} >
                        {isMobile && <img src={ArrowBack} alt="close icon" onClick={handleBack} />}

        <Box width={isMobile ? '100%' : '75%'} mt={2}>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Box>
                <Typography variant={typographyOptions.H1} paddingLeft="10px" mb={1}>
                    {en.pushNotifications}
                </Typography>
                <Typography variant={typographyOptions.H1} color={colors.secondaryGrey} paddingLeft="10px">
                    {en.pushNotificationsDescription}
                </Typography>
                </Box>
                <Toggle checked={true} onChange={()=>{}} activeColor={colors.ThemeColor}   />
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box>
                    <Typography variant={typographyOptions.H1} paddingLeft="10px" mb={1}>   
                        {en.emailNotifications}
                    </Typography>
                    <Typography variant={typographyOptions.H1} color={colors.secondaryGrey} paddingLeft="10px">
                        {en.emailNotificationsDescription}
                    </Typography>
                    </Box>
                    <Toggle checked={true} onChange={()=>{}} activeColor={colors.ThemeColor}/>
                </Box>
            </Box>
        </Box>
        </Box>
    )
}

export default Notifications