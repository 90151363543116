import React, { useState } from 'react';
import { 
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  Stack,
  Divider
} from '@mui/material';
import { typographyOptions } from '../../../constants';
import { Close } from '../../../assests/exportImages';
import en from '../../../locale/en.json';
import { colors } from '../../../themes/colors';
import Input from '../../../components/atom/Input';
import { CustomButton } from '../../../components/atom/CustomButton';
import { BorderColor } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser } from '../../../redux/actions/authActions';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 6,
  boxShadow: 24,
  p: 4,
};

function DeleteAccount({open,handleClose}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {loading} = useSelector((state: any) => state.authReducer);
  const handleDelete = () => {
    dispatch(deleteUser())
      localStorage.removeItem("Login_Token");
      navigate(`/`);
      toast.success("User Deleted successfully");
      handleClose();
  };

  return (
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={modalStyle}>
          <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant={typographyOptions.H1} fontSize={18} flex={1} textAlign={'center'}>
            {en.deleteAccount}
          </Typography>
          <img src={Close} alt="close icon" onClick={handleClose} />
          </Box>
          
          <Divider sx={{my:2}}/>
        
          <Typography variant={typographyOptions.BODY1} color={colors.primaryGrey} fontSize={15} textAlign={'center'} px={4} py={2}>
            {en.deleteAccountDescription}
              </Typography>

          {/* <Stack gap="10px" mt={2}>
            <Stack display={'flex'} flexDirection={'row'}>
              <Typography variant={typographyOptions.H1} color={colors.primaryGrey} paddingLeft="10px">
                {en.reason}
              </Typography>
              <Typography variant={typographyOptions.SUB_HEADING} paddingLeft="10px">
                ({en.required})
              </Typography>
              </Stack>
              <Input
                placeholder={en.deleteReasonPlaceholder}
                maxLength={500}
                showLength
                customStyle={{ minHeight: '20vh',height: '20vh', maxHeight: '20vh', overflow: 'auto', display: 'flex', alignItems: 'flex-start', fontSize: '15px' }}

                multiline
                maxWidth='100%'
              />
            </Stack> */}
            <Divider sx={{my:2}}/>
          <Stack direction="row" spacing={2} justifyContent="space-between">
          <CustomButton
                title={en.cancel}
                customStyle={{ borderRadius: '20px', backgroundColor: colors.primaryWhite, marginY: '10px', border:"1px solid black" }}
                customTypography={{ color: colors.secondaryBlack }}
                onClick={handleClose}
            />
            <CustomButton
                onClick={handleDelete}
                title={en.deleteAccount}
                customStyle={{ borderRadius: '20px', backgroundColor: colors.tertiaryRed, marginY: '10px' }}
                customTypography={{ color: colors.primaryWhite }}
            />
          </Stack>
        </Box>
      </Modal>
  );
}

export default DeleteAccount;