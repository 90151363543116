import config from "./config.json";

const configEnv = config;
const env = "production";

export const BASE_URL = configEnv[env]["BASE_URL"];

export const mapToKenProd = configEnv[env]["MAP_TOKEN"];
export const googleMapApiKey = "AIzaSyAqbQxY46nE2ttj_3-mHFRcgoXf9KoZlz0";

export const clientID =
  "347667917266-u4ilrig6mm1om3rmr3m5trqgktt82tjh.apps.googleusercontent.com";

export const getApiUrls = (id = "") => {
  return {
    publishedTrips: "publishedTrips",
    getImages: `maps/photo?reference=${id}&maxWidth=700`,
    getTrendingDestinationsList: "ai/suggestionsInAutocomplete",

    getFeaturedUsers: "users/featuredUsers",
    getItineraryDetails: `trip/${id}/items`,
    getFeaturedUsersDetails: `users/userDetailById/${id}`,
    getFeaturedUserPublishedTrips: `publishedTripByUserId?userId=${id}`,
    getSearchedItinerary: `new/searchTrip?search=${id}`,
    getSearchedDestinations: `maps/autocomplete`,
    getPopularTrips: `new/itinerariesSortedByLikesDesc`,
    getWeekendGateways: `new/shortItinerariesSortedByLikesDesc`,
    getLongTrips: `new/longItinerariesSortedByLikesDesc`,
    getSortedItinerary: `new/filteredTripsSearch`,
    getPlaceDetails: `maps/place-details`,
    getCoordinates: `maps/place-details`,
    searchUsers: `/users/search?search=${id}`,
    getMyTrips:`trips`,
    getViatorTags:'viator/search-tags?tagName',

    //hotel apis
    getCitySearch: `agoda/city-search`,
    searchHotelAndCity:`agoda/searchHotelAndCity?searchQuery=${id}`,
    getHotelDetails:`agoda/hotelDetail/${id}`,
    getHotelByIds:`agoda/hotels-by-ids`,

    //currentLocation
    getCurrentLocation: `https://nominatim.openstreetmap.org/reverse?lat=latitude&lon=longitude&format=json`,

    //things to do apis
    getThingsToDoProductSearch: `viator/productSearch`,
    getThingsToDoLocation: `viator/destination-name/${id}`,
    getThingsToDoActivitySearch: `viator/search-tags?tagName=${id}`,
    getThingsToDoDestinationList: `viator/search-destinations?destinationName=${id}`,
    getThingsToDoFreeTextSearch: `viator/freeTextSearch`,
    getThingsToDoDestination: `viator/destination-name/${id}`,
    getThingsToDoProductDetails: `viator/productDetail/${id}`,

    // feedback api
    feedback: `feedback/registered`,

    // auth apis
    userSignIn: `users/signin`,
    userSignUp: `users/`,
    userSignUpGoogle: `users/oAuthToken`,
    emailExistsorNot: "users/email-exists",
    fetchUserData: "https://www.googleapis.com/oauth2/v3/userinfo",
    verifyReset: "users/verify-reset",
    checkUserNameExists: `users/isUserNameExists?query=${id}`,
    resentVerification: "users/resend-verification-code",
    initiateResetPassword: "users/initiate-reset",
    confirmResetPassword: "users/confirm-reset",
    verifyAccountEmail: "users/verify-account",
    getUserDetails: "users/",
    updateUserDetails: "users/userDetails",
    deleteUser: "users/",
    changePasword: "users/password"
  };
};
