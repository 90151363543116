import { Box, Divider, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { typographyOptions } from '../../../constants';
import Input from '../../../components/atom/Input';
import { CustomButton } from '../../../components/atom/CustomButton';
import { colors } from '../../../themes/colors';
import en from '../../../locale/en.json';
import { Close } from '../../../assests/exportImages';
import { checkWhiteSpace, validatePassword } from '../../../utils/helpers';
import { NewLineKind } from 'typescript';
import { useDispatch, useSelector } from 'react-redux';
import { changePasword } from '../../../redux/actions/authActions';
import { RootState } from '../../../redux/reducers';
import { toast } from 'react-toastify';
import { authAction } from '../../../redux/actions/actionTypes';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '25%',
  bgcolor: 'background.paper',
  borderRadius: 6,
  boxShadow: 24,
  p: 4,
  px:5
};



const ChangePassword = ({open,handleClose}) => {
  const dispatch = useDispatch();
  const { failureMessage, loading, isPassChanged } = useSelector(
    (state: RootState) => state.authReducer
  );

    const [oldPass, setoldPass] = useState("")
    const [newPass, setnewPass] = useState("")
    const [confirmPass, setconfirmPass] = useState("")
      const [errors, setErrors] = useState({
        oldPass: "",
        newPass: "",
        confirmPass: ""
      });

    const handleChangePass = () => {
      setErrors({
        oldPass: "",
        newPass: "",
        confirmPass: "",
      });
      if (oldPass === "" || newPass === "" || confirmPass === "") {
        if (oldPass === "") {
          setErrors((prevState: any) => ({
            ...prevState,
            oldPass: "Please enter your old password.",
          }));
        } 
        if (newPass === "") {
          setErrors((prevState: any) => ({
            ...prevState,
            newPass: "Please enter a new password.",
          }));
        } 
        if (confirmPass === "") {
          setErrors((prevState: any) => ({
            ...prevState,
            confirmPass: "Please confirm your new password.",
          }));
        }
        return;
      }

      const newPassCheck = validatePassword(newPass);
      if (!newPassCheck.status) {
        setErrors((prevState: any) => ({
          ...prevState,
          newPass: newPassCheck.error,
        }));
        return;
      }

      const confirmPassCheck = newPass === confirmPass
      if (!confirmPassCheck) {
        setErrors((prevState: any) => ({
          ...prevState,
          confirmPass: "New Password and Confirm Password do not match."
        }));
        return;
      }
      
      const payload = {
        password:oldPass,
        newPassword:newPass
      }

      dispatch(changePasword(payload))
    };

    useEffect(() => {
      if (!loading && isPassChanged ) {
        dispatch({ type: authAction.RESET_CHANGE_PASS })
        handleClose()
      }
    }, [loading, isPassChanged]);
    
    return (
        <Modal
          open={open}
          onClose={handleClose}
        >
          <Box sx={modalStyle}>
            <Box display={'flex'} justifyContent={'space-between'}>
            <Typography variant={typographyOptions.H1} fontSize={18} flex={1} textAlign={'center'}>
              {en.resetPassword}
            </Typography>
            <img src={Close} alt="close icon" onClick={handleClose} />
            </Box>
            
            <Divider sx={{my:2}}/>
          
            <Stack gap="20px">
            <Stack gap="10px">
            <Typography
              variant={typographyOptions.H1}
              color={colors.primaryGrey}
              paddingLeft="10px"
            >
              {en.oldPassword}
            </Typography>
            <Input
            maxWidth='100%'
            placeholder={en.oldPassword}
              passwordInput={true}
              value={oldPass}
              onChange={(e: any) => {
                checkWhiteSpace(e.target.value) &&
                  setoldPass(e.target.value);
              }}
              errormessage={errors?.oldPass || failureMessage}
            />
          </Stack>
          <Stack gap="10px">
            <Typography
              variant={typographyOptions.H1}
              color={colors.primaryGrey}
              paddingLeft="10px"
            >
              {en.newPassword}
            </Typography>
            <Input
            maxWidth='100%'
            placeholder={en.eightPlusCharacters}
              passwordInput={true}
              value={newPass}
              onChange={(e: any) => {
                checkWhiteSpace(e.target.value) &&
                  setnewPass(e.target.value);
              }}
              errormessage={errors?.newPass}
            />
          </Stack>
          <Stack gap="10px">
            <Typography
              variant={typographyOptions.H1}
              color={colors.primaryGrey}
              paddingLeft="10px"
            >
              {en.reEnterPassword}
            </Typography>
            <Input
            maxWidth='100%'
            placeholder={en.reEnterPassword}
              passwordInput={true}
              value={confirmPass}
              onChange={(e: any) => {
                checkWhiteSpace(e.target.value) &&
                  setconfirmPass(e.target.value);
              }}
              errormessage={errors?.confirmPass}
            />
          </Stack>
          </Stack>
          <Divider sx={{my:2}}/>
            <Stack direction="row" spacing={2} justifyContent="space-between">
            <CustomButton
                  title={en.cancel}
                  customStyle={{ borderRadius: '20px', backgroundColor: colors.primaryWhite, marginY: '10px', border:"1px solid black" }}
                  customTypography={{ color: colors.secondaryBlack }}
                  onClick={handleClose}
              />
              <CustomButton
              onClick={handleChangePass}
                  title={en.changePassword}
                  customStyle={{ borderRadius: '20px',backgroundColor: colors.secondaryBlack, marginY: '10px' }}
                  customTypography={{ color: colors.primaryWhite }}
                  loading={loading}
              />
            </Stack>
          </Box>
        </Modal>
    );
}

export default ChangePassword