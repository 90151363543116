import { Avatar, Box, Button, Divider, Stack, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { CustomButton } from '../../../components/atom/CustomButton';
import { colors } from '../../../themes/colors';
import Input from '../../../components/atom/Input';
import en from '../../../locale/en.json';
import { SettingComponentIcons, typographyOptions } from '../../../constants';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { googleMapApiKey } from '../../../config/apiurls';
import Styles from '../../Auth/index.module.css';
import ChangePassword from './ChangePassword';
import { ArrowBack } from '../../../assests/exportImages';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { getFullName, getImagefromBase64, getInitials } from '../../../utils/helpers';
import { screenActionsCall } from '../../../redux/actions';

interface ProfileProps {
  handleBack?: () => void;
  isMobile?: boolean;
}




const Profile: React.FC<ProfileProps> = ({ handleBack, isMobile }) => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState<{ formattedAddress?: string }>({});
  const { userDetails } = useSelector(
    (state: RootState) => state.screenReducer
  );



  const [name, setname] = useState<string>(userDetails?.name)
  const [about, setAbout] = useState<string>(userDetails?.about);
  const [username, setUsername] = useState<string>(userDetails?.username);
  const [location, setLocation] = useState<string>();
  const [locationInput, setlocationInput] = useState(userDetails?.location)
  const [errors, setErrors] = useState({
    name: "",
    username: "",
    bio: "",
    location: ""
  });
  const [openChangePasswordModal, setopenChangePasswordModal] = useState(false);
  const handleOpenChangePasswordModal = () => setopenChangePasswordModal(true);
  const handleCloseChangePasswordModal = () => setopenChangePasswordModal(false);

  const avatar = getInitials(
    getFullName(userDetails?.name ? userDetails?.name : "Escapekar")
  );


  const fileInputRef = useRef(null);

  const handleChangePicture = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = ['image/jpeg', 'image/png'];
      if (!validTypes.includes(file.type)) {
        alert('Only JPG and PNG files are allowed.');
        fileInputRef.current.value = "";
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === "string") {
          const userImageAsBase64 = reader.result.split(",")[1];
          dispatch(
            screenActionsCall.updateUserDetails({
              userImage: userImageAsBase64,
            })
          );
        } else {
          console.error("reader.result is not a string");
        }
      };


    }
  };

  const handleDeletePicture = () => {
    dispatch(
      screenActionsCall.updateUserDetails({
        userImage: ""
      })
    );
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };



  const handleSaveChanges = () => {
    let newErrors = { ...errors };

    if (username === "" || username == null) {
      newErrors.username = "Username is required";
      setErrors(newErrors);
    } else {

      dispatch(
        screenActionsCall.updateUserDetails({
          name,
          username,
          about,
          location
        })
      );
      setErrors({
        name: "",
        username: "",
        bio: "",
        location: ""
      });
    }
  }

  return (
    <Box flex={1} px={2}>
      {openChangePasswordModal && <ChangePassword open={openChangePasswordModal} handleClose={handleCloseChangePasswordModal} />}
      {isMobile && <img src={ArrowBack} alt="close icon" onClick={handleBack} />}
      <Box width={isMobile ? '100%' : '75%'} mt={2} >
        <Box display="flex" alignItems="center" gap={2} mb={4}>
          {userDetails?.userImageAsBase64 === "" ? (
            <Avatar
              sx={{
                backgroundColor: " #20b08f",
                color: "white",
                width: 100,
                height: 100,
                borderRadius: "50%",
                fontSize: 40,
                fontWeight: "bold",
              }}
            >
              {avatar}
            </Avatar>
          ) : (
            <img
              src={getImagefromBase64(
                userDetails?.userImageAsBase64
              )}
              style={{
                borderRadius: "100%",
                objectFit: "cover",
                width: 100,
                height: 100,
              }}
            />
          )}
          {/* <Avatar
            src="https://images.unsplash.com/photo-1560807707-8cc77767d783"
            sx={{ width: 100, height: 100 }}
          /> */}
          <Box display={'flex'} alignItems={'center'}>
            <CustomButton
              onClick={handleChangePicture}
              title={en.changePicture}
              border={`1px solid ${colors.quatenaryGrey}`}
              customStyle={{ borderRadius: '20px', backgroundColor: colors.primaryWhite, marginX: '10px' }}
              customTypography={{ color: colors.black }}
            />
            <input
              ref={fileInputRef}
              type='file'
              accept='image/jpeg, image/png'
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <Button onClick={handleDeletePicture} sx={{ border: 1, py: 1.2, minWidth: 42, borderRadius: '50%', borderColor: colors.quatenaryGrey }}>
              <img
                src={SettingComponentIcons['Delete']}
                alt='Delete icon'
                style={{ width: '20px', height: '20px' }}
              />
            </Button>
          </Box>
        </Box>

        <Box component="form" noValidate autoComplete="off">
          <Stack gap="20px">
            <Stack gap="10px">
              <Typography variant={typographyOptions.H1} color={colors.primaryGrey} paddingLeft="10px">
                {en.fullName}
              </Typography>
              <Input errormessage={errors?.name} onChange={(e) => setname(e.target.value)} value={name} maxWidth='100%' customStyle={{ fontSize: '15px' }} />
            </Stack>
            <Stack gap="10px">
              <Typography variant={typographyOptions.H1} color={colors.primaryGrey} paddingLeft="10px">
                {en.userName}
              </Typography>
              <Input value={username} errormessage={errors?.username} onChange={(e) => setUsername(e.target.value)} maxWidth='100%' customStyle={{ fontSize: '15px' }} />
            </Stack>
            <Stack gap="10px">
              <Typography variant={typographyOptions.H1} color={colors.primaryGrey} paddingLeft="10px">
                {en.location}
              </Typography>
              <ReactGoogleAutocomplete
                apiKey={googleMapApiKey}
                className={Styles.autoCompleteInput}
                options={{
                  types: ["(regions)"],
                }}
                placeholder={"Select Location"}
                value={locationInput}
                onChange={(e:any)=>setlocationInput(e.target.value)}
                onPlaceSelected={(place: any) => {
                  if (place && place.formatted_address) {
                    setLocation(place.formatted_address);
                    setlocationInput(place.formatted_address)
                  }
                }}
              />
            </Stack>
            <Stack gap="10px">
              <Typography variant={typographyOptions.H1} color={colors.primaryGrey} paddingLeft="10px">
                {en.bio}
              </Typography>
              <Input
                onChange={(e) => setAbout(e.target.value)}
                placeholder={en.bioPlaceholder}
                maxLength={500}
                showLength
                customStyle={{ minHeight: '20vh', height: '20vh', maxHeight: '20vh', overflow: 'auto', display: 'flex', alignItems: 'flex-start', fontSize: '15px' }}
                value={about}
                multiline
                maxWidth='100%'
              />
            </Stack>
          </Stack>
          <Box width='100%' display={'flex'} alignItems={'flex-end'} flexDirection={'column'}>
            <CustomButton
              onClick={handleSaveChanges}
              title={en.saveChanges}
              customStyle={{ borderRadius: '20px', backgroundColor: colors.secondaryBlack, marginY: '10px' }}
              customTypography={{ color: colors.primaryWhite }}
            />
          </Box>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Box>
          <Typography variant={typographyOptions.AUTH_HEADING} fontSize={16}>
            {en.email}
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant={typographyOptions.HEADING}>{userDetails?.email}</Typography>
            <Typography variant={typographyOptions.HEADING} color={colors.ThemeColor}>
              {'(Verified)'}
            </Typography>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Typography variant={typographyOptions.AUTH_HEADING} fontSize={16}>
            {en.password}
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="space-between" mt={-1}>
            <Typography variant={typographyOptions.SUB_HEADING} fontSize={16}>
              Change your password at any time
            </Typography>
            <CustomButton
              title={en.changePassword}
              border={`1px solid ${colors.quatenaryGrey}`}
              customStyle={{ borderRadius: '20px', backgroundColor: colors.primaryWhite, marginX: '10px' }}
              customTypography={{ color: colors.black }}
              onClick={handleOpenChangePasswordModal}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
