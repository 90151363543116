import { Box, Container, Stack, Typography } from "@mui/material";
import { AndriodDownload, AppleDownload } from "../atom/DownloadAppIcons";
import styles from "../components.module.css";
import en from "../../locale/en.json";
import { AppDetailsPic, SmallQR } from "../../assests/exportImages";
import { typographyOptions } from "../../constants";
import { colors } from "../../themes/colors";
import { useState } from "react";
import { QRModal } from "./QRModal";

export const PlanYourNextTrip = (props: any) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { sx } = props;
  return (
    <div
    style={{background: "linear-gradient(180deg, rgba(241, 247, 254, 0) 0%, rgba(233, 244, 241, 0.5) 50%, rgba(246, 253, 251, 0) 100%)"}}
     className="flex flex-col items-center  px-8 py-12 w-screen bg-gradient-to-r from-[#F1F7FE] via-[#E9F4F1] to-[#F6FDFB] text-center md:text-left">
      <div className=" flex justify-center  items-center   ">
        <Box className={styles?.GetAppContainer} sx={sx}>
          <Box>
            <img src={AppDetailsPic} alt="App" />
          </Box>
          <Box className={styles.contentGetApp}>
            <h2 className="font-Mulish md:text-[52px] text-[32px] font-bold text-darkGray">
              {`${en.planYourNextTripWith} `}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#5BDBBE] to-[#20B08F]">
                {en.escapekar}
              </span>
            </h2>
            <Typography
              variant={typographyOptions.H2}
              color={colors.secondaryGrey}
              sx={{ lineHeight: '26px' }}
            >
              {en.YourTravelBuddyText}
            </Typography>
            <div className="hidden md:flex flex-row items-center gap-[10px]">
              <AndriodDownload />
              <AppleDownload />
              <div className="hidden lg:flex">
                <img
                  src={SmallQR}
                  alt="QR"
                  onClick={handleOpen}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <QRModal open={open} handleClose={handleClose} />
          </Box>
        </Box>

      </div>
      <div className="md:hidden flex flex-row items-center gap-[10px]">
        <AndriodDownload />
        <AppleDownload />
        <div className="hidden lg:flex">
          <img
            src={SmallQR}
            alt="QR"
            onClick={handleOpen}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      <QRModal open={open} handleClose={handleClose} />
    </div>
  );
};
