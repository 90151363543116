import React, { useState } from "react";
import { CustomButton } from "../atom/CustomButton";
import { DiscountTag, GreenTick, Location, PopularTripsDummy, StarIcon, TickCirlce } from "../../assests/exportImages";
import EChip from "../atom/EChip";
import { Check, Home } from "@mui/icons-material";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { calculateDiscount, convertRatingToFiveScale, isNullOrEmpty } from "../../utils/helpers";
import Star from "../../assests/svgsComponent/Star";

interface IHotel {
  hotelId: number;
  hotelName: string;
  starRating: number;
  reviewScore: number;
  reviewCount: number;
  currency: string;
  dailyRate: number;
  crossedOutRate: number;
  discountPercentage: number;
  imageURL: string;
  landingURL: string;
  includeBreakfast: boolean;
  freeWifi: boolean;
}

interface ICurrentLocation {
  city: string;
  country: string;
}

interface IHotelCard {
  onClick: () => void;
  item: IHotel;
  loadingAllImages?: boolean;
  currentLocation?: ICurrentLocation
}

export const HotelCard = (props: IHotelCard) => {
  const [showexpanded, setShowExpanded] = useState(false);
  const { onClick, item, currentLocation } = props;

  const discount = calculateDiscount(item.dailyRate, item.crossedOutRate);
  const reviewScore = convertRatingToFiveScale(item?.reviewScore);

  return (
    <>
      <div className="hidden font-Mulish md:flex flex-col w-full h-auto bg-[#e9eaf2] rounded-[14px] cursor-pointer">
        <div
          onClick={onClick}
          className="card flex flex-row h-[262px] w-full rounded-[10px] justify-center items-center shadow-sm bg-white"
        >
          <div className=" relative image w-[320px] h-[250px] rounded-[10px] pl-1">
            <img
              src={item.imageURL || PopularTripsDummy}
              alt={item.hotelName}
              className="rounded-[10px] bg-cover !h-[250px]"
              width="320px"
              height="250px"
            />
            {discount > 0 ? <div className="absolute  top-[6%]"> <img src={DiscountTag} alt="DiscountTag" className="h-[23px]" /> <p className="relative -top-5 left-2 text-white font-Mulish font-bold text-[12px]">{discount}% off</p> </div> : null}
          </div>
          <div className="image w-[calc(100%-320px)] h-full justify-between flex flex-col px-5">
            <div className="flex flex-col w-full pt-3">
              <EChip
                type="gray"
                icon={<Home className="!h-3 !fill-[#ccc]" />}
                label={`${item.starRating} Star Hotel`}
                customStyle={{
                  fontWeight: '700', '& .MuiChip-icon': {
                    marginLeft: '4px',
                    marginRight: '-16px'
                  }
                }}
              />
              <h2 className="text-lg font-bold text-[#333] my-1">
                {item.hotelName}
              </h2>
              {/* {(currentLocation?.city && currentLocation?.country) && <div className="flex gap-2 items-center my-2 ml-0.5"><img src={Location} alt="Green Tick icon" className="h-4"/><p className="text-sm font-medium text-[#999]">{currentLocation?.city}</p></div>} */}
              {item.includeBreakfast && <div className="flex gap-2 items-center"><img src={GreenTick} alt="Green Tick icon" className="h-5" /><p className="text-sm font-medium text-[#999]">Breakfast Included</p></div>}

            </div>
            <div className="flex flex-row w-full h-[74px] border-t border-t-[#F1F1F1] justify-between items-center">
              { (!isNullOrEmpty(item.reviewScore) && !isNullOrEmpty(item.reviewCount)) && <div className="flex gap-[6px] items-center justify-center"><img src={StarIcon} alt="Star icon" className="h-3" /> <p className="text-lg font-bold text-[#999]">{convertRatingToFiveScale(item.reviewScore)} {!isNullOrEmpty(item.reviewCount) && `(${item.reviewCount})`}</p></div>}
              <div className="flex flex-row justify-end items-center">
                <div className="flex flex-col mr-3">
                  {discount == 0 && (<p className="text-sm font-normal text-[#999] text-right">
                    Per night
                  </p>)}
                  {discount > 0 && (
                    <p className="text-sm font-normal text-[#999] line-through text-right">
                      {item.currency === 'USD' ? '$' : item.currency === 'INR' ? '₹' : item.currency}
                      {item.crossedOutRate}
                    </p>
                  )}
                  <p className={`text-xl font-bold ${discount > 0 ? `text-[#F05D5D]` : `text-[#121212]`} text-right`}>
                    {item.currency === 'USD' ? '$' : item.currency === 'INR' ? '₹' : item.currency}
                    {item.dailyRate}
                    {/* {discount > 0 && (
                    <span className="ml-2 text-sm font-medium text-green-600">
                      {discount}% off
                    </span>
                  )} */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div onClick={onClick} className="md:hidden block h-auto md:h-[372px] w-full bg-white rounded-[21px] p-1 pb-[9px] cursor-pointer font-Mulish">
        <div className="relative mb-[10px]">
          <img src={item?.imageURL || PopularTripsDummy} alt={item?.hotelName} className="w-full md:h-[200px] h-[160px] object-cover rounded-[21px]" />
          {discount > 0 && (
            <div className="absolute top-[12%]">
              <img src={DiscountTag} alt="DiscountTag" className="h-[23px]" />
              <p className="relative -top-5 left-2 text-white font-Mulish font-bold text-[12px]">
                {discount}% off
              </p>
            </div>
          )}
        </div>
        <div className="px-2">
          <EChip
            icon={
              <Home
                sx={{
                  color: "#cccccc",
                  fontSize: "12px",
                  "&:hover": {
                    color: "#666666",
                  },
                }}
              />
            }
            type="gray"
            customStyle={{ fontWeight: "700", color: "#cccccc", marginBottom: "8px" }}
            label="Hotel"
          />
          <p className="font-bold text-[14px] leading-[100%] text-[#333333]">
            {item?.hotelName?.split(" ").slice(0, 10).join(" ")}
          </p>


          <div className="my-2 flex items-center gap-1">
            <div className="flex gap-0.5">

              {[...Array(5)].map((_, index) => {
                const fillPercentage = Math.max(0, Math.min(100, (reviewScore - index) * 100));

                return <Star key={index} fill={fillPercentage} />;
              })}

            </div>

            <p className="font-bold text-[14px] text-[#999999]">{item?.reviewCount}</p>
          </div>

          <div className="flex items-center justify-end">
            {/* <div className="flex gap-2 items-center ml-0.5">
      <img src={Location} alt="Location" className="h-[14px]" />
      <p className="text-[12px] font-medium text-[#999]">{city}</p>
    </div> */}
            {item?.crossedOutRate > 0 && (
              <p className="text-[12px] font-normal text-[#999] line-through text-right">
                ₹{item?.crossedOutRate}
              </p>
            )}
          </div>

          <div className="flex items-center justify-between">
            {item?.includeBreakfast && (
              <div className="flex gap-2 items-center ml-[1px]">
                <img src={TickCirlce} alt="Tick Circle" className="h-[15px]" />
                <p className="text-[12px] font-medium text-[#999]">Breakfast Included</p>
              </div>
            )}
            <p className={`text-[16px] font-bold ${discount > 0 ? `text-[#F05D5D]` : `text-[#121212]`} ml-auto`}>
              ₹{item?.dailyRate}
            </p>
          </div>
        </div>
      </div></>
  );
};
