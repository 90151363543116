import Star from "../../assests/svgsComponent/Star";


const StarRatingFilter = ({ filters, setFilters }) => {
  const ratings = [5, 4, 3, 2, 1];

  const handleRatingChange = (rating: number) => {
    setFilters({
      ...filters,
      rating: filters.rating === rating ? 0 : rating,
    });
  };

  return (
    <>
    <h2 className="text-lg font-semibold mb-6 hidden md:block">Rating</h2>
    <div className="w-full flex flex-col items-center md:items-start gap-2">

      {ratings.map((rating) => (
        <label
          key={rating}
          className="flex items-center space-x-2 cursor-pointer gap-1.5"
        >
          <input
            type="checkbox"
            checked={filters.rating === rating}
            onChange={() => handleRatingChange(rating)}
            className="h-4 w-4 accent-black focus:ring-0"
          />
          <div className="flex gap-1 items-center justify-center">
            {Array.from({ length: 5 }, (_, index) => {
              const fillPercentage = index < rating ? 100 : 0
              return <Star key={index} fill={fillPercentage} height={25} width={20} />;
            })}
          </div>
        </label>
      ))}
    </div>
    </>
  );
};

export default StarRatingFilter;
