export enum authAction {
  USER_SIGN_IN_REQUESTED = "USER_SIGN_IN_REQUESTED",
  USER_SIGN_IN_SUCCESS = "USER_SIGN_IN_SUCCESS",
  USER_SIGN_IN_FAILED = "USER_SIGN_IN_FAILED",

  USER_SIGN_UP_REQUESTED = "USER_SIGN_UP_REQUESTED",
  USER_SIGN_UP_SUCCESS = "USER_SIGN_UP_SUCCESS",
  USER_SIGN_UP_FAILED = "USER_SIGN_UP_FAILED",

  USER_SIGN_UP_GOOGLE_REQUESTED = "USER_SIGN_UP_GOOGLE_REQUESTED",
  USER_SIGN_UP_GOOGLE_SUCCESS = "USER_SIGN_UP_GOOGLE_SUCCESS",
  USER_SIGN_UP_GOOGLE_FAILED = "USER_SIGN_UP_GOOGLE_FAILED",

  CHECK_EMAIL_EXISTS_REQUESTED = "CHECK_EMAIL_EXISTS_REQUESTED",
  CHECK_EMAIL_EXISTS_SUCCESS = "CHECK_EMAIL_EXISTS_SUCCESS",
  CHECK_EMAIL_EXISTS_FAILED = "CHECK_EMAIL_EXISTS_FAILED",

  FETCH_USER_DATA_REQUESTED = "FETCH_USER_DATA_REQUESTED",
  FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS",
  FETCH_USER_DATA_FAILED = "FETCH_USER_DATA_FAILED",

  VERIFY_ACCOUNT_EMAIL_REQUESTED = "VERIFY_ACCOUNT_EMAIL_REQUESTED",
  VERIFY_ACCOUNT_EMAIL_SUCCESS = "VERIFY_ACCOUNT_EMAIL_SUCCESS",
  VERIFY_ACCOUNT_EMAIL_FAILED = "VERIFY_ACCOUNT_EMAIL_FAILED",

  CHECK_USERNAME_EXISTS_REQUESTED = "CHECK_USERNAME_EXISTS_REQUESTED",
  CHECK_USERNAME_EXISTS_SUCCESS = "CHECK_USERNAME_EXISTS_SUCCESS",
  CHECK_USERNAME_EXISTS_FAILED = "CHECK_USERNAME_EXISTS_FAILED",

  RESEND_OTP_REQUESTED_REQUESTED = "RESEND_OTP_REQUESTED_REQUESTED",
  RESEND_OTP_REQUESTED_SUCCESS = "RESEND_OTP_REQUESTED_SUCCESS",
  RESEND_OTP_REQUESTED_FAILED = "RESEND_OTP_REQUESTED_FAILED",

  INITIATE_RESET_REQUESTED = "INITIATE_RESET_REQUESTED",
  INITIATE_RESET_SUCCESS = "INITIATE_RESET_SUCCESS",
  INITIATE_RESET_FAILED = "INITIATE_RESET_FAILED",

  CONFIRM_RESET_PASS_REQUESTED = "CONFIRM_RESET_PASS_REQUESTED",
  CONFIRM_RESET_PASS_SUCCESS = "CONFIRM_RESET_PASS_SUCCESS",
  CONFIRM_RESET_PASS_FAILED = "CONFIRM_RESET_PASS_FAILED",

  VERIFY_RESET_PASS_REQUESTED = "VERIFY_RESET_PASS_REQUESTED",
  VERIFY_RESET_PASS_SUCCESS = "VERIFY_RESET_PASS_SUCCESS",
  VERIFY_RESET_PASS_FAILED = "VERIFY_RESET_PASS_FAILED",

  DELETE_USER_REQUESTED = "DELETE_USER_REQUESTED",
  DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS",
  DELETE_USER_FAILED = "DELETE_USER_FAILED",

  CHANGE_PASS_REQUESTED = "CHANGE_PASS_REQUESTED",
  CHANGE_PASS_SUCCESS = "CHANGE_PASS_SUCCESS",
  CHANGE_PASS_FAILED = "CHANGE_PASS_FAILED",
  RESET_CHANGE_PASS = "RESET_CHANGE_PASS"
}

export enum screenActions {
  GET_PUBLISHED_TRIP_REQUESTED = "GET_PUBLISHED_TRIP_REQUESTED",
  GET_PUBLISHED_TRIP_SUCCEEDED = "GET_PUBLISHED_TRIP_SUCCEEDED",
  GET_PUBLISHED_TRIP_FAILED = "GET_PUBLISHED_TRIP_FAILED",

  GET_IMAGES_REQUESTED = "GET_IMAGES_REQUESTED",
  GET_IMAGES_SUCCEEDED = "GET_IMAGES_SUCCEEDED",
  GET_IMAGES_FAILED = "GET_IMAGES_FAILED",

  GET_ALL_IMAGES_REQUESTED = "GET_ALL_IMAGES_REQUESTED",
  GET_ALL_IMAGES_SUCCEEDED = "GET_ALL_IMAGES_SUCCEEDED",
  GET_ALL_IMAGES_FAILED = "GET_ALL_IMAGES_FAILED",

  GET_TRENDING_DESTINATIONS_SUCCESS = "GET_TRENDING_DESTINATIONS_SUCCESS",
  GET_TRENDING_DESTINATIONS_FAILED = "GET_TRENDING_DESTINATIONS_FAILED",
  GET_TRENDING_DESTINATIONS_REQUESTED = "GET_TRENDING_DESTINATIONS_REQUESTED",

  GET_FEATURED_USERS_REQUESTED = "GET_FEATURED_USERS_REQUESTED",
  GET_FEATURED_USERS_SUCCESS = "GET_FEATURED_USERS_SUCCESS",
  GET_FEATURED_USERS_FAILED = "GET_FEATURED_USERS_FAILED",

  GET_ITINERARY_DETAILS_REQUESTED = "GET_ITINERARY_DETAILS_REQUESTED",
  GET_ITINERARY_DETAILS_SUCCESS = "GET_ITINERARY_DETAILS_SUCCESS",
  GET_ITINERARY_DETAILS_FAILED = "GET_ITINERARY_DETAILS_FAILED",

  GET_FEATURED_USER_DETAILS_REQUESTED = "GET_FEATURED_USER_DETAILS_REQUESTED",
  GET_FEATURED_USER_DETAILS_SUCCESS = "GET_FEATURED_USER_DETAILS_SUCCESS",
  GET_FEATURED_USER_DETAILS_FAILED = "GET_FEATURED_USER_DETAILS_FAILED",

  GET_FEATURED_USER_PUBLISHED_TRIPS_REQUESTED = "GET_FEATURED_USER_PUBLISHED_TRIPS_REQUESTED",
  GET_FEATURED_USER_PUBLISHED_TRIPS_SUCCESS = "GET_FEATURED_USER_PUBLISHED_TRIPS_SUCCESS",
  GET_FEATURED_USER_PUBLISHED_TRIPS_FAILED = "GET_FEATURED_USER_PUBLISHED_TRIPS_FAILED",

  GET_SEARCHED_ITINERARY_REQUESTED = "GET_SEARCHED_ITINERARY_REQUESTED",
  GET_SEARCHED_ITINERARY_SUCCESS = "GET_SEARCHED_ITINERARY_SUCCESS",
  GET_SEARCHED_ITINERARY_FAILED = "GET_SEARCHED_ITINERARY_FAILED",

  GET_SEARCHED_DESTINATIONS_REQUESTED = "GET_SEARCHED_DESTINATIONS_REQUESTED",
  GET_SEARCHED_DESTINATIONS_SUCCESS = "GET_SEARCHED_DESTINATIONS_SUCCESS",
  GET_SEARCHED_DESTINATIONS_FAILED = "GET_SEARCHED_DESTINATIONS_FAILED",

  GET_SEARCHED_USERS_REQUESTED = "GET_SEARCHED_USERS_REQUESTED",
  GET_SEARCHED_USERS_SUCCESS = "GET_SEARCHED_USERS_SUCCESS",
  GET_SEARCHED_USERS_FAILED = "GET_SEARCHED_USERS_FAILED",

  GET_POPULAR_TRIPS_REQUESTED = "GET_POPULAR_TRIPS_REQUESTED",
  GET_POPULAR_TRIPS_SUCCESS = "GET_POPULAR_TRIPS_SUCCESS",
  GET_POPULAR_TRIPS_FAILED = "GET_POPULAR_TRIPS_FAILED",

  GET_WEEKENDS_GATEWAY_REQUESTED = "GET_WEEKENDS_GATEWAY_REQUESTED",
  GET_WEEKENDS_GATEWAY_SUCCESS = "GET_WEEKENDS_GATEWAY_SUCCESS",
  GET_WEEKENDS_GATEWAY_FAILED = "GET_WEEKENDS_GATEWAY_FAILED",

  GET_LONG_TRIPS_REQUESTED = "GET_LONG_TRIPS_REQUESTED",
  GET_LONG_TRIPS_SUCCESS = "GET_LONG_TRIPS_SUCCESS",
  GET_LONG_TRIPS_FAILED = "GET_LONG_TRIPS_FAILED",

  GET_SORTED_ITINERARY_REQUESTED = "GET_SORTED_ITINERARY_REQUESTED",
  GET_SORTED_ITINERARY_SUCCESS = "GET_SORTED_ITINERARY_SUCCESS",
  GET_SORTED_ITINERARY_FAILED = "GET_SORTED_ITINERARY_FAILED",

  GET_PLACE_DETAILS_REQUESTED = "GET_PLACE_DETAILS_REQUESTED",
  GET_PLACE_DETAILS_SUCCESS = "GET_PLACE_DETAILS_SUCCESS",
  GET_PLACE_DETAILS_FAILED = "GET_PLACE_DETAILS_FAILED",

  GET_COORDINATES_REQUESTED = "GET_COORDINATES_REQUESTED",
  GET_COORDINATES_SUCCESS = "GET_COORDINATES_SUCCESS",
  GET_COORDINATES_FAILED = "GET_COORDINATES_FAILED",

  SEARCH_USERS_REQUESTED = "SEARCH_USERS_REQUESTED",
  SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS",
  SEARCH_USERS_FAILED = "SEARCH_USERS_FAILED",

  GET_USER_DETAILS_REQUESTED = "GET_USER_DETAILS_REQUESTED",
  GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAILED = "GET_USER_DETAILS_FAILED",

  UPDATE_USER_DETAILS_REQUESTED = "UPDATE_USER_DETAILS_REQUESTED",
  UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS",
  UPDATE_USER_DETAILS_FAILED = "UPDATE_USER_DETAILS_FAILED",

  GET_THINGS_TO_DO_PRODUCT_SEARCH_REQUESTED = "GET_THINGS_TO_DO_PRODUCT_SEARCH_REQUESTED",
  GET_THINGS_TO_DO_PRODUCT_SEARCH_SUCCESS = "GET_THINGS_TO_DO_PRODUCT_SEARCH_SUCCESS",
  GET_THINGS_TO_DO_PRODUCT_SEARCH_FAILED = "GET_THINGS_TO_DO_PRODUCT_SEARCH_FAILED",
  CLEAR_THINGS_TO_DO_PRODUCT_SEARCH_REQUESTED = "CLEAR_THINGS_TO_DO_PRODUCT_SEARCH_REQUESTED",
  CLEAR_THINGS_TO_DO_PRODUCT_SEARCH_SUCCESS = "CLEAR_THINGS_TO_DO_PRODUCT_SEARCH_SUCCESS",

  GET_THINGS_TO_DO_LOCATION_REQUESTED = "GET_THINGS_TO_DO_LOCATION_REQUESTED",
  GET_THINGS_TO_DO_LOCATION_SUCCESS = "GET_THINGS_TO_DO_LOCATION_SUCCESS",
  GET_THINGS_TO_DO_LOCATION_FAILED = "GET_THINGS_TO_DO_LOCATION_FAILED",
  
  GET_THINGS_TO_DO_ACTIVITY_SEARCH_REQUESTED = "GET_THINGS_TO_DO_ACTIVITY_SEARCH_REQUESTED",
  GET_THINGS_TO_DO_ACTIVITY_SEARCH_SUCCESS = "GET_THINGS_TO_DO_ACTIVITY_SEARCH_SUCCESS",
  GET_THINGS_TO_DO_ACTIVITY_SEARCH_FAILED = "GET_THINGS_TO_DO_ACTIVITY_SEARCH_FAILED",
  CLEAR_THINGS_TO_DO_ACTIVITY_SEARCH_REQUESTED = "CLEAR_THINGS_TO_DO_ACTIVITY_SEARCH_REQUESTED",
  CLEAR_THINGS_TO_DO_ACTIVITY_SEARCH_SUCCESS = "CLEAR_THINGS_TO_DO_ACTIVITY_SEARCH_SUCCESS",

  GET_THINGS_TO_DO_DESTINATION_LIST_REQUESTED = "GET_THINGS_TO_DO_DESTINATION_LIST_REQUESTED",
  GET_THINGS_TO_DO_DESTINATION_LIST_SUCCESS = "GET_THINGS_TO_DO_DESTINATION_LIST_SUCCESS",
  GET_THINGS_TO_DO_DESTINATION_LIST_FAILED = "GET_THINGS_TO_DO_DESTINATION_LIST_FAILED",

  GET_THINGS_TO_DO_FREE_TEXT_SEARCH_REQUESTED = "GET_THINGS_TO_DO_FREE_TEXT_SEARCH_REQUESTED",
  GET_THINGS_TO_DO_FREE_TEXT_SEARCH_SUCCESS = "GET_THINGS_TO_DO_FREE_TEXT_SEARCH_SUCCESS",
  GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FAILED = "GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FAILED",

  GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FOR_HOME_REQUESTED = "GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FOR_HOME_REQUESTED",
  GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FOR_HOME_SUCCESS = "GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FOR_HOME_SUCCESS",
  GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FOR_HOME_FAILED = "GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FOR_HOME_FAILED",
  CLEAR_THINGS_TO_DO_FREE_TEXT_FOR_HOME_SEARCH_REQUESTED = "CLEAR_THINGS_TO_DO_FREE_TEXT_FOR_HOME_SEARCH_REQUESTED",
  CLEAR_THINGS_TO_DO_FREE_TEXT_FOR_HOME_SEARCH_SUCCESS = "CLEAR_THINGS_TO_DO_FREE_TEXT_FOR_HOME_SEARCH_SUCCESS",

  GET_THINGS_TO_DO_PRODUCT_DETAILS_REQUESTED = "GET_THINGS_TO_DO_PRODUCT_DETAILS_REQUESTED",
  GET_THINGS_TO_DO_PRODUCT_DETAILS_SUCCESS = "GET_THINGS_TO_DO_PRODUCT_DETAILS_SUCCESS",
  GET_THINGS_TO_DO_PRODUCT_DETAILS_FAILED = "GET_THINGS_TO_DO_PRODUCT_DETAILS_FAILED",

  GET_THINGS_LOCATION_FROM_DESTINATION_REF_REQUESTED = "GET_THINGS_LOCATION_FROM_DESTINATION_REF_REQUESTED",
  GET_THINGS_LOCATION_FROM_DESTINATION_REF_SUCCESS = "GET_THINGS_LOCATION_FROM_DESTINATION_REF_SUCCESS",
  GET_THINGS_LOCATION_FROM_DESTINATION_REF_FAILED = "GET_THINGS_LOCATION_FROM_DESTINATION_REF_FAILED",
  
  GET_MY_TRIPS_REQUESTED = "GET_MY_TRIPS_REQUESTED",
  GET_MY_TRIPS_SUCCESS = "GET_MY_TRIPS_SUCCESS",
  GET_MY_TRIPS_FAILED = "GET_MY_TRIPS_FAILED",

  GET_THINGS_TO_DO_SIMILAR_EXPERIENCE_REQUESTED = "GET_THINGS_TO_DO_SIMILAR_EXPERIENCE_REQUESTED",
  GET_THINGS_TO_DO_YOU_MAY_ALSO_LIKE_REQUESTED = "GET_THINGS_TO_DO_YOU_MAY_ALSO_LIKE_REQUESTED",
  GET_THINGS_TO_DO_NEARBY_ACTIVITIES_REQUESTED = "GET_THINGS_TO_DO_NEARBY_ACTIVITIES_REQUESTED",
  GET_THINGS_TO_DO_NEARBY_THINGS_TO_DO_REQUESTED = "GET_THINGS_TO_DO_NEARBY_THINGS_TO_DO_REQUESTED",
  GET_THINGS_TO_DO_SIMILAR_EXPERIENCE_SUCCESS = "GET_THINGS_TO_DO_SIMILAR_EXPERIENCE_SUCCESS",
  GET_THINGS_TO_DO_YOU_MAY_ALSO_LIKE_SUCCESS = "GET_THINGS_TO_DO_YOU_MAY_ALSO_LIKE_SUCCESS",
  GET_THINGS_TO_DO_NEARBY_ACTIVITIES_SUCCESS = "GET_THINGS_TO_DO_NEARBY_ACTIVITIES_SUCCESS",
  GET_THINGS_TO_DO_NEARBY_THINGS_TO_DO_SUCCESS = "GET_THINGS_TO_DO_NEARBY_THINGS_TO_DO_SUCCESS",
  GET_THINGS_TO_DO_SIMILAR_EXPERIENCE_FAILED = "GET_THINGS_TO_DO_SIMILAR_EXPERIENCE_FAILED",
  GET_THINGS_TO_DO_YOU_MAY_ALSO_LIKE_FAILED = "GET_THINGS_TO_DO_YOU_MAY_ALSO_LIKE_FAILED",
  GET_THINGS_TO_DO_NEARBY_ACTIVITIES_FAILED = "GET_THINGS_TO_DO_NEARBY_ACTIVITIES_FAILED",
  GET_THINGS_TO_DO_NEARBY_THINGS_TO_DO_FAILED = "GET_THINGS_TO_DO_NEARBY_THINGS_TO_DO_FAILED",

  // GET_HOTEL_LIST_REQUESTED = "GET_HOTEL_LIST_REQUESTED",
  // GET_HOTEL_LIST_SUCCESS = "GET_HOTEL_LIST_SUCCESS",
  // GET_HOTEL_LIST_FAILED = "GET_HOTEL_LIST_FAILED",

  GET_HOTEL_DETAILS_REQUESTED = "GET_HOTEL_DETAILS_REQUESTED",
  GET_HOTEL_DETAILS_SUCCESS = "GET_HOTEL_DETAILS_SUCCESS",
  GET_HOTEL_DETAILS_FAILED = "GET_HOTEL_DETAILS_FAILED",

  GET_SEARCH_CITY_AND_HOTEL_LIST_REQUESTED = "GET_SEARCH_CITY_AND_HOTEL_LIST_REQUESTED",
  GET_SEARCH_CITY_AND_HOTEL_LIST_SUCCESS = "GET_SEARCH_CITY_AND_HOTEL_LIST_SUCCESS",
  GET_SEARCH_CITY_AND_HOTEL_LIST_FAILED = "GET_SEARCH_CITY_AND_HOTEL_LIST_FAILED",

  GET_SEARCH_CITY_AND_HOTEL_LIST_FOR_SEARCH_REQUESTED = "GET_SEARCH_CITY_AND_HOTEL_LIST_FOR_SEARCH_REQUESTED",
  GET_SEARCH_CITY_AND_HOTEL_LIST_FOR_SEARCH_SUCCESS = "GET_SEARCH_CITY_AND_HOTEL_LIST_FOR_SEARCH_SUCCESS",
  GET_SEARCH_CITY_AND_HOTEL_LIST_FOR_SEARCH_FAILED = "GET_SEARCH_CITY_AND_HOTEL_LIST_FOR_SEARCH_FAILED",

  GET_CITY_SEARCH_REQUESTED = "GET_CITY_SEARCH_REQUESTED",
  GET_CITY_SEARCH_SUCCESS = "GET_CITY_SEARCH_SUCCESS",
  GET_CITY_SEARCH_FAILED = "GET_CITY_SEARCH_FAILED",

  GET_CITY_SEARCH_SIMILAR_EXPERIENCE_REQUESTED = "GET_CITY_SEARCH_SIMILAR_EXPERIENCE_REQUESTED",
  GET_CITY_SEARCH_SIMILAR_EXPERIENCE_SUCCESS = "GET_CITY_SEARCH_SIMILAR_EXPERIENCE_SUCCESS",
  GET_CITY_SEARCH_SIMILAR_EXPERIENCE_FAILED = "GET_CITY_SEARCH_SIMILAR_EXPERIENCE_FAILED",

  GET_CITY_SEARCH_NEARBY_PLACES_REQUESTED = "GET_CITY_SEARCH_NEARBY_PLACES_REQUESTED",
  GET_CITY_SEARCH_NEARBY_PLACES_SUCCESS = "GET_CITY_SEARCH_NEARBY_PLACES_SUCCESS",
  GET_CITY_SEARCH_NEARBY_PLACES_FAILED = "GET_CITY_SEARCH_NEARBY_PLACES_FAILED",

  GET_CITY_COUNTRY_FROM_COORDINATES_REQUESTED = "GET_CITY_COUNTRY_FROM_COORDINATES_REQUESTED",
  GET_CITY_COUNTRY_FROM_COORDINATES_SUCCESS = "GET_CITY_COUNTRY_FROM_COORDINATES_SUCCESS",
  GET_CITY_COUNTRY_FROM_COORDINATES_FAILED = "GET_CITY_COUNTRY_FROM_COORDINATES_FAILED",

  GET_HOTEL_BY_IDS_REQUESTED = "GET_HOTEL_BY_IDS_REQUESTED",
  GET_HOTEL_BY_IDS_SUCCESS = "GET_HOTEL_BY_IDS_SUCCESS",
  GET_HOTEL_BY_IDS_FAILED = "GET_HOTEL_BY_IDS_FAILED",

  SEND_FEEDBACK_REQUESTED = "SEND_FEEDBACK_REQUESTED",
  SEND_FEEDBACK_SUCCESS = "SEND_FEEDBACK_SUCCESS",
  SEND_FEEDBACK_FAILED = "SEND_FEEDBACK_FAILED",
}
