import React, { useState } from "react";
import { Divider, InputAdornment, TextField } from "@mui/material";
import { ChevronDown, ChevronUp, Search } from "lucide-react";
import SortBy from "../Filters/SortBy";
import StarRatingFilter from "../Filters/StarRatingFilter";
import Input from "../atom/Input";
import CheckBox from "./CheckBox";
import Rating from "./Rating";
import Duration from "./Duration";
import TimeOfDay from "./TimeOfDay";
import Categories from "./Categories";
import SpecialsFilter from "./Specials";
import PriceRangeSlider from "./PriceRangeSlider";

const MobileFilterOptions = ({ openFilters, toggleFilter, handleClearAll, filters, setFilters, searchText, setsearchText }) => {
  return (
    <div className="md:hidden w-full flex flex-col text-[12px] gap-2 text-[#999999]">
      <div className="flex gap-2 overflow-x-auto">
        {[
          { label: "Categories", key: "categories" },
          { label: "Price", key: "price" },
          { label: "Rating", key: "rating" },
          { label: "Duration", key: "duration" },
          { label: "Specials", key: "specials" }
        ].map(({ label, key }) => (
          <button
            key={key}
            className="flex justify-between items-center px-2 h-8 border-[#d8e0e9] border-2 rounded-[10px] bg-white"
            onClick={() => toggleFilter(key)}
          >
            <span className="font-Mulish font-semibold text-nowrap">{label}</span>
            {openFilters[key] ? <ChevronUp size={12} /> : <ChevronDown size={12} />}
          </button>
        ))}
        <button onClick={handleClearAll} className="text-sm font-Mulish font-bold underline text-nowrap text-center">
          Clear All
        </button>
      </div>

<div className="w-full flex items-center justify-center">
      {openFilters.categories && <div className="mt-2"><Categories filters={filters} setFilters={setFilters} searchText={searchText} setsearchText={setsearchText}/></div>}
      {openFilters.price && <div className="mt-2"><PriceRangeSlider filters={filters} setFilters={setFilters} /></div>}
      {openFilters.rating && <div className="mt-2"><Rating filters={filters} setFilters={setFilters} /></div>}
      {openFilters.duration && <div className="mt-2"><Duration filters={filters} setFilters={setFilters} /></div>}
      {openFilters.specials && <div className="mt-2"><SpecialsFilter filters={filters} setFilters={setFilters} /></div>}
      </div>
    </div>
  );
};

const ThingsToDoFilter = ({ filters, setFilters, handleClearAll,searchText, setsearchText }) => {
  const [openFilters, setOpenFilters] = useState({
    categories: false,
    price: false,
    rating: false,
    duration: false,
    specials: false,
  });

  const toggleFilter = (filter) => {
    setOpenFilters((prev) => ({
      categories: false,
      price: false,
      rating: false,
      duration: false,
      specials: false,
      [filter]: !prev[filter],
    }));
  };

  return (
    <div className="flex flex-col w-full md:pr-10 md:items-start items-center justify-center">
      {/* Mobile View - Collapsible Filters */}
      <MobileFilterOptions 
        openFilters={openFilters} 
        toggleFilter={toggleFilter} 
        handleClearAll={handleClearAll} 
        filters={filters} 
        setFilters={setFilters} 
        searchText={searchText}
        setsearchText={setsearchText}
      />

      {/* Desktop View - Always Visible Filters */}
      <div className="hidden md:flex flex-col w-full">
        <div className="flex w-full flex-row justify-between items-center mb-4">
          <h2 className="text-lg font-Mulish font-bold">Categories</h2>
          <button onClick={handleClearAll} className="text-sm font-Mulish font-bold underline">
            Clear All
          </button>
        </div>
{/* <CheckBox/> */}
<Categories filters={filters} setFilters={setFilters} searchText={searchText} setsearchText={setsearchText}/>

        <Divider sx={{ mb: 4 }} />
        <PriceRangeSlider filters={filters} setFilters={setFilters}/>
        <Divider sx={{ my: 4 }} />
        <Rating filters={filters} setFilters={setFilters} />
        <Divider sx={{ my: 4 }} />
        <Duration filters={filters} setFilters={setFilters} />
        <Divider sx={{ my: 4 }} />
        <SpecialsFilter filters={filters} setFilters={setFilters} />
        {/* <TimeOfDay filters={filters} setFilters={setFilters} /> */}
      </div>
    </div>
  );
};

export default ThingsToDoFilter;
