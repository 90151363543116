import { InputAdornment, TextField } from "@mui/material";
import { forwardRef, useState, useEffect } from "react";
import useDebounce from "../../hooks/useDebounce";
import { screenActionsCall } from "../../redux/actions";
import { useDispatch } from "react-redux";
import SearchCity from "../Filters/SearchCity";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import { CalendarToday, People } from "@mui/icons-material";
import moment, { Moment } from "moment";
import RoomGuestSelector from "../Filters/RoomGuestSelector";
import { UsersRound } from "lucide-react";
import { CustomButton } from "./CustomButton";
import DatePicker from "./DatePicker";

interface ITwinInput {
  startAdornment?: any;
  type?: string;
  placeholder?: string;
  value?: any;
  searchBarData?: ISearchBarData;
  setsearchBarData?: React.Dispatch<React.SetStateAction<ISearchBarData>>;
  handleSearch?: (searchData: ISearchBarData) => void;
}

interface IOccupancy {
  roomCount?: number;
  adultCount?: number;
  childCount?: number;
  childAge?: number[];
}

interface ISearchBarData {
  searchText?: string;
  cityId?: number;
  hotelId?: number;
  checkInDate?: string;
  checkOutDate?: string;
  occupancy?: IOccupancy;
}

const TwinInput = forwardRef((props: ITwinInput) => {
  const {
    startAdornment,
    type = "text",
    placeholder,
    searchBarData,
    setsearchBarData,
    handleSearch,
  } = props || {};
  const dispatch = useDispatch();
  const [searchBarDataLocal, setsearchBarDataLocal] = useState(searchBarData);

  const debouncedSearchValue = useDebounce(searchBarDataLocal?.searchText, 500);

  useEffect(() => {
    if (debouncedSearchValue) {
      dispatch(screenActionsCall.getSearchCityAndHotelListforSearch(debouncedSearchValue));
    }
  }, [debouncedSearchValue, dispatch]);

  const [openSearch, setOpenSearch] = useState(false);
  const [openRoomGuestSelector, setOpenRoomGuestSelector] = useState(false);

  const handleCloseSearch = () => {
    setOpenSearch(false);
  };
  const handleCloseRoomGuestSelector = () => {
    setOpenRoomGuestSelector(false);
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setsearchBarDataLocal((prevData) => ({
      ...prevData,
      checkInDate: startDate ? startDate : "",
      checkOutDate: endDate ? endDate : "",
    }));
  };

  const [focusedDate, setFocusedDate] = useState<FocusedInputShape | null>(null);

  const handleFocusChange = (arg: FocusedInputShape | null) => {
    setFocusedDate(arg);
  };

  useEffect(() => {
    if (searchBarDataLocal?.searchText !== "") setOpenSearch(true);
    if (searchBarDataLocal?.searchText === "") setOpenSearch(false);
  }, [searchBarDataLocal?.searchText])
  
  const handleSearchButton = () => {
    
    setsearchBarData(searchBarDataLocal);
    handleSearch && handleSearch(searchBarDataLocal);
  };
  

  return (
    <div className="w-full relative ">
      <div className="border-0 flex md:flex-row flex-col justify-between items-center md:rounded-[60px] rounded-2xl md:px-2.5 text-lg w-full md:h-[70px] h-auto bg-white shadow-md">
        <div className="md:w-[100%] w-full pl-3 md:pl-0 border-b-[2px] py-1 md:py-0 md:border-b-[0px] border-[#F1F1F1]">
          <TextField
            id="search"
            variant="standard"
            placeholder={placeholder}
            type={type || "text"}
            role="button"
            onChange={(e) => {
              setsearchBarDataLocal({
                ...searchBarDataLocal,
                searchText: e.target.value,
              });
            }}
            value={searchBarDataLocal?.searchText}
            inputProps={{ sx: { border: "0px", outline: "0px" } }}
            InputProps={{
              sx: {
                fontSize: "16px",
                paddingRight: "8px",
                paddingY: "10px",
                width: "100%",
                '@media (max-width: 600px)': {
                  fontSize: "14px",
                },
              },
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ),
            }}
            sx={{
              color: "rgba(60, 60, 67, 0.4)",
              width: "100%",
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
          />
        </div>
        <div className="w-full md:border-l-[2px] flex  md:border-r-[2px] border-[#F1F1F1] ml-3 md:ml-0  py-2 md:py-0">
          {/* <DateRangePicker
            noBorder
            hideKeyboardShortcutsPanel
            keepOpenOnDateSelect={false}
            startDate={moment(searchBarDataLocal?.checkInDate)}
            startDateId="startDate"
            endDate={moment(searchBarDataLocal?.checkOutDate)}
            endDateId="endDate"
            onDatesChange={handleDatesChange}
            focusedInput={focusedDate}
            onFocusChange={handleFocusChange}
            isOutsideRange={(day) => {
              if (!searchBarDataLocal?.checkInDate) {
                return day.isBefore(moment(), "day"); // Disable past dates before today
              }
              return day.isBefore(moment(searchBarDataLocal?.checkInDate), "day"); // Disable dates before start date
            }}
            // customInputIcon={
            //   <CalendarToday className="!h-[24px] !w-[24px]" />
            // }
          /> */}
          <DatePicker startDate={searchBarDataLocal?.checkInDate} endDate={searchBarDataLocal?.checkOutDate} onDatesChange={handleDatesChange} />
        </div>
        <div className="md:w-[100%] pl-5 pr-5 md:pr-0 w-full flex md:flex-row flex-col justify-between cursor-pointer border-t-[2px] pt-3 md:pt-0 md:border-t-[0px] border-[#F1F1F1]">
          <div className="w-full flex items-center gap-2" onClick={() => setOpenRoomGuestSelector(true)}>
            <UsersRound className="!h-[24px] !w-[24px]" />
            <div className="flex flex-col ml-2">
              <p className="text-black font-medium md:text-[16px] text-[14px] font-Mulish leading-[18px]">{searchBarDataLocal?.occupancy?.adultCount} Adults, {searchBarDataLocal?.occupancy?.childCount} Child</p>
              <p className="text-[#999999] md:text-[16px] text-[14px] font-Mulish leading-[18px]">{searchBarDataLocal?.occupancy?.roomCount} room</p>
            </div>
          </div>

          <CustomButton
            title="Search"
            className="!md:h-[56px] !h-[48px] !my-5 !md:my-0 font-bold !rounded-[60px]"
            fontSize="16px"
            onClick={handleSearchButton}
            disabled={searchBarDataLocal?.cityId === 0}
          />
        </div>
      </div>

      {openSearch && (
        <div className="absolute left-0 z-100">
          <SearchCity handleClose={handleCloseSearch} setsearchBarDataLocal={setsearchBarDataLocal} />
        </div>
      )}
      {openRoomGuestSelector && (
        <div className="absolute md:right-[360px] z-50 right-[calc(50%+130px)]">
        <RoomGuestSelector occupancy={searchBarDataLocal?.occupancy} handleClose={handleCloseRoomGuestSelector} setsearchBarDataLocal={setsearchBarDataLocal} />
        </div>
      )}
    </div>
  );
});

export default TwinInput;
