import Button, { ButtonProps } from "@mui/material/Button";
import * as React from "react";
import theme from "../../themes";
import { CircularProgress, Typography } from "@mui/material";
import { colors } from "../../themes/colors";

interface CustomButton extends ButtonProps {
  variant?: "contained" | "outlined";
  onClick?: any;
  width?: string;
  border?: string;
  startIcon?: string;
  fontSize?: string;
  label?: string;
  title: string;
  customStyle?: React.CSSProperties | any;
  customTypography?: React.CSSProperties | any;
  loading?: boolean;
  className?: string;
  bg?:string;
  endIcon?:string
}

export const CustomButton: React.FC<CustomButton> = ({
  label,
  variant = "contained",
  onClick,
  width,
  border,
  startIcon,
  fontSize,
  title,
  customStyle,
  customTypography,
  loading = false,
  className,
  bg,
  endIcon,
  ...rest
}) => {
  return (
    <Button
      variant={variant ? variant : "contained"}
      onClick={onClick}
      sx={{
        backgroundColor:
          variant === "contained" ? (bg ? bg : theme.palette.primary.main) : "",
        textTransform: "none",
        borderRadius: "10px",
        minHeight: "40px",
        // width: width ? width : "150px",
        minWidth: "112px",
        boxShadow: "unset",
        transition: "transform 0.2s ease-in-out",
        border:
          variant === "outlined" ? "1px solid #20B08F" : border ? border : "",
        "&:hover": {
          backgroundColor: bg ? bg : theme.palette.primary.main,
          borderColor: bg ? bg : theme.palette.primary.main,
          borderWidth: "1px",
          "& .MuiTypography-root": {
            color: !bg  && theme.palette.primary.contrastText,
          },
          transform: "scale(1.05)"
        },
        ...customStyle,
      }}
      startIcon={startIcon && <img src={startIcon} alt="icon" />}
      disabled={loading}
      className={className}
      {...rest}
    >
      <Typography
        fontSize={fontSize ? fontSize : "12px"}
        variant="h1"
        color={variant === "contained" ? "#fff" : colors?.ThemeColor}
        {...customTypography}
      >
        {!loading ? title : <CircularProgress color={"inherit"} size={12} />}
      </Typography>
      {endIcon && <img src={endIcon} alt={endIcon} className="ml-2 !h-auto !w-[12px]" />}
    </Button>
  );
};
