import { Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/template/Footer";
import Header from "../../components/template/Header";
import Helmet from "react-helmet";
import TwinInput from "../../components/atom/TwinInputSet";
import { Search } from "@mui/icons-material";
import EChip from "../../components/atom/EChip";
import Filters from "../../components/Filters/Filters";
import { SkeletonCard } from "../../components/skeltonLoader/cardSkelton";
import { ThingsToDoCard } from "../../components/ThingsToDoCard/ThingsToDoCard";
import { useDispatch, useSelector } from "react-redux";
import { screenActionsCall } from "../../redux/actions";
import en from "../../locale/en.json";
import { useEffect, useState } from "react";
import { logPageView } from "../../utils/analytics";
import SkeletonViatorCard from "../../components/skeltonLoader/viatorCards";
import { RootState } from "../../redux/reducers";
import {
  viatorFreeTextSearchBody,
  viatorInitialProductBody,
} from "../../config/apiBody";
import {
  isEmptyObject,
  isNullOrEmpty,
  validateFiltersViator,
} from "../../utils/helpers";
import { CustomButton } from "../../components/atom/CustomButton";
import ThingsToDoFilter from "../../components/ThingsToDoFilter/ThingsToDoFilter";
import FilterChips from "../../components/ThingsToDoFilter/FilterChips";
import SearchDate from "../../components/ThingsToDoFilter/SearchBar/SearchDate";
import { getThingsToDoProductSearch } from "../../redux/actions/screenActions";
import { Viator } from "../../constants";

const ThingsToDo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    thingsToDoProductSearchLoading,
    thingsToDoProductSearchDetails,
    thingsToDoFreeTextSearchListForHome,
    thingsToDoFreeTextSearchForHomeLoading,
    thingsToDoDestinationFromRefList,
    thingsToDoDestinationFromRefListLoader,
  } = useSelector((state: RootState) => state.screenReducer);

  const [filters, setfilters] = useState({});
  const [destinationId, setdestinationId] = useState("723");
  const [searchText, setsearchText] = useState("");
  const [searchBarData, setsearchBarData] = useState<{ searchText?: string, destinationId?: string, startDate?: string, endDate?: string }>({});
  const validatedFilters = validateFiltersViator(filters, destinationId);

  //Initial India API call
  useEffect(() => {
    // if(isNullOrEmpty(thingsToDoProductSearchDetails?.products)) {

    //  dispatch(screenActionsCall.getThingsToDoProductSearch(viatorInitialProductBody(1, validatedFilters)))
    logPageView({
      name: en.anayticsPageName.HOME,
      path: location.pathname,
    });
    // }
  }, []);

  const handleThingsToDoCardClick = (card: any) => {
    navigate(`/things-to-do/${card?.productCode}`, { state: { card } });
  };

  const handleSeeMore = () => {
    
    if(isNullOrEmpty(thingsToDoFreeTextSearchListForHome?.products)) {
      dispatch(
        screenActionsCall.getThingsToDoProductSearch(
          viatorInitialProductBody(
            thingsToDoProductSearchDetails?.nextStart,
            validatedFilters
          )
        )
      );
    }else{
      dispatch(
        screenActionsCall.getThingsToDoFreeTextSearchForHome(
          viatorFreeTextSearchBody(
            thingsToDoFreeTextSearchListForHome?.nextStart,
            searchBarData?.searchText,
            Viator.numberOfResultsPerPage
          )
        )
      );
    }

  };

  const handleClearAll = () => {
    setfilters({});
    setsearchText("");
  };

  useEffect(() => {
    if (filters) {
      dispatch(screenActionsCall.clearThingsToDoProductSearch());
      dispatch(
        screenActionsCall.getThingsToDoProductSearch(
          viatorInitialProductBody(1, validatedFilters)
        )
      );
    }
  }, [filters]);

  const handleSearch = (searchBarData) => {
    dispatch(screenActionsCall.clearThingsToDoFreeTextSearchForHome());
    if (isNullOrEmpty(searchBarData?.destinationId)) {
      dispatch(screenActionsCall.clearThingsToDoProductSearch());
      dispatch(
        screenActionsCall.getThingsToDoFreeTextSearchForHome(
          viatorFreeTextSearchBody(1, searchBarData?.searchText, Viator.numberOfResultsPerPage)
        )
      );
    } else {
      setdestinationId(searchBarData?.destinationId);
      setfilters({
        startDate: searchBarData?.startDate,
        endDate: searchBarData?.endDate,
      });
    }
  };

  return (
    <Stack>
      <Helmet>
        <title>Escapekar: Your Ultimate Travel Guide</title>
        <meta name="title" content="Escapekar: Your Ultimate Travel Guide" />
        <meta
          name="description"
          content="Plan your perfect trip with Escapekar's ultimate travel guide. Discover featured travels, hidden gems, and popular destinations. Get personalized recommendations and explore any place you desire with our advanced search functionality."
        />
        <meta
          name="keywords"
          content="travel guide, featured travels, hidden gems, popular destinations, travel itinerary, trip planner, personalized recommendations, search destinations, travel planning"
        />

        <meta name="url" content="https://escapekar.com/" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://escapekar.com/" />
        <meta
          property="og:title"
          content="Escapekar: Your Ultimate Travel Guide"
        />
        <meta
          property="og:description"
          content="Discover featured travels, hidden gems, and popular destinations with Escapekar's travel guide. Get personalized trip planning recommendations and explore any location with our comprehensive search."
        />
        <meta property="og:site_name" content="Escapekar" />
        <meta property="og:locale" content="en-IN" />
        <meta property="og:country-name" content="IN" />

        <script id="data" type="application/json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "TravelGuide",
            name: "Escapekar Travel Guide",
            description:
              "Your ultimate resource for travel planning. Discover featured travels, hidden gems, and popular destinations. Get personalized recommendations and search for any location.",
            url: "https://escapekar.com/",
            sameAs: [
              "https://www.facebook.com/escapekar/",
              "https://www.instagram.com/escapekar/",
              "https://www.linkedin.com/company/escapekar/",
            ],

            hasPart: [
              {
                "@type": "TravelDestination",
                name: "Featured Travels",
                description:
                  "Explore our featured travel destinations and experiences.",
              },
              {
                "@type": "TravelDestination",
                name: "Hidden Gems",
                description:
                  "Discover hidden gems and lesser-known destinations.",
              },
              {
                "@type": "TravelDestination",
                name: "Popular Destinations",
                description:
                  "Browse popular destinations that are favored by travelers.",
              },
            ],
            contactPoint: {
              "@type": "ContactPoint",
              areaServed: "IN",
              availableLanguage: "en",
            },
          })}
        </script>
      </Helmet>
      <Header />
      <div className="flex flex-col items-center w-full bg-[#f6f6f6]">
        <div
          style={{
            background:
              "linear-gradient(90deg, #E2FFF8 14.25%, rgba(255, 255, 255, 0) 100%), linear-gradient(90deg, #EAFAFE 0%, #EADEF9 100%)",
          }}
          className="flex relative h-[160px] md:h-[140px] w-full mt-[82px] justify-center items-center font-bold font-Mulish text-[#333] mb-[124px] md:mb-0"
        >
          <p className="placeholder:b-4 text-[24px] md:text-[36px]">
            Explore something fun
          </p>
          <div className="w-[95%] sm:w-[90%] max-w-[1280px] absolute md:-bottom-[35px] -bottom-[130px] h-auto mx-auto flex flex-row justify-center items-center px-2 sm:px-0">
            <SearchDate
              placeholder="Search Destinations and Activities..."
              startAdornment={<Search className="ml-2 font-[#999999]" />}
              searchBarData={searchBarData}
              setsearchBarData={setsearchBarData}
              handleSearch={handleSearch}
              handleThingsToDoCardClick={handleThingsToDoCardClick}
            />
          </div>
        </div>
        <div className="px-4 sm:px-[70px] 2xl:px-0 py-6 sm:py-10 w-full max-w-[1280px]">
          <FilterChips filters={filters} setFilters={setfilters} />
          <div className="flex flex-col md:flex-row justify-between items-start w-full h-full">
            <div className="flex flex-col w-full md:w-[25%] h-full mb-6 md:mb-0">
              <ThingsToDoFilter
                filters={filters}
                setFilters={setfilters}
                handleClearAll={handleClearAll}
                searchText={searchText}
                setsearchText={setsearchText}
              />
            </div>
            <div className="flex flex-col w-full md:w-[75%] h-full gap-y-5 md:pl-6">
              {thingsToDoFreeTextSearchListForHome?.products?.length >
              0
                ? thingsToDoFreeTextSearchListForHome?.products?.map(
                    (data, index) => (
                      <ThingsToDoCard
                        key={index}
                        item={data}
                        onClick={() => handleThingsToDoCardClick(data)}
                      />
                    )
                  )
                : thingsToDoProductSearchDetails?.products?.length > 0 &&
                  thingsToDoProductSearchDetails?.products?.map(
                    (data, index) => (
                      <ThingsToDoCard
                        key={index}
                        item={data}
                        onClick={() => handleThingsToDoCardClick(data)}
                      />
                    )
                  )}
              {!thingsToDoProductSearchLoading &&
                !thingsToDoFreeTextSearchForHomeLoading &&
                !(
                  thingsToDoFreeTextSearchListForHome?.products
                    ?.length > 0
                ) &&
                !(thingsToDoProductSearchDetails?.products?.length > 0) && (
                  <p className="text-center font-Mulish  text-[36px] font-bold mt-8">
                    No Result Found
                  </p>
                )}
              {/* {thingsToDoProductSearchDetails?.products?.length > 0 && thingsToDoProductSearchDetails?.products?.map((data, index) => (
                <ThingsToDoCard
                  key={index}
                  item={data}
                  onClick={() => handleThingsToDoCardClick(data)}
                />
              ))}

{thingsToDoFreeTextSearchListForHome?.products?.results?.length > 0 && thingsToDoFreeTextSearchListForHome?.products?.results?.map((data, index) => (
                <ThingsToDoCard
                  key={index}
                  item={data}
                  onClick={() => handleThingsToDoCardClick(data)}
                />
              ))} */}
              {(thingsToDoProductSearchLoading ||
                thingsToDoFreeTextSearchForHomeLoading) &&
                Array.from({ length: Viator.numberOfResultsPerPage }, (_, i) => (
                  <SkeletonViatorCard key={i} />
                ))}

              {!thingsToDoProductSearchLoading &&
                !thingsToDoFreeTextSearchForHomeLoading &&
                (
                  thingsToDoFreeTextSearchListForHome?.products
                    ?.length > 0
                 ||
                thingsToDoProductSearchDetails?.products?.length > 0) && (
                  <CustomButton
                    title="See More"
                    className="!h-[48px] !w-fit !my-5 !md:my-0 font-bold !rounded-[60px] self-center"
                    fontSize="16px"
                    onClick={handleSeeMore}
                    disabled={thingsToDoProductSearchLoading}
                  />
                )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Stack>
  );
};

export default ThingsToDo;
