import { useState } from "react";
import { formatDate } from "../../../utils/helpers";
import { Box, Typography } from "@mui/material";
import { Agoda, CarIcon, PopularTripsDummy, StarIcon, Viator } from "../../../assests/exportImages";
import { PlacesTypeIcons } from "../../../constants";
import styles from "./section.module.css";
import { typographyOptions } from "../../../constants";
import { colors } from "../../../themes/colors";
import {
  DescriptionCard,
  RatingsUI,
  TimeAndDistance,
} from "./smallSectionsDetailPage";
import { Book } from "lucide-react";
import BookNow from "./BookNow";

const AgodaViatorItenaryDetailCard = ({ trip }) => {
  const type = trip?.type;
  let agodaUrl = `https://www.agoda.com/partners/partnersearch.aspx?cid=1933768&hid=${trip?.productId}&currency=INR`;
  const handleClick = () => {
    type === "VIATOR" ? window.open(trip?.reference, "_blank") : window.open(agodaUrl, "_blank");
  }
  return (
    <Box onClick={handleClick}>
      <Box className={styles.itenaryDetailsCard}>
        <div className="flex justify-between items-center px-[10px] py-[7px]">
          <img
            src={type === "AGODA" ? Agoda : Viator}
            alt={`${type} logo`}
            style={{
              width: type === "AGODA" ? "35px" : "42px",
              height: type === "AGODA" ? "17px" : "12px",
            }}
          />
          <BookNow />
        </div>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Box className={styles.detailPageUI}>
            <img
              src={trip?.photoReference || PopularTripsDummy}
              alt="place img"
              style={{
                width: "70px",
                height: "70px",
                borderRadius: "12px",
                marginBottom: "7px",
              }}
            />

            <Box>
              <Typography
                fontWeight={"700"}
                color={colors.transparentWhite}
                variant={typographyOptions.H2}
              >
                {trip.name ? trip.name : trip.startAddress}

              </Typography>
              <div className="font-[400] text-[12px] text-[#999999]">
                {trip.place_type && trip.place_type}
              </div>

            </Box>
          </Box>
        </Box>
        <RatingsUI trip={trip} />

        {/* Description -card */}
        {trip?.description && <DescriptionCard description={trip?.description} />}
      </Box>
      {(trip?.time || trip?.distance) && <TimeAndDistance trip={trip} />}
    </Box>
  );
};

export default AgodaViatorItenaryDetailCard;
