import { useId } from "react";

const Star = ({ fill = 100, width = 14, height = 12 }) => {
  const id = useId(); // Generates a unique ID

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10 9">
      <defs>
        <linearGradient id={`grad-${id}`} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset={`${fill}%`} stopColor="#EAAE33" /> {/* Yellow */}
          <stop offset={`${fill}%`} stopColor="#D3D3D3" /> {/* Grey */}
        </linearGradient>
      </defs>
      <path
        d="M4.42931 0.85624C4.60892 0.303454 5.39096 0.303454 5.57058 0.85624L6.21232 2.83132C6.29264 3.07853 6.52302 3.24591 6.78295 3.24591H8.85967C9.44091 3.24591 9.68257 3.98968 9.21234 4.33132L7.53224 5.55198C7.32195 5.70477 7.23395 5.97559 7.31428 6.2228L7.95602 8.19788C8.13563 8.75067 7.50294 9.21034 7.03272 8.8687L5.35261 7.64804C5.14232 7.49525 4.85756 7.49525 4.64727 7.64804L2.96717 8.8687C2.49694 9.21034 1.86425 8.75067 2.04386 8.19788L2.68561 6.2228C2.76593 5.97559 2.67794 5.70477 2.46764 5.55198L0.787541 4.33132C0.317313 3.98968 0.558979 3.24591 1.14021 3.24591H3.21693C3.47687 3.24591 3.70724 3.07853 3.78757 2.83132L4.42931 0.85624Z"
        fill={`url(#grad-${id})`}
      />
    </svg>
  );
};

export default Star;
