import React from "react";
import { CarRental, FlightAlert, InteractiveMaps, MoreReasonsTag, SocialProfile } from "../../../assests/exportImages";

const MoreReasons = () => {
    return (
        <div className="bg-white w-screen flex items-center justify-center gap-10 py-28 flex-wrap">
            <div className="max-w-[250px] flex flex-col items-center justify-center">
                <p className="heading text-[52px] font-extrabold lg:text-left m-0 leading-[60px]">
                    More
                    reasons
                </p>
                <img
                    width={332}
                    src={MoreReasonsTag}
                    alt="More Reasons Tags"
                    className="-ml-2"
                />
            </div>

            <div className="flex gap-8 flex-wrap items-center justify-center">
                <img
                    width={178}
                    src={InteractiveMaps}
                    alt="InteractiveMaps Image"
                    className='hover:scale-110 duration-200 md:w-[178px] w-[158px]'
                />

                <img
                    src={SocialProfile}
                    alt="SocialProfile Image"
                    className='hover:scale-110 duration-200 md:w-[178px] w-[158px]'
                />
                <img
                    width={178}
                    src={FlightAlert}
                    alt="FlightAlert Image"
                    className="transform rotate-[15deg] hover:scale-110 duration-200 md:w-[178px] w-[158px]"
                />
                <img
                    width={178}
                    src={CarRental}
                    alt="CarRental Image"
                    className='hover:scale-110 duration-200 md:w-[178px] w-[158px]'
                />

            </div>

        </div>
    );
};

export default MoreReasons;
