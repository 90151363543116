import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import HotelOverviewNearbyCard1 from "../HotelOverviewNearbyCard1";
import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";

const SimilarExperience = ({ city, type }) => {
  const {
    citySearchSimilarExperienceList,
    citySearchNearbyPlacesList,
    citySearchSimilarExperienceListLoader,
  } = useSelector((state: RootState) => state.screenReducer);

  const [currentComponent, setcurrentComponent] = useState<{ results: any[] } | null>(null);
  const [ComponentName, setComponentName] = useState("");

  useEffect(() => {
    if (type === "NEARBY_PLACES") {
      setcurrentComponent(citySearchNearbyPlacesList);
      setComponentName("Nearby Places");
    } else if (type === "SIMILAR_EXPERIENCE") {
      setcurrentComponent(citySearchSimilarExperienceList);
      setComponentName("Similar Experience");
    }
  }, [type, citySearchNearbyPlacesList, citySearchSimilarExperienceList]);

  return (
    <div className="flex justify-center overflow-hidden md:overflow-visible mt-2">
      <div className="flex flex-col w-full md:w-auto justify-center">
        <p className="text-black text-[16px] font-bold mb-8">{ComponentName}</p>

        {/* Scrollable Wrapper */}
        <div className="overflow-x-auto w-full">
          <div className="flex gap-4 flex-nowrap md:flex-wrap md:w-[1120px]">
            {citySearchSimilarExperienceListLoader
              ? Array.from({ length: 8 }).map((_, index) => (
                  <div className="h-auto md:h-[372px] w-[268px] bg-white rounded-[21px] p-1 pb-[9px]">
                  <Skeleton variant="rectangular" width="260px" height={200} className="rounded-[21px]" />
                  <div className="px-2 mt-2">
                    <Skeleton variant="text" width="60%" height={20} />
                    <Skeleton variant="text" width="80%" height={20} />
                    <Skeleton variant="text" width="40%" height={20} />
                    <Skeleton variant="text" width="50%" height={20} />
                  </div>
                </div>
                ))
              : currentComponent?.results?.slice(0, 8).map((item, index) => (
                  <div key={index} className="shrink-0 ">
                    <HotelOverviewNearbyCard1 item={item} city={city} />
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimilarExperience;
