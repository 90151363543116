import { screenRoutes } from "./constants/routeConstants";
import en from "./locale/en.json";

interface IFlattenItems {
  tripArray: Object[];
}

export const flattenItems = (tripArray: IFlattenItems) => {
  const items: object[] = [];

  function extractItems(array: any) {
    array.forEach((item: any) => {
      if (!item.items && Object.keys(item)?.length) items.push(item);

      if (item.items && item.items.length > 0) {
        extractItems(item.items);
      }
    });
  }

  extractItems(tripArray);
  return items;
};

// export function flattenItems(tripArray) {
//   const items = [];

//   function extractItems(array) {
//     array.forEach((item) => {
//       items.push(item);
//       if (item.items && item.items.length > 0) {
//         extractItems(item.items);
//       }
//     });
//   }

//   extractItems(tripArray);
//   return items;
// }

// Return the first two initials
export const getInitials = (name: string) => {
  const nameParts = name.split(" ").filter(Boolean);
  const initials = nameParts
    .map((part) => part[0])
    .join("")
    .toUpperCase();
  return initials.slice(0, 1);
};

export const getFullName = (first = "", last = "") => {
  let fullName = `${first ? first : " "}` + " " + `${last ? last : " "}`;
  fullName = fullName.trim();
  return fullName;
};

export const getImagefromBase64 = (url: any) => {
  const imageSrc = `data:image/png;base64,${url}`;
  return imageSrc;
};

export const getStoredImages = (reference: string | null) => {
  const images = localStorage.getItem(`${reference}`);
  return images ? JSON.parse(images) : null;
};

export const clearLocalStorage = () => {
  localStorage.clear();
  return "cleared";
};

export const getRandomIndex = (arr: string | any[], indexCounts: number) => {
  const randomIndexes: number[] = [];
  for (let i = 0; i < indexCounts; i++) {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * arr.length);
    } while (randomIndexes.includes(randomIndex));
    randomIndexes.push(randomIndex);
  }
  return randomIndexes;
};

export function formatDate(dateString: string | number | Date, place = "") {
  const date = new Date(dateString);

  // Short day of the week (first 3 letters)
  const dayOfWeek = date
    .toLocaleDateString("en-US", { weekday: "short" })
    .slice(0, 3);

  // Two-digit month and short month (first 3 letters)
  const digitMonth = date.toLocaleDateString("en-US", { month: "2-digit" });
  const month = date
    .toLocaleDateString("en-US", { month: "short" })
    .slice(0, 3);

  // Two-digit day and full year
  const day = date.toLocaleDateString("en-US", { day: "2-digit" });
  const year = date.getFullYear();

  // Time with AM/PM
  const timeOptions: any = { hour: "2-digit", minute: "2-digit", hour12: true };
  const time = date.toLocaleTimeString("en-US", timeOptions);

  if (place) {
    return {
      dayOfWeek,
      day,
      month,
      year,
      digitMonth,
      time,
    };
  } else {
    return `${day} ${month} ${year}, ${dayOfWeek} ${time}`;
  }
}

interface IQuickLink {
  link: string;
  text: string;
}

export interface IQuickLinks extends Array<IQuickLink> {}

export const quickLinksArr: IQuickLinks = [
  // { link: screenRoutes.HOME, text: en.explore },
  { link: screenRoutes.EXPLORE, text: en.explore },
  { link: screenRoutes.TRAVEL_GUIDES, text: en.travelGuides },
  { link: screenRoutes.HOTELS, text: en.hotels },
  { link: screenRoutes.THINGS_TO_DO, text: en.thingsToDo },
  // { link: screenRoutes.GET_APP, text: en.downloadApp },
];
