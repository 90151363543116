import { useEffect, useState } from "react";
import { CustomButton } from "../../components/atom/CustomButton";
import { AlternateEmailOutlined, Check, CheckCircleRounded, Circle, Close, FmdGoodOutlined, OpenInNew, PhoneOutlined, Star } from "@mui/icons-material";
import { Agoda } from "../../assests/exportImages";
import { convertRatingToFiveScale, isEmptyObject, partnerURLAgoda } from "../../utils/helpers";
import DownloadAppCard from "../components/DownloadAppCard";
import SimilarExperience from "../components/SimilarExperience";
import { Divider, useMediaQuery } from "@mui/material";
import { colors } from "../../themes/colors";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import AgodaBookNow from "../../components/molecule/AgodaBookNow";

// function formatTime(timeStr) {
//   console.log('time',timeStr);


//   // Check if the input follows the pattern of digits followed by AM or PM
//   if (!timeStr || typeof timeStr !== "string") {
//     return "Invalid time input";
//   }
//   const timeRegex = /^(\d+)(AM|PM)$/i;
//   const match = timeStr.match(timeRegex);

//   if (!match) {
//     return "Invalid time format. Please use format like '2PM' or '10AM'.";
//   }

//   // Extract the hour and period (AM/PM)
//   const hour = parseInt(match[1], 10);
//   const period = match[2].toUpperCase();

//   // Validate hour
//   if (hour < 1 || hour > 12) {
//     return "Hour must be between 1 and 12.";
//   }

//   // Format the time as HH:00 AM/PM
//   return `${hour}:00 ${period}`;
// }

const getRatingRemark = (rating: number) => {
  if (rating === 0) return null;
  if (rating >= 4.5) return "Excellent";
  if (rating >= 4.0) return "Very Good";
  if (rating >= 3.0) return "Good";
  if (rating >= 2.0) return "Fair";
  return "Poor";
};

function formatTime(time) {
  if (!time || typeof time !== "string") {
    return "Invalid time input";
  }
  return time.replace(/^(\d)([APM])$/, '0$1:00$2').replace(/^(\d{2})([APM])$/, '$1:00$2');
}
const HotelOverviewPanel = ({ hotelDetails, searchBarData, paramsData }) => {

  const { hotelName, cityId, ratesFrom, overview, zipcode, country, state, city, addressline2, addressline1, checkOut, checkIn, url, starRating, ratingAverage, numberOfReviews } = hotelDetails;
  const [isReadMore, setIsReadMore] = useState(false);
  const [isReadMoreInfo, setIsReadMoreInfo] = useState(false);

  const rating = convertRatingToFiveScale(ratingAverage);

  // const overviewDescription = hotelDetails?.overview
  // "There's no getting around it, Bangkok is intense. Temples swarm with crowds. Street food sizzles into the early hours. The heat doesn't quit. And the nightlife. Great Barrier Reef Snorkeling and Diving Cruise from Cairns. Bangkok is intense. Temples swarm with crowds. Street food sizzles into the early hours. The heat doesn't quit. And the nightlife. Great Barrier Reef Snorkeling and Diving Cruise from Cairns.";

  const toggleReadMore = () => {
    setIsReadMore((prevState) => !prevState);
  };

  const additionalInfo = [
    "Confirmation will be received at time of booking",
    "Not wheelchair accessible",
    "A minimum of 2 people per booking is required",
    "Camera Recommended",
    "Infants must sit on laps",
    "No heart problems or other serious medical conditions",
  ];

  const { citySearchSimilarExperienceListLoader } = useSelector((state: RootState) => state.screenReducer);
  const [partnerURL, setpartnerURL] = useState("")

  const [overviewLength, setoverviewLength] = useState(0)
  const isMobile = useMediaQuery("(max-width:700px)");

  useEffect(() => {
    if(isMobile){
      setoverviewLength(130)
    }else{
      setoverviewLength(500)
    }
  }, [isMobile])

    useEffect(() => {
      if(isEmptyObject(hotelDetails)) return;
      const url = partnerURLAgoda(hotelDetails, searchBarData, paramsData )
      setpartnerURL(url)
    }, [hotelDetails, searchBarData, paramsData])
  

  return (
    <div className="w-full font-Mulish mb-10">
    <div className="relative flex md:flex-row flex-col-reverse ">
      <div className="leftPanel w-full ">
        {overview && <div className="w-full border-b-2 border-[#F1F1F1] pb-5">
          <p className="text-sm font-medium text-[#666] md:max-w-[90%] mt-3 leading-[28px]">
            {overview?.length > overviewLength
              ? (isReadMore ? overview : overview.slice(0, overviewLength) + "...")
              : overview}

            {overview?.length > overviewLength && (
              <span
                className="text-sm ml-1 font-bold text-[#333] underline cursor-pointer"
                onClick={toggleReadMore}
              >
                {isReadMore ? "Read Less" : "Read More"}
              </span>

            )}
          </p>
        </div>}
        {(checkIn !=="" && checkOut !=="") && <div className="flex flex-col w-full border-b-2 border-[#F1F1F1] py-5 gap-y-3">
          {checkIn !=="" && <p className="text-sm font-bold text-[#000]">
            Check in:
            <span className="text-[#999] ml-1"> {formatTime(checkIn)} </span>
          </p>}
          {checkOut !=="" && <p className="text-sm font-bold text-[#000]">
            Check out:
            <span className="text-[#999] ml-1"> {formatTime(checkOut)} </span>
          </p>}
        </div>}
        <div className="flex md:flex-row flex-col justify-between w-full py-5 border-b-2  border-[#F1F1F1] gap-x-3">
          <div className="flex flex-col md:w-[50%] gap-y-6">
            {(addressline1 || addressline2 || city || state || country) &&<div className="flex flex-col gap-y-1">
              <p className="text-sm font-bold text-[#000]">
                <FmdGoodOutlined className="!w-[18px] !h-[18px] mr-1 mb-1" />{" "}
                Address
              </p>
              <p className="text-sm font-medium text-[#666] pl-6">
                {`${addressline1}, ${addressline2}, ${city}, ${state}, ${country}, ${zipcode}`}
              </p>
            </div>}
            {/* <div className="flex flex-col gap-y-1">
              <p className="text-sm font-bold text-[#000]">
                <PhoneOutlined className="!w-[18px] !h-[18px] mr-1 mb-1" />
                Phone
              </p>
              <p className="text-sm font-medium text-[#666] pl-6">+65 6670 9750</p>
            </div> */}
          </div>
          <div className="flex flex-col md:w-[50%] gap-y-6 mt-5 md:mt-0">
            {url && <div className="flex flex-col gap-y-1">
              <p className="text-sm font-bold text-[#000]">
                <AlternateEmailOutlined className="!w-[18px] !h-[18px] mr-1 mb-1" />
                Website
              </p>
              <a href={partnerURL} target="_blank"><p className="text-sm font-medium text-[#666] pl-6 underline">{partnerURL?.slice(0, 44) + "..."}</p></a>
            </div>}
            {/* <div className="flex flex-col gap-y-1">
              <p className="text-sm font-bold text-[#000]">
                <PhoneOutlined className="!w-[18px] !h-[18px] mr-1 mb-1" />
                Working Hours
              </p>
              <p className="text-sm font-bold text-[#F05D5D] pl-6">Closed</p>
              <div className="flex flex-row gap-x-5 justify-start pl-6">
                <div className="flex flex-col gap-y-1">
                  <p className="text-sm font-medium text-[#666]">Mon</p>
                  <p className="text-sm font-medium text-[#666]">Tue</p>
                  <p className="text-sm font-medium text-[#666]">Wed</p>
                  <p className="text-sm font-medium text-[#666]">Thu</p>
                  <p className="text-sm font-medium text-[#666]">Fri</p>
                  <p className="text-sm font-medium text-[#666]">Sat</p>
                  <p className="text-sm font-medium text-[#666]">Sun</p>
                </div>
                <div className="flex flex-col gap-y-1">
                  <p className="text-sm font-medium text-[#666]">Closed</p>
                  <p className="text-sm font-medium text-[#666]">Closed</p>
                  <p className="text-sm font-medium text-[#666]">9 AM – 6 PM</p>
                  <p className="text-sm font-medium text-[#666]">9 AM – 6 PM</p>
                  <p className="text-sm font-medium text-[#666]">9 AM – 6 PM</p>
                  <p className="text-sm font-medium text-[#666]">9 AM – 6 PM</p>
                  <p className="text-sm font-medium text-[#666]">9 AM – 6 PM</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* <div className="flex flex-col w-full border-b-2 border-[#F1F1F1] py-5 gap-y-3">
          <p className="text-base font-bold text-[#000] mb-1">
            What’s Included
          </p>
          <div className="flex flex-row justify-start w-full gap-x-3">
            <div className="flex flex-col w-[50%] gap-y-3">
              <p className="font-Mulish text-xs font-medium text-[#666] !mb-1">
                <Check className="!w-[16px] !h-[16px] !text-[#20B08F] !mr-3" />
                Lunch at local restaurant
              </p>
              <p className="font-Mulish text-xs font-medium text-[#666] !mb-1">
                <Check className="!w-[16px] !h-[16px] !text-[#20B08F] !mr-3" />
                All taxes and service fees included
              </p>
              <p className="font-Mulish text-xs font-medium text-[#666] !mb-1">
                <Check className="!w-[16px] !h-[16px] !text-[#20B08F] !mr-3" />
                Driver/guide
              </p>
              <p className="font-Mulish text-xs font-medium text-[#666] !mb-1">
                <Check className="!w-[16px] !h-[16px] !text-[#20B08F] !mr-3" />
                Transport by air-conditioned minivan
              </p>
              <p className="font-Mulish text-xs font-medium text-[#666] !mb-1">
                <Check className="!w-[16px] !h-[16px] !text-[#20B08F] !mr-3" />
                Lunch
              </p>
            </div>
            <div className="flex flex-col w-[50%] gap-y-3">
              <p className="font-Mulish text-xs font-medium text-[#666] !mb-1">
                <Close className="!w-[16px] !h-[16px] !text-[#F05D5D] !mr-3" />
                Souvenir photos (available to purchase)
              </p>
            </div>
          </div>
        </div> */}
        {/* <div className="flex flex-col w-full border-b-2 border-[#F1F1F1] py-5 gap-y-3">
          <p className="text-base font-bold text-[#000] mb-1">
            What’s Included
          </p>
          <div className="flex flex-row justify-start w-full gap-x-3">
            <div className="flex flex-col w-[50%] gap-y-3">
              {additionalInfo
                .slice(0, isReadMoreInfo ? additionalInfo.length : 5)
                .map((info, index) => (
                  <p
                    key={index}
                    className="font-Mulish text-xs font-medium text-[#666] !mb-1 content-center"
                  >
                    <Circle className="!w-[6px] !h-[6px] !text-[#000] !mr-3" />
                    {info}
                  </p>
                ))}
              <p
                className="text-sm font-medium text-[#333] underline cursor-pointer"
                onClick={toggleReadMoreInfo}
              >
                {isReadMoreInfo ? "Read Less" : "Read More"}
              </p>
            </div>
          </div>
        </div> */}

      </div>
      <div className="hidden rightPanel md:flex justify-between w-[400px] px-5">
          <AgodaBookNow searchBarData={searchBarData} paramsData={paramsData} />
      </div>

    </div>
    <div className="my-[60px]">
    <DownloadAppCard />
    </div>
    <div className="flex flex-col w-full py-5 gap-y-3">
          <p className="text-base font-bold text-[#000] mb-5">Reviews</p>
          <div className="flex flex-row justify-start w-full mb-5 gap-x-2">
            {/* <p className="font-bold text-[42px] text-[#000] leading-9">{rating}</p> */}
            <div className="flex flex-col justify-start">
              <div className="flex flex-row justify-start w-full mb-5 gap-x-2">
                {rating > 0 && (
                  <>
                    <p className="font-bold text-[42px] text-[#000] leading-9">{rating}</p>
                    <div className="flex flex-col justify-start">
                      {getRatingRemark(rating) && (
                        <p className="text-sm font-medium">{getRatingRemark(rating)}</p>
                      )}
                      <p className="text-xs font-medium">
                        <Star className="mr-1 !w-3 !h-3" />
                        {numberOfReviews} reviews
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>  
          </div>
          <a href={partnerURL} target="_blank">
            {(rating>0) && <div className="w-[162px] h-[76px] border cursor-pointer gap-y-1 flex flex-col justify-center items-center rounded-2xl">
              <div className="flex flex-row gap-x-1 items-center">
                <img src={Agoda} alt="Agoda" className="!h-auto !w-[71px]" />
                <OpenInNew className="!w-4 !h-4" />
              </div>
              <div className="flex flex-row justify-between gap-x-2">
                <p className="text-xs font-bold text-[#333]">{rating}/5</p>
                <p className="text-xs font-bold text-[#ccc]">{numberOfReviews} reviews</p>
              </div>
            </div>}
          </a>
        </div>

        <div className="bg-[#F1F1F1] h-[1px] w-full mt-8 mb-3"></div>

        <SimilarExperience type={"SIMILAR_EXPERIENCE"} city={hotelDetails?.city}/>

    </div>
  );
};

export default HotelOverviewPanel;
