import { Viator } from "../constants";

export const viatorInitialProductBody = (start: number, validatedFilters: string) => ({
    "filtering": validatedFilters,
    "pagination": { "start": start, "count": Viator.numberOfResultsPerPage },
    "currency": "INR"
});

export const viatorSuggestionBody = ( validatedFilters) => ({
    "filtering": validatedFilters,
    "pagination": { "start": 1, "count": Viator.numberOfResultsPerPage },
    "currency": "INR"
});

export const viatorFreeTextSearchBody = (start,searchTerm,count=10 ) => ({
    "searchTerm": searchTerm, "searchTypes": [{ "searchType": "PRODUCTS", "pagination": { "start": start, "count": count } }], "currency": "INR"
});