
import { useSelector } from 'react-redux';
import {  Viator } from '../../constants';
import EChip from '../atom/EChip';
import { RootState } from '../../redux/reducers';

const renderFilterChips = (filters, setFilters, thingsToDoActivitySearch) => {
    const activeFilters = [];
  
    // Price Filter
    if (
      filters?.lowestPrice !== undefined &&
      filters?.highestPrice !== undefined &&
      (filters?.lowestPrice !== Viator.minPrice || filters?.highestPrice !== Viator.maxPrice)
    ) {
      activeFilters.push(
        <EChip
          key="price"
          type="filter"
          label={`₹${filters.lowestPrice} - ₹${filters.highestPrice}`}
          onDelete={() => setFilters({ ...filters, lowestPrice: undefined, highestPrice: undefined })}
        />
      );
    }
  
    // Category (Activity Tags) Filter
    if (filters?.tags?.length > 0) {
      filters.tags.forEach((tag) => {
        const theTag = Viator.activityTags.find((t) => t.tag === tag) || thingsToDoActivitySearch?.data?.content?.find((t) => t.tag === tag);
        if (theTag) {
          activeFilters.push(
            <EChip
              key={theTag.tag}
              type="filter"
              label={theTag.name}
              onDelete={() => setFilters({ ...filters, tags: filters.tags.filter((id) => id !== tag) })}
            />
          );
        }
      });
    }
  
    // Rating Filter
    if (filters?.rating) {
      activeFilters.push(
        <EChip
          key="rating"
          type="filter"
          label={`Rated ${filters.rating.from} & up`}
          onDelete={() => setFilters({ ...filters, rating: undefined })}
        />
      );
    }
  
    // Duration Filter
    if (filters?.durationInMinutes) {
      const durationLabel = Viator.durationOptions.find(
        (opt) => opt.value.from === filters.durationInMinutes.from
      )?.label;
  
      if (durationLabel) {
        activeFilters.push(
          <EChip
            key="duration"
            type="filter"
            label={durationLabel}
            onDelete={() => setFilters({ ...filters, durationInMinutes: undefined })}
          />
        );
      }
    }
  
    // Specials Filter
    if (filters?.flags?.length > 0) {
      filters.flags.forEach((flag) => {
        const flagLabel = Viator.specialsOptions.find((opt) => opt.value === flag)?.name;
        if (flagLabel) {
          activeFilters.push(
            <EChip
              key={flag}
              type="filter"
              label={flagLabel}
              onDelete={() => setFilters({ ...filters, flags: filters.flags.filter((f) => f !== flag) })}
            />
          );
        }
      });
    }
  
    return activeFilters.length > 0 ? activeFilters : null;
  };
  
  
  

const FilterChips = ({filters, setFilters}) => {
  const { thingsToDoActivitySearch } = useSelector((state: RootState) => state.screenReducer);
  return (
    <div className="hidden w-full mt-4 mb-6 md:flex flex-wrap items-center gap-2 sm:gap-3">
    {renderFilterChips(filters, setFilters, thingsToDoActivitySearch)}
  </div>
  )
}

export default FilterChips