import React from 'react'
import { Facebook, Instagram, tagUs1, tagUs2, tagUs3, tagUs4, tagUs5, tagUs6, Twitter, yt } from '../../../assests/exportImages'

const icons = [
    { name: Instagram, link: 'https://www.instagram.com/escapekar/' }, { name: yt, link: 'https://www.youtube.com/@Escapekar' }, { name: Twitter, link: 'https://x.com/escapekar_app' }, { name: Facebook, link: 'https://www.facebook.com/escapekar/' }
]

const TagUs = () => {
    return (
        <div className='md:py-24 bg-white w-screen  flex items-center justify-center overflow-hidden'>
            <div className='flex items-center gap-32 '>
                <div className='hidden md:block'>
                    <img src={tagUs1} alt='social media logo' className=' hover:scale-110 duration-200' />
                    <img src={tagUs3} alt='social media logo' className='relative right-[70px] top-[15px]  hover:scale-110 duration-200' />
                    <img src={tagUs6} alt='social media logo' className='relative left-[50px] bottom-[30px]  hover:scale-110 duration-200' />
                </div>
                <div className='text-center'>
                    <p className="heading">
                        Tag us on your next trip
                    </p>
                    <p className=" text-base md:text-2xl lg:text-base font-normal max-w-[600px] mb-10 font-Mulish text-[#666666]">
                        #Escapekar #LetsEscapekar #TimeToEscape #TakeABreak
                    </p>
                    <div className='flex  gap-4 items-center justify-center'>
                        {icons.map((item) => {
                            return (
                                <a href={item.link} target='_blank'>
                                    <img src={item.name} alt='social media logo' className='hover:scale-110 duration-200' />
                                </a>
                            )
                        })}
                    </div>
                    <div className="md:hidden flex mt-24 gap-2 overflow-hidden justify-center">
                        <img src={tagUs6} alt="social media logo" className=" relative top-14" />
                        <img src={tagUs3} alt="social media logo" className=" relative top-20 scale-[0.9]" />
                        <img src={tagUs5} alt="social media logo" className=' relative top-2' />
                    </div>



                </div>
                <div className='ml-5 hidden md:block'>
                    <img src={tagUs2} alt='social media logo' className='relative left-[70px] bottom-[12px]  hover:scale-110 duration-200' />
                    <img src={tagUs5} alt='social media logo' className='relative right-[70px] bottom-[42px] hover:scale-110 duration-200 ' />
                    <img src={tagUs4} alt='social media logo' className='relative left-[20px] bottom-[34px] hover:scale-110 duration-200' />
                </div>


            </div>

        </div>
    )
}

export default TagUs