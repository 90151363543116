import { InputAdornment, TextField } from "@mui/material";
import { forwardRef, useState, useEffect } from "react";
import useDebounce from "../../../hooks/useDebounce";
import { screenActionsCall } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import SearchCity from "../../Filters/SearchCity";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import { CalendarToday, People } from "@mui/icons-material";
import moment, { Moment } from "moment";
import RoomGuestSelector from "../../Filters/RoomGuestSelector";
import { UsersRound } from "lucide-react";
import DatePicker from "../../atom/DatePicker";
import { CustomButton } from "../../atom/CustomButton";
import { viatorFreeTextSearchBody } from "../../../config/apiBody";
import CityProductListPopUp from "./CityProductListPopUp";

interface ISearchDateInput {
  startAdornment?: any;
  type?: string;
  placeholder?: string;
  value?: any;
  searchBarData?: ISearchBarData;
  setsearchBarData?: React.Dispatch<React.SetStateAction<ISearchBarData>>;
  handleSearch?: (searchData: ISearchBarData) => void;
  handleThingsToDoCardClick?: (card: any) => void;
}


interface ISearchBarData {
  searchText?: string;
  isProduct?: boolean,
  destinationId?: string,
  startDate?: string;
  endDate?: string;
}

const SearchDate = forwardRef((props: ISearchDateInput) => {
  const {
    startAdornment,
    type = "text",
    placeholder,
    searchBarData,
    setsearchBarData,
    handleSearch,
    handleThingsToDoCardClick
  } = props || {};
  const dispatch = useDispatch();
  const [searchBarDataLocal, setsearchBarDataLocal] = useState(searchBarData);

  const debouncedSearchValue = useDebounce(searchBarDataLocal?.searchText, 500);

  useEffect(() => {
    if (debouncedSearchValue) {
      dispatch(screenActionsCall.getThingsToDoDestinationList(debouncedSearchValue));
      dispatch(screenActionsCall.getThingsToDoFreeTextSearch(viatorFreeTextSearchBody(1,debouncedSearchValue)));
    }
  }, [debouncedSearchValue, dispatch]);

  const [openSearch, setOpenSearch] = useState(false);

  const handleCloseSearch = () => {
    setOpenSearch(false);
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setsearchBarDataLocal((prevData) => ({
      ...prevData,
      startDate: startDate ? startDate : "",
      endDate: endDate ? endDate : "",
    }));
  };

  useEffect(() => {
    if (searchBarDataLocal?.searchText !== "") setOpenSearch(true);
    if (searchBarDataLocal?.searchText === "") setOpenSearch(false);
  }, [searchBarDataLocal?.searchText])
  
  const handleSearchButton = () => {
    
    setsearchBarData(searchBarDataLocal);
    handleSearch && handleSearch(searchBarDataLocal);
  };
  

  return (
    <div className="w-full relative ">
      <div className="border-0 flex md:flex-row flex-col justify-between items-center md:rounded-[60px] rounded-2xl md:px-2.5 text-lg w-full md:h-[70px] h-auto bg-white shadow-md">
        <div className="md:w-[100%] w-full pl-3 md:pl-0 border-b-[2px] py-1 md:py-0 md:border-b-[0px] border-[#F1F1F1]">
          <TextField
            id="search"
            variant="standard"
            placeholder={placeholder}
            type={type || "text"}
            role="button"
            onChange={(e) => {
              setsearchBarDataLocal({
                ...searchBarDataLocal,
                searchText: e.target.value,
                destinationId: "",
              });
            }}
            value={searchBarDataLocal?.searchText}
            inputProps={{ sx: { border: "0px", outline: "0px" } }}
            InputProps={{
              sx: {
                fontSize: "16px",
                paddingRight: "8px",
                paddingY: "10px",
                width: "100%",
                '@media (max-width: 600px)': {
                  fontSize: "14px",
                },
              },
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ),
            }}
            sx={{
              color: "rgba(60, 60, 67, 0.4)",
              width: "100%",
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
          />                    
        </div>
                <div className="w-full md:border-l-[2px] flex md:flex-row md:items-center flex-col justify-between border-[#F1F1F1]  py-2 md:py-0">
                  <DatePicker startDate={searchBarDataLocal?.startDate} endDate={searchBarDataLocal?.endDate} onDatesChange={handleDatesChange} />
                  <div className="mx-5 md:mx-0  my-2 ">
                            <CustomButton
                              title="Search"
                              className="!md:h-[56px] !h-[48px] w-full font-bold !rounded-[60px]"
                              fontSize="16px"
                              onClick={handleSearchButton}
                              disabled={searchBarDataLocal?.searchText === ""}
                            />
                            </div>
                </div>
      </div>

      {openSearch && (
        <div className="absolute left-0 z-100">
          <CityProductListPopUp handleClose={handleCloseSearch} setsearchBarDataLocal={setsearchBarDataLocal} handleThingsToDoCardClick={handleThingsToDoCardClick} />
        </div>
      )}
    </div>
  );
});

export default SearchDate;
