import { useState } from "react";
import Star from "../../assests/svgsComponent/Star";
import { Viator } from "../../constants";
import CustomRadio from "../atom/CustomRadioButton";

const Rating = ({ filters, setFilters }) => {


  const setSelected = (value) => {
    setFilters({ ...filters, rating: value });
  };



  return (
    <div className="w-flex flex-col items-center md:items-start">
      <h3 className="text-lg font-semibold mb-6 hidden md:block">Rating</h3>
      <div className="space-y-2">
        {Viator.ratingOptions.map((option, index) => (
          <label
            key={index}
            className="flex items-center space-x-2 cursor-pointer"
            onClick={() => setSelected(option.value)}
          >
            <CustomRadio selected={filters.rating?.from === option.value.from} onClick={() => setSelected(option.value)} />

            <div className="flex gap-1 items-center justify-center">
              {Array.from({ length: 5 }, (_, starIndex) => {
                const fillPercentage = starIndex < option.value.from ? 100 : 0;
                return <Star key={starIndex} fill={fillPercentage} height={25} width={20} />;
              })}
            </div>
            {index !== 0 && <p className="font-[500] text-[14px] font-Mulish">& up</p>}
          </label>
        ))}
      </div>
    </div>
  );
  
};

export default Rating;
