import { Box, Button, Divider, Modal, Stack, Typography } from "@mui/material";
import { CustomButton } from "../../../components/atom/CustomButton";
import { followersRange, platforms, typographyOptions } from "../../../constants";
import { colors } from "../../../themes/colors";
import Input from "../../../components/atom/Input";
import { Like } from "../../../assests/exportImages";
import { useEffect, useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { googleMapApiKey } from "../../../config/apiurls";
import Styles from '../../Auth/index.module.css';
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import en from "../../../locale/en.json";
import CustomSelect from "../../../components/atom/Select";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "810px",
  height: "592px",
  bgcolor: colors.primaryWhite,
  borderRadius: "20px",
  boxShadow: 24,

  "@media (max-width: 768px)": {
    width: "90%",
  },
};


const LocationComponent = ({ location, setLocation, locationInput, setlocationInput }) => {

  return (
    <div className="w-[480px]">
      <p className="md:text-[20px] text-[16px] font-Mulish text-black  font-bold text-left mb-5">
        Where are you located?
      </p>
      <ReactGoogleAutocomplete
        apiKey={googleMapApiKey}
        className={Styles.autoCompleteInput}
        style={{ width: '100%' }}
        options={{
          types: ["(regions)"],
        }}
        placeholder={"Select Location"}
        value={locationInput}
        onChange={(e: any) => {
          setlocationInput(e.target.value)
        }}
        onPlaceSelected={(place: any) => {
          if (place && place.formatted_address) {
            setLocation(place.formatted_address);
            setlocationInput(place.formatted_address)
          }
        }}
      />
    </div>
  )
}

const SocialMediaComponent = ({ fields, setFields }) => {


  const addFieldSet = () => {
    setFields([...fields, { id: Date.now(), platform: "", username: "", followers: "" }]);
  };

  return (
    <div className="w-[480px] max-w-[100%]">
      <p className="md:text-[20px] text-[16px] font-Mulish text-black font-bold text-left mb-5">Do you want to share your social accounts?</p>

      {fields.map((field, index) => (
        <div key={field.id} style={{ marginBottom: "32px" }}>
          {index !== 0 && <Divider sx={{ my: '48px', bgcolor: colors.darkBackground }} />}


          <div className="flex gap-4 w-full">

            <CustomSelect
              sx={{ width: '50%' }}
              labelId={`platform-select-${field.id}`}
              id={`platform-select-${field.id}`}
              value={platforms.find((p) => p.name === field.platform)?.displayName || ""}
              handleChange={(e: any) => {
                const newFields = [...fields];
                newFields[index].platform = platforms.find((p) => p.displayName === e)?.name || "";
                setFields(newFields);
              }}
              placeholder="Platform"
              andormentTextSx={{
                "& .MuiTypography-root": {
                  fontSize: "14px"
                }
              }}
              options={platforms.map((p) => p.displayName)}
            />

            <div className="w-[50%] -mb-3">
              <Input
                onChange={(e) => {
                  const newFields = [...fields];
                  newFields[index].username = e.target.value;
                  setFields(newFields);
                }}
                value={field.username}

                customStyle={{ fontSize: "15px" }}
                placeholder="@username"
              />
            </div>
          </div>
          <CustomSelect
            labelId={`followers-select-${field.id}`}
            id={`followers-select-${field.id}`}
            value={followersRange.find((f) => f.name === field.followers)?.displayName || ""}
            handleChange={(e: any) => {
              const newFields = [...fields];
              newFields[index].followers = followersRange.find(f => f.displayName === e)?.name || "";
              setFields(newFields);
            }}
            options={followersRange.map(f => f.displayName)}
            placeholder="Number of followers"
            sx={{ width: "100%" }}
            andormentTextSx={{
              "& .MuiTypography-root": {
                fontSize: "14px"
              }
            }}
          />
        </div>
      ))}

      <Button onClick={addFieldSet} style={{
        textTransform: "none", fontSize: "16px", textDecoration: "underline",
        color: "black"
      }}>
        Add Another
      </Button>
    </div>
  );
};

const TellUsPopOver = ({ open, handleClose }) => {

  const { userDetails } = useSelector(
    (state: RootState) => state.screenReducer
  );


  const [fields, setFields] = useState([{ id: Date.now(), platform: "", username: "", followers: "" }]);
  const [location, setLocation] = useState<string>(userDetails?.location);
  const [locationInput, setlocationInput] = useState(userDetails?.location)
  const [currentComponent, setcurrentComponent] = useState('LOCATION')

  useEffect(() => {
    setcurrentComponent("LOCATION")
    setLocation(userDetails?.location)
    setlocationInput(userDetails?.location)
    setFields([{ id: Date.now(), platform: "", username: "", followers: "" }])
  }, [open]);


  const handleNext = () => {
    if (location === locationInput) {
      setcurrentComponent("SOCIAL_HANDLES")
    }
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ zIndex: 1000 }}
    >
      <Box sx={modalStyle}>
        <div className="flex flex-col h-full">
          <div className="relative bg-[#6425c8] text-white px-6 md:py-8 py-4 flex items-center justify-center rounded-t-[20px] w-full">
            <div className="text-center">
              <h2 className="md:text-[46px] text-[32px] font-fugaz text-white font-normal opacity-40">
                TELL US ABOUT YOU
              </h2>
              <p className="text-[12px] font-Mulish text-white font-normal text-center">
                This helps us create a more personalized and engaging experience for you.
              </p>
            </div>
            <img src={Like} alt="close icon" className="absolute md:right-4 md:top-4 right-1 top-1 md:scale-100 scale-75" onClick={handleClose} />

          </div>

          <div className={`flex justify-center flex-1 my-10 ${currentComponent === "SOCIAL_HANDLES" && `overflow-y-scroll overflow-x-hidden`} px-5`}>
            {currentComponent === "LOCATION" ? <LocationComponent location={location} setLocation={setLocation} locationInput={locationInput} setlocationInput={setlocationInput} /> : <SocialMediaComponent fields={fields} setFields={setFields} />}
          </div>

          <Divider />
          <div className="flex flex-row justify-between px-5 py-5">

            <CustomButton
              title={en.cancel}
              customStyle={{ borderRadius: '20px', backgroundColor: colors.primaryWhite, border: "1px solid black" }}
              customTypography={{ color: colors.secondaryBlack }}
              onClick={handleClose}
            />
            <CustomButton
              disabled={!locationInput}
              onClick={handleNext}
              title={currentComponent === "LOCATION" ? "Next" : en.submit}
              customStyle={{ borderRadius: '20px' }}
            // customTypography={{ color: colors.primaryWhite }}
            // loading={loading}
            />
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default TellUsPopOver
