interface ToggleProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  disabled?: boolean;
  activeColor?: string;
  inactiveColor?: string;
}

export function Toggle({ 
  checked, 
  onChange, 
  label, 
  disabled = false,
  activeColor = 'bg-blue-600',
  inactiveColor = 'bg-gray-200'
}: ToggleProps) {
  return (
    <label className="inline-flex items-center cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          className="sr-only"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
        />
        <div
          className={`block w-14 h-8 rounded-full transition-colors duration-200 ease-in-out ${
            checked ? `bg-[${activeColor}]` : `bg=[${inactiveColor}]`
          } ${disabled ? 'opacity-50' : ''}`}
        />
        <div
          className={`absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform duration-200 ease-in-out ${
            checked ? 'transform translate-x-6' : 'transform translate-x-0'
          }`}
        />
      </div>
      {label && (
        <span className={`ml-3 text-sm font-medium ${disabled ? 'text-gray-400' : 'text-gray-700'}`}>
          {label}
        </span>
      )}
    </label>
  );
}