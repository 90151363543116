import { Viator } from "../../constants";
import { IActionInterface } from "../../interfaces";
import { screenActions } from "../actions/actionTypes";

interface IInitialState {
  publishedTrips: any;
  loadingImages: boolean;
  loading: boolean;
  loadingAllImages: boolean;
  trendingDestinationsList: any;
  publishedTripsLoader: boolean;
  trendingDestinationsListLoader: boolean;
  featuredUsers: any;
  featuredUsersLoader: boolean;
  longTrips: any;
  longTripsLoader: boolean;
  itineraryDetailsTrips: any;
  itineraryDetailsTripsLoader: any;
  weekendGatewaysLoader: boolean;
  weekendGateways: any;
  searchItenairyList: any;
  searchItenairyLoader: boolean;
  featuredUserDetail: any;
  featuredUserDetailLoader: boolean;
  featuredUserTripsList: any;
  searchLoading: boolean;
  searchUserList: any;
  searchDestinationList: any;
  userDetails: any;
  placeDetails: any;
  loadingDestination: boolean;
  myTrips: any;
  myTripsLoader: boolean;
  cityAndHotelList: any;
  cityAndHotelListLoader: boolean;
  hotelDetails: any;
  loadingHotelDetails: boolean;
  citySearchList: any;
  citySearchListLoader: boolean;
  citySearchSimilarExperienceList: any;
  citySearchSimilarExperienceListLoader: boolean;
  citySearchNearbyPlacesList: any;
  citySearchNearbyPlacesLoader: boolean;
  currentLocation: any;
  currentLocationLoader: boolean;
  cityAndHotelListForSearch: any;
  cityAndHotelListForSearchLoader: boolean;
  hotelByIdsData: any;
  hotelByIdsLoader: boolean;

  thingsToDoProductSearchDetails: any;
  thingsToDoProductSearchLoading: boolean;
  thingsToDoLocation: string;
  thingsToDoLocationLoading: boolean;
  thingsToDoActivitySearch: any;
  thingsToDoActivitySearchLoading: boolean;
  thingsToDoDestinationList: any;
  thingsToDoDestinationListLoading: boolean;
  thingsToDoFreeTextSearchList: any;
  thingsToDoFreeTextSearchLoading: boolean;
  thingsToDoFreeTextSearchListForHome: any;
  thingsToDoFreeTextSearchForHomeLoading: boolean;
  thingsToDoProductDetails: any;
  thingsToDoProductDetailsLoading: boolean;
  thingsToDoDestinationFromRefList: any;
  thingsToDoDestinationFromRefListLoader: boolean;

  thingsToDoSimilarExperienceList: any;
  thingsToDoNearbyActivitiesList: any;
  thingsToDoYouMayAlsoLikeList: any;
  thingsToDoNearbyThingsToDoList: any;
  thingsToDoNearbyThingsToDoListLoader: boolean;
  thingsToDoYouMayAlsoLikeListLoader: boolean;
  thingsToDoSimilarExperienceListLoader: boolean;
  thingsToDoNearbyActivitiesListLoader: boolean;
  
  feedbackLoader: boolean;
}

const initialState: IInitialState = {
  publishedTrips: {},
  publishedTripsLoader: false,
  loadingImages: false,
  loading: false,
  loadingAllImages: false,
  trendingDestinationsList: [],
  trendingDestinationsListLoader: false,
  featuredUsersLoader: false,
  featuredUsers: [],
  longTrips: [],
  longTripsLoader: false,
  itineraryDetailsTrips: {},
  itineraryDetailsTripsLoader: false,
  weekendGatewaysLoader: false,
  weekendGateways: [],
  searchItenairyList: [],
  searchItenairyLoader: false,
  featuredUserDetail: [],
  featuredUserDetailLoader: false,
  featuredUserTripsList: [],
  searchUserList: [],
  searchLoading: false,
  searchDestinationList: [],
  userDetails: [],
  placeDetails: {},
  loadingDestination: false,
  myTrips: {},
  myTripsLoader: false,
  cityAndHotelList: [],
  cityAndHotelListLoader: false,
  hotelDetails: {},
  loadingHotelDetails: false,
  citySearchList: {},
  citySearchListLoader: false,
  citySearchSimilarExperienceList: {},
  citySearchSimilarExperienceListLoader: false,
  citySearchNearbyPlacesList: {},
  citySearchNearbyPlacesLoader: false,
  currentLocation: { city: "Delhi", country: "India" },
  currentLocationLoader: false,
  cityAndHotelListForSearch: {},
  cityAndHotelListForSearchLoader: false,
  hotelByIdsData: {},
  hotelByIdsLoader: false,

  thingsToDoProductSearchDetails: { nextStart: 1, products: [] },
  thingsToDoProductSearchLoading: false,
  thingsToDoLocation: "",
  thingsToDoLocationLoading: false,
  thingsToDoActivitySearch: { currentPointer: 0, data: {} },
  thingsToDoActivitySearchLoading: false,
  thingsToDoDestinationList: {},
  thingsToDoDestinationListLoading: false,
  thingsToDoFreeTextSearchList: {},
  thingsToDoFreeTextSearchLoading: false,
  thingsToDoFreeTextSearchListForHome: { nextStart: 1, products: [] },
  thingsToDoFreeTextSearchForHomeLoading: false,
  thingsToDoProductDetails: {},
  thingsToDoProductDetailsLoading: false,
  thingsToDoDestinationFromRefList: [],
  thingsToDoDestinationFromRefListLoader: false,

  thingsToDoSimilarExperienceList: { nextStart: 1, products: [] },
  thingsToDoNearbyActivitiesList: { nextStart: 1, products: [] },
  thingsToDoYouMayAlsoLikeList: { nextStart: 1, products: [] },
  thingsToDoNearbyThingsToDoList: { nextStart: 1, products: [] },
  thingsToDoNearbyThingsToDoListLoader: false,
  thingsToDoYouMayAlsoLikeListLoader: false,
  thingsToDoSimilarExperienceListLoader: false,
  thingsToDoNearbyActivitiesListLoader: false,

  feedbackLoader: false,

};

const screenReducer = (state = initialState, action: IActionInterface) => {
  const { type, payload } = action;
  switch (type) {
    case screenActions.GET_PUBLISHED_TRIP_REQUESTED:
      return {
        ...state,
        publishedTrips: {},
        publishedTripsLoader: true,
      };
    case screenActions.GET_PUBLISHED_TRIP_SUCCEEDED:
      return {
        ...state,
        publishedTrips: payload,
        publishedTripsLoader: false,
      };

    case screenActions.GET_PUBLISHED_TRIP_FAILED:
      return {
        ...state,
        publishedTrips: {},
        publishedTripsLoader: false,
      };

    case screenActions.GET_IMAGES_REQUESTED:
      return {
        ...state,
      };
    case screenActions.GET_IMAGES_FAILED:
      return {
        ...state,
      };
    case screenActions.GET_IMAGES_SUCCEEDED:
      return {
        ...state,
      };
    case screenActions.GET_ALL_IMAGES_REQUESTED:
      return {
        ...state,
        loadingAllImages: true,
      };
    case screenActions.GET_ALL_IMAGES_FAILED:
      return {
        ...state,
        loadingAllImages: false,
      };
    case screenActions.GET_ALL_IMAGES_SUCCEEDED:
      return {
        ...state,
        loadingAllImages: false,
      };

    case screenActions.GET_TRENDING_DESTINATIONS_REQUESTED:
      return {
        ...state,
        trendingDestinationsListLoader: true,
        trendingDestinationsList: [],
      };

    case screenActions?.GET_TRENDING_DESTINATIONS_SUCCESS:
      return {
        ...state,
        trendingDestinationsListLoader: false,
        trendingDestinationsList: payload,
      };

    case screenActions.GET_TRENDING_DESTINATIONS_FAILED:
      return {
        ...state,
        trendingDestinationsListLoader: false,
        trendingDestinationsList: [],
      };

    case screenActions.GET_FEATURED_USERS_REQUESTED:
      return {
        ...state,
        featuredUsers: [],
        featuredUsersLoader: true,
      };

    case screenActions.GET_FEATURED_USERS_SUCCESS:
      return {
        ...state,
        featuredUsers: payload,
        featuredUsersLoader: false,
      };

    case screenActions.GET_FEATURED_USERS_FAILED:
      return {
        ...state,
        featuredUsers: {},
        featuredUsersLoader: false,
      };

    case screenActions.GET_MY_TRIPS_REQUESTED:
      return {
        ...state,
        myTrips: {},
        featuredUsersLoader: true,
      };

    case screenActions.GET_MY_TRIPS_SUCCESS:
      return {
        ...state,
        myTrips: payload,
        myTripsLoader: false,
      };

    case screenActions.GET_MY_TRIPS_FAILED:
      return {
        ...state,
        myTrips: [],
        myTripsLoader: false,
      };

    case screenActions.GET_ITINERARY_DETAILS_REQUESTED:
      return {
        ...state,
        itineraryDetailsTrips: {},
        itineraryDetailsTripsLoader: true,
      };

    case screenActions.GET_ITINERARY_DETAILS_SUCCESS:
      return {
        ...state,
        itineraryDetailsTrips: payload,
        itineraryDetailsTripsLoader: false,
      };

    case screenActions.GET_ITINERARY_DETAILS_FAILED:
      return {
        ...state,
        itineraryDetailsTrips: {},
        itineraryDetailsTripsLoader: false,
      };

    case screenActions.GET_FEATURED_USER_DETAILS_REQUESTED:
      return {
        ...state,
        featuredUserDetail: [],
        featuredUserDetailLoader: true,
      };

    case screenActions.GET_FEATURED_USER_DETAILS_SUCCESS:
      return {
        ...state,
        featuredUserDetail: payload,
        featuredUserDetailLoader: false,
      };

    case screenActions.GET_FEATURED_USER_DETAILS_FAILED:
      return {
        ...state,
        featuredUserDetail: [],
        featuredUserDetailLoader: false,
      };

    case screenActions.GET_FEATURED_USER_PUBLISHED_TRIPS_REQUESTED:
      return {
        ...state,
        featuredUserTripsList: [],
        featuredUserDetailLoader: true,
      };

    case screenActions.GET_FEATURED_USER_PUBLISHED_TRIPS_SUCCESS:
      return {
        ...state,
        featuredUserTripsList: payload,
        featuredUserDetailLoader: false,
      };

    case screenActions.GET_FEATURED_USER_PUBLISHED_TRIPS_FAILED:
      return {
        ...state,
        featuredUserTripsList: [],
        featuredUserDetailLoader: false,
      };

    case screenActions.GET_SEARCHED_ITINERARY_REQUESTED:
      return {
        ...state,
        searchItenairyList: [],
        searchItenairyLoader: true,
      };

    case screenActions.GET_SEARCHED_ITINERARY_SUCCESS:
      return {
        ...state,
        searchItenairyList: payload,
        searchItenairyLoader: false,
      };

    case screenActions.GET_SEARCHED_ITINERARY_FAILED:
      return {
        ...state,
        searchItenairyList: [],
        searchItenairyLoader: false,
      };

    case screenActions.GET_SEARCHED_USERS_REQUESTED:
      return {
        ...state,
      };

    case screenActions.GET_SEARCHED_USERS_SUCCESS:
      return {
        ...state,
      };

    case screenActions.GET_SEARCHED_USERS_FAILED:
      return {
        ...state,
      };

    case screenActions.GET_POPULAR_TRIPS_REQUESTED:
      return {
        ...state,
      };

    case screenActions.GET_POPULAR_TRIPS_SUCCESS:
      return {
        ...state,
      };

    case screenActions.GET_POPULAR_TRIPS_FAILED:
      return {
        ...state,
      };

    case screenActions.GET_WEEKENDS_GATEWAY_REQUESTED:
      return {
        ...state,
        weekendGateways: [],
        weekendGatewaysLoader: true,
      };

    case screenActions.GET_WEEKENDS_GATEWAY_SUCCESS:
      return {
        ...state,
        weekendGateWays: payload,
        weekendGatewaysLoader: false,
      };

    case screenActions.GET_WEEKENDS_GATEWAY_FAILED:
      return {
        ...state,
        weekendGateways: [],
        weekendGatewaysLoader: false,
      };

    case screenActions.GET_LONG_TRIPS_REQUESTED:
      return {
        ...state,
        longTrips: [],
        longTripsLoader: true,
      };

    case screenActions.GET_LONG_TRIPS_SUCCESS:
      return {
        ...state,
        longTrips: payload,
        longTripsLoader: false,
      };

    case screenActions.GET_LONG_TRIPS_FAILED:
      return {
        ...state,
        longTrips: [],
        longTripsLoader: false,
      };

    case screenActions.GET_SORTED_ITINERARY_REQUESTED:
      return {
        ...state,
      };

    case screenActions.GET_SORTED_ITINERARY_SUCCESS:
      return {
        ...state,
      };

    case screenActions.GET_SORTED_ITINERARY_FAILED:
      return {
        ...state,
      };

    case screenActions.GET_PLACE_DETAILS_REQUESTED:
      return {
        ...state,
        placeDetails: {},
        loadingDestination: true,
      };

    case screenActions.GET_PLACE_DETAILS_SUCCESS:
      return {
        ...state,
        placeDetails: payload,
        loadingDestination: false,
      };

    case screenActions.GET_PLACE_DETAILS_FAILED:
      return {
        ...state,
        placeDetails: {},
        loadingDestination: false,
      };

    case screenActions.GET_COORDINATES_REQUESTED:
      return {
        ...state,
      };

    case screenActions.GET_COORDINATES_SUCCESS:
      return {
        ...state,
      };

    case screenActions.GET_COORDINATES_FAILED:
      return {
        ...state,
      };

    case screenActions.SEARCH_USERS_REQUESTED:
      return {
        ...state,
        searchLoading: true,
        searchUserList: [],
      };
    case screenActions.SEARCH_USERS_SUCCESS:
      return {
        ...state,
        searchUserList: payload,
        searchLoading: false,
      };
    case screenActions.SEARCH_USERS_FAILED:
      return {
        ...state,
        searchLoading: false,
      };

    case screenActions.GET_SEARCHED_DESTINATIONS_REQUESTED:
      return {
        ...state,
        searchLoading: true,
        searchDestinationList: [],
      };
    case screenActions.GET_SEARCHED_DESTINATIONS_SUCCESS:
      return {
        ...state,
        searchDestinationList: payload,
        searchLoading: false,
      };
    case screenActions.GET_SEARCHED_DESTINATIONS_FAILED:
      return {
        ...state,
        searchLoading: false,
        searchDestinationList: [],
      };

    case screenActions.GET_USER_DETAILS_REQUESTED:
      return {
        ...state,
        userDetails: [],
      };
    case screenActions.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: payload,
      };
    case screenActions.GET_USER_DETAILS_FAILED:
      return {
        ...state,
        userDetails: [],
      };

    //Things To Do
    case screenActions.GET_THINGS_TO_DO_PRODUCT_SEARCH_REQUESTED:
      return {
        ...state,
        thingsToDoProductSearchLoading: true,
      };
    case screenActions.GET_THINGS_TO_DO_PRODUCT_SEARCH_SUCCESS:
      return {
        ...state,
        thingsToDoProductSearchDetails: {
          ...state.thingsToDoProductSearchDetails,
          nextStart: state.thingsToDoProductSearchDetails.nextStart + Viator.numberOfResultsPerPage,
          products: [
            ...(state.thingsToDoProductSearchDetails?.products || []),
            ...payload.products,
          ],
        },
        thingsToDoProductSearchLoading: false,
      };

    case screenActions.GET_THINGS_TO_DO_PRODUCT_SEARCH_FAILED:
      return {
        ...state,
        thingsToDoProductSearchLoading: false,
      };

    case screenActions.CLEAR_THINGS_TO_DO_PRODUCT_SEARCH_SUCCESS:
      return {
        ...state,
        thingsToDoProductSearchDetails: { nextStart: 1, products: [] },
        thingsToDoProductSearchLoading: false,
      };

    case screenActions.GET_THINGS_TO_DO_LOCATION_REQUESTED:
      return {
        ...state,
        thingsToDoLocation: "",
        thingsToDoLocationLoading: true,
      };

    case screenActions.GET_THINGS_TO_DO_LOCATION_SUCCESS:
      return {
        ...state,
        thingsToDoLocation: payload,
        thingsToDoLocationLoading: false,
      };

    case screenActions.GET_THINGS_TO_DO_LOCATION_FAILED:
      return {
        ...state,
        thingsToDoLocation: "",
        thingsToDoLocationLoading: false,
      };

    case screenActions.GET_THINGS_TO_DO_ACTIVITY_SEARCH_REQUESTED:
      return {
        ...state,
        thingsToDoActivitySearchLoading: true,
      };

    case screenActions.GET_THINGS_TO_DO_ACTIVITY_SEARCH_SUCCESS:
      return {
        ...state,
        thingsToDoActivitySearch: {
          ...state.thingsToDoActivitySearch,
          currentPointer: state.thingsToDoActivitySearch?.data?.content?.length || 0, // Keep pointer at last API call
          data: {
            ...payload, // Keep all metadata from payload
            content: [
              ...(state.thingsToDoActivitySearch?.data?.content || []), // Append old results
              ...payload.content, // Add new results
            ],
          },
        },
        thingsToDoActivitySearchLoading: false,
      };


    case screenActions.GET_THINGS_TO_DO_ACTIVITY_SEARCH_FAILED:
      return {
        ...state,
        thingsToDoActivitySearchLoading: false,
      };

    case screenActions.CLEAR_THINGS_TO_DO_ACTIVITY_SEARCH_SUCCESS:
      return {
        ...state,
        thingsToDoActivitySearch: {},
        thingsToDoActivitySearchLoading: false,
      };

    case screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_REQUESTED:
      return {
        ...state,
        thingsToDoFreeTextSearchList: {},
        thingsToDoFreeTextSearchLoading: true,
      };

    case screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_SUCCESS:
      return {
        ...state,
        thingsToDoFreeTextSearchList: payload,
        thingsToDoFreeTextSearchLoading: false,
      };

    case screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FAILED:
      return {
        ...state,
        thingsToDoFreeTextSearchList: {},
        thingsToDoFreeTextSearchLoading: false,
      };

    case screenActions.GET_THINGS_TO_DO_DESTINATION_LIST_REQUESTED:
      return {
        ...state,
        thingsToDoDestinationList: {},
        thingsToDoDestinationListLoading: true,
      };

    case screenActions.GET_THINGS_TO_DO_DESTINATION_LIST_SUCCESS:
      return {
        ...state,
        thingsToDoDestinationList: payload,
        thingsToDoDestinationListLoading: false,
      };

    case screenActions.GET_THINGS_TO_DO_DESTINATION_LIST_FAILED:
      return {
        ...state,
        thingsToDoDestinationList: {},
        thingsToDoDestinationListLoading: false,
      };

    case screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FOR_HOME_REQUESTED:
      return {
        ...state,
        thingsToDoFreeTextSearchForHomeLoading: true,
      };

    case screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FOR_HOME_SUCCESS:
      return {
        ...state,
        thingsToDoFreeTextSearchListForHome: {
          ...state.thingsToDoFreeTextSearchListForHome,
          nextStart: (state.thingsToDoFreeTextSearchListForHome?.nextStart || 1) + (Viator.numberOfResultsPerPage || 0), // Ensure Viator.numberOfResultsPerPage is defined
          products: [
            ...(state.thingsToDoFreeTextSearchListForHome?.products || []),
            ...(payload.products?.results || []), // Ensure results array exists
          ],
        },
        thingsToDoFreeTextSearchForHomeLoading: false,
      };

    case screenActions.CLEAR_THINGS_TO_DO_FREE_TEXT_FOR_HOME_SEARCH_SUCCESS:
      return {
        ...state,
        thingsToDoFreeTextSearchListForHome: { nextStart: 1, products: [] },
        thingsToDoFreeTextSearchForHomeLoading: false,
      };



    case screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FOR_HOME_FAILED:
      return {
        ...state,
        thingsToDoFreeTextSearchListForHome: {},
        thingsToDoFreeTextSearchForHomeLoading: false,
      };

    case screenActions.GET_THINGS_TO_DO_PRODUCT_DETAILS_REQUESTED:
      return {
        ...state,
        thingsToDoProductDetails: {},
        thingsToDoProductDetailsLoading: true,
      };

    case screenActions.GET_THINGS_TO_DO_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        thingsToDoProductDetails: payload,
        thingsToDoProductDetailsLoading: false,
      };
    case screenActions.GET_THINGS_TO_DO_PRODUCT_DETAILS_FAILED:
      return {
        ...state,
        thingsToDoProductDetails: {},
        thingsToDoProductDetailsLoading: false,
      };

    case screenActions.GET_THINGS_LOCATION_FROM_DESTINATION_REF_REQUESTED:
      return {
        ...state,
        thingsToDoDestinationFromRefListLoader: true,
      };

      case screenActions.GET_THINGS_LOCATION_FROM_DESTINATION_REF_SUCCESS:
        return {
          ...state,
          thingsToDoDestinationFromRefList: {
            ...(state.thingsToDoDestinationFromRefList || {}), // Preserve previous entries
            ...payload.products, // Merge new entries
          },
          thingsToDoDestinationFromRefListLoader: false,
        };
      

    case screenActions.GET_THINGS_LOCATION_FROM_DESTINATION_REF_FAILED:
      return {
        ...state,
        thingsToDoDestinationFromRefListLoader: false,
      };





    //Suggestion 1 TTD
    case screenActions.GET_THINGS_TO_DO_SIMILAR_EXPERIENCE_REQUESTED:
      return {
        ...state,
        thingsToDoSimilarExperienceListLoader: true,
      };
    case screenActions.GET_THINGS_TO_DO_SIMILAR_EXPERIENCE_SUCCESS:
      return {
        ...state,
        thingsToDoSimilarExperienceList: {
          ...state.thingsToDoSimilarExperienceList,
          nextStart: state.thingsToDoSimilarExperienceList.nextStart + Viator.numberOfResultsPerPage,
          products: [
            ...(state.thingsToDoSimilarExperienceList?.products || []),
            ...payload.products,
          ],
        },
        thingsToDoSimilarExperienceListLoader: false,
      };

    case screenActions.GET_THINGS_TO_DO_SIMILAR_EXPERIENCE_FAILED:
      return {
        ...state,
        thingsToDoSimilarExperienceListLoader: false,
      };

    case screenActions.GET_THINGS_TO_DO_YOU_MAY_ALSO_LIKE_REQUESTED:
      return {
        ...state,
        thingsToDoYouMayAlsoLikeListLoader: true,
      };
    case screenActions.GET_THINGS_TO_DO_YOU_MAY_ALSO_LIKE_SUCCESS:
      return {
        ...state,
        thingsToDoYouMayAlsoLikeList: {
          ...state.thingsToDoYouMayAlsoLikeList,
          nextStart: state.thingsToDoYouMayAlsoLikeList.nextStart + Viator.numberOfResultsPerPage,
          products: [
            ...(state.thingsToDoYouMayAlsoLikeList?.products || []),
            ...payload.products,
          ],
        },
        thingsToDoYouMayAlsoLikeListLoader: false,
      };

    case screenActions.GET_THINGS_TO_DO_YOU_MAY_ALSO_LIKE_FAILED:
      return {
        ...state,
        thingsToDoYouMayAlsoLikeListLoader: false,
      };

    case screenActions.GET_THINGS_TO_DO_NEARBY_ACTIVITIES_REQUESTED:
      return {
        ...state,
        thingsToDoNearbyActivitiesListLoader: true,
      };

    case screenActions.GET_THINGS_TO_DO_NEARBY_ACTIVITIES_SUCCESS:
      return {
        ...state,
        thingsToDoNearbyActivitiesList: {
          ...state.thingsToDoNearbyActivitiesList,
          nextStart: state.thingsToDoNearbyActivitiesList.nextStart + Viator.numberOfResultsPerPage,
          products: [
            ...(state.thingsToDoNearbyActivitiesList?.products || []),
            ...payload.products,
          ],
        },
        thingsToDoNearbyActivitiesListLoader: false,
      };

    case screenActions.GET_THINGS_TO_DO_NEARBY_ACTIVITIES_FAILED:
      return {
        ...state,
        thingsToDoNearbyActivitiesListLoader: false,
      };

    case screenActions.GET_THINGS_TO_DO_NEARBY_THINGS_TO_DO_REQUESTED:
      return {
        ...state,
        thingsToDoNearbyThingsToDoListLoader: true,
      };

    case screenActions.GET_THINGS_TO_DO_NEARBY_THINGS_TO_DO_SUCCESS:
      return {
        ...state,
        thingsToDoNearbyThingsToDoList: {
          ...state.thingsToDoNearbyThingsToDoList,
          nextStart: state.thingsToDoNearbyThingsToDoList.nextStart + Viator.numberOfResultsPerPage,
          products: [
            ...(state.thingsToDoNearbyThingsToDoList?.products || []),
            ...payload.products,
          ],
        },
        thingsToDoNearbyThingsToDoListLoader: false,
      };

    case screenActions.GET_THINGS_TO_DO_NEARBY_THINGS_TO_DO_FAILED:
      return {
        ...state,
        thingsToDoNearbyThingsToDoListLoader: false,
      };












    case screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_REQUESTED:
      return {
        ...state,
        cityAndHotelList: {},
        cityAndHotelListLoader: true,
      };

    case screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_SUCCESS:
      return {
        ...state,
        cityAndHotelList: payload,
        cityAndHotelListLoader: false,
      };

    case screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_FAILED:
      return {
        ...state,
        cityAndHotelList: payload || {},
        cityAndHotelListLoader: false,
      };

    case screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_FOR_SEARCH_REQUESTED:
      return {
        ...state,
        cityAndHotelListForSearch: {},
        cityAndHotelListForSearchLoader: true,
      };

    case screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_FOR_SEARCH_SUCCESS:
      return {
        ...state,
        cityAndHotelListForSearch: payload,
        cityAndHotelListForSearchLoader: false,
      };

    case screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_FOR_SEARCH_FAILED:
      return {
        ...state,
        cityAndHotelListForSearch: {},
        cityAndHotelListForSearchLoader: false,
      };

    case screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_REQUESTED:
      return {
        ...state,
        cityAndHotelList: {},
        cityAndHotelListLoader: true,
      };

    case screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_SUCCESS:
      return {
        ...state,
        cityAndHotelList: payload,
        cityAndHotelListLoader: false,
      };

    case screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_FAILED:
      return {
        ...state,
        cityAndHotelList: {},
        cityAndHotelListLoader: false,
      };
    // case screenActions.GET_HOTEL_LIST_REQUESTED:
    //   return {
    //     ...state,
    //     loadingHotels: true,
    //     hotelDetails: {},
    //   };

    // case screenActions.GET_HOTEL_LIST_SUCCESS:
    //   return {
    //     ...state,
    //     hotelDetails: payload,
    //     loadingHotels: false,
    //   };

    // case screenActions.GET_HOTEL_LIST_FAILED:
    //   return {
    //     ...state,
    //     hotelDetails: {},
    //     loadingHotels: false,
    //   };

    case screenActions.GET_CITY_SEARCH_REQUESTED:
      return {
        ...state,
        citySearchList: {},
        citySearchListLoader: true,
      };

    case screenActions.GET_CITY_SEARCH_SUCCESS:
      return {
        ...state,
        citySearchList: payload,
        citySearchListLoader: false,
      };

    case screenActions.GET_CITY_SEARCH_FAILED:
      return {
        ...state,
        citySearchList: {},
        citySearchListLoader: false,
      };

    case screenActions.GET_HOTEL_DETAILS_REQUESTED:
      return {
        ...state,
        loadingHotels: true,
        loadingHotelDetails: {},
      };

    case screenActions.GET_HOTEL_DETAILS_SUCCESS:
      return {
        ...state,
        hotelDetails: payload,
        loadingHotelDetails: false,
      };

    case screenActions.GET_HOTEL_DETAILS_FAILED:
      return {
        ...state,
        hotelDetails: {},
        loadingHotelDetails: false,
      };

    case screenActions.GET_CITY_SEARCH_SIMILAR_EXPERIENCE_REQUESTED:
      return {
        ...state,
        citySearchSimilarExperienceList: {},
        citySearchSimilarExperienceListLoader: true,
      };

    case screenActions.GET_CITY_SEARCH_SIMILAR_EXPERIENCE_SUCCESS:
      return {
        ...state,
        citySearchSimilarExperienceList: payload,
        citySearchSimilarExperienceListLoader: false,
      };

    case screenActions.GET_CITY_SEARCH_SIMILAR_EXPERIENCE_FAILED:
      return {
        ...state,
        citySearchSimilarExperienceList: {},
        citySearchSimilarExperienceListLoader: false,
      };


    case screenActions.GET_CITY_SEARCH_NEARBY_PLACES_REQUESTED:
      return {
        ...state,
        citySearchNearbyPlacesList: {},
        citySearchNearbyPlacesLoader: true,
      };
    case screenActions.GET_CITY_SEARCH_NEARBY_PLACES_SUCCESS:
      return {
        ...state,
        citySearchNearbyPlacesList: payload,
        citySearchNearbyPlacesLoader: false,
      };
    case screenActions.GET_CITY_SEARCH_NEARBY_PLACES_FAILED:
      return {
        ...state,
        citySearchNearbyPlacesList: {},
        citySearchNearbyPlacesLoader: false,
      };

    case screenActions.GET_CITY_COUNTRY_FROM_COORDINATES_REQUESTED:
      return {
        ...state,
        currentLocation: {},
        currentLocationLoader: true,
      };
    case screenActions.GET_CITY_COUNTRY_FROM_COORDINATES_SUCCESS:
      return {
        ...state,
        currentLocation: payload,
        currentLocationLoader: false,
      };
    case screenActions.GET_CITY_COUNTRY_FROM_COORDINATES_FAILED:
      return {
        ...state,
        currentLocation: { city: "Delhi", country: "India" },
        currentLocationLoader: false,
      };

    case screenActions.GET_HOTEL_BY_IDS_REQUESTED:
      return {
        ...state,
        hotelByIdsData: {},
        hotelByIdsLoader: true,
      };
    case screenActions.GET_HOTEL_BY_IDS_SUCCESS:
      return {
        ...state,
        hotelByIdsData: payload,
        hotelByIdsLoader: false,
      };
    case screenActions.GET_HOTEL_BY_IDS_FAILED:
      return {
        ...state,
        hotelByIdsData: {},
        hotelByIdsLoader: false,
      };

      case screenActions.SEND_FEEDBACK_REQUESTED:
        return {
          ...state,
          feedbackLoader: true,
        };

      case screenActions.SEND_FEEDBACK_SUCCESS:
        return {
          ...state,
          feedbackLoader: false,
        };

      case screenActions.SEND_FEEDBACK_FAILED:
        return {
          ...state,
          feedbackLoader: false,
        };

    default:
      return { ...state };
  }
};

export default screenReducer;
