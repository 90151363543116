import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { CustomButton } from "../../../components/atom/CustomButton";
import Input from "../../../components/atom/Input";
import { typographyOptions } from "../../../constants";
import en from "../../../locale/en.json";
import { screenActionsCall } from "../../../redux/actions";
import { checkUserNameExists } from "../../../redux/actions/authActions";
import { RootState } from "../../../redux/reducers";
import { colors } from "../../../themes/colors";
import { checkWhiteSpace, scrollToTop } from "../../../utils/helpers";
import Styles from "../index.module.css";
import { googleMapApiKey } from "../../../config/apiurls";

export const TellUsBitMore = ({
  fields,
  handleChange,
  errorFields,
  setFields,
  setErrorFields,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userNameExists } = useSelector(
    (state: RootState) => state.authReducer
  );
  const [address, setAddress] = useState<any>("");

  const [selectedLocation, setSelectedLocation] = useState<any>(null);

  const handleSelect = (data: any) => {
    setSelectedLocation(data);
  };

  useEffect(() => {
    if (userNameExists?.status) {
      setErrorFields((prevState: any) => ({
        ...prevState,
        userName: userNameExists?.message,
      }));
    } else {
      setErrorFields((prevState: any) => ({
        ...prevState,
        userName: userNameExists?.message,
      }));
    }
  }, [userNameExists]);

  const handleExploreClick = () => {
    if (fields.userName === "") {
      setErrorFields((prevState: any) => ({
        ...prevState,
        userName: "This is required field",
      }));
      return;
    }
    dispatch(
      screenActionsCall.updateUserDetails({
        userName: fields.userName,
        location: selectedLocation,
      })
    );
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Stack gap="40px">
      <Typography variant={typographyOptions.AUTH_HEADING}>
        {en.tellAboutYourself}
      </Typography>

      <Stack gap="20px">
        <Stack gap="20px">
          <Stack gap="10px">
            <Typography
              variant={typographyOptions.H1}
              color={colors.primaryGrey}
              paddingLeft="10px"
            >
              {en.userName}
            </Typography>
            <Input
              value={fields?.userName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = e.target.value;
                if (checkWhiteSpace(newValue)) {
                  handleChange("userName", newValue);

                  if (newValue.length > 3) {
                    dispatch(checkUserNameExists({ name: newValue })); // Replace with your API call function
                  }
                }
              }}
              customStyle={{
                border: userNameExists?.status
                  ? "1px solid #20B08F"
                  : !userNameExists?.status
                  ? "1px solid #F05D5D"
                  : "none",
              }}
            />
            <Typography
              variant={typographyOptions.ERROR}
              color={
                userNameExists?.status ? colors.ThemeColor : colors.tertiaryRed
              }
              marginLeft="8px"
              marginTop="-3px"
            >
              {errorFields?.userName}
            </Typography>
          </Stack>
        </Stack>

        <Stack gap="10px">
          <Typography
            variant={typographyOptions.H1}
            color={colors.primaryGrey}
            paddingLeft="10px"
          >
            {en.location} {`(optional)`}
          </Typography>

          <ReactGoogleAutocomplete
            apiKey={googleMapApiKey}
            className={Styles.autoCompleteInput}
            options={{
              types: ["(regions)"],
            }}
            placeholder={"Select Location"}
            value={address?.formattedAddress}
            onChange={(e: any) => {
              setAddress((prev: any) => ({
                ...prev,
                formattedAddress: e.target.value,
              }));
            }}
            onPlaceSelected={(place: any) => {}}
          />
        </Stack>
        {/* Sign in button */}
        <CustomButton
          variant="contained"
          title={en.explore}
          fontSize="16px"
          customStyle={{
            backgroundColor: colors.black,
            maxWidth: "420px",
            border: "2px solid #333333",
            borderRadius: "36px",
            "&:hover": {
              backgroundColor: colors.black,
              borderColor: colors.black,
              borderWidth: "0px",
              "& .MuiTypography-root": {
                color: colors.primaryWhite,
              },
            },
          }}
          customTypography={{
            color: colors.primaryWhite,
          }}
          onClick={handleExploreClick}
        />
      </Stack>
    </Stack>
  );
};
