import { ThemeProvider } from "@mui/material";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logPageView } from "../src/utils/analytics";
import "./App.css";
import MainRouter from "./mainRouter";
import store from "./redux/store";
import theme from "./themes";
import AppInitializer from "./AppInitializer";

function App() {
  // mount google analytics
  React.useEffect(() => {
    logPageView();
  }, []);

  return (
    <Provider store={store}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          {/* MainRouter used to mount all the components and routes */}
          <AppInitializer/>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
