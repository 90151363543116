import styles from "./section.module.css";
import { typographyOptions } from "../../../constants";
import { colors } from "../../../themes/colors";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { CarIcon, StarIcon } from "../../../assests/exportImages";
import { convertRatingToFiveScale } from "../../../utils/helpers";

export const DescriptionCard = ({ description }: any) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  // Function to toggle expand/collapse
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const isLongText = description?.length > 200;
  return (
    <Box
      style={{
        borderTop: "1px solid #F1F1F1",
        padding: "7px 10px 7px 10px",
      }}
    >
      <Box className={expanded ? styles.fullTextStyle : styles.truncatedStyle}>
        {description}
      </Box>
      {isLongText && (
        <Box onClick={toggleExpanded}>
          <Typography
            variant={typographyOptions.SUB_HEADING}
            fontSize="10px"
            color={colors.black}
            style={{ textDecoration: "underline" }}
          >
            {expanded ? "View Less" : "View More"}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export const RatingsUI = ({ trip }: any) => {
  const type = trip?.type;
  return (
    <Box style={{ display: "flex", justifyContent: "space-between" }}>
      {trip?.rating && (
        <Box style={{ display: "flex", gap: "3px" }} sx={{alignItems: "center"}}>
          <img src={StarIcon} alt="place img" width={"10px"} height={"10px"} />
          <Typography
            fontSize="12px"
            fontWeight={"700"}
            color={colors.transparentWhite}
            variant={typographyOptions.H2}
          >
            {`${type === "AGODA" ? convertRatingToFiveScale(trip?.rating) : trip?.rating} (${trip?.total_ratings})`}
          </Typography>
        </Box>
      )}
      <Typography variant={typographyOptions.SUB_HEADING} fontWeight="700">
        {trip?.cost_range_per_person_in_inr ? (
          `${trip?.cost_range_per_person_in_inr}/person`
        ) : (
          <></>
        )}
      </Typography>
    </Box>
  );
};

export const TimeAndDistance = ({ trip }: any) => {
  return (
    <Box className={styles.detailPageBox}>
      <img src={CarIcon} alt="place img" />
      <Typography variant={typographyOptions.SUB_HEADING}>
        {trip?.time ? `${trip.time} min` : ""}
        {trip?.time && trip?.distance ? ", " : ""}
        {trip?.distance ? `${trip.distance} m` : ""}
      </Typography>
    </Box>
  );
};
