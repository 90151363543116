import { CustomButton } from '../atom/CustomButton'
import { Agoda, Bookmark, OpenLink } from '../../assests/exportImages'
import { calculateDiscount, isEmptyObject, partnerURLAgoda } from '../../utils/helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { useEffect, useState } from 'react';

const AgodaBookNow = ({searchBarData, paramsData}) => {
  const { hotelByIdsData, hotelByIdsLoader, hotelDetails } = useSelector((state: RootState) => state.screenReducer);
  const [partnerURL, setpartnerURL] = useState("")
  const dailyRate = hotelByIdsData?.results?.[0]?.dailyRate;
  const crossedOutRate = hotelByIdsData?.results?.[0]?.crossedOutRate;
  const discount = calculateDiscount(dailyRate, crossedOutRate);
  const currency = hotelByIdsData?.results?.[0]?.currency;



  useEffect(() => {
    if(isEmptyObject(hotelDetails)) return;
    const url = partnerURLAgoda(hotelDetails, searchBarData, paramsData )
    setpartnerURL(url)
  }, [hotelDetails, searchBarData, paramsData])


  // final url = urlString;
  //   // Base URL
  //   String baseUrl = url;

  //   // Query parameters
  //   Map<String, String> queryParams = {
  //     "cid": "1933768",
  //     "hid": hotelId,
  //     "currency": "INR",
  //     "checkin": checkInDate,
  //     "checkout": checkOutDate,
  //     "NumberofAdults": adultNumber.toString(),
  //     "NumberofChildren": childNumber.toString(),
  //     "Rooms": roomNumber.toString(),
  //     "pcs": "6"
  //   };

  //   // Construct the URL with query parameters
  //   Uri uri = Uri.parse(baseUrl).replace(queryParameters: queryParams);
  //   String finalUrl = uri.toString();

  //   // Print the final URL
  //   log ("its final $finalUrl");

  //   if (await canLaunchUrl(Uri.parse(finalUrl))) {

  //  // await launchUrl(Uri.parse(finalUrl),mode: LaunchMode.externalApplication);
  //   }

  return (
    <div>
      {/* <div className='h-10 bg-[#E7F5F1] rounded-t-2xl'></div> */}
    <div className="sticky flex flex-col p-4 w-[360px] h-[fit-content] bg-white rounded-b-2xl rounded-t-2xl shadow-[0px 3px 28px 3px #0000000A]">
    {/* <EChip label="Likely to Sell Out" type={"warning"} /> */}
    <div className="flex flex-row justify-between items-center w-full py-3 border-b border-[#F1F1F1]">
      <div className="flex flex-col justify-start max-w-[170px]">
        <img src={Agoda} alt="Agoda" className="!h-auto !w-[68px]" />
        <p className="text-[10px] font-normal text-[#999] mt-1">
        Price shown is per night before taxes & fees 
        </p>
      </div>
      <div className="flex flex-row justify-end items-center">
              <div className="flex flex-col mr-3">
              {discount == 0 && (<p className="text-sm font-normal text-[#999] text-right">
                  Per night
                </p>)}
                {discount > 0 && (
                  <p className="text-sm font-normal text-[#999] line-through text-right">
                    {currency === 'USD' ? '$' : currency === 'INR' ? '₹' : currency}
                    {crossedOutRate}
                  </p>
                )}
                <p className={`text-xl font-bold ${discount>0 ? `text-[#F05D5D]` :`text-[#121212]` } text-right`}>
                  {currency === 'USD' ? '$' : currency === 'INR' ? '₹' : currency}
                  {dailyRate}
                </p>
              </div>
            </div>
    </div>
    <div className="flex flex-col w-full gap-4 pt-4">
      <CustomButton
        title="Book Now"
        border={"3px solid #CCF4EC"}
        customStyle={{
          borderRadius: "35px",
          background: "linear-gradient(90deg, #5BDBBE 0%, #20B08F 100%)",
        }}
        className="h-[42px] !w-full"
        endIcon={OpenLink}
        disabled={isEmptyObject(hotelDetails)}
        onClick={() => window.open(partnerURL)}
      />
      <CustomButton
        title="Add to Trip"
        border={"3px solid #CCF4EC"}
        customStyle={{
          borderRadius: "35px",
          border: "none",
          background: "linear-gradient(90deg, #EADEF9 0%, #E2F6FE 100%)",
          "&:hover": {
            "& .MuiTypography-root": {
              color: "#000",
            },
          },
        }}
        customTypography={{
          color: "#000",
        }}
        className="h-[42px] !w-full"
        startIcon={Bookmark}
      />
      {/* <div className="flex flex-row justify-between items-center bg-[#F3F9F7] py-3 mt-1 px-4 gap-x-4 w-full">
        <CheckCircleRounded className="text-[#20B08F] !w-[15px] !h-[15px]" />
        <p className="text-xs font-semibold text-[#999]">
          <span className="text-[#000] underline cursor-pointer">
            Free Cancellation
          </span>{" "}
          up to 24 hours before the experience starts (local time)
        </p>
      </div> */}
    </div>
  </div>
  </div>
  )
}

export default AgodaBookNow