import { Activities, Clock, DiscountTag, GreenTick, Location, PopularTripsDummy, StarIcon, TickCirlce } from "../../../assests/exportImages"
import { Home } from "@mui/icons-material"
import EChip from "../../../components/atom/EChip"
import { calculateDiscount, convertRatingToFiveScale, formatDuration, isNullOrEmpty } from "../../../utils/helpers";
import Star from "../../../assests/svgsComponent/Star";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ImageBanner from "../../../components/atom/ImageBanner";
import Chip from "../../../components/atom/Chip";
import LocationSVG from "../../../assests/svgsComponent/Location";



const SuggestionCard = ({ item }) => {
  const navigate = useNavigate();

  const [durationText, setdurationText] = useState("")

  const [flagsAvailables, setflagsAvailables] = useState({
    PRIVATE_TOUR: false,
    FREE_CANCELLATION: false,
    LIKELY_TO_SELL_OUT: false,
    FULL_DAY_TOUR: false,
  })

  const reviewScore = item?.reviews?.combinedAverageRating?.toFixed(1)
  const totalReviews = item?.reviews?.totalReviews
  const fromPrice = item?.pricing?.summary?.fromPrice
  const fromPriceBeforeDiscount = item?.pricing?.summary?.fromPriceBeforeDiscount
  const fixedDurationInMinutes = item?.duration?.fixedDurationInMinutes
  const variableDurationFromMinutes = item?.duration?.variableDurationFromMinutes
  const variableDurationToMinutes = item?.duration?.variableDurationToMinutes
  const flags = item?.flags
  const url = item?.images?.[0]?.variants?.[12]?.url || item?.images?.[0]?.variants?.[11]?.url;
  const destination = item?.destination



  useEffect(() => {
    fixedDurationInMinutes && setdurationText(`About ${formatDuration(fixedDurationInMinutes)}`);
    variableDurationFromMinutes && setdurationText(`About ${formatDuration(variableDurationFromMinutes)} - ${formatDuration(variableDurationToMinutes)}`);
    if (flags) {
      flags.forEach((flag) => {
        if (flag === "PRIVATE_TOUR") setflagsAvailables((prev) => ({ ...prev, PRIVATE_TOUR: true }))
        if (flag === "FREE_CANCELLATION") setflagsAvailables((prev) => ({ ...prev, FREE_CANCELLATION: true }))
        if (flag === "LIKELY_TO_SELL_OUT") setflagsAvailables((prev) => ({ ...prev, LIKELY_TO_SELL_OUT: true }))
        if (flag === "FULL_DAY_TOUR") setflagsAvailables((prev) => ({ ...prev, FULL_DAY_TOUR: true }))
      });
    }
  }, [])

  const handleClick = (card: any) => {
    navigate(`/things-to-do/${card?.productCode}`, { state: { card } });
  };
  return (
    <div
      onClick={()=>handleClick(item)}
      className="h-auto md:h-[372px] w-[268px] bg-white rounded-[21px] p-1 pb-[9px] cursor-pointer"
    >
      <div className="relative mb-[10px]">
        <img
          src={url || PopularTripsDummy}
          alt={item?.title}
          className="w-full md:h-[200px] h-[160px] object-cover rounded-[21px]"
        />
        {flagsAvailables?.LIKELY_TO_SELL_OUT && (
          <ImageBanner
            text="Likely to Sell Out"
            bgColor="whiteRed"
            textColor="red"
            className="absolute top-[7%]"
          />
        )}
      </div>
      <div className="px-2">
        <div className="flex gap-1">
          <Chip icon={<LocationSVG />} label={destination} />

          {flagsAvailables?.PRIVATE_TOUR && <Chip label={"Private Tour"} />}
          {flagsAvailables?.FULL_DAY_TOUR && <Chip label={"Private Tour"} />}
        </div>
        <p className="font-bold text-[14px] mt-3 leading-[100%] text-[#333333]">
          {item?.title?.split(" ").slice(0, 10).join(" ")}
        </p>

        <div className="my-1 flex items-center gap-1">
          <div className="flex gap-0.5">
            {[...Array(5)].map((_, index) => {
              const fillPercentage = Math.max(
                0,
                Math.min(100, (reviewScore - index) * 100)
              );

              return <Star key={index} fill={fillPercentage} />;
            })}
          </div>

          <p className="font-bold text-[14px] text-[#999999]">{totalReviews}</p>
        </div>

<div className="flex justify-between">
        <div className="flex justify-between flex-col">
          {(!isNullOrEmpty(fixedDurationInMinutes) ||
            (!isNullOrEmpty(variableDurationFromMinutes) &&
              !isNullOrEmpty(variableDurationToMinutes))) && (
            <div className="flex gap-2 items-center my-1">
              <img src={Clock} alt="Green Tick icon" className="h-[13px]" />
              <p className="text-[12px] font-medium text-[#999]">{durationText}</p>
            </div>
          )}

          {flagsAvailables?.FREE_CANCELLATION && (
            <div className="flex gap-2 items-center">
              <img src={TickCirlce} alt="Green Tick icon" className="h-[15px]" />
              <p className="text-[12px] font-medium text-[#999]">
                Free Cancellation
              </p>
            </div>
          )}
        </div>

        <div className="flex items-center justify-between">
        <p className={`text-[16px] font-bold  text-[#121212] ml-auto`}>
    ₹{fromPrice || fromPriceBeforeDiscount}
  </p>
        </div>

        </div>
      </div>
    </div>
  );
};

export default SuggestionCard