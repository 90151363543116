import React from 'react'
import { Agoda, Google, OpenAi, Viator } from '../../../assests/exportImages'

const Partners = () => {
  return (
    <div className="  w-screen bg-white py-20 hidden md:block">
      <p className="text-[48px] m-10 font-Mulish text-darkGray text-center font-bold">
        Partnered with the best
      </p>
      <div className="flex flex-wrap justify-center gap-32 my-28">
        <img src={Agoda} alt="Agoda" className="h-12" />
        <img src={Viator} alt="Viator" className="h-10" />
        <img src={Google} alt="Google" className="h-12" />
        <img src={OpenAi} alt="OpenAI" className="h-12" />
      </div>
    </div>
  )
}

export default Partners