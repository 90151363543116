import React from 'react'
import { bolt } from '../../../assests/exportImages'
import { CustomButton } from '../../../components/atom/CustomButton'
import { colors } from '../../../themes/colors'

const BecomePartner = () => {

    const openGmail = () => {
        window.open("mailto:admin@escapekar.com?subject=Contact Us", "_blank");
    };

    return (
        <div className="w-screen flex items-center justify-center flex-col text-center my-20 px-5">
            <button className="bg-[#F4F853] font-Mulish text-darkGray font-extrabold px-8 py-4 rounded-full flex items-center gap-2 mb-6">
                <img src={bolt} alt='lightningimage' />BECOME A PARTNER?
            </button>
            <h2 className="font-Mulish md:text-[52px] text-[36px] font-bold text-darkGray max-w-[728px]">
                Let’s collaborate and turn big ideas into{" "}
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#5BDBBE] to-[#20B08F]">
                    reality!
                </span>
            </h2>
            <CustomButton
                title={"Let's Talk"}
                customStyle={{ borderRadius: '16px', marginY: '20px', paddingX: '30px' }}
                customTypography={{ color: colors.primaryWhite }}
                bg={colors.black}
                onClick={openGmail}
            />
        </div>
    )
}

export default BecomePartner