import {
  Box,
  Chip,
  Container,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  formatDate,
  formatDateWithoutTime,
  getImagefromBase64,
  getStoredImages,
  scrollToTop,
} from "../../utils/helpers";
import { screenActionsCall } from "../../redux/actions";
import { RootState } from "../../redux/reducers";
import ItenaryDetailCard, {
  ItenaryDetailCardSkelton,
} from "./sections/itineraryDetailCard";
import OtherTypeCard from "./sections/otherTypeCard";
import {
  BackIcon,
  EyeChip,
  PopularTripsDummy,
  UserChip,
} from "../../assests/exportImages";
import { Likes } from "../../components/atom/Likes";
import AppleMap from "./sections/appleMaps";
import { typographyOptions } from "../../constants";
import { colors } from "../../themes/colors";
import en from "../../locale/en.json";
import styles from "./index.module.css";
import { PassIcon } from "../../components/atom/PassIcon";
import AboutMeCard from "../../components/molecule/AboutMeCard";
import { screenRoutes } from "../../constants/routeConstants";
import Helmet from "react-helmet";
import AgodaViatorItenaryDetailCard from "./sections/AgodaViatorItenaryDetailCard";

const ItineraryDetails = () => {
  const location: any = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { card, image } = location.state || {};

  const { itineraryDetailsTrips = {}, itineraryDetailsTripsLoader } =
    useSelector((state: RootState) => state.screenReducer);

  const { trip } = itineraryDetailsTrips;
  const [markers, setMarkers] = useState([]);

  const [cardData, setCardData] = useState<any>({
    totalDays: 0,
    daysFilter: [{ key: "all", value: "All" }],
    filteredCards: {},
    allFilteredCards: [],
    selectedFilter: { key: "all", value: "All" },
    placesKeys: [],
  });

  const [center, setCenter] = useState({
    latitude: 28.4595,
    longitude: 77.0266,
  });

  const [zoom, setZoom] = useState({ latitudeSpan: 0.05, longitudeSpan: 0.05 });

  const handleSelectMarker = (marker: any) => {
    console.log("Selected marker:", marker);
  };

  const handleProfileClick = () => {
    if (card.associatedUsers?.length && card.associatedUsers[0]?.id) {
      navigate(`/traveller-detail/${card.associatedUsers[0]?.id}`, {
        state: {
          card: {
            userId: card.associatedUsers[0]?.id,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (card && card.id) {
      dispatch(screenActionsCall.getItineraryDetails({ tripId: card.id }));
    }
    scrollToTop();
  }, [card?.id]);

  useEffect(() => {
    let arrMarkers = [];
    if (Object.keys(itineraryDetailsTrips).length) {
      let totalDays = trip?.length;
      let daysFilter = [{ key: "all", value: "All" }];
      let placesKeys = [];

      // plannedDay,plannedDate
      for (let i = 0; i < totalDays; i++) {
        if (trip[i].items?.length) {
          if (trip[i].plannedDay)
            daysFilter.push({
              key: "day",
              value: `${i}`,
            });
          else if (trip[i].plannedDate)
            daysFilter.push({
              key: "date",
              value: `${trip[i].plannedDate}`,
            });
          else if (!trip[i].plannedDate || !trip[i].plannedDay)
            daysFilter.push({
              key: "",
              value: "NYP",
            });

          let trips = trip[i].items?.length;

          for (let j = 0; j < trips; j++) {
            if (trip[i].items[j].latitude && trip[i].items[j].longitude) {
              let obj = {
                latitude: parseFloat(trip[i].items[j].latitude),
                longitude: parseFloat(trip[i].items[j].longitude),
                type: trip[i].items[j]?.type,
                placeType: trip[i].items[j]?.place_type,
                costRange: trip[i].items[j]?.cost_range_per_person_in_inr,
                rating: trip[i].items[j]?.rating,
                totalRatings: trip[i].items[j]?.total_ratings,
                title: trip[i].items[j]?.startAddress ?? trip[i].items[j]?.name,
                image: getStoredImages(trip[i].items[j]?.photoReference),
              };

              arrMarkers.push(obj);
            }
          }
        }
      }

      setCenter((prev) => ({
        ...prev,
        latitude: parseFloat(arrMarkers[0]?.latitude),
        longitude: parseFloat(arrMarkers[0]?.longitude),
      }));

      setMarkers(arrMarkers);
      setCardData((prev) => ({
        ...prev,
        totalDays,
        daysFilter,
        placesKeys,
        selectedFilter: { key: "all", value: "All" },
      }));
      handleSelection({ key: "all", value: "All" });
    }
  }, [itineraryDetailsTrips]);

  const chipStyle = {
    backgroundColor: "#F1F1F1",
    fontFamily: "Mulish",
    fontWeight: "700",
    fontSize: "14px",
    color: "#666666",
    padding: "3px 10px 2px 10px",
    borderRadius: "12px",
  };

  const mobileChipStyle = {
    ...chipStyle,
    fontSize: "10px",
    padding: "0px",
  };

  const viewOnlyChipStyle = {
    ...chipStyle,
    color: "#EAAE33",
    backgroundColor: "#FDF7EB",
  };

  const viewOnlyChipStyleMobile = {
    ...mobileChipStyle,
    color: "#EAAE33",
    backgroundColor: "#FDF7EB",
  };

  const handleSelection = (filter: any) => {
    const value: number = parseInt(filter.value);
    if (filter.key === "all") {
      setCardData((prev: any) => ({
        ...prev,
        filteredCards: {},
        selectedFilter: filter,
        allFilteredCards: itineraryDetailsTrips?.trip,
      }));
    } else {
      let dayTrips = [];
      if (filter?.key === "day") {
        dayTrips = itineraryDetailsTrips?.trip.filter((trip: any) => {
          return value === trip?.plannedDay;
        })[0];
      } else {
        dayTrips = itineraryDetailsTrips?.trip.filter(
          (trip: any) => value === trip?.plannedDate
        )[0];
      }

      setCardData((prev: any) => ({
        ...prev,
        filteredCards: dayTrips,
        selectedFilter: filter,
      }));
    }
  };

  // cards UI
  const renderCards = (day: any, items = []) => {
    const handleCardClick = (card: any) => {
      navigate(
        `${screenRoutes.DESTINATION_DETAIL}${card?.startAddress}/${card?.place_id}`,
        {
          state: { card: { place_id: card?.startPlace } },
        }
      );
    };

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
        <Typography
          variant={typographyOptions.H1}
          color={colors.transparentWhite}
          fontSize="18px"
          padding="15px 0px"
          marginBottom={"-16px"}
          marginTop={"26px"}
        >
          {day?.plannedDate ? (
            formatDateWithoutTime(day?.plannedDate).toString()
          ) : day?.plannedDay ? (
            `Day ${day?.plannedDay}`
          ) : (
            <Stack gap="12px">
              {/* <Box>Not yet planned</Box> */}
              {/* <Box sx={{ marginRight: "3px" }}>Nothing's here!</Box> */}
            </Stack>
          )}
        </Typography>

        {items?.map((item, index) => {
          // Determine which card to render based on the type

          if (item.type === "PLACE") {
            return (
              <ItenaryDetailCard
                key={index}
                trip={item}
                image={getStoredImages(item?.photoReference)}
                onClick={() => handleCardClick(item)}
              />
            );
          } else if (item.type === "AGODA" || item.type === "VIATOR") {
            return (
              <AgodaViatorItenaryDetailCard
                key={index}
                trip={item}
              />
            );

          } else {
            return <OtherTypeCard key={index} trip={item} />;
          }
        })}
      </Box>
    );
  };

  useEffect(() => {
    let obj = {
      name: "itinerary Detail Page",
      path: location.path,
    };
    // logPageView(obj); // analytics
  }, [location]);

  return (
    // className="itenary-detail-main " style={{ marginBottom: "20px" }}
    <Box>
      <Helmet>
        {" "}
        <title>Escapekar: Trip Planner</title>
        <meta name="title" content="Escapekar: Trip Planner" />
        <meta
          name="description"
          content="Plan your perfect trip with our ultimate itinerary guide. Discover hidden gems, explore best destinations, and get personalized recommendations for a memorable travel experience. Use our AI-powered trip planner to craft your ideal travel itinerary."
        />
        <meta
          name="keywords"
          content="travel itinerary, trip planner, AI trip planning, personalized travel itinerary, best destinations, hidden gems, travel plans, trip planning AI, travel planning, secret places"
        />
        <meta name="url" content="https://escapekar.com/" />
        <meta
          name="keywords"
          content="hidden gems, best hidden gems, secret place, Diveagar Beach Maharashtra, Kollukumalai, Mandvi Gujarat, trip planner AI, AI travel, travel itinerary, travel planning, trip planner, AI trip planner, trip plans, artificial intelligence trip planning"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://escapekar.com/" />
        <meta
          property="og:title"
          content="Trip Planner - Your Ultimate Travel Guide"
        />
        <meta
          property="og:description"
          content="Discover your next adventure with Escapekar, the ultimate trip planner. Effortlessly create and customize your travel itinerary, explore destinations, and get personalized recommendations to make every journey unforgettable."
        />
        <meta property="og:site_name" content="Escapekar: Trip Planner" />
        <meta property="og:locale" content="en-IN" />
        <meta property="og:country-name" content="in" />
        <script id="data" type="application/json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "TravelItinerary",
            name: "Ultimate Travel Itinerary",
            alternateName: "Escapekar",
            description:
              "Plan your perfect trip with our ultimate itinerary guide. Discover hidden gems, explore best destinations, and get personalized recommendations.",
            url: "https://escapekar.com/",
            contactPoint: {
              "@type": "ContactPoint",
              // telephone: "+91 9599819940",
              // contactType: "customer service",
              // contactOption: "TollFree",
              areaServed: "IN",
              availableLanguage: "en",
            },
            sameAs: [
              "https://www.facebook.com/escapekar/",
              "https://www.instagram.com/escapekar/",
              "https://www.linkedin.com/company/escapekar/",
            ],
          })}
        </script>
      </Helmet>
      {!card ? (
        <Typography>{en.noCardDataAvailable}</Typography>
      ) : (
        <Box className={styles.itenairyDetailUI}>
          <Box className={styles.backgroundImageItenairy}>
            {itineraryDetailsTripsLoader ? (
              <Skeleton
                animation="wave"
                sx={{ height: "360px" }}
                className={styles.itenairyDetailBgImg}
              ></Skeleton>
            ) : (
              <Box
                sx={{
                  backgroundImage: `url(${image?.config?.url || PopularTripsDummy
                    })`,
                }}
                className={styles.itenairyDetailBgImg}
              >
                <Box
                  sx={{
                    padding: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <PassIcon icon={BackIcon} onClick={() => navigate(-1)} />

                  {/* <PassIcon icon={MapOpenIcon} onClick={()=} /> */}
                </Box>

                {/* For desktop */}
                <Box className={styles.itenaryDetailBgImgDetails}>
                  <Box>
                    <ChipStyles
                      itineraryDetailsTrips={itineraryDetailsTrips}
                      chipStyles={chipStyle}
                      friendsChipStyles={chipStyle}
                      ViewOnlyChipStyles={viewOnlyChipStyle}
                    />

                    {/* title */}
                    <Typography
                      color={colors.primaryWhite}
                      fontSize="32px"
                      variant={typographyOptions.H1}
                    >
                      {card.name}
                    </Typography>

                    <ProfileUI
                      card={card}
                      itineraryDetailsTrips={itineraryDetailsTrips}
                      handleProfileClick={handleProfileClick}
                    />
                  </Box>
                </Box>
                {/* For mobile */}
                <Box className={styles.itenaryDetailBgImgDetailsMobile}>
                  {/* title */}
                  <Typography
                    color={colors.black}
                    fontSize="18px"
                    variant={typographyOptions.H1}
                  >
                    {card.name}
                  </Typography>

                  <ChipStyles
                    itineraryDetailsTrips={itineraryDetailsTrips}
                    chipStyles={chipStyle}
                    friendsChipStyles={mobileChipStyle}
                    ViewOnlyChipStyles={viewOnlyChipStyleMobile}
                  />

                  <ProfileUI
                    card={card}
                    itineraryDetailsTrips={itineraryDetailsTrips}
                    handleProfileClick={handleProfileClick}
                  />
                </Box>
              </Box>
              
            )}
          </Box>

          <Box className={styles.widthResponsive}>
            {/* Overview */}

            <Box sx={{ marginBottom: "20px" }}>
              {itineraryDetailsTrips?.description && (
                <AboutMeCard
                  about={itineraryDetailsTrips?.description}
                  heading={en.overview}
                  loading={itineraryDetailsTripsLoader}
                />
              )}
            </Box>

            {/* Days Filter */}
            <Box className={styles.daysFilterScroll}>
              {cardData?.daysFilter?.map((filter: any, index: number) => {
                let date: any = {};
                if (filter.key === "date") {
                  date = formatDate(filter.value, "md");
                }
                return (
                  <Box
                    key={index}
                    className={`${styles.itenaryDaysFilterSelection} ${cardData.selectedFilter.value === filter.value
                        ? styles.selected
                        : ""
                      }`}
                    onClick={() => handleSelection(filter)}
                  >
                    {filter?.key === "day" ? (
                      <>
                        {filter?.key !== "all" && (
                          <Typography
                            variant={typographyOptions.HEADING}
                            fontSize="10px"
                            color={
                              cardData.selectedFilter.value === filter.value &&
                              colors.primaryWhite
                            }
                          >
                            Day
                          </Typography>
                        )}
                        <Typography
                          variant={typographyOptions.H2}
                          color={
                            cardData.selectedFilter.value !== filter.value &&
                            colors.black
                          }
                        >
                          {filter?.value}
                        </Typography>
                      </>
                    ) : (
                      <>
                        {filter?.key === "all" || filter?.key === "" ? (
                          <Typography
                            variant={typographyOptions.H2}
                            color={
                              cardData.selectedFilter.value === filter.value
                                ? colors.primaryWhite
                                : colors.black
                            }
                          >
                            {filter?.value}
                          </Typography>
                        ) : (
                          <Stack alignItems={"center"}>
                            <Typography
                              variant={typographyOptions.HEADING}
                              fontSize="10px"
                              color={
                                cardData.selectedFilter.value === filter.value
                                  ? colors.primaryWhite
                                  : colors.black
                              }
                            >
                              {date.dayOfWeek}
                            </Typography>
                            <Typography
                              variant={typographyOptions.HEADING}
                              color={
                                cardData.selectedFilter.value === filter.value
                                  ? colors.primaryWhite
                                  : colors.black
                              }
                            >
                              {date.day}
                            </Typography>
                            <Typography
                              variant={typographyOptions.HEADING}
                              fontSize="10px"
                              color={
                                cardData.selectedFilter.value === filter.value
                                  ? colors.primaryWhite
                                  : colors.black
                              }
                            >
                              {date.digitMonth}
                            </Typography>
                          </Stack>
                        )}
                      </>
                    )}
                  </Box>
                );
              })}
            </Box>

            {/* Render ItenaryDetailCard based on selected filter */}
            {/* for Attraction and Palace */}

            {itineraryDetailsTripsLoader ? (
              <ItenaryDetailCardSkelton />
            ) : cardData.selectedFilter.key === "all" ? (
              <>
                {cardData?.allFilteredCards?.length > 0 &&
                  cardData?.allFilteredCards?.map((item: any) =>
                    renderCards(item, item.items)
                  )}
              </>
            ) : (
              <>
                {Object?.keys(cardData)?.length > 0 &&
                  renderCards(
                    cardData?.filteredCards,
                    cardData?.filteredCards?.items
                  )}
              </>
            )}
          </Box>

          <Box className={styles.stickyMap}>
            <AppleMap
              markers={markers}
              setMarkers={setMarkers}
              onSelectMarker={handleSelectMarker}
              center={center}
              zoom={zoom}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ItineraryDetails;

const ProfileUI = ({
  handleProfileClick,
  itineraryDetailsTrips,
  card,
}: any) => {
  return (
    <Box className={styles.itenairyDetailBox1}>
      {itineraryDetailsTrips?.associatedUsers?.length ? (
        <Box className={styles.itenairyDetailBox} onClick={handleProfileClick}>
          <img
            src={getImagefromBase64(
              itineraryDetailsTrips?.associatedUsers?.[0]?.profileImgBase64
            )}
            alt="img"
            style={{
              width: "25px",
              height: "25px",
              borderRadius: "50%",
            }}
          />
          <Typography
            color={colors.quatenaryGrey}
            variant={typographyOptions.H1}
          >
            {itineraryDetailsTrips?.associatedUsers?.[0]?.name}
          </Typography>
        </Box>
      ) : (
        <Box></Box>
      )}
      <Box>
        <Likes likesCount={card?.likes} positionAbs={"no"} />
      </Box>
    </Box>
  );
};

const ChipStyles = ({
  itineraryDetailsTrips,
  chipStyles,
  friendsChipStyles,
  ViewOnlyChipStyles,
}: any) => {
  const startDateDay: any = formatDate(itineraryDetailsTrips?.start, "string");

  const getEndDate = () => {
    const date: any = formatDate(itineraryDetailsTrips?.end, "string");
    return `${date?.day} ${date?.month} ${date?.year}`;
  };
  return (
    <Box style={{ display: "flex", gap: "7px" }}>
      <Chip
        label={`${itineraryDetailsTrips?.start
            ? `${startDateDay?.day} - ${getEndDate()}`
            : `${itineraryDetailsTrips?.days || 0} days`
          }`}
        style={chipStyles}
      />
      <Chip
        icon={<img src={UserChip} alt="user chip" />}
        label="Friends"
        style={friendsChipStyles}
      />
      <Chip
        icon={<img src={EyeChip} alt="Eye chip" />}
        label="View Only"
        style={ViewOnlyChipStyles}
      />
    </Box>
  );
};
