import { useState } from "react";
import CustomRadio from "../atom/CustomRadioButton";

const SortBy = ({filters, setFilters}) => {
  
  const options = [
    {AllGuestsReviewScore: "Top Guest Rating"},
    {PriceAsc:"Price Low to High"},
    {PriceDesc:"Price High to Low"},
    {StarRatingDesc:"Stars (5 to 0)"}
  ];

  const setSelected = (option: string) => {
    // Get the key from the object at the specified index
    const sortKey = Object.keys(options[option])[0];
    setFilters({ ...filters, sortBy: sortKey });
  }


  return (
    <div className="w-flex flex-col items-center md:items-start">
      <h3 className="text-lg font-semibold mb-6 hidden md:block">Sort By</h3>
      <div className="space-y-2">
        {options.map((option, index) => (
          <label
            key={index}
            className="flex items-center space-x-2 cursor-pointer"
            onClick={() => setSelected(index.toString())}
          >
            <CustomRadio selected={filters.sortBy === Object.keys(option)[0]} onClick={() => setSelected(index.toString())} />
            <span className="text-[#121212] font-[400]">{Object.values(option)[0]}</span>
          </label>
        ))}
      </div>
    </div>
  );
  
  
};

export default SortBy;
