import React, { useEffect, useState } from "react";
import AppleMap from "../ItineraryDetails/sections/appleMaps";
import { viatorSuggestionBody } from "../../config/apiBody";
import { screenActionsCall } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import ThingsToDoSuggestionContainer from "./SuggestionCards/ThingsToDoSuggestionContainer";

const ThingsToDoNearbyPanel = () => {
  const {  thingsToDoYouMayAlsoLikeListLoader, thingsToDoNearbyActivitiesListLoader, thingsToDoNearbyThingsToDoListLoader } = useSelector((state: RootState) => state.screenReducer);

  // const { hotelDetails } = useSelector((state: RootState) => state.screenReducer);
  // States
  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = useState({
    latitude: 28.4595,
    longitude: 77.0266,
  });

  const [zoom, setZoom] = useState({
    latitudeSpan: 0.05,
    longitudeSpan: 0.05,
  });

  const handleSelectMarker = (marker: any) => {
    console.log("Selected marker:", marker);
  };

  return (
    <>
    {/* <div className="flex flex-col w-full h-[480px] mb-10">
      <AppleMap
        markers={markers}
        setMarkers={setMarkers}
        onSelectMarker={handleSelectMarker}
        center={center}
        zoom={zoom}
      />
    </div> */}
    <div className="mb-10">
    <ThingsToDoSuggestionContainer type={"YOU_MAY_ALSO_LIKE"} /> 
    <ThingsToDoSuggestionContainer type={"NEARBY_ACTIVITIES"} /> 
    <ThingsToDoSuggestionContainer type={"NEARBY_THINGS_TO_DO"} /> 
    </div>
    </>
  );
};

export default ThingsToDoNearbyPanel;
