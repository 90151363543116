import React, { useEffect, useRef, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { CustomButton } from '../atom/CustomButton';
import { PassIcon } from '../atom/PassIcon';
import { Minus, PlusLarge } from '../../assests/exportImages';
import { Divide } from 'lucide-react';

const modalStyle = {
  position: 'absolute',
  zIndex: 100,
  width: '265px',
  
  borderRadius: 5,
  maxHeight: 'fit-content',
  backgroundColor: 'white',
  boxShadow: 24,
  marginTop: 1,
  paddingX: "20px",
  paddingY: "10px",
  display: "flex",
  flexDirection: "column",
};

interface ISearchBarData {
  searchText?: string;
  cityId?: number;
  hotelId?: number;
  checkInDate?: string;
  checkOutDate?: string;
  occupancy?: IOccupancy;
}

interface IOccupancy {
  roomCount?: number;
  adultCount?: number;
  childCount?: number;
  childAge?: number[];
}

interface RoomGuestSelectorProps {
  occupancy: IOccupancy;
  setsearchBarDataLocal: React.Dispatch<React.SetStateAction<ISearchBarData>>;
  handleClose: () => void;
}

const RoomGuestSelector: React.FC<RoomGuestSelectorProps> = ({ setsearchBarDataLocal, occupancy, handleClose }) => {
  const modalRef = useRef(null);

  const [occupancyLocal, setOccupancyLocal] = useState<IOccupancy>(occupancy);

  const handleIncrement = (field: keyof IOccupancy) => {
    const newOccupancy = { ...occupancyLocal };
    if (field === 'childCount') {
      newOccupancy.childCount = (newOccupancy.childCount || 0) + 1;
      newOccupancy.childAge = [...(newOccupancy.childAge || []), 0];
    } else if (field !== 'childAge') {
      newOccupancy[field] = ((newOccupancy[field] as number) || 0) + 1;
    }
    setOccupancyLocal(newOccupancy);
  };

  const handleDecrement = (field: keyof IOccupancy) => {
    const newOccupancy = { ...occupancyLocal };
    if (field === 'childCount' && (newOccupancy.childCount || 0) > 0) {
      newOccupancy.childCount = (newOccupancy.childCount || 0) - 1;
      newOccupancy.childAge = (newOccupancy.childAge || []).slice(0, -1);
    } else if (field !== 'childAge' && ((newOccupancy[field] as number) || 0) > 1) {
      newOccupancy[field] = ((newOccupancy[field] as number) || 0) - 1;
    }
    setOccupancyLocal(newOccupancy);
  };

  const handleChildAgeChange = (index: number, value: string) => {
    const newOccupancy = { ...occupancyLocal };
    const age = parseInt(value);
    newOccupancy.childAge = (newOccupancy.childAge || []).map((currentAge, i) =>
      i === index ? (value === "" ? 0 : Math.max(0, Math.min(age, 17))) : currentAge
    );
    setOccupancyLocal(newOccupancy);
  };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          handleClose();
        }
      };
    
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const handleClickUpdate = () => {
      setsearchBarDataLocal((prevData) => ({
        ...prevData,
        occupancy: occupancyLocal,
      }));
      handleClose();
    }

  return (
    <Box sx={modalStyle} ref={modalRef}>
      {/* Rooms Section */}
      <div className="flex items-center justify-between my-4">
        <div>
          <h3 className="text-[16px] font-bold">Rooms</h3>
        </div>
        <div className="flex items-center gap-4">
            <PassIcon icon={Minus} onClick={() => handleDecrement('roomCount')} />
          <span className="w-5 text-center text-[14px]">{occupancyLocal.roomCount}</span>
            <PassIcon icon={PlusLarge} onClick={() => handleIncrement('roomCount')} />
        </div>
      </div>

      <Divider sx={{ my: "2px" }} />

      {/* Adults Section */}
      <div className="flex items-center justify-between my-4">
        <div>
          <h3 className="text-[16px] font-bold">Adults</h3>
          <p className="text-[10px] font-normal text-[#666666]">Ages 18 or above</p>
        </div>
        <div className="flex items-center gap-4">
            <PassIcon icon={Minus} onClick={() => handleDecrement('adultCount')} />
          <span className="w-5 text-center text-[14px]">{occupancyLocal.adultCount}</span>
            <PassIcon icon={PlusLarge} onClick={() => handleIncrement('adultCount')} />
        </div>
      </div>

      <Divider sx={{ my: "2px" }} />

      {/* Children Section */}
      <div className="flex items-center justify-between my-4">
        <div>
          <h3 className="text-[16px] font-bold">Children</h3>
          <p className="text-[10px] font-normal text-[#666666]">Ages 0 - 17</p>
        </div>
        <div className="flex items-center gap-4">
            <PassIcon onClick={() => handleDecrement('childCount')} icon={Minus} />
          <span className="w-5 text-center text-[14px]">{occupancyLocal.childCount}</span>
            <PassIcon onClick={() => handleIncrement('childCount')} icon={PlusLarge} />
        </div>
      </div>
      <Divider sx={{ my: "2px" }} />
      {/* Children Ages Section */}
      {occupancyLocal.childCount > 0 && (
        <div className="space-y-3">
          {occupancyLocal.childAge?.map((age, index) => (
            <div key={index} className="flex items-center justify-between my-4">
              <label className="text-[16px] font-bold">Child {index + 1}</label>
              <input
                type="number"
                min="0"
                max="17"
                value={age === 0 ? "" : age}
                onChange={(e) => handleChildAgeChange(index, e.target.value)}
                className="w-24 px-3 py-2 border-2 text-[14px] border-red-300 rounded-[16px] focus:outline-none focus:ring-2 focus:ring-red-200 text-center"
                placeholder="0 - 17"
              />
            </div>
          ))}
        </div>
      )}

          <CustomButton
            title="Update"
            onClick={handleClickUpdate}
            customStyle={{
              marginTop: "40px",
            }}
            className="!h-[42px] !w-[146px] !font-bold !rounded-[20px] self-center"
            fontSize="16px"
          />
    </Box>
  );
};

export default RoomGuestSelector;