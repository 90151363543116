import { Activities, DiscountTag, Location, PopularTripsDummy, StarIcon, TickCirlce } from "../../../assests/exportImages"
import { Home } from "@mui/icons-material"
import EChip from "../../../components/atom/EChip"
import { calculateDiscount, convertRatingToFiveScale } from "../../../utils/helpers";
import Star from "../../../assests/svgsComponent/Star";
import { useNavigate } from "react-router-dom";



const HotelOverviewNearbyCard1 = ({ item, city }) => {
  const discount = calculateDiscount(item?.dailyRate, item?.crossedOutRate);
  const reviewScore = convertRatingToFiveScale(item?.reviewScore);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/hotels/${item.hotelId}`, { state: { card: item } });
  }
  return (
    <div onClick={handleClick} className="h-auto md:h-[372px] w-[268px] bg-white rounded-[21px] p-1 pb-[9px] cursor-pointer">
      <div className="relative mb-[10px]">
        <img src={item?.imageURL || PopularTripsDummy} alt={item?.hotelName} className="w-full md:h-[200px] h-[160px] object-cover rounded-[21px]" />
        {discount > 0 && (
          <div className="absolute top-[12%]">
            <img src={DiscountTag} alt="DiscountTag" className="h-[23px]" />
            <p className="relative -top-5 left-2 text-white font-Mulish font-bold text-[12px]">
              {discount}% off
            </p>
          </div>
        )}
      </div>
      <div className="px-2">
        <EChip
          icon={
            <Home
              sx={{
                color: "#cccccc",
                fontSize: "12px",
                "&:hover": {
                  color: "#666666",
                },
              }}
            />
          }
          type="gray"
          customStyle={{ fontWeight: "700", color: "#cccccc", marginBottom: "8px" }}
          label="Hotel"
        />
        <p className="font-bold text-[14px] leading-[100%] text-[#333333]">
          {item?.hotelName?.split(" ").slice(0, 10).join(" ")}
        </p>


        <div className="my-2 flex items-center gap-1">
          <div className="flex gap-0.5">
            {[...Array(5)].map((_, index) => {
              const fillPercentage = Math.max(0, Math.min(100, (reviewScore - index) * 100));

              return <Star key={index} fill={fillPercentage} />;
            })}
          </div>

          <p className="font-bold text-[14px] text-[#999999]">{item?.reviewCount}</p>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex gap-2 items-center ml-0.5">
            <img src={Location} alt="Location" className="h-[14px]" />
            <p className="text-[12px] font-medium text-[#999]">{city}</p>
          </div>
          {item?.crossedOutRate > 0 && (
            <p className="text-[12px] font-normal text-[#999] line-through text-right">
              ₹{item?.crossedOutRate}
            </p>
          )}
        </div>

        <div className="flex items-center justify-between">
          {item?.includeBreakfast && (
            <div className="flex gap-2 items-center ml-[1px]">
              <img src={TickCirlce} alt="Tick Circle" className="h-[15px]" />
              <p className="text-[12px] font-medium text-[#999]">Breakfast Included</p>
            </div>
          )}
          <p className={`text-[16px] font-bold ${discount > 0 ? `text-[#F05D5D]` : `text-[#121212]`} ml-auto`}>
            ₹{item?.dailyRate}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HotelOverviewNearbyCard1