import React, { useState, useRef, useEffect } from "react";

const ImageSlider = ({ images, onClick }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [currentIndex]);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current - touchEndX.current > 50) {
      // Swipe left (next)
      goToNext();
    }

    if (touchStartX.current - touchEndX.current < -50) {
      // Swipe right (previous)
      goToPrevious();
    }
  };

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };
  return (
    <div
      className="slider-container"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onClick={onClick}
    >
      <div
        className="slider-content"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images.map((image, index) => (
          <div className="slide" key={index}>
            <img src={image.config.url} alt="" className="slider-image" />
          </div>
        ))}
      </div>

      {/* Dots Navigation */}
      <div className="dots-container">
        {images.map((_, index) => (
          <div
            key={index}
            className={`dot ${currentIndex === index ? "active" : ""}`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

// CSS Styling
const styles = `
  .slider-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 380px; /* Set a height or make it responsive */
  }

  .slider-content {
    display: flex;
    transition: transform 0.5s ease;
    width: 100%;
    height: 380px;
  }

  .slide {
    min-width: 100%;
    box-sizing: border-box;
  }

  .slider-image {
    width: 100%;
    height: 100%;
   
    object-fit: cover;
  }

  .dots-container {
    position: absolute;
    bottom: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .dot.active {
    background-color: rgba(255, 255, 255, 1);
  }
`;

// Inject styles into the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default ImageSlider;
