import { call, put, takeLatest } from "redux-saga/effects";
import { IActionInterface } from "../../interfaces";
import { authAction } from "../actions/actionTypes";
import authService from "../services/authService";
import { toast } from "react-toastify";

function* userSignIn(action: IActionInterface): any {
  try {
    // Check if email exists before signing up
    const emailCheckResponse = yield call(authService.checkEmailExists, {
      email: action.payload.data.email,
    });

    if (emailCheckResponse.status === 200 && !emailCheckResponse.data) {
      toast.error("Email does not exist");
      yield put({
        type: authAction.CHECK_EMAIL_EXISTS_FAILED,
      });
      return;
    }
    // Proceed with the signup
    const { data, signInSuccessCallback } = action.payload;
    const response = yield call(authService.userSignIn, data); 
    if (response?.status === 200) {
      yield put({
        type: authAction?.USER_SIGN_IN_SUCCESS,
        payload: response?.data?.data,
      });

      localStorage.setItem(`Login_Token`, response?.data?.token);
      signInSuccessCallback && signInSuccessCallback();
    } else {
      toast.error(response?.data?.message);
      yield put({ type: authAction.USER_SIGN_IN_FAILED });
    }
  } catch (error) {
    toast.error(error);
    yield put({
      type: authAction?.USER_SIGN_IN_FAILED,
    });
  }
}

function* userSignUp(action: IActionInterface): any {
  try {
    // Check if email exists before signing up
    const emailCheckResponse = yield call(authService.checkEmailExists, {
      email: action.payload.data.email,
    });
    if (emailCheckResponse.status === 200 && emailCheckResponse.data) {
      toast.info("Email already exist ,Kindly sign in to continue.");
      yield put({
        type: authAction.CHECK_EMAIL_EXISTS_FAILED,
      });
      return;
    }
    // Proceed with the signup

    const { data, signUpSuccessCallback } = action.payload;
    const response = yield call(authService.userSignUp, data);

    if (response.status === 200) {
      yield put({
        type: authAction.USER_SIGN_UP_SUCCESS,
        payload: response.data.data,
      });

      localStorage.setItem(`Login_Token`, response?.data?.token);

      signUpSuccessCallback && signUpSuccessCallback();
      // Redirect to email verification page
    } else {
      yield put({ type: authAction.USER_SIGN_UP_FAILED });
    }
  } catch (error) {
    yield put({
      type: authAction.USER_SIGN_UP_FAILED,
    });
  }
}

function* userSignUpGoogle(action: IActionInterface): any {
  try {
    const response = yield call(authService.userSignUpGoogle, action?.payload);
    if (response.status === 200) {
      yield put({
        type: authAction.USER_SIGN_UP_GOOGLE_SUCCESS,
      });

      localStorage.setItem(`Login_Token`, response?.data?.token);
      action.payload.onSuccess && action.payload.onSuccess();
    } else {
      yield put({ type: authAction.USER_SIGN_UP_GOOGLE_FAILED });
    }
  } catch (error) {
    yield put({
      type: authAction?.USER_SIGN_UP_GOOGLE_FAILED,
    });
  }
}

function* fetchUserDataSaga(action: IActionInterface): any {
  try {
    const response = yield call(authService.fetchUserData, action?.payload);
    if (response.status === 200) {
      const userData = response?.data;
      yield put({
        type: authAction?.FETCH_USER_DATA_SUCCESS,
        payload: userData,
      });
      yield put({
        type: authAction?.USER_SIGN_UP_GOOGLE_REQUESTED,
        payload: {
          email: userData.email,
          name: userData.given_name,
          onSuccess: action.payload.onSuccess,
        },
      });
    } else {
      yield put({ type: authAction.FETCH_USER_DATA_FAILED });
    }
  } catch (error) {
    yield put({
      type: authAction?.FETCH_USER_DATA_FAILED,
    });
  }
}

function* checkIsEmailExistsSaga(action: IActionInterface): any {
  try {
    const response = yield call(authService.checkEmailExists, action?.payload);
    if (response.status === 200) {
      yield put({
        type: authAction?.CHECK_EMAIL_EXISTS_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      toast.error("Email does not exist");
      yield put({ type: authAction.CHECK_EMAIL_EXISTS_FAILED });
    }
  } catch (error) {
    yield put({
      type: authAction?.CHECK_EMAIL_EXISTS_FAILED,
    });
  }
}

function* verifyResetSaga(action: IActionInterface): any {
  try {
    const response = yield call(authService.verifyReset, action?.payload);

    if (response.status === 200) {
      if (response?.data?.status === 404) {
        yield put({
          type: authAction?.VERIFY_RESET_PASS_FAILED,
        });

        toast.error(response.data?.message);
        return;
      }
      yield put({
        type: authAction?.VERIFY_RESET_PASS_SUCCESS,
        payload: response?.data?.data,
      });
      action.payload.onSuccess && action.payload.onSuccess();
    } else {
      yield put({ type: authAction.VERIFY_RESET_PASS_FAILED });
    }
  } catch (error) {
    yield put({
      type: authAction?.VERIFY_RESET_PASS_FAILED,
    });
  }
}

function* verifyAccountEmailSaga(action: IActionInterface): any {
  try {
    const response = yield call(
      authService.verifyAccountEmail,
      action?.payload
    );

    if (response.status === 200) {
      if (response?.data?.status === 404) {
        yield put({
          type: authAction?.VERIFY_ACCOUNT_EMAIL_SUCCESS,
        });

        //need to add toaster
        return;
      }
      yield put({
        type: authAction?.VERIFY_ACCOUNT_EMAIL_SUCCESS,
        payload: response?.data?.data,
      });
      action.payload.onSuccess && action.payload.onSuccess();
    } else {
      yield put({ type: authAction.VERIFY_ACCOUNT_EMAIL_FAILED });
    }
  } catch (error) {
    yield put({
      type: authAction?.VERIFY_ACCOUNT_EMAIL_FAILED,
    });
  }
}

function* checkIsUserNameExistsSaga(action: IActionInterface): any {
  try {
    const response = yield call(
      authService.checkUserNameExists,
      action?.payload
    );
    if (response.status === 200) {
      yield put({
        type: authAction?.CHECK_USERNAME_EXISTS_SUCCESS,
        payload: {
          status: response.data.status,
          message: response.data.message,
        },
      });
    } else {
      yield put({ type: authAction.CHECK_USERNAME_EXISTS_FAILED });
    }
  } catch (error) {
    yield put({
      type: authAction?.CHECK_USERNAME_EXISTS_FAILED,
    });
  }
}

function* resendVerificationSaga(action: IActionInterface): any {
  try {
    const response = yield call(
      authService.resendVerification,
      action?.payload
    );
    if (response.status === 200) {
      yield put({
        type: authAction?.RESEND_OTP_REQUESTED_SUCCESS,
      });
      action?.payload.onSuccess && action?.payload.onSuccess();
    } else {
      yield put({ type: authAction.RESEND_OTP_REQUESTED_FAILED });
    }
  } catch (error) {
    yield put({
      type: authAction?.RESEND_OTP_REQUESTED_FAILED,
    });
  }
}

function* initiateResetPasswordSaga(action: IActionInterface): any {
  try {
    const response = yield call(
      authService.initiateResetPassword,
      action?.payload
    );
    if (response.status === 200) {
      yield put({
        type: authAction?.INITIATE_RESET_SUCCESS,
      });
      action.payload?.onSuccess && action.payload?.onSuccess();
    } else {
      yield put({ type: authAction.INITIATE_RESET_FAILED });
    }
  } catch (error) {
    yield put({
      type: authAction?.INITIATE_RESET_FAILED,
    });
  }
}

function* confirmResetPasswordSaga(action: IActionInterface): any {
  try {
    const response = yield call(
      authService.confirmResetPassword,
      action?.payload
    );
    if (response.status === 200) {
      yield put({
        type: authAction?.CONFIRM_RESET_PASS_SUCCESS,
      });
      action.payload?.onSuccess && action.payload?.onSuccess();
    } else {
      yield put({ type: authAction.CONFIRM_RESET_PASS_FAILED });
    }
  } catch (error) {
    yield put({
      type: authAction?.CONFIRM_RESET_PASS_FAILED,
    });
  }
}

function* deleteUserSaga(): any {
  try {
    const response = yield call(authService.deleteUser);
    if (response.status === 200) {
      yield put({
        type: authAction?.DELETE_USER_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({ type: authAction.DELETE_USER_FAILED });
    }
  } catch (error) {
    yield put({
      type: authAction?.DELETE_USER_FAILED,
    });
  }
}

function* changePasswordSaga(action: IActionInterface): any {
  try {
    const response = yield call(
      authService.changePasword,
      action?.payload
    );
    if (response.status === 200) {
      if(response?.data?.status === 404){
        yield put({ type: authAction.CHANGE_PASS_FAILED, payload: response?.data?.message });
        response.data?.message && toast.error(response.data?.message);
      }else{
        yield put({
          type: authAction?.CHANGE_PASS_SUCCESS,
          payload: response.data?.message
        });
        toast.success("Password changed successfully"); 
      }
    } else {
      yield put({ type: authAction.CHANGE_PASS_FAILED });
    }
  } catch (error) {
    yield put({
      type: authAction?.CHANGE_PASS_FAILED,
    });
  }
}

function* authSaga() {
  yield takeLatest(authAction.USER_SIGN_IN_REQUESTED, userSignIn);
  yield takeLatest(authAction.USER_SIGN_UP_REQUESTED, userSignUp);
  yield takeLatest(authAction.USER_SIGN_UP_GOOGLE_REQUESTED, userSignUpGoogle);
  yield takeLatest(
    authAction.CHECK_EMAIL_EXISTS_REQUESTED,
    checkIsEmailExistsSaga
  );
  yield takeLatest(authAction.FETCH_USER_DATA_REQUESTED, fetchUserDataSaga);
  yield takeLatest(authAction.VERIFY_RESET_PASS_REQUESTED, verifyResetSaga);

  yield takeLatest(
    authAction.VERIFY_ACCOUNT_EMAIL_REQUESTED,
    verifyAccountEmailSaga
  );
  yield takeLatest(
    authAction.CHECK_USERNAME_EXISTS_REQUESTED,
    checkIsUserNameExistsSaga
  );

  yield takeLatest(
    authAction.RESEND_OTP_REQUESTED_REQUESTED,
    resendVerificationSaga
  );

  yield takeLatest(
    authAction.INITIATE_RESET_REQUESTED,
    initiateResetPasswordSaga
  );

  yield takeLatest(
    authAction.CONFIRM_RESET_PASS_REQUESTED,
    confirmResetPasswordSaga
  );

  yield takeLatest(
    authAction.DELETE_USER_REQUESTED,
    deleteUserSaga
  );

  yield takeLatest(
    authAction.CHANGE_PASS_REQUESTED,
    changePasswordSaga
  );
}

export default authSaga;
