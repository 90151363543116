import { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Viator } from "../../constants";

const Duration = ({ filters, setFilters }) => {

  const [showAll, setShowAll] = useState(false);
  const visibleOptions = showAll ? Viator.durationOptions : Viator.durationOptions.slice(0, 4);

  const toggleShowMore = () => setShowAll(!showAll);

  const handleChange = (value) => {
    setFilters((prev) => ({
      ...prev,
      durationInMinutes: prev.durationInMinutes?.from === value.from
        ? null
        : value,
    }));
  };

  return (
    <div className="w-flex flex-col items-center md:items-start">
      <h3 className="text-lg font-semibold mb-6 hidden md:block">Duration</h3>
      <div className="space-y-3">
        {visibleOptions.map((option, index) => (
          <label key={index} className="flex items-center space-x-2 cursor-pointer">
            <input
              type="checkbox"
              checked={filters.durationInMinutes?.from === option.value.from}
              onChange={() => handleChange(option.value)}
              className="w-[18px] h-[18px] rounded border-2 accent-black border-gray-400 transition-all"
            />
            <span className="text-[#121212] font-[400]">{option.label}</span>
          </label>
        ))}
      </div>
      {Viator.durationOptions.length > 4 && (
        <button onClick={toggleShowMore} className="text-gray-500 text-[10px]">
          <div className="mt-3 flex items-center justify-center gap-1 text-[#333333] text-[12px] font-[500]">
            {showAll ? <>Show less <ChevronUp size={12} /></> : <>Show more <ChevronDown size={12} /></>}
          </div>
        </button>
      )}
    </div>
  );
};

export default Duration;
