import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { Location } from '../../assests/exportImages';
import { Box, Skeleton, Stack } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

const modalStyle = {
  position: 'absolute',
  zIndex: 100,
  width: '398px',
  '@media (max-width: 768px)': {
      width: '91vw',
  },
  borderRadius: 5,
  minHeight: 'content-fit',
  maxHeight: '374px',
  backgroundColor: 'white',
  boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.1)',
  marginTop: 1,
  overflow: 'hidden'
};
const SearchCard = ({ item, isFirst, isLast, isHotel, onClick }) => {
  return (
    <div
      className={`flex gap-[10px] items-center px-5 py-3 border-[#F1F1F1] hover:bg-[#FAFAFA] ${
        !isLast ? 'border-b' : ''
      } ${isFirst ? 'rounded-t-[20px]' : ''} ${isLast ? 'rounded-b-[20px]' : ''}`}
      onClick={onClick}
    >
      <div className='border-2 h-[50px] w-[50px] rounded-[10px] flex items-center justify-center overflow-hidden'>
        {isHotel ? (
          <img src={item.photo1} alt="Location Image" className='h-full w-full object-cover' />
        ) : (
          <img src={Location} alt="Location Image" className='h-5' />
        )}
      </div>
      <div>
        <p className='font-Mulish font-bold text-[14px] text-black'>{isHotel ? item.hotelName : item.city}</p>
        <p className='font-Mulish font-medium text-[12px] text-[#999999]'>{isHotel ? item.city : 'City'}</p>
      </div>
    </div>
  );
};

const SearchCardSkeleton = ({ isFirst, isLast }) => {
  return (
    <Box
      className={`flex gap-[10px] items-center px-5 py-3 border-[#F1F1F1] hover:bg-[#FAFAFA] ${
        !isLast ? "border-b" : ""
      } ${isFirst ? "rounded-t-[20px]" : ""} ${isLast ? "rounded-b-[20px]" : ""}`}
    >
      <Skeleton
        variant="rectangular"
        width={50}
        height={50}
        animation="wave"
        sx={{ borderRadius: "10px" }}
      />
      <Stack spacing={0.5}>
        <Skeleton animation="wave" height={20} width="100px" />
        <Skeleton animation="wave" height={16} width="80px" />
      </Stack>
    </Box>
  );
};

const SearchCity = ({ handleClose, setsearchBarDataLocal }) => {
  const modalRef = useRef(null);
  const { cityAndHotelListForSearch, cityAndHotelListForSearchLoader } = useSelector(
    (state: RootState) => state.screenReducer
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  

  const cities = cityAndHotelListForSearch?.citySearchResponse?.responseList || [];
  const hotels = cityAndHotelListForSearch?.hotelResponse?.content || [];

  const combinedItems = [...cities, ...hotels].slice(0, 12);

  const handleItemClick = (item) => {
    
    setsearchBarDataLocal((prevData) => ({
      ...prevData,
      cityId: item?.cityId,
      hotelId: item?.hotelId,
      searchText: item?.hotelName ? item?.hotelName : item?.city,
    }));
    setTimeout(() => {
      handleClose();
    }, 0);
  };

  return (
    <Box sx={modalStyle} ref={modalRef}>
      <PerfectScrollbar className='max-h-[374px]'>
        {cityAndHotelListForSearchLoader ? (
          Array.from({ length: 5 }).map((_, index) => (
            <SearchCardSkeleton key={index} isFirst={index === 0} isLast={index === 4} />
          ))
        ) : (
          combinedItems.map((item, index) => (
            <SearchCard
              key={index}
              item={item}
              isFirst={index === 0}
              isLast={index === combinedItems.length - 1}
              isHotel={!!item.hotelName}
              onClick={() => handleItemClick(item)}
            />
          ))
        )}
      </PerfectScrollbar>
    </Box>
  );
};

export default SearchCity;