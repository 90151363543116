import { Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/template/Footer";
import Header from "../../components/template/Header";
import Helmet from "react-helmet";
import TwinInput from "../../components/atom/TwinInputSet";
import { Search } from "@mui/icons-material";
import EChip from "../../components/atom/EChip";
import { SkeletonCard } from "../../components/skeltonLoader/cardSkelton";
import { HotelCard } from "../../components/HotelCard/HotelCard";
import { useDispatch, useSelector } from "react-redux";
import { screenActionsCall } from "../../redux/actions";
import en from "../../locale/en.json";
import { useEffect, useState } from "react";
import { logPageView } from "../../utils/analytics";
import SkeletonViatorCard from "../../components/skeltonLoader/viatorCards";
import { RootState } from "../../redux/reducers";
import RoomGuestSelector from "../../components/Filters/RoomGuestSelector";
import SearchCity from "../../components/Filters/SearchCity";
import FilterPanel from "../../components/Filters/Filters";


const Hotels = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {currentLocation, currentLocationLoader} = useSelector((state: RootState) => state.screenReducer);
  // const [city, setCity] = useState(currentLocation?.city);
  // const [country, setCountry] = useState(currentLocation?.country);

  const { citySearchList, citySearchListLoader, cityAndHotelList, cityAndHotelListLoader } = useSelector(
    (state: RootState) => state.screenReducer
  );
  

  const today = new Date();
  const checkInDate = today.toISOString().split('T')[0];
  const checkOutDate = new Date(today.setDate(today.getDate() + 2)).toISOString().split('T')[0];

  const [searchBarData, setsearchBarData] = useState({
    searchText: "",
    cityId: 0,
    hotelId: 0,
    checkInDate: checkInDate,
    checkOutDate: checkOutDate,
    occupancy: {
      roomCount: 1,
      adultCount: 2,
      childCount: 0,
      childAge: [],
    },
  })
  const [filters, setFilters] = useState({
    // search: "",
    // categories: [],
    price: [0, 999999] as [number, number],
    rating: 0,
    sortBy:"AllGuestsReviewScore",
    // rooms: 1,
    // adults: 1,
    // children: 0,
    // childrenAges: [],
  });

  const handleClearAll = () => {
    setFilters({
      // search: "",
      // categories: [],
      price: [0, 999999] as [number, number],
      rating: 0,
      sortBy:"AllGuestsReviewScore",
      // rooms: 1,
      // adults: 1,
      // children: 0,
      // childrenAges: [],
    });
  };
  
  useEffect(() => {
    if(searchBarData?.cityId === 0)return;

    const apiBody = callBody(filters.price[1], filters.price[0], searchBarData?.occupancy, filters.sortBy, searchBarData?.checkInDate, searchBarData?.checkOutDate, searchBarData?.cityId, filters.rating)
    
    dispatch(screenActionsCall.getCitySearch(apiBody))

  }, [filters, searchBarData])
  

  // const body ={
  //   "criteria": {
  //       "additional": {
  //           "currency": "INR",
  //           "dailyRate": {
  //               "maximum": filters.price[1],
  //               "minimum": filters.price[0]
  //           },
  //           "discountOnly": false,
  //           "language": "en-us",
  //           "maxResult": 10,
  //           "minimumReviewScore": 0,
  //           "minimumStarRating": 0,
  //           // "occupancy": {
  //           //     "numberOfAdult": 2,
  //           //     "numberOfChildren": 1,
  //           //     "childrenAges": [10]
  //           // },
  //           "sortBy": filters.sortBy
  //       },
  //       "checkInDate": checkInDate,
  //       "checkOutDate": checkOutDate,
  //       "cityId": currentLocation?.city === "Delhi" ? 14552 : (cityAndHotelList?.citySearchResponse?.responseList?.[0]?.cityId ? cityAndHotelList?.citySearchResponse?.responseList?.[0]?.cityId : 14552)
  //   },
  // };

  const callBody =(max,min,occupancy,sort, checkInDate, checkOutDate, cityId, starRating)=>{
    return {
      "criteria": {
          "additional": {
              "currency": "INR",
              "dailyRate": {
                  "maximum": max,
                  "minimum": min
              },
              "discountOnly": false,
              "language": "en-us",
              "maxResult": 10,
              "minimumReviewScore": starRating*2,
              "minimumStarRating": 0,
              "occupancy": {
                  "numberOfAdult": occupancy?.adultCount,
                  "numberOfChildren": occupancy?.childCount,
                  "childrenAges": occupancy?.childAge
              },
              "sortBy": sort
          },
          "checkInDate": checkInDate,
          "checkOutDate": checkOutDate,
          "cityId": cityId
      },
    }
  }
  useEffect(() => {
    // dispatch(screenActionsCall.getCitySearch(body));

    // google analytics on page mount
    let obj = {
      name: en.anayticsPageName.HOME,
      path: location.pathname,
    };
    logPageView(obj);
  }, []);

  useEffect(() => {
    if(!cityAndHotelListLoader && cityAndHotelList?.citySearchResponse?.responseList?.length === 0){      
    if (!cityAndHotelListLoader && !currentLocationLoader) {
      // dispatch(screenActionsCall.getCitySearch(body));
      setsearchBarData({
        ...searchBarData,
        cityId: 14552
      });
    }
  }else if (!cityAndHotelListLoader && cityAndHotelList?.citySearchResponse?.responseList?.[0]?.cityId && cityAndHotelList?.citySearchResponse?.responseList?.length !== 0){
    if (!cityAndHotelListLoader && !currentLocationLoader) {
    setsearchBarData({
      ...searchBarData,
      cityId: cityAndHotelList?.citySearchResponse?.responseList?.[0]?.cityId
    });
  }
  }

  }, [currentLocationLoader, cityAndHotelListLoader]);

  const handleHotelCardClick = (card: any) => {
    navigate(`/hotels/${card.hotelId}`, { state: { card, searchBarData } });
  };

  const renderFilterChips = () => {
    const activeFilters = [];

    if (filters.price[0] !== 0 || filters.price[1] !== 999999) {
      activeFilters.push(
        <EChip
          key="price"
          type="filter"
          label={`₹${filters.price[0]} - ₹${filters.price[1]}`}
          onDelete={() => setFilters({ ...filters, price: [0, 999999] })}
        />
      );
    }

    if (filters.rating > 0) {
      activeFilters.push(
        <EChip
          key="rating"
          type="filter"
          label={`${filters.rating} Star`}
          onDelete={() => setFilters({ ...filters, rating: 0 })}
        />
      );
    }

    if (filters.sortBy !== "AllGuestsReviewScore") {
      const sortLabels = {
        PriceAsc: "Price: Low to High",
        PriceDesc: "Price: High to Low",
        StarRatingDesc: "Stars (5 to 0)",
      };
      activeFilters.push(
        <EChip
          key="sort"
          type="filter"
          label={sortLabels[filters.sortBy]}
          onDelete={() => setFilters({ ...filters, sortBy: "AllGuestsReviewScore" })}
        />
      );
    }

    return activeFilters;
  };

  const handleSearch = (searchBarData) => {
    if((searchBarData?.cityId === 0 && searchBarData?.cityId === undefined))return;
    const isHotel = searchBarData?.hotelId !== 0 && searchBarData?.hotelId !== undefined;
    if(isHotel){
      // sessionStorage.setItem("searchBarData", JSON.stringify(searchBarData));
      // window.open(`/hotels/${searchBarData?.hotelId}`, "_blank");
      
    window.open(`/hotels/${searchBarData?.hotelId}?checkInDate=${encodeURIComponent(searchBarData?.checkInDate)}&checkOutDate=${encodeURIComponent(searchBarData?.checkOutDate)}&rooms=${encodeURIComponent(searchBarData?.occupancy?.roomCount)}&adults=${encodeURIComponent(searchBarData?.occupancy?.adultCount)}&children=${encodeURIComponent(searchBarData?.occupancy?.childCount)}`, "_blank");
    }else{
      dispatch(screenActionsCall.getCitySearch(callBody(filters.price[1], filters.price[0], searchBarData?.occupancy, filters.sortBy, searchBarData?.checkInDate, searchBarData?.checkOutDate, searchBarData?.cityId, filters.rating)))
    }
  }  

  return (
    <Stack>
      <Helmet>
        <title>Escapekar: Your Ultimate Travel Guide</title>
        <meta name="title" content="Escapekar: Your Ultimate Travel Guide" />
        <meta
          name="description"
          content="Plan your perfect trip with Escapekar's ultimate travel guide. Discover featured travels, hidden gems, and popular destinations. Get personalized recommendations and explore any place you desire with our advanced search functionality."
        />
        <meta
          name="keywords"
          content="travel guide, featured travels, hidden gems, popular destinations, travel itinerary, trip planner, personalized recommendations, search destinations, travel planning"
        />
        <meta name="url" content="https://escapekar.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://escapekar.com/" />
        <meta
          property="og:title"
          content="Escapekar: Your Ultimate Travel Guide"
        />
        <meta
          property="og:description"
          content="Discover featured travels, hidden gems, and popular destinations with Escapekar's travel guide. Get personalized trip planning recommendations and explore any location with our comprehensive search."
        />
        <meta property="og:site_name" content="Escapekar" />
        <meta property="og:locale" content="en-IN" />
        <meta property="og:country-name" content="IN" />
        <script id="data" type="application/json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "TravelGuide",
            name: "Escapekar Travel Guide",
            description:
              "Your ultimate resource for travel planning. Discover featured travels, hidden gems, and popular destinations. Get personalized recommendations and search for any location.",
            url: "https://escapekar.com/",
            sameAs: [
              "https://www.facebook.com/escapekar/",
              "https://www.instagram.com/escapekar/",
              "https://www.linkedin.com/company/escapekar/",
            ],
            hasPart: [
              {
                "@type": "TravelDestination",
                name: "Featured Travels",
                description:
                  "Explore our featured travel destinations and experiences.",
              },
              {
                "@type": "TravelDestination",
                name: "Hidden Gems",
                description:
                  "Discover hidden gems and lesser-known destinations.",
              },
              {
                "@type": "TravelDestination",
                name: "Popular Destinations",
                description:
                  "Browse popular destinations that are favored by travelers.",
              },
            ],
            contactPoint: {
              "@type": "ContactPoint",
              areaServed: "IN",
              availableLanguage: "en",
            },
          })}
        </script>
      </Helmet>
      <Header />
      <div className="flex flex-col items-center w-full bg-[#f6f6f6]">
        <div
          style={{
            background: 'linear-gradient(90deg, #E2FFF8 14.25%, rgba(255, 255, 255, 0) 100%), linear-gradient(90deg, #EAFAFE 0%, #EADEF9 100%)'
          }}
          className="flex relative h-[160px] md:h-[140px] w-full mt-[82px] justify-center items-center font-bold font-Mulish text-[#333] mb-[214px] md:mb-0"
        >
          <p className="placeholder:b-4 text-[24px] md:text-[36px]">Find your perfect stay</p>
          <div className="w-[95%] sm:w-[90%] max-w-[1280px] absolute md:-bottom-[35px] -bottom-[210px] h-auto mx-auto flex flex-row justify-center items-center px-2 sm:px-0">
            <TwinInput
              placeholder="Search City, Area or Property Name"
              startAdornment={<Search className="ml-2 font-[#999999]" />}
              searchBarData={searchBarData}
              setsearchBarData={setsearchBarData}
              handleSearch={handleSearch}
              // onFocus={() => setOpenSearchModal(true)}
            />
          </div>
        </div>
        
        <div className="px-4 sm:px-[70px] 2xl:px-0 py-6 sm:py-10 w-full max-w-[1280px]">
          <div className="hidden w-full mt-4 mb-6 md:flex flex-wrap items-center gap-2 sm:gap-3">
            {renderFilterChips()}
          </div>
          <div className="flex flex-col md:flex-row justify-between items-start w-full h-full">
            <div className="flex flex-col w-full md:w-[25%] h-full mb-6 md:mb-0">
              <FilterPanel filters={filters} setFilters={setFilters} handleClearAll={handleClearAll} />
            </div>
            <div className="flex flex-col w-full md:w-[75%] h-full gap-y-5 md:pl-6">
              {citySearchListLoader || cityAndHotelListLoader ? (
                Array.from({ length: 5 }, (_, i) => <SkeletonViatorCard key={i} />)
              ) : (
                citySearchList?.results === null ? <p className="text-center font-Mulish  text-[36px] font-bold mt-8">No Hotels Found</p> :
                <>
                  {citySearchList?.results?.map((data, index: number) => (
                    <HotelCard
                      key={data.hotelId}
                      item={data}
                      currentLocation={{ city: currentLocation?.city, country: currentLocation?.country }}
                      onClick={() => handleHotelCardClick(data)}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Stack>
  );
};

export default Hotels;
