/**
 * Constructs a URL with query parameters from the given payload object.
 * Each key in the payload object represents a query parameter name, and its corresponding value is the parameter value.
 * @url The base URL to which query parameters will be appended.
 * @payload An object where keys represent query parameter names and values represent parameter values.
 * @returns The constructed URL with properly encoded query parameters.
 */

export const REGEX = {
  alfa: /^[a-zA-Z ]*$/,
  alfaNumeric: /^[0-9a-zA-Z ]*$/,
  phone: new RegExp("^[6-9]([0-9]*)$"),
  numeric: /^[0-9]*$/,
  float: /^[+-]?[0-9]+([.][0-9]+)?([eE][+-]?[0-9]+)?$/,
  pin: /^[0-9]*$/,
  space: /^\S*$/,
  zipCode: /^\d{5}(-\d{4})?$/,
};

export const checkWhiteSpace = (value: string) => {
  let valid = true;
  if (!REGEX.space.test(value)) {
    valid = false;
  }
  return valid;
};

export const checkAlfaNumeric = (value: string) => {
  let valid = true;
  if (!REGEX.alfaNumeric.test(value)) {
    valid = false;
  }
  return valid;
};

export const checkAlfa = (value: string) => {
  let valid = true;
  if (!REGEX.alfa.test(value)) {
    valid = false;
  }
  return valid;
};

export const checkNumeric = (value: string) => {
  let valid = true;
  if (!REGEX.numeric.test(value) && value !== "") {
    valid = false;
  }
  return valid;
};

export const checkFloat = (value: string) => {
  let valid = true;
  if (!REGEX.float.test(value) && value !== "") {
    valid = false;
  }
  return valid;
};

export const checkPhone = (value: string) => {
  let valid = true;
  if (!REGEX.phone.test(value) && value !== "") {
    valid = false;
  }
  return valid;
};

export const checkImeiPhone = (value: string) => {
  let valid = true;
  if (!REGEX.numeric.test(value) && value !== "" && value.length < 14) {
    valid = false;
  }
  return valid;
};

export const checkPin = (value: string) => {
  let valid = true;
  if (!REGEX.pin.test(value) && value !== "") {
    valid = false;
  }
  return valid;
};

export const checkZipCode = (value: string) => {
  let valid = true;
  if (!REGEX.zipCode.test(value) && value !== "") {
    valid = false;
  }
  return valid;
};

// regex to remove leading zero (using in input field having type no.)
export const removeLeadingZero = (value: string) => {
  if (value === "0") return value;
  return value.toString().replace(/^0+/, "");
};

// Generic function to construct URL with query parameters
export const constructUrl = (url: string, payload: any): string => {
  let apiUrl: string = `${url}`;
  let queryParams: string[] = [];

  // Loop through the payload object and construct query parameters
  for (const [key, value] of Object.entries(payload)) {
    if (value) {
      if (Array.isArray(value)) {
        // Handle array values by formatting them correctly
        const formattedArray = value.map((item) => JSON.stringify(item));
        queryParams.push(`${key}=[${formattedArray.join(", ")}]`);
      } else {
        // Handle other types of values (i.e., string, number, boolean)
        queryParams.push(`${key}=${encodeURIComponent(String(value))}`);
      }
    }
  }

  // Join query parameters with '&' and append to apiUrl if there are any
  if (queryParams.length > 0) {
    apiUrl += `?${queryParams.join("&")}`;
  }

  return apiUrl;
};

// Return the first two initials
export const getInitials = (name: string) => {
  const nameParts = name.split(" ").filter(Boolean);
  const initials = nameParts
    .map((part) => part[0])
    .join("")
    .toUpperCase();
  return initials.slice(0, 1);
};

export const getFullName = (first = "", last = "") => {
  let fullName = `${first ? first : " "}` + " " + `${last ? last : " "}`;
  fullName = fullName.trim();
  return fullName;
};

export const getImagefromBase64 = (url) => {
  const imageSrc = `data:image/png;base64,${url}`;
  return imageSrc;
};

export const getStoredImages = (reference) => {
  const images = localStorage.getItem(`${reference}`);
  return images ? JSON.parse(images) : [];
};

export const clearLocalStorage = async () => {
  localStorage.clear();
  return "cleared";
};

export const getRandomIndex = (arr, indexCounts) => {
  const randomIndexes = [];
  for (let i = 0; i < indexCounts; i++) {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * arr.length);
    } while (randomIndexes.includes(randomIndex));
    randomIndexes.push(randomIndex);
  }
  return randomIndexes;
};

export function formatDate(dateString, place = "") {
  const date = new Date(dateString);

  // Short day of the week (first 3 letters)
  const dayOfWeek = date
    .toLocaleDateString("en-US", { weekday: "short" })
    .slice(0, 3);

  // Two-digit month and short month (first 3 letters)
  const digitMonth = date.toLocaleDateString("en-US", { month: "2-digit" });
  const month = date
    .toLocaleDateString("en-US", { month: "short" })
    .slice(0, 3);

  // Two-digit day and full year
  const day = date.toLocaleDateString("en-US", { day: "2-digit" });
  const year = date.getFullYear();

  // Time with AM/PM
  const timeOptions: any = { hour: "2-digit", minute: "2-digit", hour12: true };
  const time = date.toLocaleTimeString("en-US", timeOptions);

  if (place) {
    return {
      dayOfWeek,
      day,
      month,
      year,
      digitMonth,
      time,
    };
  } else {
    return `${day} ${month} ${year}, ${dayOfWeek} ${time}`;
  }
}

export function formatDateWithoutTime(dateString, place = "") {
  const date = new Date(dateString);

  // Short day of the week (first 3 letters)
  const dayOfWeek = date
    .toLocaleDateString("en-US", { weekday: "short" })
    .slice(0, 3);

  // Two-digit month and short month (first 3 letters)
  const digitMonth = date.toLocaleDateString("en-US", { month: "2-digit" });
  const month = date
    .toLocaleDateString("en-US", { month: "short" })
    .slice(0, 3);

  // Two-digit day and full year
  const day = date.toLocaleDateString("en-US", { day: "2-digit" });
  const year = date.getFullYear();

  // Time with AM/PM
  const timeOptions: any = { hour: "2-digit", minute: "2-digit", hour12: true };
  const time = date.toLocaleTimeString("en-US", timeOptions);

  if (place) {
    return {
      dayOfWeek,
      day,
      month,
      year,
      digitMonth,
      time,
    };
  } else {
    return `${day} ${month} ${year}`;
  }
}

// fuction to scroll to top of component
export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const validateStatus: any = {
  required: "The field is required",
  validateRegEx: "Invalid Email Address",
  minLength: "minLength",
};

export const validateEmail = (email: string) => {
  const emailRegex =
    /^[A-Z0-9_-]+([\.][A-Z0-9_]+)*@[A-Z0-9-]+(\.[a-zA-Z]{2,3})+$/i;
  email = email?.trim();
  if (email == "" || email == undefined || email == null)
    return { status: false, error: validateStatus.required };
  else if (!emailRegex.test(email))
    return { status: false, error: validateStatus.validateRegEx };
  else return { status: true, error: "" };
};

export const validatePassword = (pwd: string) => {
  if (pwd.length < 8) {
    return {
      status: false,
      error: "Password must be at least 8 characters long.",
    };
  }
  // if (!/[A-Z]/.test(pwd)) {
  //   return {
  //     status: false,
  //     error: "Password must contain at least one uppercase letter.",
  //   };
  // }
  // if (!/[a-z]/.test(pwd)) {
  //   return {
  //     status: false,
  //     error: "Password must contain at least one lowercase letter.",
  //   };
  // }
  // if (!/\d/.test(pwd)) {
  //   return {
  //     status: false,
  //     error: "Password must contain at least one digit.",
  //   };
  // }
  // if (!/[!@#$%^&*(),.?":{}|<>]/.test(pwd)) {
  //   return {
  //     status: false,
  //     error: "Password must contain at least one special character.",
  //   };
  // }
  return {
    status: true,
    error: "",
  };
};

// convert seconds to 00:00 time format
export const formatSecondsToTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${
    remainingSeconds >= 10 ? remainingSeconds : "0" + remainingSeconds
  }`;
};

export const isLoggedIn = () => {
  const token = localStorage.getItem("Login_Token");
  return token ? true : false;
};

// Convert rating from 10 to 5 scale and round to 1 decimal placE
export const convertRatingToFiveScale = (rating: number): number => {
  if (rating === 0) return 0;
  return Number((rating / 2).toFixed(1));
};

export const calculateDiscount = (dailyRate: number, crossedOutRate: number): number => {
  if (crossedOutRate <= 0 || dailyRate >= crossedOutRate) {
    return 0;
  }
  return Math.round(((crossedOutRate - dailyRate) / crossedOutRate) * 100);
};

export const partnerURLAgoda = (hotelDetails, searchBarData, paramsData) => {
    const today = new Date();
    const checkInDate = today.toISOString().split('T')[0];
    const checkOutDate = new Date(today.setDate(today.getDate() + 2)).toISOString().split('T')[0];      

  const params = {
    cid: hotelDetails?.cityId,
    hid: hotelDetails?.hotelId,
    currency: "INR",
    checkin: searchBarData?.checkInDate || paramsData?.checkInDateParam || checkInDate,
    checkout: searchBarData?.checkOutDate || paramsData?.checkOutDateParam || checkOutDate,
    NumberofAdults: searchBarData?.occupancy?.adultCount?.toString() || paramsData?.adults?.toString() || "2",
    NumberofChildren: searchBarData?.occupancy?.childCount?.toString() || paramsData?.children?.toString() || "0",
    Rooms: searchBarData?.occupancy?.roomCount?.toString() || paramsData?.rooms?.toString() || "1",
    pcs: "6"
  }

  const url = new URL(hotelDetails?.url);
  Object.keys(params).forEach((key) => url.searchParams.set(key, params[key]));
  return url.toString();
};


export const isEmptyObject = (obj) => Object.keys(obj).length === 0;

export const isNullOrEmpty = (value: any) => {
  if (value === null || value === undefined || Number.isNaN(value)) {
    return true
  }
  if (typeof value === 'string') {
    return value.trim() === ''
  }
  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return value.length === 0
    }
    return Object.keys(value).length === 0
  }
  return false
}

export const formatDuration = (minutes) => {
  const hrs = Math.floor(minutes / 60);
  const mins = minutes % 60;
  if (hrs > 0 && mins === 0) return `${hrs} hr`; // Remove "0 min"
  return hrs > 0 ? `${hrs} hr ${mins} minutes` : `${mins} min`;
};

export const validateFiltersViator = (filters, destinationId) => {
  const validatedFilters = { ...filters };

  // Remove empty filters
  Object.keys(validatedFilters).forEach((key) => {
    if (isNullOrEmpty(validatedFilters[key])) {
      delete validatedFilters[key];
    }
  });

  // Add destination filter
  validatedFilters.destination = destinationId;

  return validatedFilters;
}

export const getRatingRemark = (rating: number) => {
  if (rating === 0) return null;
  if (rating >= 4.5) return "Excellent";
  if (rating >= 4.0) return "Very Good";
  if (rating >= 3.0) return "Good";
  if (rating >= 2.0) return "Fair";
  return "Poor";
};