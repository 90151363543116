import {
  Business,
  Couple,
  Family,
  Friends,
  Solo,
  stars,
} from "../../assests/exportImages";
import { Box, Typography } from "@mui/material";
import styles from "./components.module.css";
import { typographyOptions } from "../../constants";
import { colors } from "../../themes/colors";


interface VacationListCardProps {
  borderRad?: number;
}

const VacationList: React.FC<VacationListCardProps> = ({ borderRad }) => {
  const cardArr = [
    {
      img: Solo,
      text: "Solo",
    },
    {
      img: Couple,
      text: "Couple",
    },
    {
      img: Friends,
      text: "Friends",
    },
    {
      img: Family,
      text: "Family",
    },
    {
      img: Business,
      text: "Business",
    },
  ];
  return (
    // <Box className="bothSidesPadding">
    <Box className={styles.vacationListCard} borderRadius={borderRad !== undefined ? borderRad : undefined}>
      <Box
        className={styles.vacationListCardIn}
        sx={{
          backgroundImage: `url(${stars}),url(${stars}),url(${stars}),url(${stars}),url(${stars}),url(${stars})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition:
            "top left, top right, bottom left, bottom right, center center, bottom center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundImage: `url(${stars}),url(${stars})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: " bottom right,center left",
          }}
        >
          <Typography
            variant={typographyOptions.H2}
            fontSize="18px"
            marginBottom="7px"
          >
            VACATION FOR ALL TYPE OF
          </Typography>
          <Typography
            variant={typographyOptions.CARDS_HEADING}
            color={colors.secondaryRed}
            fontFamily="Dosis"
            fontWeight="400"
          >
            Travellers
          </Typography>
        </Box>
        <Box className={styles.vacationListCards}>
          {cardArr.map((card, index) => (
            <Box key={index} className={styles.cardVacation}>
              <img
                src={card.img}
                alt={card.text}
                style={{ borderRadius: "16px 16px 0px 0px" }}
              />
              <Typography
                variant={typographyOptions.H2}
                fontSize="18px"
                marginBottom="7px"
              >
                {card.text}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
    // </Box>
  );
};
export default VacationList;
