import { Box, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  BedIcon,
  BinocularIcon,
  FlagIcon,
  PopularTripsDummy,
  RestaurantIcon,
  StarIcon,
} from "../../../assests/exportImages";
import { PlacesType, typographyOptions } from "../../../constants";
import { LoadMapKitScript } from "./loadMapkit";
import styles from "./section.module.css";
import { colors } from "../../../themes/colors";
import { mapToKenProd } from "../../../config/apiurls";

const AppleMap = ({ markers = [], setMarkers, onSelectMarker, initialLat, initialLong }: any) => {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const [hoveredMarkerIndex, setHoveredMarkerIndex] = useState(null);

  const calculateBoundingRegion = (markerArray: any) => {
    if (!markerArray.length) return null;

    let minLat = Infinity,
      maxLat = -Infinity,
      minLng = Infinity,
      maxLng = -Infinity;

    markerArray.forEach(({ latitude, longitude }) => {
      const lat = parseFloat(latitude);
      const lng = parseFloat(longitude);

      if (lat < minLat) minLat = lat;
      if (lat > maxLat) maxLat = lat;
      if (lng < minLng) minLng = lng;
      if (lng > maxLng) maxLng = lng;
    });

    const centerLat = initialLat || (minLat + maxLat) / 2;
    const centerLng = initialLong || (minLng + maxLng) / 2;

    const latSpan = (maxLat - minLat) * 1.5;
    const lngSpan = (maxLng - minLng) * 1.5;

    return {
      center: new window.mapkit.Coordinate(centerLat, centerLng),
      span: new window.mapkit.CoordinateSpan(latSpan, lngSpan),
    };
  };

  useEffect(() => {
    LoadMapKitScript(() => {
      if (window.mapkit) {
        window.mapkit.init({
          authorizationCallback: (done) => {
            done(mapToKenProd);
          },
        });
        mapInstance.current = new window.mapkit.Map(mapRef.current, {
          center: new window.mapkit.Coordinate(20.5937, 78.9629),
          span: new window.mapkit.CoordinateSpan(0.02, 0.02),
        });
      }
    });
  }, []);

  useEffect(() => {
    if (!mapInstance.current || markers.length === 0) return

    // Calculate bounding region
    const boundingRegion = calculateBoundingRegion(markers);

    if (boundingRegion) {
      // Adjust the map's region to fit all markers
      mapInstance.current.region = new window.mapkit.CoordinateRegion(
        boundingRegion.center,
        boundingRegion.span
      );
    }
  }, [markers]);

  useEffect(() => {
    if (!mapInstance.current || markers.length === 0) return;

    // Clear existing annotations
    mapInstance.current.annotations.forEach((annotation) => {
      mapInstance.current.removeAnnotation(annotation);
    });

    const getIconForType = (type) => {
      switch (type) {
        case "Attraction":
          return BinocularIcon;
        case "Stay":
          return BedIcon;
        case "Eat":
          return RestaurantIcon;
        case "Place":
          return FlagIcon;
        default:
          return FlagIcon;
      }
    };

    // Add new annotations
    markers.slice(0, 10).forEach((marker: any, index: number) => {
      const {
        latitude: propsLatitude,
        longitude: propsLongitude,
        title = "",
        subtitle = "",
        type = "",
      } = marker;

      const latitude = parseFloat(propsLatitude);
      const longitude = parseFloat(propsLongitude);
      const coordinate = new mapkit.Coordinate(latitude, longitude);
      const annotation = new mapkit.MarkerAnnotation(coordinate, {
        title: marker?.title,
        subtitle,
        glyphImage: {
          1: getIconForType(type),
          2: getIconForType(type),
          3: getIconForType(type),
        },
        color: "#20B08F",
      });

      annotation.addEventListener("select", () => {
        const newMarkers = markers.map((m, i) => ({
          ...m,
          selected: i === index,
        }));
        setMarkers(newMarkers);
        onSelectMarker(marker);
      });

      annotation.addEventListener("mouseover", () => {
        setHoveredMarkerIndex(index);
      });

      annotation.addEventListener("mouseout", () => {
        setHoveredMarkerIndex(null);
      });

      mapInstance.current.addAnnotation(annotation);
    });
  }, [markers, hoveredMarkerIndex, setMarkers, onSelectMarker]);

  return (
    <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
      <Box
        ref={mapRef}
        sx={{ width: "100%", height: "100%", borderRadius: "20px" }}
      />
      <Box
        className={styles.appleCardsContainer}
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: "180px", // Adjust the height of the cards container
          overflowX: "auto",
          display: "flex",
          alignItems: "center",
          padding: "10px",
          boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
          zIndex: 10, // Ensure it appears above the map
          scrollbarWidth: "none" /* Firefox */,
          msOverflowStyle: "none" /* Internet Explorer and Edge */,
        }}
      >
        {/* Example card content */}
        {markers.map((marker: any, index: number) => (
          <Box
            key={index}
            className={styles.appleCard}
            sx={{
              minWidth: "360px",
              height: "150px", // Adjust the card height
              marginRight: "10px",
              padding: "10px",
              borderRadius: "20px",
              boxShadow: "0px 3px 28px 3px #0000000A",
              backgroundColor: "#FFFFFF",
              display: "flex",
              gap: "20px",
            }}
          >
            <img
              src={marker?.image?.config?.url || PopularTripsDummy}
              alt=""
              style={{ width: "130px", borderRadius: "20px" }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "6px",
              }}
            >
              <Tooltip title={marker?.title}>
                <Typography
                  variant={typographyOptions.H2}
                  color={colors.black}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "180px",
                    cursor: "pointer",
                  }}
                >
                  {marker.title}
                </Typography>
              </Tooltip>
              <Typography variant={typographyOptions.SUB_HEADING}>
                {marker?.type === "PLACE"
                  ? marker.placeType ?? PlacesType[marker.type]
                  : PlacesType[marker.type]}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {" "}
                {marker?.rating && (
                  <Box sx={{ display: "flex", gap: "3px" }}>
                    <img
                      src={StarIcon}
                      alt="place img"
                      width={"10px"}
                      height={"10px"}
                    />
                    <Typography
                      fontSize="12px"
                      fontWeight={"700"}
                      color={colors.transparentWhite}
                      variant={typographyOptions.H2}
                    >
                      {`${marker?.rating} (${marker?.totalRatings})`}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Typography
                fontSize="12px"
                fontWeight={"700"}
                color={colors.transparentWhite}
                variant={typographyOptions.H2}
              >
                {marker?.costRange ? `${marker?.costRange} /person` : <></>}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AppleMap;
