import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";

import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import SuggestionCard from "./SuggestionCard";

const ThingsToDoSuggestionContainer = ({ type }) => {
  const {
    thingsToDoSimilarExperienceList,
    thingsToDoNearbyActivitiesList,
    thingsToDoYouMayAlsoLikeList,
    thingsToDoNearbyThingsToDoList,
    thingsToDoNearbyThingsToDoListLoader,
    thingsToDoYouMayAlsoLikeListLoader,
    thingsToDoSimilarExperienceListLoader,
    thingsToDoNearbyActivitiesListLoader,
  } = useSelector((state: RootState) => state.screenReducer);

  const [currentComponent, setcurrentComponent] = useState<{ products: any[] } | null>(null);
  const [ComponentName, setComponentName] = useState("");
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    let componentData: { products: any[] } | null = null;
    let componentName = "";
    let loaderVal = false

    switch (type) {
      case "SIMILAR_EXPERIENCE":
        componentData = thingsToDoSimilarExperienceList;
        loaderVal = thingsToDoSimilarExperienceListLoader
        componentName = "Similar Experience";
        break;
      case "NEARBY_ACTIVITIES":
        componentData = thingsToDoNearbyActivitiesList;
        loaderVal = thingsToDoNearbyActivitiesListLoader
        componentName = "Nearby Activities";
        break;
      case "YOU_MAY_ALSO_LIKE":
        componentData = thingsToDoYouMayAlsoLikeList;
        loaderVal = thingsToDoYouMayAlsoLikeListLoader
        componentName = "You May Also Like";
        break;
      case "NEARBY_THINGS_TO_DO":
        componentData = thingsToDoNearbyThingsToDoList; 
        loaderVal = thingsToDoNearbyThingsToDoListLoader
        componentName = "Nearby Things To Do";
        break;
      default:
        break;
    }

    setcurrentComponent(componentData);
    setComponentName(componentName);
    setLoader(loaderVal)
  }, [type, thingsToDoSimilarExperienceList, thingsToDoNearbyActivitiesList, thingsToDoYouMayAlsoLikeList, thingsToDoNearbyThingsToDoList, thingsToDoNearbyActivitiesListLoader, thingsToDoYouMayAlsoLikeListLoader, thingsToDoSimilarExperienceListLoader, thingsToDoNearbyThingsToDoListLoader]);

  return (
    <div className="flex justify-center overflow-hidden md:overflow-visible mt-2 md:mb-[114px] mb-[50px]">
      <div className="flex flex-col w-full md:w-auto justify-center">
        <p className="text-black text-[16px] font-bold mb-8">{ComponentName}</p>

        {/* Scrollable Wrapper */}
        <div className="overflow-x-auto w-full">
          <div className="flex gap-4 flex-nowrap md:flex-wrap md:w-[1120px]">
            {loader
              ? Array.from({ length: 8 }).map((_, index) => (
                  <div className="h-auto md:h-[372px] w-[268px] bg-white rounded-[21px] p-1 pb-[9px]">
                  <Skeleton variant="rectangular" width="260px" height={200} className="rounded-[21px]" />
                  <div className="px-2 mt-2">
                    <Skeleton variant="text" width="60%" height={20} />
                    <Skeleton variant="text" width="80%" height={20} />
                    <Skeleton variant="text" width="40%" height={20} />
                    <Skeleton variant="text" width="50%" height={20} />
                  </div>
                </div>
                ))
              : currentComponent?.products?.slice(0, 8).map((item, index) => (
                  <div key={index} className="shrink-0 ">
                    <SuggestionCard item={item} />
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThingsToDoSuggestionContainer;
