import React from 'react'
import { ArrowDiagonal } from '../../../assests/exportImages'

const BookNow = () => {
  return (
    <div className='w-[76px] h-[17px] rounded-[4px] flex items-center justify-center gap-1 border border-[#20B08F] cursor-pointer'>
        <p className='text-[#20B08F] font-bold text-[10px] font-Mulish'>Book Now</p>
        <img src={ArrowDiagonal} alt="Arrow" />
    </div>
  )
}

export default BookNow