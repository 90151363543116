import { InputAdornment, TextField } from "@mui/material";
import { ChevronDown, ChevronUp, Search } from "lucide-react";
import { useEffect, useState } from "react";
import { Viator } from "../../constants";
import useDebounce from "../../hooks/useDebounce";
import { screenActionsCall } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { isNullOrEmpty } from "../../utils/helpers";
import CustomRadio from "../atom/CustomRadioButton";

const Categories = ({ filters, setFilters,searchText, setsearchText }) => {
  const dispatch = useDispatch();
  const { thingsToDoActivitySearch, thingsToDoActivitySearchLoading } = useSelector((state: RootState) => state.screenReducer);

  const [activities, setActivities] = useState(Viator.activityTags);
  const [showAll, setShowAll] = useState(false);

  const handleSelect = (tag) => {
    setFilters({ ...filters, tags: [tag] });
  };

  
  const visibleOptions = showAll ? activities : activities?.slice(0, 8);

  const toggleShowMore = () => setShowAll(!showAll);

  const debouncedSearchValue = useDebounce(searchText, 300);

    useEffect(() => {
      if (debouncedSearchValue) {
        dispatch(screenActionsCall.getThingsToDoActivitySearch(debouncedSearchValue));
      }
    }, [debouncedSearchValue, dispatch]);

    useEffect(() => {
      if (!isNullOrEmpty(thingsToDoActivitySearch?.data?.content)) {
        const newActivities = thingsToDoActivitySearch?.data?.content?.slice(thingsToDoActivitySearch?.currentPointer);
        setActivities(newActivities);
      }
    }, [thingsToDoActivitySearch]);
    
    useEffect(() => {
      if (searchText === "" && activities !== Viator.activityTags) {
        setActivities(Viator.activityTags);
      }
    }, [searchText]); // Separate effect to avoid overwriting new results

    

  return (

<div className="w-full">
  <TextField
    placeholder="Search Activity"
    fullWidth
    InputProps={{
      sx: {
        fontSize: "14px",
        paddingX: "8px",
        width: "100%",
        borderRadius: "24px",
        border: "2px solid #f1f1f1",
        bgcolor: "white",
      },
      disableUnderline: true,
      startAdornment: (
        <InputAdornment position="start">
          <Search size={18} />
        </InputAdornment>
      ),
    }}
    value={searchText}
    onChange={(e) => setsearchText(e.target.value)}
  />
  
  <div className="space-y-2 my-4">
    {visibleOptions?.map((tag) => (
      <label
        key={tag?.tag}
        className="flex items-center space-x-2 py-1 rounded-lg cursor-pointer hover:bg-gray-100"
        onClick={() => handleSelect(tag?.tag)}
      >
        <CustomRadio selected={filters?.tags?.includes(tag?.tag)} onClick={() => handleSelect(tag?.tag)} />
        <span className="text-gray-800">{tag?.name}</span>
      </label>
    ))}
    
    {activities?.length > 8 && (
      <button onClick={toggleShowMore} className="text-gray-500 text-[10px]">
        <div className="flex items-center justify-center gap-1 text-[#333333] text-[12px] font-[500]">
          {showAll ? <>Show less <ChevronUp size={12} /></> : <>Show more <ChevronDown size={12} /></>}
        </div>
      </button>
    )}
  </div>
</div>

  );
};

export default Categories;
