import React, { useState, useEffect } from 'react';
import useDebounce from '../../hooks/useDebounce';

interface PriceRangeSliderProps {
  filters: {
    price: [number, number];
    [key: string]: any;
  };
  setFilters: (filters: any) => void;
}

export default function PriceRangeSlider({ filters, setFilters }: PriceRangeSliderProps) {
  const [minPrice, setMinPrice] = useState(filters.price[0]);
  const [maxPrice, setMaxPrice] = useState(filters.price[1]);

  const debouncedMinPrice = useDebounce(minPrice, 500);
  const debouncedMaxPrice = useDebounce(maxPrice, 500);

  useEffect(() => {
    setMinPrice(filters.price[0]);
    setMaxPrice(filters.price[1]);
  }, [filters.price]);

  useEffect(() => {
    setFilters({
      ...filters,
      price: [debouncedMinPrice, debouncedMaxPrice]
    });
  }, [debouncedMinPrice, debouncedMaxPrice]);

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMin = Math.min(Number(e.target.value), Number(maxPrice) - 100);
    setMinPrice(newMin);
  };

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMax = Math.max(Number(e.target.value), Number(minPrice) + 100);
    setMaxPrice(newMax);
  };

  const minPercentage = ((Number(minPrice) - 0) / (999999 - 0)) * 100;
  const maxPercentage = ((Number(maxPrice) - 0) / (999999 - 0)) * 100;

  return (
    <div className="w-full max-w-md">
      <h2 className="text-lg font-semibold mb-6 hidden md:block">
        {/* Price Range */}
        </h2>
      
      <div className="relative pt-2">
        <div className="relative">
          {/* Track background */}
          <div className="absolute h-1 w-full bg-gray-200 rounded"></div>
          
          {/* Active track */}
          <div 
            className="absolute h-1 bg-black rounded" 
            style={{
              left: `${minPercentage}%`,
              right: `${100 - maxPercentage}%`
            }}
          ></div>

          {/* Min slider */}
          <input
            type="range"
            min={0}
            max={999999}
            value={minPrice}
            onChange={handleMinChange}
            className="absolute w-full pointer-events-auto appearance-none bg-transparent [&::-webkit-slider-thumb]:w-3 [&::-webkit-slider-thumb]:h-3 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-black [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:cursor-pointer"
          />

          {/* Max slider */}
          <input
            type="range"
            min={0}
            max={999999}
            value={maxPrice}
            onChange={handleMaxChange}
            className="absolute w-full pointer-events-auto appearance-none bg-transparent [&::-webkit-slider-thumb]:w-3 [&::-webkit-slider-thumb]:h-3 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-black [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:cursor-pointer"
          />
        </div>
        
        <div className="flex justify-between mt-4">
          <div className="flex flex-col items-center">
            <input
              type="number"
              value={minPrice}
              onChange={handleMinChange}
              className="mt-1 px-4 py-2 bg-transparent border text-sm font-medium w-20 text-center outline-none rounded-[20px] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              placeholder="Min"
            />
          </div>
          
          <div className="flex flex-col items-center ">
            <input
              type="number"
              value={maxPrice}
              onChange={handleMaxChange}
              className="mt-1 px-4 py-2 bg-transparent border text-sm font-medium w-20 text-center outline-none rounded-[20px] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              placeholder="Max"
            />
          </div>
        </div>
      </div>
    </div>
  );
}