import { ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";
import { Viator } from "../../constants";

const SpecialsFilter = ({ filters, setFilters }) => {

  const [showAll, setShowAll] = useState(false);
  const visibleOptions = showAll ? Viator.specialsOptions : Viator.specialsOptions.slice(0, 4);
  const toggleShowMore = () => setShowAll(!showAll);

  const handleChange = (value) => {
    setFilters((prev) => ({
      ...prev,
      flags: prev?.flags?.includes(value)
        ? prev.flags.filter((item) => item !== value)
        : [...(prev.flags || []), value],
    }));
  };
  

  return (
    <div >
      <h2 className="text-lg font-semibold mb-6 hidden md:block">Specials</h2>
      <div className="space-y-2">
        {visibleOptions.map((option) => (
          <label
            key={option?.value}
            className="flex items-center space-x-2 py-1 rounded-lg cursor-pointer hover:bg-gray-100"
          >
            <input
              type="checkbox"
              value={option?.value}
              checked={filters?.flags?.includes(option?.value)}
              onChange={() => handleChange(option?.value)}
              className="w-[18px] h-[18px] rounded border-2 accent-black border-gray-400  transition-all"
            />
            <span className="text-gray-800">{option?.name}</span>
          </label>
        ))}
      </div>
      {Viator.specialsOptions.length > 4 && (
      <button
          onClick={toggleShowMore}
          className="text-gray-500 text-[10px]"
        >
      <div className="mt-2 flex items-center justify-center gap-1 text-[#333333] text-[12px] font-[500]" onClick={toggleShowMore}>
  {showAll ? <>Show less <ChevronUp size={12} /></> : <>Show more <ChevronDown size={12} /></>}
</div>
</button>)}
    </div>
  );
};

export default SpecialsFilter;
