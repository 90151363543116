import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { forwardRef, useState } from "react";
import { typographyOptions } from "../../constants";
interface IInput {
  onIconClick?: () => void;
  customStyle?: any;
  inputContainerStyle?: any;
  startAdornment?: any;
  labelText?: string;
  isAutoComplete?: boolean;
  icon?: any;
  iconStart?: any;
  errormessage?: string;
  error?: boolean;
  type?: string;
  onChange?: any;
  value?: any;
  passwordInput?: boolean;
  maxWidth?: string;
  maxLength?: number;
  showLength?: boolean;
  placeholder?: string;
  multiline?:boolean;
}

const Input = forwardRef((props: IInput, ref) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const {
    onIconClick,
    customStyle = {},
    inputContainerStyle = {},
    startAdornment: StartIcon,
    labelText = "",
    isAutoComplete = false,
    iconStart,
    icon,
    errormessage = "",
    error,
    type = "text",
    onChange,
    value,
    passwordInput = false,
    maxWidth = "420px",
    maxLength,
    showLength=false,
    placeholder,
    multiline=false,
  } = props || {};

  // handles show password
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box
        sx={{
          "& .MuiTextField-root": {
            maxWidth: { maxWidth },
            width: "100%",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            fontSize: "12px",
            top: "1px",
          },
          "& .MuiInputLabel-root": {
            fontSize: "12px",
            top: "1px",
            margin: "1px",
          },
        }}
      >
        {/* {iconStart && <img src={iconStart} />} */}
        <TextField
          id="outlined-search"
          placeholder={placeholder}
          label={labelText}
          type={passwordInput ? (showPassword ? "text" : "password") : type}
          role="button"
          autoComplete={isAutoComplete ? "on" : "off"}
          onChange={onChange}
          value={value}
          multiline={multiline}
          inputProps={{ maxLength }}
          InputProps={{
            sx: {
              borderRadius: "16px",
              fontSize: "12px",
              minHeight: "42px",
              height: "6vh",
              maxHeight: "50px",
              boxShadow:
                "11px 10px 32px 0px #0000000A, -19px -7px 32px 0px #3333330A,1px 1px 2px 0px #00000021 inset",
              border: errormessage !== "" ? "1px  solid #F05D5D " : "none",
              ...customStyle,
            },

            startAdornment: (
              <InputAdornment position="start">
                {StartIcon ? <StartIcon /> : <img src={iconStart} />}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                {passwordInput ? (
                  showPassword ? (
                    <Box onClick={handleTogglePasswordVisibility}>
                      <VisibilityIcon />
                    </Box>
                  ) : (
                    <Box onClick={handleTogglePasswordVisibility}>
                      <VisibilityOffIcon />
                    </Box>
                  )
                ) : (
                  <img src={icon} onClick={onIconClick} />
                )}
              </InputAdornment>
            ),
          }}
          sx={{
            color: "rgba(60, 60, 67, 0.4)",
            // remove upper and lower icon in case of type number
            "& input[type=number]": {
              "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            ...inputContainerStyle,
          }}
          onBlur={() => setFocused(false)}
          onFocus={() => setFocused(true)}
          focused={focused}
          inputRef={ref}
          error={error ?? false}
          {...props}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" width={maxWidth}>
        <Box flex={1} minHeight="20px">
        {errormessage && (
          <Typography variant={typographyOptions.ERROR} marginLeft="8px">
            {errormessage}
          </Typography>
        )}
      </Box>
        {showLength && (
          <Typography
            variant={typographyOptions.SUB_HEADING}
            marginRight="8px"
            marginTop="5px"
            textAlign="right"
            minWidth="50px"
          >
            {value?.length ?? 0}/{maxLength}
          </Typography>
        )}
</Box>

    </Box>
  );
});

export default Input;
