import React, { useState } from "react";
import { Box, Card, CardContent, Skeleton } from "@mui/material";

const SkeletonViatorCard = () => {
  const [showExpanded, setShowExpanded] = useState(false);

  return (
    <>
      {/* Desktop Skeleton */}
      <Box
        className="font-Mulish hidden md:flex flex-col w-full h-auto bg-[#e9eaf2] rounded-[14px] cursor-pointer"
        sx={{
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
          "&:hover": {
            transform: "scale(0.98)",
          },
        }}
      >
        {/* Main Card */}
        <Card
          sx={{
            display: "flex",
            flexDirection: "row",
            height: 262,
            width: "100%",
            borderRadius: 2,
            boxShadow: 1,
          }}
        >
          {/* Image Placeholder */}
          <Skeleton
            variant="rectangular"
            width={320}
            height={250}
            sx={{ borderRadius: 2, marginTop: "6px", marginLeft: "6px" }}
          />

          {/* Text Content */}
          <Box
            sx={{
              flex: 1,
              px: 3,
              py: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <CardContent sx={{ padding: 0 }}>
              <Skeleton variant="text" width={120} height={20} />
              <Skeleton variant="text" width="80%" height={25} />
              <Skeleton variant="text" width="60%" height={25} />
              <Skeleton variant="text" width={200} height={20} />
              <Skeleton variant="text" width={150} height={20} />
            </CardContent>

            {/* Rating & Price */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderTop: "1px solid #F1F1F1",
                paddingTop: 2,
              }}
            >
              <Skeleton variant="text" width={50} height={30} />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "column", mr: 3 }}>
                  <Skeleton variant="text" width={50} height={15} />
                  <Skeleton variant="text" width={80} height={25} />
                </Box>
                <Skeleton
                  variant="rectangular"
                  width={100}
                  height={40}
                  sx={{ borderRadius: 2 }}
                />
              </Box>
            </Box>
          </Box>
        </Card>
              {/* Additional Details */}
        {/* <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", px: 3, py: 2 }}>
            <Box sx={{ flex: 1 }}>
            <Skeleton variant="text" width="90%" height={20} />
            {showExpanded &&
                [...Array(4)].map((_, index) => <Skeleton key={index} variant="text" width="90%" height={20} />)}
            </Box>
            <Typography
            className="font-Mulish text-xs font-bold text-[#333] underline cursor-pointer"
            onClick={() => setShowExpanded(!showExpanded)}
            >
            {showExpanded ? "View less" : "+4 more"}
            </Typography>
        </Box> */}
      </Box>

      {/* Mobile Skeleton */}
      <Box className="md:hidden block h-auto w-full bg-white rounded-[21px] p-1 pb-[9px] cursor-pointer font-Mulish">
        {/* Image Skeleton */}
        <Skeleton
          variant="rectangular"
          width="100%"
          height={160}
          sx={{ borderRadius: "21px", mb: "10px" }}
        />

        <Box className="px-2">
          {/* Category Tag */}
          <Skeleton variant="text" width={50} height={15} sx={{ mb: "8px" }} />

          {/* Hotel Name */}
          <Skeleton variant="text" width="80%" height={20} />

          {/* Rating & Review Count */}
          <Box className="my-2 flex items-center gap-1">
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="text" width={50} height={15} />
          </Box>

          {/* Discounted Price */}
          <Box className="flex items-center justify-end">
            <Skeleton variant="text" width={60} height={15} />
          </Box>

          {/* Price & Breakfast */}
          <Box className="flex items-center justify-between">
            <Skeleton variant="text" width={100} height={15} />
            <Skeleton variant="text" width={60} height={20} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SkeletonViatorCard;
