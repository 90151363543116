import React, { useState } from 'react';
import { Calendar, ChevronLeft, ChevronRight, X } from 'lucide-react';

interface DatePickerProps {
  startDate: string;
  endDate: string;
  onDatesChange: (dates: { startDate: string; endDate: string }) => void;
}

const MONTHS = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const formatDisplayDate = (dateStr: string) => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  return date.toLocaleDateString('en-IN', { 
    day: 'numeric',
    month: 'short'
  });
};

const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-CA'); // 'YYYY-MM-DD' format in local time
  };

const DatePicker = ({ startDate, endDate, onDatesChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [hoverDate, setHoverDate] = useState<Date | null>(null);

  // Set default dates (today and tomorrow) when component mounts
  React.useEffect(() => {
    if (!startDate && !endDate) {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      
      onDatesChange({
        startDate: today.toISOString().split('T')[0],
        endDate: tomorrow.toISOString().split('T')[0]
      });
    }
  }, []);

  const getDaysInMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDay = new Date(year, month, 1).getDay();
    
    const days: Date[][] = [[]];
    let currentWeek = 0;

    // Add empty cells for days before the first of the month
    for (let i = 0; i < firstDay; i++) {
      days[0].push(new Date(year, month, -firstDay + i + 1));
    }

    // Add the days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      if (date.getDay() === 0 && day !== 1) {
        currentWeek++;
        days[currentWeek] = [];
      }
      days[currentWeek].push(date);
    }

    // Fill in the remaining days of the last week
    const lastWeek = days[days.length - 1];
    const remainingDays = 7 - lastWeek.length;
    for (let i = 1; i <= remainingDays; i++) {
      lastWeek.push(new Date(year, month + 1, i));
    }

    return days;
  };

  const isDateSelected = (date: Date) => {
    const dateStr = formatDate(date);
    return dateStr === startDate || dateStr === endDate;
  };

  const isDateInRange = (date: Date) => {
    const dateStr = formatDate(date);
    if (startDate && endDate) {
      return dateStr > startDate && dateStr < endDate;
    }
    if (startDate && hoverDate) {
      const hoverStr = hoverDate.toISOString().split('T')[0];
      const start = startDate < hoverStr ? startDate : hoverStr;
      const end = startDate < hoverStr ? hoverStr : startDate;
      return dateStr > start && dateStr < end;
    }
    return false;
  };

  const isDateDisabled = (date: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  };

  const handleDateClick = (date: Date) => {
    if (isDateDisabled(date)) return;

    const dateStr = formatDate(date);
    
    if (!startDate || (startDate && endDate)) {
      onDatesChange({ startDate: dateStr, endDate: '' });
    } else {
      if (dateStr < startDate) {
        onDatesChange({ startDate: dateStr, endDate: '' });
      } else {
        onDatesChange({ startDate, endDate: dateStr });
      }
    }
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
  };

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(true)}
        className="w-full flex items-center gap-4 px-4 py-2 rounded-lg  transition-colors"
      >
        <Calendar className="w-5 h-5 text-gray-500" />
        <span className="text-gray-700 font-medium md:text-[16px] text-[14px]">
          {startDate ? formatDisplayDate(startDate) : 'Check-in'} 
          {' - '} 
          {endDate ? formatDisplayDate(endDate) : 'Check-out'}
        </span>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4 md:p-0 mt-8">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-[720px] max-h-[70vh] overflow-y-auto">
            <div className="sticky top-0 bg-white border-b p-4 flex justify-between items-center">
              <h3 className="text-lg font-semibold">Select Dates</h3>
              <button 
                onClick={() => setIsOpen(false)} 
                className="p-1 hover:bg-gray-100 rounded-full"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <div className="p-4">
              <div className="grid md:grid-cols-2 gap-8">
                {[0, 1].map((offset) => {
                  const monthDate = new Date(currentDate);
                  monthDate.setMonth(monthDate.getMonth() + offset);
                  
                  return (
                    <div key={offset}>
                      <div className="flex justify-between items-center mb-4">
                        <h4 className="font-medium">
                          {MONTHS[monthDate.getMonth()]} {monthDate.getFullYear()}
                        </h4>
                        {offset === 0 && (
                          <button 
                            onClick={prevMonth} 
                            className="p-1 hover:bg-gray-100 rounded-full"
                          >
                            <ChevronLeft className="w-5 h-5" />
                          </button>
                        )}
                        {offset === 1 && (
                          <button 
                            onClick={nextMonth} 
                            className="p-1 hover:bg-gray-100 rounded-full"
                          >
                            <ChevronRight className="w-5 h-5" />
                          </button>
                        )}
                      </div>

                      <div className="grid grid-cols-7 gap-1">
                        {DAYS.map((day) => (
                          <div key={day} className="text-center text-sm text-gray-500 py-1">
                            {day}
                          </div>
                        ))}
                        
                        {getDaysInMonth(monthDate).map((week, weekIndex) => (
                          <React.Fragment key={weekIndex}>
                            {week.map((date, dateIndex) => {
                              const dateStr = formatDate(date);
                              const isSelected = isDateSelected(date);
                              const isInRange = isDateInRange(date);
                              const isToday = date.toDateString() === new Date().toDateString();
                              const disabled = isDateDisabled(date);
                              const isStartDate = dateStr === startDate;
                              const isEndDate = dateStr === endDate;
                              
                              return (
                                <button
                                  key={dateIndex}
                                  onClick={() => handleDateClick(date)}
                                  onMouseEnter={() => !disabled && setHoverDate(date)}
                                  onMouseLeave={() => setHoverDate(null)}
                                  disabled={disabled}
                                  className={`
                                    relative h-10 flex items-center justify-center text-sm
                                    transition-colors
                                    ${disabled ? 'text-gray-300 cursor-not-allowed' : 'hover:bg-gray-100'}
                                    ${isInRange ? 'bg-gray-100' : ''}
                                    ${isSelected ? 'bg-black text-white' : ''}
                                    ${isStartDate ? 'bg-black rounded-l-lg' : ''}
                                    ${isEndDate ? 'bg-black rounded-r-lg' : ''}
                                    ${date.getMonth() !== monthDate.getMonth() ? 'text-gray-300' : ''}
                                  `}
                                >
                                  {date.getDate()}
                                </button>
                              );
                            })}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* <div className="flex justify-between items-center mt-4 pt-4 border-t sticky bottom-0 bg-white"> */}
                {/* <button
                  onClick={() => {
                    onDatesChange({ startDate: '', endDate: '' });
                    setIsOpen(false);
                  }}
                  className="text-gray-600 hover:text-gray-800"
                >
                  Clear
                </button> */}
                {/* <button
                  onClick={() => setIsOpen(false)}
                  className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800"
                >
                  Apply
                </button>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;