const CustomRadio = ({ selected, onClick }: { selected: boolean; onClick: () => void }) => {
    return (
      <div
        className={`relative w-5 h-5 border-2 rounded-full transition-all flex items-center justify-center 
        ${selected ? 'border-black' : 'border-gray-500'}`}
        onClick={onClick}
      >
        {selected && <div className="w-[12px] h-[12px] bg-black rounded-full" />}
      </div>
    );
  };
  
  export default CustomRadio;
  