const Chip = ({ label, icon, type = "default" }: { label: string; icon?: JSX.Element; type?: "default" | "alert" }) => {
  const styles = type === "alert"
    ? "bg-[#FEEFEF] text-[#F05D5D]"
    : "bg-[#f1f1f1] text-[#666666]";

  return (
    <div className={`flex items-center gap-1 px-3 py-1 rounded-lg text-[10px] font-bold font-Mulish ${styles}`}>
      {icon && <span className="w-[8px] h-[8px] flex items-center justify-center">{icon}</span>}
      <span>{label}</span>
    </div>
  );
};

export default Chip;
