import React from 'react'
import { howItHelps1, WhatElse } from '../../../assests/exportImages'
import { exploreImages } from '../../../constants'

const WhatElseYouGet = () => {

    const cardData = [
        { title: "Hidden gems", description: "Ditch the crowds and discover the places only seasoned travelers know. Find hidden gems that turn a good trip into an unforgettable adventure.", image: 'hiddenGem' },
        { title: "AI checklist", description: "Based on your itinerary Escapekar’s AI automatically creates a personalised packing list—so you never forget the essentials.", image: 'aiChecklist' },
        { title: "Collab with friends & family", description: "Get your crew in on the planning! Friends and family can jump in, add spots, swap things around, or fine-tune the itinerary—all in real time.", image: 'collab' },
        { title: "Personalised recommendations", description: "Travel, your way! Escapekar learns what you love and finds the best experiences, stays, and activities tailored just for you.", image: 'recommendation' }
    ]
    return (
        <div className='w-fit px-5 mb-[100px] '>
            <p className="heading">
                What else you get
            </p>
                <div className='bg-white rounded-3xl md:py-24 md:px-10 px-5 py-5'>
                    <div className="flex items-center md:gap-32 justify-center flex-wrap md:flex-row flex-col-reverse">
                        <div>
                            <div>
                            <p className="subHeading md:text-[40px] md:max-w-[412px] mt-5 md:mt-0 ">
                                Share customisable
                                trip plans
                            </p>
                            </div>
                            <p className="bodytext max-w-[412px] ">
                                
                                Unlike PDFs or Word docs, Escapekar trip links let you click to explore, check photos, read reviews, and book instantly. Plus, your friends can customise the itinerary instead of being stuck with a fixed plan.
                            </p>
                        </div>
                        <img
                            width={359}
                            src={WhatElse}
                            alt="What Else Image"
                        />
                    </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-5">
  {cardData?.map((item, index) => (
    <div key={index} className="flex flex-col-reverse md:flex-col items-center md:py-20 px-8 bg-white rounded-3xl">
        <div>   
      <p className="subHeading mt-5 md:mt-0 text-center">
        {item?.title}
      </p>
      <p className="bodytext text-center mt-6">
        {item?.description}
      </p>
      </div>
      <img width={490} src={exploreImages[item?.image]} alt={`${item?.title} image`} />
    </div>
  ))}
</div>

        </div>
    )
}

export default WhatElseYouGet