import React, { useEffect, useState } from "react";
import { CustomButton } from "../atom/CustomButton";
import { Clock, DiscountTag, GreenTick, PopularTripsDummy, StarIcon, TickCirlce } from "../../assests/exportImages";
import EChip from "../atom/EChip";
import { Check, Home } from "@mui/icons-material";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { calculateDiscount, convertRatingToFiveScale, formatDuration, isNullOrEmpty } from "../../utils/helpers";
import Star from "../../assests/svgsComponent/Star";
import { RootState } from "../../redux/reducers";
import { useSelector } from "react-redux";
import LocationSVG from "../../assests/svgsComponent/Location";
import { Skeleton } from "@mui/material";
import ImageBanner from "../atom/ImageBanner";
import Chip from "../atom/Chip";

interface IProduct {
  productCode: string;
  title: string;
  reviews: any;
  images: any;
  duration: any;
  pricing: any;
  destination: string;
  tags: [];
  flags: [];
}

interface IThingsToDoCard {
  onClick: () => void;
  item: IProduct;
  loadingAllImages?: boolean;
}

export const ThingsToDoCard = (props: IThingsToDoCard) => {
  const { onClick, item } = props;
  const {  thingsToDoDestinationFromRefList, thingsToDoDestinationFromRefListLoader } = useSelector((state: RootState) => state.screenReducer);
  const [durationText, setdurationText] = useState("")

  const [flagsAvailables, setflagsAvailables] = useState({
    PRIVATE_TOUR: false,
    FREE_CANCELLATION: false,
    LIKELY_TO_SELL_OUT: false,
    FULL_DAY_TOUR: false,
  })

  const reviewScore = item?.reviews?.combinedAverageRating?.toFixed(1)
  const totalReviews = item?.reviews?.totalReviews
  const fromPrice = item?.pricing?.summary?.fromPrice
  const fromPriceBeforeDiscount = item?.pricing?.summary?.fromPriceBeforeDiscount
  const fixedDurationInMinutes = item?.duration?.fixedDurationInMinutes
  const variableDurationFromMinutes = item?.duration?.variableDurationFromMinutes
  const variableDurationToMinutes = item?.duration?.variableDurationToMinutes
  const flags = item?.flags
  const url = item?.images?.[0]?.variants?.[12]?.url || item?.images?.[0]?.variants?.[11]?.url;
  const destination = item?.destination
  



  useEffect(() => {
    fixedDurationInMinutes && setdurationText(`About ${formatDuration(fixedDurationInMinutes)}`);
    variableDurationFromMinutes && setdurationText(`About ${formatDuration(variableDurationFromMinutes)} - ${formatDuration(variableDurationToMinutes)}`);
    if (flags) {
      flags.forEach((flag) => {
        if (flag === "PRIVATE_TOUR") setflagsAvailables((prev) => ({ ...prev, PRIVATE_TOUR: true }))
        if (flag === "FREE_CANCELLATION") setflagsAvailables((prev) => ({ ...prev, FREE_CANCELLATION: true }))
        if (flag === "LIKELY_TO_SELL_OUT") setflagsAvailables((prev) => ({ ...prev, LIKELY_TO_SELL_OUT: true }))
        if (flag === "FULL_DAY_TOUR") setflagsAvailables((prev) => ({ ...prev, FULL_DAY_TOUR: true }))
      });
    }
  }, [])

  return (
    <>
      <div className="hidden font-Mulish md:flex flex-col w-full h-auto bg-[#e9eaf2] rounded-[14px] cursor-pointer">
        <div
          onClick={onClick}
          className="card flex flex-row h-[262px] w-full rounded-[10px] justify-center items-center shadow-sm bg-white"
        >

          <div className=" relative image w-[320px] h-[250px] rounded-[10px] pl-1">
            <img
              src={url || PopularTripsDummy}
              alt={item.title}
              className="rounded-[10px] bg-cover !h-[250px]"
              width="320px"
              height="250px"
            />
            {flagsAvailables?.LIKELY_TO_SELL_OUT && <ImageBanner text="Likely to Sell Out" bgColor="whiteRed" textColor="red" className={'absolute  top-[7%]'} />}
            {/* {<ImageBanner text="Additional 5% Cashback" bgColor="green" textColor="white" className={'absolute  top-[18.5%]'} />} */}
          </div>
          <div className="image w-[calc(100%-320px)] h-full justify-between flex flex-col px-5">
            <div className="flex flex-col w-full pt-3">
              <div className="flex gap-2">
                {thingsToDoDestinationFromRefListLoader ? <Skeleton className="w-20"/> :
                  <Chip
                    icon={<LocationSVG />}
                    label={thingsToDoDestinationFromRefList?.[item?.productCode]}
                  />
                   }
                {flagsAvailables?.PRIVATE_TOUR && <Chip label="Private Tour"/>}
                {flagsAvailables?.FULL_DAY_TOUR  && <Chip label="Full Day Tour" />}

              </div>
              {item?.title && (<h2 className="text-lg font-bold text-[#333] my-1 pr-3">
                {item?.title}
              </h2>)}
              {(!isNullOrEmpty(fixedDurationInMinutes) || (!isNullOrEmpty(variableDurationFromMinutes) && !isNullOrEmpty(variableDurationToMinutes))) && <div className="flex gap-2 items-center my-2 ml-0.5"><img src={Clock} alt="Green Tick icon" className="h-4" /><p className="text-sm font-medium text-[#999]">{durationText}</p></div>}
              {flagsAvailables?.FREE_CANCELLATION && <div className="flex gap-2 items-center"><img src={GreenTick} alt="Green Tick icon" className="h-5" /><p className="text-sm font-medium text-[#999]">Free Cancellation</p></div>}

            </div>
            {(!isNullOrEmpty(fromPrice) || !isNullOrEmpty(fromPriceBeforeDiscount)) && <div className="flex flex-row w-full h-[74px] border-t border-t-[#F1F1F1] justify-between items-center">
              { (!isNullOrEmpty(reviewScore) && !isNullOrEmpty(totalReviews)) && <div className="flex gap-[6px] items-center justify-center"><img src={StarIcon} alt="Star icon" className="h-3" /> <p className="text-lg font-bold text-[#999]">{reviewScore} {!isNullOrEmpty(totalReviews) && `(${totalReviews})`}</p></div>}
              <div className="flex flex-row justify-end items-center flex-1">
                <div className="flex flex-col mr-3">
                  <p className="text-sm font-normal text-[#999] text-right">
                    From
                  </p>
                  <p className={`text-xl font-bold text-[#121212]`}>
                    {item?.pricing?.currency === 'USD' ? '$' : item?.pricing?.currency === 'INR' ? '₹' : item?.pricing?.currency}
                    {fromPrice || fromPriceBeforeDiscount}
                  </p>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>

      {/* Mobile UI */}
      <div onClick={onClick} className="md:hidden block h-auto w-full bg-white rounded-[21px] p-1 pb-[9px] cursor-pointer font-Mulish">
      <div className="relative mb-[10px]">
        <img src={url || PopularTripsDummy} alt={item?.title} className="w-full h-[160px] object-cover rounded-[21px]" />
        {flagsAvailables?.LIKELY_TO_SELL_OUT && (
          <ImageBanner text="Likely to Sell Out" bgColor="whiteRed" textColor="red" className="absolute top-[7%]" />
        )}
      </div>
      <div className="px-2">
        <div className="flex gap-3">
        {thingsToDoDestinationFromRefListLoader ? <Skeleton className="w-10" /> :
                  <Chip
                    icon={<LocationSVG />}
                    label={thingsToDoDestinationFromRefList?.[item?.productCode]}
                  />
                   }
          {flagsAvailables?.PRIVATE_TOUR && <p className="text-sm font-medium text-[#999]">Private Tour</p>}
          {flagsAvailables?.FULL_DAY_TOUR && <p className="text-sm font-medium text-[#999]">Full Day Tour</p>}
        </div>
        <h2 className="font-bold text-[14px] text-[#333333] mt-1">{item?.title}</h2>
        {durationText && (
          <div className="flex gap-2 items-center my-2">
            <img src={Clock} alt="Clock" className="h-4 ml-[px]" />
            <p className="text-sm font-medium text-[#999]">{durationText}</p>
          </div>
        )}
        {flagsAvailables?.FREE_CANCELLATION && (
          <div className="flex gap-2 items-center">
            <img src={GreenTick} alt="Green Tick" className="h-5" />
            <p className="text-sm font-medium text-[#999]">Free Cancellation</p>
          </div>
        )}
        <div className="my-2 flex items-center justify-between gap-1">
          <div className="flex items-center gap-1">
          <div className="flex gap-0.5">
            {[...Array(5)].map((_, index) => (
              <Star key={index} fill={Math.max(0, Math.min(100, (reviewScore - index) * 100))} />
            ))}
          </div>
          <p className="font-bold text-[14px] text-[#999999]">({totalReviews})</p>
          </div>
          <div className="flex justify-between items-center">
          <p className="text-[16px] font-bold text-[#121212]">
            {item?.pricing?.currency === 'USD' ? '$' : item?.pricing?.currency === 'INR' ? '₹' : item?.pricing?.currency}
            {fromPrice}
          </p>
        </div>
        </div>

      </div>
    </div>
    </>
  );
};
