import React, { useEffect } from 'react';
import MainRouter from '../mainRouter';
import { useDispatch } from 'react-redux';
import { screenActionsCall } from '../redux/actions';

const AppInitializer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(screenActionsCall.getUserDetails());

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          dispatch(screenActionsCall.getCurrentLocation({ latitude, longitude }));
        },
        (error) => {
          console.error('Error getting location:', error);
          // Fallback to Delhi coordinates if location permission is denied
          const delhiCoordinates = { latitude: 28.6139, longitude: 77.2090 };
          dispatch(screenActionsCall.getCurrentLocation(delhiCoordinates));
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      // Fallback to Delhi coordinates if geolocation is not supported
      const delhiCoordinates = { latitude: 28.6139, longitude: 77.2090 };
      dispatch(screenActionsCall.getCurrentLocation(delhiCoordinates));
    }
  }, [dispatch]);

  return (
    <>
      <MainRouter />
    </>
  );
};

export default AppInitializer;