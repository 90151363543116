import { Stack, Tab, Tabs } from "@mui/material";
import Helmet from "react-helmet";
import Header from "../../components/template/Header";
import Footer from "../../components/template/Footer";
import EChip from "../../components/atom/EChip";
import {
  BookmarkBorderOutlined,
  FavoriteBorderOutlined,
  Home,
  Hotel,

  RestaurantMenu,
  ShareOutlined,
  Star,
  StarRounded,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import HotelOverviewPanel from "./HotelOverviewPanel";
import HotelNearbyPanel from "./HotelNearbyPanel";
import { convertRatingToFiveScale, scrollToTop } from "../../utils/helpers";
import { screenActionsCall } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { RootState } from "../../redux/reducers";
import { useParams } from 'react-router-dom';
import { colors } from "../../themes/colors";
import { Image, PopularTripsDummy } from "../../assests/exportImages";
import ImageSlider from "../../components/molecule/ImageSlider";
import HotelDetailsSkeleton from "./HotelDetailsSkeleton";
import AgodaBookNow from "../../components/molecule/AgodaBookNow";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const today = new Date();
const checkInDate = today.toISOString().split('T')[0];
const checkOutDate = new Date(today.setDate(today.getDate() + 2)).toISOString().split('T')[0];

const callBody = (cityId, starRating=0, sortBy="AllGuestsReviewScore")=>{
  return {
    "criteria": {
      "additional": {
        "currency": "INR",
        "dailyRate": {
          "maximum": 999999,
          "minimum": 0
        },
        "discountOnly": false,
        "language": "en-us",
        "maxResult": 10,
        "minimumReviewScore": 0,
        "minimumStarRating": starRating,
        "sortBy": sortBy
      },
      "checkInDate": checkInDate,
      "checkOutDate": checkOutDate,
      "cityId": cityId
    },
  }
}

const callHotelIdsBody = (hotelId, searchBarData, checkInDateParam, checkOutDateParam)=>{
  return {
    "criteria": {
        "checkInDate":checkInDateParam || searchBarData?.checkInDate || checkInDate,
        "checkOutDate":checkOutDateParam || searchBarData?.checkOutDate || checkOutDate,
        "hotelId": [hotelId],
}
}
}

const HotelDetails = () => {
    const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const [value, setValue] = useState(0);
  const [searchParams] = useSearchParams();
  const query = new URLSearchParams(useLocation().search);
  const checkInDateParam = query.get("checkInDate");
  const checkOutDateParam = query.get("checkOutDate");
  const rooms = query.get("rooms");
  const adults = query.get("adults");
  const children = query.get("children");
  
  
  

  const { card, searchBarData } = location.state || {};
  // const searchBarDataSession = JSON.parse(sessionStorage.getItem("searchBarData"));
  const hotelId = card?.hotelId || params.id;
  

  useEffect(() => {
    scrollToTop();
    if (hotelId) {
      dispatch(screenActionsCall.getHotelDetails(hotelId));
    }
  }, [hotelId]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <div className="w-full">{children}</div>}
      </div>
    );
  };

  const a11yProps = (index: number) => {
    return {
      hotelId: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

    const { hotelDetails, loadingHotelDetails } = useSelector((state: RootState) => state.screenReducer);

    useEffect(() => {
      if(hotelDetails?.cityId && hotelDetails?.starRating)
        dispatch(screenActionsCall.getCitySearchSimilarExperience(callBody(hotelDetails?.cityId, hotelDetails?.starRating)));
        dispatch(screenActionsCall.getCitySearchNearbyPlaces(callBody(hotelDetails?.cityId, 0, "StarRatingDesc")));
        dispatch(screenActionsCall.getHotelByIds(callHotelIdsBody(hotelDetails?.hotelId, searchBarData, checkInDateParam, checkOutDateParam)));
    }, [hotelDetails]);


  return (
    <Stack>
      <Helmet>
        <title>Escapekar: Your Ultimate Hotel Guide</title>
        <meta name="title" content="Escapekar: Your Ultimate Hotel Guide" />
        <meta
          name="description"
          content="Plan your perfect stay with Escapekar's ultimate hotel guide. Discover featured hotels, hidden gems, and popular destinations. Get personalized recommendations and explore any place you desire with our advanced search functionality."
        />
        <meta
          name="keywords"
          content="hotel guide, featured hotels, hidden gems, popular destinations, hotel itinerary, trip planner, personalized recommendations, search hotels, travel planning"
        />

        <meta name="url" content="https://escapekar.com/" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://escapekar.com/" />
        <meta
          property="og:title"
          content="Escapekar: Your Ultimate Hotel Guide"
        />
        <meta
          property="og:description"
          content="Discover featured hotels, hidden gems, and popular destinations with Escapekar's hotel guide. Get personalized trip planning recommendations and explore any location with our comprehensive search."
        />
        <meta property="og:site_name" content="Escapekar" />
        <meta property="og:locale" content="en-IN" />
        <meta property="og:country-name" content="IN" />

        <script id="data" type="application/json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "HotelGuide",
            name: "Escapekar Hotel Guide",
            description:
              "Your ultimate resource for hotel planning. Discover featured hotels, hidden gems, and popular destinations. Get personalized recommendations and search for any location.",
            url: "https://escapekar.com/",
            sameAs: [
              "https://www.facebook.com/escapekar/",
              "https://www.instagram.com/escapekar/",
              "https://www.linkedin.com/company/escapekar/",
            ],

            hasPart: [
              {
                "@type": "Hotel",
                name: "Featured Hotels",
                description:
                  "Explore our featured hotel destinations and experiences.",
              },
              {
                "@type": "Hotel",
                name: "Hidden Gems",
                description:
                  "Discover hidden gems and lesser-known hotel destinations.",
              },
              {
                "@type": "Hotel",
                name: "Popular Destinations",
                description:
                  "Browse popular hotel destinations that are favored by travelers.",
              },
            ],
            contactPoint: {
              "@type": "ContactPoint",
              areaServed: "IN",
              availableLanguage: "en",
            },
          })}
        </script>
      </Helmet>
      <Header />
      {loadingHotelDetails ? <HotelDetailsSkeleton /> :       <div className="flex flex-col items-center w-full  bg-[#f6f6f6] md:mt-20 mt-[68px] font-Mulish md:px-20 px-5 ">
        <div className=" max-w-[1280px] flex flex-col w-full items-center pt-10">
          <div className="flex md:flex-col flex-col-reverse">
          <div className="topBar flex flex-row justify-between items-start w-full md:pb-10 p-5 md:p-0">
            <div className="flex flex-col w-auto gap-y-3">
              <div className="flex md:flex-col flex-col-reverse gap-y-1 md:gap-y-3 ">
              <h2 className="font-bold text-2xl font-Mulish">
                {hotelDetails?.hotelName}
              </h2>
              <div className="flex flex-row w-full gap-x-3">
              {hotelDetails?.accommodationType && (
              <EChip
                icon={<Home sx={{ 
                  color: "#cccccc",
                  fontSize: "12px",
                  '&:hover': {
                    color: "#666666"
                  }
                }} />  }
                type="gray"
                customStyle={{ fontWeight: "700", color: "#cccccc" }}
                label={hotelDetails.accommodationType === "Apartment/Flat\r" ? "Apartment" : hotelDetails.accommodationType} 
              />
            )}                
            {/* <EChip type="gray" label={`${hotelDetails?.starRating || 0} Star`} /> */}
              </div>
              </div>
              <div className="flex md:flex-row flex-col-reverse font-medium text-sm text-[#999]">
                <p className="text-[#000]">
                  <StarRounded className="mr-1 !w-4 !h-4 mb-1" />
                  {convertRatingToFiveScale(hotelDetails?.ratingAverage) || 0}
                  <span className="text-[#999] mx-2">({hotelDetails?.numberOfReviews || 0} reviews)</span>
                </p>
                
                <p className="hidden md:block">•</p>
                <p className="md:ml-1 mb-2  md:mb-0 -mt-1 md:-mt-0">{`${hotelDetails?.city}, ${hotelDetails?.country}`}</p>
              </div>
            </div>
            {/* <div className="flex flex-row justify-between items-start w-[286px] mt-1">
              <p className="font-bold text-base underline text-[#333] cursor-pointer">
                <FavoriteBorderOutlined className="!w-4 !h-4 text-[#333] mr-1" />
                Like
              </p>
              <p className="font-bold text-base underline text-[#333] cursor-pointer">
                <BookmarkBorderOutlined className="!w-4 !h-4 text-[#333] mr-1" />
                Add to Trip
              </p>
              <p className="font-bold text-base underline text-[#333] cursor-pointer">
                <ShareOutlined className="!w-4 !h-4 text-[#333] mr-1" />
                Share
              </p>
            </div> */}
          </div>
          <div className="md:w-[1119px] md:h-[360px] h-[320px] object-cover">
            <div className="relative flex flex-wrap w-full h-full items-center justify-center gap-[7.74px]">
              {/* <div className=" rounded-[10px] absolute flex items-center gap-2 justify-center px-3 py-2 bottom-[5%] right-[2%] bg-white">
                <div>
                <img src={Image} className="h-[10.65px]"/>
                </div>
                <div>
                  <p className="font-Mulish font-medium text-[12px]">View All Images</p>
                </div>

              </div> */}
              {/* Main large image */}
              <div className="md:hidden block w-screen h-[320px]">
              <ImageSlider images={[
                {url: hotelDetails?.photo1, alt: hotelDetails?.hotelName},
                {url: hotelDetails?.photo2, alt: hotelDetails?.hotelName},
                {url: hotelDetails?.photo3, alt: hotelDetails?.hotelName},
                {url: hotelDetails?.photo4, alt: hotelDetails?.hotelName},
                {url: hotelDetails?.photo5, alt: hotelDetails?.hotelName},
              ]} classname=" rounded-none rounded-b-3xl object-cover"/>
              </div>
              <div className="w-auto hidden md:block">
                <img
                  src={hotelDetails?.photo1 || PopularTripsDummy}
                  alt={hotelDetails?.hotelName}
                  className="w-[555px] h-[360px] object-cover rounded-l-[20px]"
                />
              </div>
              
              {/* Right side smaller images container */}
              <div className="hidden md:flex flex-1 gap-[7.74px]">
                <div>
                <img
                  src={hotelDetails?.photo2 || PopularTripsDummy}
                  alt={hotelDetails?.hotelName}
                  className="w-[275.75px] h-[175px] object-cover mb-[7.74px]"
                />
                <img
                  src={hotelDetails?.photo4 || PopularTripsDummy}
                  alt={hotelDetails?.hotelName}
                  className="w-[275.75px] h-[175px] object-cover"
                />
                
                </div>
                <div>
                <img
                  src={hotelDetails?.photo3 || PopularTripsDummy}
                  alt={hotelDetails?.hotelName}
                  className="w-[275.75px] h-[175px] object-cover rounded-tr-[20px]  mb-[7.74px]"
                />
                <img
                  src={hotelDetails?.photo5 || PopularTripsDummy}
                  alt={hotelDetails?.hotelName}
                  className="w-[275.75px] h-[175px] object-cover rounded-br-[20px]"
                />
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="md:hidden block">
          <AgodaBookNow searchBarData={searchBarData} paramsData={{rooms, adults, children, checkInDateParam, checkOutDateParam}}/>
          </div>
          <div className="tabsHolder w-full">
            <div className="TabsOption border-b-[2px] border-[#f1f1f1] my-5">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#000",
                  },
                }}
                sx={{
                  ".Mui-selected": {
                    color: `#333`,
                  },
                }}
              >
                <Tab
                sx={{
                  color: colors.secondaryGrey,
                  "&.Mui-selected": {
                    color: colors.transparentWhite,
                  },
                }}
                  label={
                    <span className="!normal-case font-bold text-sm">
                      Overview
                    </span>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                sx={{
                  color: colors.secondaryGrey,
                  "&.Mui-selected": {
                    color: colors.transparentWhite,
                  }}}
                  label={
                    <span className="!normal-case font-bold text-sm">
                      Nearby
                    </span>
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </div>
            <CustomTabPanel value={value} index={0}>
              <HotelOverviewPanel hotelDetails={hotelDetails} searchBarData={searchBarData} paramsData={{rooms, adults, children, checkInDateParam, checkOutDateParam}} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <HotelNearbyPanel latitude={hotelDetails?.latitude} longitude={hotelDetails?.longitude} />
            </CustomTabPanel>
          </div>
        </div>
      </div>}

      <Footer />
    </Stack>
  );
};

export default HotelDetails;