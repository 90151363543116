import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import {
  Plus,
  User,
  Users,
  Eye,
  Calendar,
  MapPin,
  CheckCheck,
  X,
} from "lucide-react";
import { typographyOptions } from "../../constants";
import { colors } from "../../themes/colors";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import en from "../../locale/en.json";
import { getStoredImages } from "../../helper";
import { useNavigate } from "react-router-dom";

interface TripItem {
  id: string;
  name: string;
  days: number;
  items: number;
  is_Published: string;
  image: string;
  userTripRole: "OWNER" | "COLLABORATOR" | "VIEWER";
  startDate: string;
  endDate: string;
}

const getRoleIcon = (userTripRole: TripItem["userTripRole"]) => {
  const iconProps = {
    size: 16,
    color: userTripRole === "VIEWER" ? "#EAAE33" : "#2388FF",
  };

  const iconStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Box sx={iconStyles}>
      {userTripRole === "OWNER" && <User {...iconProps} />}
      {userTripRole === "COLLABORATOR" && <Users {...iconProps} />}
      {userTripRole === "VIEWER" && <Eye {...iconProps} />}
    </Box>
  );
};

const MyTripsPopover = ({setmyTripsVisible}) => {

  const navigate = useNavigate();

  const { myTrips } = useSelector((state: RootState) => state.screenReducer);
  

  const handleTripsToggle = () => {
    setmyTripsVisible(prev => !prev)
  };

  const handlePopularTripsCardClick = (card: any) => {
    const html = document.querySelector('html')
      html.style.overflow = 'auto'
    
    const image = getStoredImages(card.reference);
    navigate(`/itinerary-detail/${card.id}`, { state: { card, image } });
  };

  const getDaysBetweenDates = (startDate: string, endDate: string): number => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const difference = end.getTime() - start.getTime();
    return difference / (1000 * 60 * 60 * 24) +1;
};

  return (
    <Box
      sx={{
        height: "100vh",
        overflow: "auto",
        p: 2,
        pb: 16,
        bgcolor: colors.primaryWhite,
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      
      <Stack spacing={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant={typographyOptions.H1}
            sx={{ width: "95%", textAlign: "center", fontSize: "20px" }}
          >
            {myTrips?.trips && `${myTrips.trips.length} Trip${myTrips.trips.length > 1 ? 's' : ''}`}

          </Typography>
          <Box
            onClick={handleTripsToggle}
            component="button"
            sx={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
              "&:hover": { opacity: 0.7 },
            }}
          >
            ✕
          </Box>
        </Box>
        {myTrips?.trips ? myTrips?.trips?.length>0 ? <>
        <Divider />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            p: 2,
            cursor: "pointer",
            "&:hover": {
              bgcolor: colors.secondaryWhite,
              transitionDuration: "200ms",
            },
            borderRadius: 1,
          }}
        >
          <Plus size={24}  />
          <Typography variant={typographyOptions.H1}
            sx={{fontSize: "20px" }} >
            {en.createATrip}
          </Typography>
        </Box>

        <Divider />

        <Stack spacing={2}>
          {myTrips?.trips && myTrips?.trips.map((trip) => {
            return(
            <Box
            onClick={()=>handlePopularTripsCardClick(trip)}
              key={trip.id}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                cursor: "pointer",
                "&:hover": {
                  bgcolor: colors.secondaryWhite,
                  transitionDuration: "200ms",
                },
                borderRadius: 1,
                p: 1,
              }}
            >
              <img
                src={getStoredImages(trip?.reference)?.config?.url}
                alt={trip.name}
                style={{
                  width: 60,
                  height: 60,
                  borderRadius: 8,
                  objectFit: "cover",
                }}
              />
              <Box sx={{ flex: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mb: 0.5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      backgroundColor:
                        trip.userTripRole === "VIEWER" ? "#FDF7EB" : "#DFF2FF",
                      color: trip.userTripRole === "VIEWER" ? "#EAAE33" : "#2388FF",
                      padding: "5px 10px",
                      borderRadius: "10px",
                    }}
                  >
                    {getRoleIcon(trip?.userTripRole)}
                    <Typography
                      sx={{
                        fontSize: "12px",
                        textTransform: "capitalize",
                      }}
                    >
                      {trip.userTripRole.charAt(0).toUpperCase() + trip.userTripRole.slice(1).toLowerCase()}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant={typographyOptions.H1}
                  sx={{ mb: 0.5, fontWeight: "700" }}
                >
                  {trip?.name}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <Typography
                      variant={typographyOptions.SUB_HEADING}
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      <Calendar size={14} />
                      {(trip.startDate !== null  && trip.endDate !== null)? getDaysBetweenDates(trip.startDate, trip.endDate):trip.days} Days
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <Typography
                      variant={typographyOptions.SUB_HEADING}
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      <MapPin size={14} />
                      {trip.items} Places
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <Typography
                      variant={typographyOptions.SUB_HEADING}
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      {trip.is_Published === true ? (
                        <CheckCheck size={14} />
                      ) : (
                        <X size={14} />
                      )}
                      {trip.is_Published ? "Published" : "Not Published"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )})}
        </Stack>
        </>:
        <>
        <Divider />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            p: 2,
            cursor: "pointer",
            "&:hover": {
              bgcolor: colors.secondaryWhite,
              transitionDuration: "200ms",
            },
            borderRadius: 1,
          }}
        >
          <Plus size={24}  />
          <Typography variant={typographyOptions.H1}
            sx={{fontSize: "20px" }} >
            {en.createATrip}
          </Typography>
        </Box>

        <Divider />

        {/* <Stack spacing={2}>
          {myTrips?.trips && myTrips?.trips.map((trip) => {
            return(
            <Box
            onClick={()=>handlePopularTripsCardClick(trip)}
              key={trip.id}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                cursor: "pointer",
                "&:hover": {
                  bgcolor: colors.secondaryWhite,
                  transitionDuration: "200ms",
                },
                borderRadius: 1,
                p: 1,
              }}
            >
              <img
                src={getStoredImages(trip?.reference)?.config?.url}
                alt={trip.name}
                style={{
                  width: 60,
                  height: 60,
                  borderRadius: 8,
                  objectFit: "cover",
                }}
              />
              <Box sx={{ flex: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mb: 0.5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      backgroundColor:
                        trip.userTripRole === "VIEWER" ? "#FDF7EB" : "#DFF2FF",
                      color: trip.userTripRole === "VIEWER" ? "#EAAE33" : "#2388FF",
                      padding: "5px 10px",
                      borderRadius: "10px",
                    }}
                  >
                    {getRoleIcon(trip?.userTripRole)}
                    <Typography
                      sx={{
                        fontSize: "12px",
                        textTransform: "capitalize",
                      }}
                    >
                      {trip.userTripRole.charAt(0).toUpperCase() + trip.userTripRole.slice(1).toLowerCase()}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant={typographyOptions.H1}
                  sx={{ mb: 0.5, fontWeight: "700" }}
                >
                  {trip?.name}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <Typography
                      variant={typographyOptions.SUB_HEADING}
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      <Calendar size={14} />
                      {trip.days} Days
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <Typography
                      variant={typographyOptions.SUB_HEADING}
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      <MapPin size={14} />
                      {trip.items} Places
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <Typography
                      variant={typographyOptions.SUB_HEADING}
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      {trip.is_Published === true ? (
                        <CheckCheck size={14} />
                      ) : (
                        <X size={14} />
                      )}
                      {trip.is_Published ? "Published" : "Not Published"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )})}
        </Stack> */}
        <Typography variant={typographyOptions.H1} fontSize={20} textAlign={'center'}>No trips available</Typography>
        </> : <Container
  sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "75vh",
    width: "100%",
  }}
>
  <div className="loader h-10 w-10"></div> 
</Container>
 }
      </Stack>

    </Box>
  );
};

export default MyTripsPopover;
 