import axios from "axios";
import { BASE_URL, getApiUrls } from "../../../config/apiurls";
import { constructUrl } from "../../../utils/helpers";
import axiosInstance from "../../../config/customAxios";

const getPublishedTrip = () => {
  try {
    const url: string = BASE_URL + getApiUrls().publishedTrips;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getImages = (id: string) => {
  try {
    const url: string = BASE_URL + getApiUrls(id).getImages;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getTrendingDestinationsList = () => {
  try {
    const url: string = BASE_URL + getApiUrls().getTrendingDestinationsList;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getFeaturedUsersList = () => {
  try {
    const url: string = BASE_URL + getApiUrls().getFeaturedUsers;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getItineraryDetails = (payload: any) => {
  try {
    const url: string =
      BASE_URL + getApiUrls(payload?.tripId).getItineraryDetails;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getFeaturedUserDetails = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls(payload).getFeaturedUsersDetails;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getFeaturedPublishedTrip = (payload: any) => {
  try {
    const url: string =
      BASE_URL + getApiUrls(payload).getFeaturedUserPublishedTrips;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getSearchedItinerary = (payload: any) => {
  try {
    const url: string =
      BASE_URL + getApiUrls(payload?.queryParams).getSearchedItinerary;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getSearchedDestinations = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().getSearchedDestinations;
    return axios.post(url, payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getPopularTrips = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().getPopularTrips;
    const apiUrl: string = constructUrl(url, payload);
    return axios.get(apiUrl).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getWeekendGateways = () => {
  try {
    const url: string = BASE_URL + getApiUrls().getWeekendGateways;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getLongTrips = () => {
  try {
    const url: string = BASE_URL + getApiUrls().getLongTrips;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getSortedItinerary = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().getSortedItinerary;
    const apiUrl: string = constructUrl(url, payload);
    return axios.get(apiUrl).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getPlaceDetails = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().getPlaceDetails;
    return axios.post(url, payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getCoordinates = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().getCoordinates;
    return axios.post(url, payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const searchUsers = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls(payload?.queryParams).searchUsers;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

//get user details
const getUserDetails = () => {
  const token = localStorage.getItem("Login_Token") || "";
  const tokenWithoutQuotes = token.replace(/"/g, "");
  try {
    const url: string = BASE_URL + getApiUrls().getUserDetails;
    return axiosInstance
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenWithoutQuotes}`,
        },
      })
      .catch((error) => error);
  } catch (error) {
    return error;
  }
};


const updateUserDetails = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().updateUserDetails;
    return axiosInstance.put(url, payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getMyTripsList = () => {
  const token = localStorage.getItem("Login_Token") || "";
  const tokenWithoutQuotes = token.replace(/"/g, "");
  try {
    const url: string = BASE_URL + getApiUrls().getMyTrips;
    return axiosInstance
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenWithoutQuotes}`,
        },
      })
      .catch((error) => error);
  } catch (error) {
    return error;
  }
};

const searchCityAndHotel = (payload: any) => {  
  try {
    const url: string = BASE_URL + getApiUrls(payload).searchHotelAndCity
    // const url: string  = ("http://13.232.168.222:8080/dev/" + getApiUrls(payload).searchHotelAndCity);
    return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};
const getCitySearchList = (payload: any) => {
  try {
    const url = new URL(BASE_URL + getApiUrls().getCitySearch);
    return axios.post(url.toString(), payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};


// const getHotelList = (payload: any) => {
//   try {
//     const url: string = BASE_URL + getApiUrls().getHotels;
//     return axios.get(url).catch((error) => error);
//   } catch (error) {
//     return error;
//   }
// }

// const getHotelDetails = (payload: any) => {
//   try {
//     const url: string =
//       BASE_URL + getApiUrls(payload?.hotelId).getHotelDetails;
//     return axios.get(url).catch((error) => error);
//   } catch (error) {
//     return error;
//   }
// };

const getHotelDetails = (payload: any) => {
  const token = localStorage.getItem("Login_Token") || "";
  const tokenWithoutQuotes = token.replace(/"/g, "");
  try {
    const url: string =
      BASE_URL + getApiUrls(payload).getHotelDetails;
      // const url: string  = ("http://13.232.168.222:8080/dev/" + getApiUrls(payload).getHotelDetails);
      return axiosInstance
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenWithoutQuotes}`,
        },
      })
      .catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getCityAndCountryFromCoordinates = (payload: { latitude: number; longitude: number }) => {
  try {
    const url = getApiUrls().getCurrentLocation.replace("latitude", payload.latitude.toString()).replace("longitude", payload.longitude.toString());    
    return axios.get(url.toString()).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getHotelByIds = (payload: any) => {
  try {
    const url = new URL(BASE_URL + getApiUrls().getHotelByIds);
    // const url: string  = ("http://13.232.168.222:8080/dev/" + getApiUrls().getHotelByIds);
    return axios.post(url.toString(), payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getThingsToDoProductSearch = (payload: any) => {
  try {
    const url = new URL(BASE_URL + getApiUrls().getThingsToDoProductSearch);
    // const url: string  = ("http://13.232.168.222:8080/dev/" + getApiUrls().getThingsToDoProductSearch);
    return axios.post(url.toString(), payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};

// const getThingsToDoLocation = (payload: any) => {
//   const token = localStorage.getItem("Login_Token") || "";
//   const tokenWithoutQuotes = token.replace(/"/g, "");
//   try {
//     const url: string =
//       BASE_URL + getApiUrls(payload).getThingsToDoLocation;
//       // const url: string  = ("http://13.232.168.222:8080/dev/" + getApiUrls(payload).getHotelDetails);
//       return axiosInstance
//       .get(url, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${tokenWithoutQuotes}`,
//         },
//       })
//       .catch((error) => error);
//   } catch (error) {
//     return error;
//   }
// };

const getThingsToDoActivitySearch = (payload: any) => {
  try {
    const url: string =
      BASE_URL + getApiUrls(payload).getThingsToDoActivitySearch;

      return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getThingsToDoDestinationList = (payload: any) => {
  try {
    const url: string =
      BASE_URL + getApiUrls(payload).getThingsToDoDestinationList;

      return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getThingsToDoFreeTextSearch = (payload: any) => {
  try {
    const url = new URL(BASE_URL + getApiUrls().getThingsToDoFreeTextSearch);
    return axios.post(url.toString(), payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getThingsToDoDestination = (payload: any) => {
  try {
    const url = new URL(BASE_URL + getApiUrls(payload).getThingsToDoDestination);
    return axios.get(url.toString()).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getThingsToDoProductDetails = (payload: any) => {
  try {
    const url = new URL(BASE_URL + getApiUrls(payload).getThingsToDoProductDetails);
    // const url: string  = ("http://13.232.168.222:8080/dev/" + getApiUrls(payload).getThingsToDoProductDetails);
    return axios.get(url.toString()).catch((error) => error);
  } catch (error) {
    return error;
  }
};
const sendFeedback = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().feedback;
    return axiosInstance
    .post(url,payload)
    .catch((error) => error);
  } catch (error) {
    return error;
  }
};


export default {
  getPublishedTrip,
  getImages,
  getTrendingDestinationsList,
  getFeaturedUsersList,
  getMyTripsList,
  getItineraryDetails,
  getFeaturedUserDetails,
  getFeaturedPublishedTrip,
  getSearchedItinerary,
  getSearchedDestinations,
  getPopularTrips,
  getWeekendGateways,
  getLongTrips,
  getSortedItinerary,
  getPlaceDetails,
  getCoordinates,
  searchUsers,
  getUserDetails,
  updateUserDetails,
  searchCityAndHotel,
  getCitySearchList,
  getHotelDetails,
  getCityAndCountryFromCoordinates,
  getHotelByIds,

  getThingsToDoProductSearch,
  // getThingsToDoLocation,
  getThingsToDoActivitySearch,
  getThingsToDoDestinationList,
  getThingsToDoFreeTextSearch,
  getThingsToDoDestination,
  getThingsToDoProductDetails,
  sendFeedback
};
