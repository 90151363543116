import React, { useState } from "react";
import StarRatingFilter from "./StarRatingFilter";
import { Divider } from "@mui/material";
import SortBy from "./SortBy";
import PriceRangeSlider from "./PriceRangeSlider";
import { ChevronDown, ChevronUp } from "lucide-react";

const MobileFilterOptions = ({ openFilters, toggleFilter, handleClearAll, filters, setFilters }) => {
  return (
    <div className="md:hidden w-full flex flex-col text-[12px] gap-2 text-[#999999]">
      <div className="flex gap-2 overflow-x-auto">
        {[
          { label: "Price (per night)", key: "price" },
          { label: "Sort By", key: "sortBy" },
          { label: "Star Rating", key: "rating" }
        ].map(({ label, key }) => (
          <button
            key={key}
            className="flex justify-between items-center px-2 h-8 border-[#d8e0e9] border-2 rounded-[10px] bg-white"
            onClick={() => toggleFilter(key)}
          >
            <span className="font-Mulish font-semibold text-nowrap">{label}</span>
            {openFilters[key] ? <ChevronUp size={12} /> : <ChevronDown size={12} />}
          </button>
        ))}
        <button onClick={handleClearAll} className="text-sm font-Mulish font-bold underline text-nowrap text-center">
          Clear All
        </button>
      </div>

      {openFilters.price && <div className="mt-2"><PriceRangeSlider filters={filters} setFilters={setFilters} /></div>}
      {openFilters.sortBy && <div className="mt-2"><SortBy filters={filters} setFilters={setFilters} /></div>}
      {openFilters.rating && <div className="mt-2"><StarRatingFilter filters={filters} setFilters={setFilters} /></div>}
    </div>
  );
};

const FilterPanel = ({ filters, setFilters, handleClearAll }) => {
  const [openFilters, setOpenFilters] = useState({
    price: false,
    sortBy: false,
    rating: false,
  });

  const toggleFilter = (filter) => {
    setOpenFilters((prev) => ({
      price: false,
      sortBy: false,
      rating: false,
      [filter]: !prev[filter],
    }));
  };

  return (
    <div className="flex flex-col w-full md:pr-10 md:items-start items-center justify-center">
      {/* Mobile View - Collapsible Filters */}
      <MobileFilterOptions 
        openFilters={openFilters} 
        toggleFilter={toggleFilter} 
        handleClearAll={handleClearAll} 
        filters={filters} 
        setFilters={setFilters} 
      />

      {/* Desktop View - Always Visible Filters */}
      <div className="hidden md:flex flex-col w-full">
        <div className="flex w-full flex-row justify-between items-center mb-4">
          <h2 className="text-lg font-Mulish font-bold">Price (per night)</h2>
          <button onClick={handleClearAll} className="text-sm font-Mulish font-bold underline">
            Clear All
          </button>
        </div>
        <PriceRangeSlider filters={filters} setFilters={setFilters} />
        <Divider sx={{ my: 4 }} />
        <SortBy filters={filters} setFilters={setFilters} />
        <Divider sx={{ my: 4 }} />
        <StarRatingFilter filters={filters} setFilters={setFilters} />
      </div>
    </div>
  );
};

export default FilterPanel;
