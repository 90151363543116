import { screenActions } from "../actionTypes";

//get published trips
export const getPublishedTrip = () => {
  return {
    type: screenActions.GET_PUBLISHED_TRIP_REQUESTED,
  };
};

//get images
export const getImages = () => {
  return {
    type: screenActions.GET_IMAGES_REQUESTED,
  };
};

//get trending destinations list
export const getTrendingDestinations = () => {
  return {
    type: screenActions.GET_TRENDING_DESTINATIONS_REQUESTED,
  };
};

// get featured details
export const getFeaturedUsers = () => ({
  type: screenActions.GET_FEATURED_USERS_REQUESTED,
});

//get itinerary details
export const getItineraryDetails = (payload: any) => ({
  type: screenActions.GET_ITINERARY_DETAILS_REQUESTED,
  payload,
});

//get featured users details
export const getFeaturedUserDetails = (payload: any) => ({
  type: screenActions.GET_FEATURED_USER_DETAILS_REQUESTED,
  payload,
});

// get featured users published trips
export const getFeaturedUserPublishedTrips = (payload: any) => ({
  type: screenActions.GET_FEATURED_USER_PUBLISHED_TRIPS_REQUESTED,
  payload,
});

// get search itinerary
export const getSearchItinerary = (payload: any) => ({
  type: screenActions.GET_SEARCHED_ITINERARY_REQUESTED,
  payload,
});

// get search destinations
export const getSearchDestinations = (payload: any) => ({
  type: screenActions.GET_SEARCHED_DESTINATIONS_REQUESTED,
  payload,
});

// get search user
export const getSearchUser = (payload: any) => ({
  type: screenActions.GET_SEARCHED_USERS_REQUESTED,
  payload,
});

// get popular trips
export const getPopularTrips = (payload: any) => ({
  type: screenActions.GET_POPULAR_TRIPS_REQUESTED,
  payload,
});

// get weekend gateways
export const getWeekendGateways = () => ({
  type: screenActions.GET_WEEKENDS_GATEWAY_REQUESTED,
});

// get long trips
export const getLongTrips = () => ({
  type: screenActions.GET_LONG_TRIPS_REQUESTED,
});

// get sorted itinerary
export const getSortedItinerary = (payload: any) => ({
  type: screenActions.GET_SORTED_ITINERARY_REQUESTED,
  payload,
});

// get place details
export const getPlaceDetails = (payload: any) => ({
  type: screenActions.GET_PLACE_DETAILS_REQUESTED,
  payload,
});

// get coordinates
export const getCoordinates = (payload: any) => ({
  type: screenActions.GET_COORDINATES_REQUESTED,
  payload,
});

// search users
export const searchUsers = (payload: any) => ({
  type: screenActions.SEARCH_USERS_REQUESTED,
  payload,
});

// search users
export const getUserDetails = () => ({
  type: screenActions.GET_USER_DETAILS_REQUESTED,
});

// update user details
export const updateUserDetails = (payload: any) => ({
  type: screenActions.UPDATE_USER_DETAILS_REQUESTED,
  payload,
});

//get things to do details
export const getThingsToDoProductSearch = (payload: any) => {
  return {
    type: screenActions.GET_THINGS_TO_DO_PRODUCT_SEARCH_REQUESTED,
    payload,
  };
};

//clear things to do details
export const clearThingsToDoProductSearch = () => {
 return {
    type: screenActions.CLEAR_THINGS_TO_DO_PRODUCT_SEARCH_REQUESTED,
  };
}

//get things to do activity search
export const getThingsToDoActivitySearch = (payload: any) => {
  return {
    type: screenActions.GET_THINGS_TO_DO_ACTIVITY_SEARCH_REQUESTED,
    payload,
  };
};

//clear things to do activity search
export const clearThingsToDoActivitySearch = () => {
  return {
    type: screenActions.CLEAR_THINGS_TO_DO_ACTIVITY_SEARCH_REQUESTED,
  };
}

//get things to do free text search
export const getThingsToDoFreeTextSearch = (payload: any) => {
  return {
    type: screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_REQUESTED,
    payload,
  };
};

//get things to do free text search
export const getThingsToDoFreeTextSearchForHome = (payload: any) => {
  return {
    type: screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FOR_HOME_REQUESTED,
    payload,
  };
};

//clear things to do activity search
export const clearThingsToDoFreeTextSearchForHome = () => {
  return {
    type: screenActions.CLEAR_THINGS_TO_DO_FREE_TEXT_FOR_HOME_SEARCH_REQUESTED,
  };
}

//get things to do destination list
export const getThingsToDoDestinationList = (payload: any) => {
  return {
    type: screenActions.GET_THINGS_TO_DO_DESTINATION_LIST_REQUESTED,
    payload,
  };
}

//get things to do product details
export const getThingsToDoProductDetails = (payload: any) => {
  return {
    type: screenActions.GET_THINGS_TO_DO_PRODUCT_DETAILS_REQUESTED,
    payload,
  };
};



//get things to do product details
export const getthingsToDoSimilarExperienceList = (payload: any) => {
  return {
    type: screenActions.GET_THINGS_TO_DO_SIMILAR_EXPERIENCE_REQUESTED,
    payload,
  };
};
//get things to do product details
export const getthingsToDoYouMayAlsoLikeList = (payload: any) => {
  return {
    type: screenActions.GET_THINGS_TO_DO_YOU_MAY_ALSO_LIKE_REQUESTED,
    payload,
  };
};
//get things to do product details
export const getthingsToDoNearbyActivitiesList = (payload: any) => {
  return {
    type: screenActions.GET_THINGS_TO_DO_NEARBY_ACTIVITIES_REQUESTED,
    payload,
  };
};
//get things to do product details
export const getthingsToDoNearbyThingsToDoList = (payload: any) => {
  return {
    type: screenActions.GET_THINGS_TO_DO_NEARBY_THINGS_TO_DO_REQUESTED,
    payload,
  };
};

//get things to do destination from ref
export const getThingsLocationFromDestinationRef = (payload: any) => {
  return {
    type: screenActions.GET_THINGS_LOCATION_FROM_DESTINATION_REF_REQUESTED,
    payload,
  };
}
export const getMyTrips = () => {
  return {
    type: screenActions.GET_MY_TRIPS_REQUESTED,
  };
}

export const getCitySearch = (payload: any) => {  
  return {
    type: screenActions.GET_CITY_SEARCH_REQUESTED,
    payload
  };
}

export const getCitySearchSimilarExperience = (payload: any) => {  
  return {
    type: screenActions.GET_CITY_SEARCH_SIMILAR_EXPERIENCE_REQUESTED,
    payload
  };
}

export const getCitySearchNearbyPlaces = (payload: any) => {  
  return {
    type: screenActions.GET_CITY_SEARCH_NEARBY_PLACES_REQUESTED,
    payload
  };
}

export const getHotelDetails = (payload: any) => {
  return {
    type: screenActions.GET_HOTEL_DETAILS_REQUESTED,
    payload
  };
}

export const getCurrentLocation = (payload: any) => {
  return {
    type: screenActions.GET_CITY_COUNTRY_FROM_COORDINATES_REQUESTED,
    payload
  };
}

export const getSearchCityAndHotelList = (payload: any) => {
  return {
    type: screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_REQUESTED,
    payload
  };
}

export const getSearchCityAndHotelListforSearch = (payload: any) => {
  return {
    type: screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_FOR_SEARCH_REQUESTED,
    payload
  };
}

export const getHotelByIds = (payload: any) => {
  return {
    type: screenActions.GET_HOTEL_BY_IDS_REQUESTED,
    payload
  };
}

export const sendFeedback = (payload: any) => {
  return {
    type: screenActions.SEND_FEEDBACK_REQUESTED,
    payload
  };
}
