import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./components/atom/TwinInputSet.css";
import reportWebVitals from "./reportWebVitals";
import { initGA, logPageView } from "./utils/analytics";
import { useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { clientID } from "./config/apiurls";

// Add AWIN Tracking Code Dynamically
const awinScript = document.createElement("script");
awinScript.src = "https://tracking.awin.com/your-tracking-code.js";
awinScript.async = true;
document.head.appendChild(awinScript);

// anlytics mesurement id
const measurementId = "G-BHE716ELVS";

// initialize id
initGA(measurementId);

const Root = () => {
  useEffect(() => {
    logPageView();
  }, []);

  return (
    <GoogleOAuthProvider clientId={clientID}>
      <App />
    </GoogleOAuthProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
