import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Divider,
} from '@mui/material';

import Header from '../../components/template/Header';
import { colors } from '../../themes/colors';

import en from "../../locale/en.json";

import { SettingComponentIcons, SettingKeys, typographyOptions } from '../../constants';
import Profile from '../Settings/sections/Profile';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../../utils/helpers';
import { screenRoutes } from '../../constants/routeConstants';
import Feedback from './sections/Feedback';
import Notifications from './sections/Notifications';
import DeleteAccount from './sections/DeleteAccount';
import { useMediaQuery } from "@mui/material";

interface ListItemType {
  text: string;
  icon: keyof typeof SettingComponentIcons;
  component: SettingKeys;
  divider?: boolean;
}

const ListItems: ListItemType[] = [
  { text: en.profile, icon: "Profile", component: SettingKeys.PROFILE },
  { text: en.shareFeedback, icon: "Feedback", component: SettingKeys.SHARE_FEEDBACK },
  { text: en.notification, icon: "Notifications", component: SettingKeys.NOTIFICATION, divider: true },
];

interface CustomListButtonProps {
  selected: boolean;
  icon: string;
  text: string;
  onClick: () => void;
  divider?: boolean;
}

const CustomListButton: React.FC<CustomListButtonProps> = ({ selected, icon, text, onClick, divider = false }) => {
  return (
    <>
      {divider && <Divider />}
      <ListItem button selected={selected} onClick={onClick} sx={{
        py: 2,
        "&.Mui-selected": {
          borderRight: "4px solid " + colors.black,
          bgcolor: colors.darkBackground,
          "&.Mui-selected:hover": {
            bgcolor: colors.background,
          },
        },
      }}>
        <ListItemIcon sx={{ marginRight: -3, marginLeft: 1 }}>
          <img
            src={icon}
            alt={`${text} icon`}
            style={{
              width: "20px",
              height: "20px",
            }}
          />
        </ListItemIcon>
        <Typography sx={{ fontWeight: selected ? 700 : 'normal' }} variant={typographyOptions.HEADING}>
          {text}
        </Typography>
      </ListItem>
    </>
  );
};

const Settings: React.FC = () => {
  const [component, setComponent] = useState<SettingKeys>(SettingKeys.PROFILE);
  const navigate = useNavigate();
  const loggedIn = isLoggedIn();
  const isMobile = useMediaQuery("(max-width:700px)");
  const [isNavVisible, setisNavVisible] = useState(true);
  const [isComponentVisible, setIsComponentVisible] = useState(false);

  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState(false);

  const handleOpenDeleteAccountModal = () => setOpenDeleteAccountModal(true);
  const handleCloseDeleteAccountModal = () => setOpenDeleteAccountModal(false);

  const closeMobileComp = () =>{
    setisNavVisible(true)
    setIsComponentVisible(false)
  }

  const openMobileComp = () =>{
    alert('fs')
    setisNavVisible(false)
    setIsComponentVisible(true)
  }

  const handleItemClick = (comp: SettingKeys) => {
    setComponent(comp);
    if(isMobile){
      setisNavVisible(false)
      setIsComponentVisible(true)
    }
  };

  useEffect(() => {
    if(!isMobile){
      setisNavVisible(true)
      setIsComponentVisible(true)
    }else{
      isNavVisible && setIsComponentVisible(false)
    }
  }, [isMobile, isComponentVisible, isNavVisible])
  

  // useEffect(() => {
  //   if(component === SettingKeys.DELETE_ACCOUNT){
  //     handleOpenDeleteAccountModal();
  //   }
  // }, [component])
  

  useEffect(() => {
    if (!loggedIn) {
      navigate(screenRoutes.HOME);
    }
  }, [loggedIn, navigate]);

  if (!loggedIn) return null;

  return (
    <>
      <Header />
      <Container maxWidth={false} sx={{ pt: 18, pb: 2, bgcolor: colors.background, width: '100vw', minHeight: '100vh', display: 'flex', alignItems: 'center' }}>
        <Box sx={{
          py: 3, bgcolor: colors.primaryWhite, borderRadius: 8, flex: 1, width: '90%', height: '100%', boxShadow:
            "11px 10px 32px 0px #0000000A, -19px -7px 32px 0px #3333330A,1px 1px 2px 0px #00000021 inset",
        }} display="flex" gap={3}>
          {isNavVisible && <Box sx={{
            position: 'sticky',
            top: 110,
            overflowY: 'auto',
            display: 'flex', flexDirection: "column", justifyContent: 'space-between', height: 'calc(100vh - 200px)', minWidth: `${isMobile ? '100%' : '250px'}`, borderRight: 2, borderColor: colors.background
          }}>
            <List>
              {ListItems.map((item) => (
                <CustomListButton key={item.text} selected={item.component === component} text={item.text} icon={SettingComponentIcons[item.icon]} onClick={() => handleItemClick(item.component)} divider={item.divider} />
              ))}
            </List>

            <ListItem button sx={{ color: 'error.main' }} onClick={handleOpenDeleteAccountModal}>
              <ListItemIcon sx={{ marginRight: -3, marginLeft: 1 }}>
                <img
                  src={SettingComponentIcons['Delete']}
                  alt={`Delete icon`}
                  style={{ width: "20px", height: "20px" }}
                />
              </ListItemIcon>
              <Typography sx={{ color: colors.secondaryRed }} variant={typographyOptions.HEADING}>
                {en.deleteAccount}
              </Typography>
            </ListItem>
          </Box>}
          {openDeleteAccountModal && <DeleteAccount open={openDeleteAccountModal} handleClose={handleCloseDeleteAccountModal}/>}
          {isComponentVisible && <Box flex={1} sx={{justifyItems: `${isMobile && 'center'}`}}>
            {component === SettingKeys.PROFILE ? (
              <Profile handleBack={closeMobileComp} isMobile={isMobile} />
            ) : component === SettingKeys.SHARE_FEEDBACK ? (
              <Feedback handleBack={closeMobileComp} isMobile={isMobile} />
            ) : component === SettingKeys.NOTIFICATION ? (
              <Notifications handleBack={closeMobileComp} isMobile={isMobile} />
            ) : null }
          </Box>}
        </Box>
      </Container>
    </>
  );
};

export default Settings;
