import React, { useEffect, useState } from 'react'
import Header from '../../components/template/Header'
import { Explore } from '@mui/icons-material'
import { AndriodDownload, AppleDownload } from '../../components/atom/DownloadAppIcons'
import { Bolt, Collaborate, Diamond, MyBaliTrip, Sam, SmallQR, TopInfluencer, TravelGraphic } from "../../assests/exportImages";
import { QRModal } from '../../components/template/QRModal';
import HowItHelps from './HowItHelps';
import WhatElseYouGet from './WhatElseYouGet';
import VacationList from '../components/VacationList';
import MoreReasons from './MoreReasons';
import Footer from '../../components/template/Footer';
import { logPageView } from '../../utils/analytics';
import en from "../../locale/en.json";
import { useDispatch } from 'react-redux';
import { screenActionsCall } from '../../redux/actions';
import { useLocation } from 'react-router-dom';
import TellUs from './TellUs';
import TagUs from './TagUs';
import BecomePartner from './BecomePartner';
import Partners from './Partners';
import { PlanYourNextTrip } from '../../components/template/PlanYourNextTrip';
import { ReactTyped, Typed } from 'react-typed';

const Home = () => {
  const location = useLocation();

  useEffect(() => {

    // google analytics on page mount
    let obj = {
      name: en.anayticsPageName.HOME,
      path: location.pathname,
    };
    logPageView(obj);
  }, []);
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsTop(false);
      } else {
        setIsTop(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="relative w-screen h-screen flex flex-col items-center justify-center overflow-hidden">
        <img src={MyBaliTrip} alt='MyBaliTrip' className='absolute h-16 md:left-[80%] md:top-[20%] top-[82%] left-[-6%] animate-floating' />
        <img src={Diamond} alt='Diamond' className='absolute h-20 right-[70%] md:top-[80%] top-[22%] animate-floating-left' />
        <img src={Bolt} alt='Bolt' className='absolute h-16 left-[80%] top-[90%] animate-floating-right' />
        <img src={TopInfluencer} alt='TopInfluencer' className='md:absolute h-16 md:right-[70%] md:top-[15%] hidden animate-floating-up' />
        <img src={Sam} alt='Sam' className='absolute h-24 md:right-[82%] md:top-[50%] top-[15%] right-[1%] animate-floating-down' />

        <img
          className="w-screen h-screen md:h-screen object-cover absolute top-0 left-0 z-[-1] rounded-b-[30px]"
          src={Collaborate}
          alt="Background"
        />

        <Header boxShadow="none" bgTransparent={isTop} />
        <p className="md:leading-[110px] md:text-[118px] text-[52px] leading-[55px]  max-w-[581px] lg:max-w-none mb-5 font-Mulish text-center font-light md:h-60 h-24">
          It’s time to{" "}<br></br>
          <ReactTyped
            className='font-bold'
            strings={["travel.",
              "escape.",
              "take a break.",
              "plan.",
              "dream.",
              "inspire."]}
            typeSpeed={30}
            backSpeed={70}
            loop
            showCursor={false}
          />
        </p>
        <p className=" text-base md:text-2xl lg:text-base font-normal max-w-[600px] mb-10 font-Mulish text-[#333333] text-center md:text-left p-5 md:p-0">
          From itinerary to bookings, Escapekar simplifies travel planning like never before.
        </p>
        <div className="flex flex-row items-center gap-4">



          <AndriodDownload />
          <AppleDownload />
          <div className="hidden lg:flex">
            <img
              src={SmallQR}
              alt="QR"
              onClick={handleOpen}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>

        <QRModal open={open} handleClose={handleClose} />


      </div>
      <div className='bg-[#fafafa] flex flex-col items-center justify-center'>
        <HowItHelps />
        <WhatElseYouGet />
        <VacationList borderRad={0} />
        <MoreReasons />
        <TellUs />
        <TagUs />
        <BecomePartner />
        <Partners />
      </div>
      <PlanYourNextTrip />
      <Footer />
    </>


  )
}

export default Home