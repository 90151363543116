import { IActionInterface } from "../../interfaces";
import { authAction } from "../actions/actionTypes";

const initialState = {
  user: null,
  isAuthenticated: false,
  isEmailExists: false,
  userDataSignUp: {},
  failureMessage: "",
  isPassChanged: false,
  loading: false,
  isUserSignedUp: false,
  userNameExists: {},
};

const authReducer = (state = initialState, action: IActionInterface) => {
  const { type, payload } = action;
  switch (type) {
    case authAction.USER_SIGN_IN_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case authAction.USER_SIGN_IN_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case authAction.USER_SIGN_IN_FAILED:
      return {
        ...state,
        loading: false,
      };

    case authAction.USER_SIGN_UP_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case authAction.USER_SIGN_UP_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case authAction.USER_SIGN_UP_FAILED:
      return {
        ...state,
        loading: false,
      };

    case authAction.USER_SIGN_UP_GOOGLE_REQUESTED:
      return {
        ...state,
        isUserSignedUp: false,
      };

    case authAction.USER_SIGN_UP_GOOGLE_SUCCESS:
      return {
        ...state,
        isUserSignedUp: true,
      };

    case authAction.USER_SIGN_UP_GOOGLE_FAILED:
      return {
        ...state,
        isUserSignedUp: false,
      };
    case authAction.CHECK_EMAIL_EXISTS_REQUESTED:
      return {
        ...state,
        isEmailExists: false,
        loading: false,
      };

    case authAction.CHECK_EMAIL_EXISTS_SUCCESS:
      return {
        ...state,
        loading: true,
        isEmailExists: action.payload?.isEmailExists,
      };

    case authAction.CHECK_EMAIL_EXISTS_FAILED:
      return {
        ...state,
        loading: false,
        isEmailExists: action.payload?.isEmailExists,
        failureMessage: action.payload?.failureMessage,
      };
    case authAction.FETCH_USER_DATA_REQUESTED:
      return {
        ...state,
      };

    case authAction.FETCH_USER_DATA_SUCCESS:
      return {
        ...state,
      };

    case authAction.FETCH_USER_DATA_FAILED:
      return {
        ...state,
      };

    case authAction.VERIFY_ACCOUNT_EMAIL_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case authAction.VERIFY_ACCOUNT_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case authAction.VERIFY_ACCOUNT_EMAIL_FAILED:
      return {
        ...state,
        loading: false,
      };

    case authAction.CHECK_USERNAME_EXISTS_REQUESTED:
      return {
        ...state,
        userNameExists: {},
      };

    case authAction.CHECK_USERNAME_EXISTS_SUCCESS:
      return {
        ...state,
        userNameExists: payload,
      };

    case authAction.CHECK_USERNAME_EXISTS_FAILED:
      return {
        ...state,
        userNameExists: {},
      };

    case authAction.RESEND_OTP_REQUESTED_REQUESTED:
      return {
        ...state,
      };

    case authAction.RESEND_OTP_REQUESTED_SUCCESS:
      return {
        ...state,
      };

    case authAction.RESEND_OTP_REQUESTED_FAILED:
      return {
        ...state,
      };

    case authAction.INITIATE_RESET_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case authAction.INITIATE_RESET_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case authAction.INITIATE_RESET_FAILED:
      return {
        ...state,
        loading: false,
      };

    case authAction.CONFIRM_RESET_PASS_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case authAction.CONFIRM_RESET_PASS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case authAction.CONFIRM_RESET_PASS_FAILED:
      return {
        ...state,
        loading: false,
      };

    case authAction.VERIFY_RESET_PASS_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case authAction.VERIFY_RESET_PASS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case authAction.VERIFY_RESET_PASS_FAILED:
      return {
        ...state,
        loading: false,
      };

      case authAction.DELETE_USER_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case authAction.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case authAction.DELETE_USER_FAILED:
      return {
        ...state,
        loading: false,
      };

      case authAction.CHANGE_PASS_REQUESTED:
        return {
          ...state,
          loading: true,
          failureMessage: "",
          isPassChanged: false
        };
  
      case authAction.CHANGE_PASS_SUCCESS:
        return {
          ...state,
          loading: false,
          isPassChanged:true
        };
  
      case authAction.CHANGE_PASS_FAILED:
        return {
          ...state,
          loading: false,
          failureMessage: action.payload,
        };

        case authAction.RESET_CHANGE_PASS:
          return {
            ...state,
            isPassChanged: false,
          };

    default:
      return { ...state };
  }
};

export default authReducer;
