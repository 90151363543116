import React, { useState } from "react";
import AppleMap from "../ItineraryDetails/sections/appleMaps";
import SimilarExperience from "../components/SimilarExperience";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";

const HotelNearbyPanel = ({latitude, longitude}) => {
  const { citySearchNearbyPlacesLoader } = useSelector((state: RootState) => state.screenReducer);
  const { hotelDetails } = useSelector((state: RootState) => state.screenReducer);

  // States
  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = useState({
    latitude: 13.762493133544922,
    longitude: 100.49934387207031,
  });

  const [zoom, setZoom] = useState({
    latitudeSpan: 0.05,
    longitudeSpan: 0.05,
  });

  const handleSelectMarker = (marker: any) => {
    console.log("Selected marker:", marker);
  };

  return (
    <>
    {/* <div className="flex flex-col w-full h-[480px] mb-10">
      <AppleMap
        markers={markers}
        setMarkers={setMarkers}
        onSelectMarker={handleSelectMarker}
        center={center}
        zoom={zoom}
        initialLat={latitude}
        initialLong={longitude}
      />
    </div> */}
    <div className="mb-10">
    {!citySearchNearbyPlacesLoader && <SimilarExperience type={"NEARBY_PLACES"} city={hotelDetails?.city}/>}
    </div>
    </>
  );
};

export default HotelNearbyPanel;
