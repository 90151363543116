import CloseIcon from "@mui/icons-material/Close";
import { Chip, SxProps, Theme } from "@mui/material";

interface EChipProps {
  label: string;
  type: "filter" | "gray" | "warning";
  onClick?: () => void;
  onDelete?: () => void;
  height?: string;
  icon?: any;
  customStyle?: SxProps<Theme>;
}

const EChip = (props: EChipProps) => {
  const { label, type, height, icon, onClick, onDelete, customStyle } = props;
  
  const filterView = type === "filter";
  const warningView = type === "warning";

  return (
    <Chip
      variant="outlined"
      sx={{
        height: height ? height : filterView ? "40px" : "20px",
        width: "fit-content",
        borderRadius: filterView ? "10px" : "6px",
        border: filterView ? "1px solid #121212" : "none",
        color: filterView ? "#121212" : warningView ? "#F05D5D" : "#666",
        backgroundColor: filterView
          ? "white"
          : warningView
          ? "#FEEFEF"
          : "#f1f1f1",
        fontSize: filterView ? "14px" : "10px",
        fontWeight: filterView ? "bold" : "normal",
        "& .MuiChip-icon": { 
          color: "inherit",
          marginLeft: "8px",
        },
        ...customStyle,
      }}
      label={label}
      icon={filterView ? null : icon}
      deleteIcon={
        onDelete ? <CloseIcon className="!w-4 !h-4 !text-[#121212]" /> : null
      }
      onClick={onClick}
      onDelete={onDelete}
    />
  );
};

export default EChip;
