import React from "react";
import { Activities, FrontLines, FrontlinesPink, Hotels, howItHelps1, howItHelps2 } from "../../../assests/exportImages";
import { CustomButton } from "../../../components/atom/CustomButton";
import { colors } from "../../../themes/colors";
import { ReactTyped } from "react-typed";
import { useNavigate } from "react-router-dom";

const HowItHelps = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-white w-screen px-5">
      <div className="mb-16">
        <p className="heading">
          How it helps
        </p>
        <div className="flex items-center gap-16 justify-center flex-col-reverse md:flex-row flex-wrap px-5 mb-2   md:mb-0">
          <div>
            <p className="subHeading">
              Let AI Plan, You Just Pack!
            </p>
            <p className="bodytext">
              Just answer a few quick questions, and watch our AI create your
              dream itinerary in seconds—complete with booking links!
            </p>
          </div>
          <img
            width={490}
            src={howItHelps1}
            alt="How It Helps Image"
          />
        </div>
      </div>

      <div>
        <div className="flex items-center gap-16 justify-center flex-col md:flex-row flex-wrap px-5">
          <img
            width={490}
            src={howItHelps2}
            alt="How It Helps Image"
          />
          <div>
            <p className="subHeading">
              Inspire from the
              best travellers!
            </p>
            <p className="bodytext">
              Get inspired by top travel influencers and explore their curated itineraries, hidden gems, and expert recommendations—all in one
              place.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-row gap-[20px] items-center justify-center my-32 flex-wrap">
        <div className=" flex flex-col items-center justify-between md:justify-start md:gap-8  md:w-[550px] md:h-[422px] w-full h-[257px] rounded-3xl bg-cover bg-center py-8"
          style={{ backgroundImage: `url(${Hotels})` }}>
          <div className="mb-4 text-center">
            <div className="flex gap-2">
              <img src={FrontlinesPink} alt={'design'} />

              <p className="font-oswald md:text-[38px] text-[26px] font-bold text-[#421152]">FIND PERFECT HOTEL


              </p>
              <img src={FrontlinesPink} alt={'design'} className="rotate-180" />
            </div>
            <p className="text-[14px] font-Mulish font-bold text-[#1B0322]">Around The World</p>
          </div>

          <CustomButton
            title={'Explore Hotels →'}
            customStyle={{ borderRadius: '16px', marginTop: '10px', paddingX: '30px' }}
            customTypography={{ color: colors.primaryWhite }}
            bg={colors.black}
            onClick={() => navigate('/hotels')}
          />
        </div>
        <div className=" flex flex-col items-center justify-between md:justify-start md:gap-8  md:w-[550px] md:h-[422px] w-full h-[257px] rounded-3xl bg-cover bg-center py-8"
          style={{ backgroundImage: `url(${Activities})` }}>
          <div className="mb-4 text-center">
            <div className="flex gap-2">
              <img src={FrontLines} alt={'design'} />
              <p className="font-oswald md:text-[38px] text-[26px] font-bold text-[#1D6993]">CREATE MEMORIES


              </p>
              <img src={FrontLines} alt={'design'} className="rotate-180 " />
            </div>
            <p className="text-[14px] font-Mulish font-bold text-[#032920]">Anywhere Everywhere</p>
          </div>

          <CustomButton
            title={'Experiences →'}
            customStyle={{ borderRadius: '16px', marginTop: '10px', paddingX: '30px' }}
            customTypography={{ color: colors.primaryWhite }}
            bg={colors.black}
            onClick={() => navigate('/things-to-do')}
          />
        </div>

      </div>

    </div>
  );
};

export default HowItHelps;
