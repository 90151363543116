import { Viator, Bookmark, OpenLink } from '../../assests/exportImages'
import { calculateDiscount, isEmptyObject, isNullOrEmpty } from '../../utils/helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { useEffect, useState } from 'react';
import { CustomButton } from '../../components/atom/CustomButton';
import { CheckCircleRounded } from '@mui/icons-material';
import Chip from '../../components/atom/Chip';

const ViatorBookNow = ({ thingsToDoProductDetails, card }) => {
  const dailyRate = thingsToDoProductDetails?.results?.[0]?.dailyRate;
  const currency = thingsToDoProductDetails?.results?.[0]?.currency;
  const cancellationPolicy = thingsToDoProductDetails?.cancellationPolicy?.description;

  const { productUrl } = thingsToDoProductDetails
  const fromPrice = card?.pricing?.summary?.fromPrice
  const fromPriceBeforeDiscount = card?.pricing?.summary?.fromPriceBeforeDiscount

  return (
    <div>
      {/* <div className='h-10 bg-[#E7F5F1] rounded-t-2xl'></div> */}
      <div className="sticky flex flex-col p-4 w-[360px] h-[fit-content] bg-white rounded-b-2xl rounded-t-2xl shadow-[0px 3px 28px 3px #0000000A]">
        {/* <EChip label="Likely to Sell Out" type={"warning"} /> */}
        <div className='w-fit'>
        {card?.flags?.includes("LIKELY_TO_SELL_OUT") && <Chip label="Likely to Sell Out" type="alert" />}

        </div>


        <div className="flex flex-row justify-between items-center w-full py-3 border-b border-[#F1F1F1]">
          <div className="flex flex-col justify-start max-w-[170px]">
            <img src={Viator} alt="Viator" className="!h-auto !w-[68px] my-2" />
            <p className="text-[10px] font-normal text-[#999] mt-1">
              Price varies by group size
            </p>
          </div>
          <div className="flex flex-row justify-end items-center">
            {(!isNullOrEmpty(fromPrice) || !isNullOrEmpty(fromPriceBeforeDiscount)) && <div className="flex flex-row w-full justify-between items-center">
              <div className="flex flex-row justify-end items-center">
                <div className="flex flex-col mr-3">
                  <p className="text-[10px] font-normal text-[#999] text-right">
                    From
                  </p>
                  <p className={`text-[18px] font-bold text-[#121212]`}>
                    {card?.pricing?.currency === 'USD' ? '$' : card?.pricing?.currency === 'INR' ? '₹' : card?.pricing?.currency}
                    {fromPrice || fromPriceBeforeDiscount}
                  </p>
                </div>
              </div>
            </div>}
          </div>
        </div>
        <div className="flex flex-col w-full gap-4 pt-4">
          <CustomButton
            title="Book Now"
            border={"3px solid #CCF4EC"}
            customStyle={{
              borderRadius: "35px",
              background: "linear-gradient(90deg, #5BDBBE 0%, #20B08F 100%)",
            }}
            className="h-[42px] !w-full"
            endIcon={OpenLink}
            disabled={isEmptyObject(thingsToDoProductDetails)}
            onClick={() => window.open(productUrl)}
          />
          <CustomButton
            title="Add to Trip"
            border={"3px solid #CCF4EC"}
            customStyle={{
              borderRadius: "35px",
              border: "none",
              background: "linear-gradient(90deg, #EADEF9 0%, #E2F6FE 100%)",
              "&:hover": {
                "& .MuiTypography-root": {
                  color: "#000",
                },
              },
            }}
            customTypography={{
              color: "#000",
            }}
            className="h-[42px] !w-full"
            startIcon={Bookmark}
          />
          {cancellationPolicy && <div className="flex flex-row justify-between items-center bg-[#F3F9F7] py-3 mt-1 px-4 gap-x-4 w-full rounded-[10px]">
            <CheckCircleRounded className="text-[#20B08F] !w-[15px] !h-[15px]" />
            <p className="text-xs font-semibold text-[#999]">
              <span className="text-[#000] underline cursor-pointer">
                Cancellation Policy:
              </span>{" "}
              {cancellationPolicy}
            </p>
          </div>}
        </div>
      </div>
    </div>
  )
}

export default ViatorBookNow