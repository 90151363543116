import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { flattenItems, getStoredImages } from "../../helper";
import { IActionInterface } from "../../interfaces";
import { screenActions } from "../actions/actionTypes";
import screenServices from "../services/screenService";
import { toast } from "react-toastify";

function* fetchSingleImages(
  item: any,
  keyName: string
): Generator<unknown, void, unknown> {
  try {
    const reference = item[keyName];

    if (reference === null || reference === undefined) return;

    yield put({ type: screenActions.GET_IMAGES_REQUESTED, reference, item });
    try {
      // yield all(
      //   items?.map((item: any) => {
      //     return getImagesApiCall(item, extractReference);
      //   })
      // );
      const response: any = yield call(screenServices.getImages, reference);
      const images = response;

      if (response?.status === 200) {
        localStorage.setItem(reference, JSON.stringify(images));

        yield put({
          type: screenActions.GET_IMAGES_SUCCEEDED,
          payload: images,
          item,
        });
      } else {
        yield put({
          type: screenActions.GET_IMAGES_FAILED,
        });
      }
    } catch (imageError) {
      yield put({
        type: screenActions.GET_IMAGES_FAILED,
      });
    }
  } catch (error) {
    yield put({ type: screenActions.GET_IMAGES_FAILED });
  }
}

function* getImagesApiCall(
  item: any,
  extractReference: any
): Generator<unknown, void, unknown> {
  const reference = extractReference(item);

  if (getStoredImages(reference)) {
    yield put({
      type: screenActions.GET_IMAGES_SUCCEEDED,
    });
    return;
  }

  if (reference === null) return;
  yield put({ type: screenActions.GET_IMAGES_REQUESTED, reference });

  try {
    const response: any = yield call(screenServices.getImages, reference);
    if (response?.status === 200) {
      // New approach added because storage quota exceeded for some images
      const itemToSet = {
        config:{
          url: response?.config?.url,
        }
      }
      localStorage.setItem(`${reference}`, JSON.stringify(itemToSet));
      yield put({
        type: screenActions.GET_IMAGES_SUCCEEDED,
      });
      return response?.status;
    } else {
      yield put({
        type: screenActions.GET_IMAGES_FAILED,
        error: response,
      });
      return response?.status;
    }
  } catch (imageError) {
    yield put({
      type: screenActions.GET_IMAGES_FAILED,
      error: imageError,
    });
  }
}

// Fetch images and itinerary details for featured travellers
function* fetchImages(action: IActionInterface): any {
  const { items = [], extractReference = () => {} } = action.payload;
  try {
    const response = yield all(
      items?.map((item: any) => {
        return getImagesApiCall(item, extractReference);
      })
    );
    if (response?.length) {
      yield put({ type: screenActions.GET_ALL_IMAGES_SUCCEEDED });
    } else {
      yield put({ type: screenActions.GET_ALL_IMAGES_FAILED });
    }
  } catch (error) {
    yield put({ type: screenActions.GET_IMAGES_FAILED });
  }
}

function* getPublishedTripSaga(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getPublishedTrip);
    if (response.status === 200) {
      const data = response.data;
      yield put({
        type: screenActions.GET_PUBLISHED_TRIP_SUCCEEDED,
        payload: data,
      });
      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: data.trips,
          extractReference: (trip: object | any) => trip?.reference,
        },
      });
    } else {
      yield put({ type: screenActions.GET_PUBLISHED_TRIP_FAILED });
    }
  } catch (error) {
    yield put({ type: screenActions.GET_PUBLISHED_TRIP_FAILED });
  }
}

function* getTrendingDestinationsSaga(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getTrendingDestinationsList);
    if (response.status === 200) {
      const trendingDestinationsList = response.data.predictions;
      yield put({
        type: screenActions?.GET_TRENDING_DESTINATIONS_SUCCESS,
        payload: [...trendingDestinationsList],
      });

      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: trendingDestinationsList,
          extractReference: (trip: object | any) => trip?.imageReference,
        },
      });
    } else {
      yield put({ type: screenActions.GET_TRENDING_DESTINATIONS_FAILED });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_TRENDING_DESTINATIONS_FAILED,
    });
  }
}

function* getFeaturedUsers(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getFeaturedUsersList);
    if (response.status === 200) {
      yield put({
        type: screenActions?.GET_FEATURED_USERS_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({ type: screenActions.GET_FEATURED_USERS_FAILED });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_FEATURED_USERS_FAILED,
    });
  }
}

function* getMyTrips(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getMyTripsList);
    if (response.status === 200) {
      yield put({
        type: screenActions?.GET_MY_TRIPS_SUCCESS,
        payload: response?.data,
      });
      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: response?.data?.trips,
          extractReference: (trip: object | any) => trip?.reference,
        },
      });
    } else {
      yield put({ type: screenActions.GET_MY_TRIPS_FAILED });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_MY_TRIPS_FAILED,
    });
  }
}

function* getItineraryDetails(action: IActionInterface): any {
  try {
    const response = yield call(
      screenServices.getItineraryDetails,
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: screenActions?.GET_ITINERARY_DETAILS_SUCCESS,
        payload: response?.data,
      });

      const allItems = flattenItems(response?.data?.trip);

      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: allItems,
          extractReference: (item: any) => item.photoReference,
        },
      });
    } else {
      yield put({ type: screenActions.GET_ITINERARY_DETAILS_FAILED });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_ITINERARY_DETAILS_FAILED,
    });
  }
}

function* getFeaturedUserDetails(action: IActionInterface): any {
  console.log(action, "wsmdwme");
  try {
    const response = yield call(
      screenServices.getFeaturedUserDetails,
      action.payload
    );
    if (response.status === 200) {
      const userDetails = response?.data;

      yield put({
        type: screenActions?.GET_FEATURED_USER_DETAILS_SUCCESS,
        payload: userDetails,
      });
    } else {
      yield put({ type: screenActions.GET_FEATURED_USER_DETAILS_FAILED });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_FEATURED_USER_DETAILS_FAILED,
    });
  }
}

function* getFeaturedPublishedTrip(action: IActionInterface): any {
  try {
    const response = yield call(
      screenServices.getFeaturedPublishedTrip,
      action.payload
    );
    if (response.status === 200) {
      const featuredTripsList = response.data;
      console.log(featuredTripsList, "mkmdkwmedk");
      yield put({
        type: screenActions?.GET_FEATURED_USER_PUBLISHED_TRIPS_SUCCESS,
        payload: featuredTripsList,
      });
      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: featuredTripsList?.trips,
          extractReference: (trip: object | any) => trip?.reference,
        },
      });
    } else {
      yield put({
        type: screenActions.GET_FEATURED_USER_PUBLISHED_TRIPS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_FEATURED_USER_PUBLISHED_TRIPS_FAILED,
    });
  }
}

function* getSearchedItinerary(action: IActionInterface): any {
  try {
    const response = yield call(
      screenServices.getSearchedItinerary,
      action.payload
    );
    if (response.status === 200) {
      const searchItenairyList = response.data.data;
      yield put({
        type: screenActions?.GET_SEARCHED_ITINERARY_SUCCESS,
        payload: searchItenairyList,
      });

      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: searchItenairyList,
          extractReference: (trip: object | any) => trip?.reference,
        },
      });
    } else {
      yield put({
        type: screenActions.GET_SEARCHED_ITINERARY_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_SEARCHED_ITINERARY_FAILED,
    });
  }
}

function* getSearchedDestinations(action: IActionInterface): any {
  try {
    const response = yield call(
      screenServices.getSearchedDestinations,
      action.payload
    );
    if (response.status === 200) {
      const searchDestinationList = response?.data?.predictions;
      
      yield put({
        type: screenActions?.GET_SEARCHED_DESTINATIONS_SUCCESS,
        payload: searchDestinationList,
      });

      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: searchDestinationList,
          extractReference: (trip: any) => trip.imageReference,
        },
      });
    } else {
      yield put({
        type: screenActions.GET_SEARCHED_DESTINATIONS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_SEARCHED_DESTINATIONS_FAILED,
    });
  }
}

function* getPopularTrips(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getPopularTrips, action.payload);
    if (response.status === 200) {
      yield put({
        type: screenActions?.GET_POPULAR_TRIPS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: screenActions.GET_POPULAR_TRIPS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_POPULAR_TRIPS_FAILED,
    });
  }
}

function* getWeekendGateways(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getWeekendGateways);
    if (response.status === 200) {
      const weekendGatewayList = response?.data?.data;
      yield put({
        type: screenActions.GET_WEEKENDS_GATEWAY_SUCCESS,
        payload: weekendGatewayList,
      });
      console.log("lklklkkk", weekendGatewayList);
      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: weekendGatewayList,
          extractReference: (trip: object | any) => trip?.reference,
        },
      });
    } else {
      yield put({
        type: screenActions.GET_WEEKENDS_GATEWAY_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_WEEKENDS_GATEWAY_FAILED,
    });
  }
}

function* getLongTrips(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getLongTrips);
    if (response.status === 200) {
      const longTripsList = response?.data?.data;
      yield put({
        type: screenActions?.GET_LONG_TRIPS_SUCCESS,
        payload: longTripsList,
      });
      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: longTripsList,
          extractReference: (trip: object | any) => trip?.reference,
        },
      });
    } else {
      yield put({
        type: screenActions.GET_LONG_TRIPS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_LONG_TRIPS_FAILED,
    });
  }
}

function* getSortedItinerary(action: IActionInterface): any {
  try {
    const response = yield call(
      screenServices.getSortedItinerary,
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: screenActions?.GET_SORTED_ITINERARY_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: screenActions.GET_SORTED_ITINERARY_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_SORTED_ITINERARY_FAILED,
    });
  }
}

function* getPlaceDetails(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getPlaceDetails, action.payload);
    if (response.status === 200) {
      const placeDetailsList = response?.data?.result;
      yield put({
        type: screenActions?.GET_PLACE_DETAILS_SUCCESS,
        payload: placeDetailsList,
      });

      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: placeDetailsList,
          extractReference: (trip: object | any) => trip?.photo_reference,
        },
      });

      for (
        let start = 0;
        start < placeDetailsList?.attractions?.results.length;
        start++
      ) {
        yield call(
          fetchSingleImages,
          placeDetailsList?.attractions?.results[start]?.photos[0],
          "photo_reference"
        );
      }

      for (
        let start = 0;
        start < placeDetailsList?.hiddenPlaces?.results.length;
        start++
      ) {
        yield call(
          fetchSingleImages,
          placeDetailsList?.hiddenPlaces?.results[start]?.photos[0],
          "photo_reference"
        );
      }
    } else {
      yield put({
        type: screenActions.GET_PLACE_DETAILS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_PLACE_DETAILS_FAILED,
    });
  }
}

function* getCoordinates(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getCoordinates, action.payload);
    if (response.status === 200) {
      yield put({
        type: screenActions?.GET_COORDINATES_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: screenActions.GET_COORDINATES_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_COORDINATES_FAILED,
    });
  }
}

function* getSearchUsersSaga(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.searchUsers, action?.payload);
    if (response.status === 200) {
      const searchUsersList = response.data.data;
      yield put({
        type: screenActions.SEARCH_USERS_SUCCESS,
        payload: searchUsersList,
      });
      // yield call(
      //   fetchImages,
      //   searchUsersList,
      //   (trip) => trip.reference,
      //   "images"
      // );
    } else {
      yield put({
        type: screenActions.SEARCH_USERS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions.SEARCH_USERS_FAILED,
    });
  }
}

function* getUserDetailsSaga(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getUserDetails);
    if (response.status === 200) {
      yield put({
        type: screenActions.GET_USER_DETAILS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: screenActions.GET_USER_DETAILS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions.GET_USER_DETAILS_FAILED,
    });
  }
}

function* updateUserDetailsSaga(action: IActionInterface): any {
  try {
    const response = yield call(
      screenServices.updateUserDetails,
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: screenActions.UPDATE_USER_DETAILS_SUCCESS,
        payload: response.data.data,
      });
      yield put({
        type: screenActions.GET_USER_DETAILS_REQUESTED,
      });
    } else {
      yield put({
        type: screenActions.UPDATE_USER_DETAILS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions.UPDATE_USER_DETAILS_FAILED,
    });
  }
}

function* getHotelDetailsSaga(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getHotelDetails, action.payload);
    if (response.status === 200) {
      yield put({
        type: screenActions.GET_HOTEL_DETAILS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: screenActions.GET_HOTEL_DETAILS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions.GET_HOTEL_DETAILS_FAILED,
    });
  }
}

function* getCitySearchSaga(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getCitySearchList, action.payload);
    if (response.status === 200) {
      yield put({
        type: screenActions.GET_CITY_SEARCH_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: screenActions.GET_CITY_SEARCH_FAILED,
      });
    }
  } catch (error) {
    console.error('Saga error:', error);
    yield put({
      type: screenActions.GET_CITY_SEARCH_FAILED,
    });
  }
}

function* getCitySearchSimilarExperience(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getCitySearchList, action.payload);
    if (response.status === 200) {
      yield put({
        type: screenActions.GET_CITY_SEARCH_SIMILAR_EXPERIENCE_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: screenActions.GET_CITY_SEARCH_SIMILAR_EXPERIENCE_FAILED,
      });
    }
  } catch (error) {
    console.error('Saga error:', error);
    yield put({
      type: screenActions.GET_CITY_SEARCH_SIMILAR_EXPERIENCE_FAILED,
    });
  }
}

function* getCitySearchNearbyPlaces(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getCitySearchList, action.payload);
    if (response.status === 200) {
      yield put({
        type: screenActions.GET_CITY_SEARCH_NEARBY_PLACES_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: screenActions.GET_CITY_SEARCH_NEARBY_PLACES_FAILED,
      });
    }
  } catch (error) {
    console.error('Saga error:', error);
    yield put({
      type: screenActions.GET_CITY_SEARCH_NEARBY_PLACES_FAILED,
    });
  }
}

function* getCurrentLocationSaga(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getCityAndCountryFromCoordinates, action.payload);
    if (response.status === 200) {
      const city = response?.data?.address?.city || response?.data?.address?.town || response?.data?.address?.village || response?.data?.address?.county || response?.data?.address?.state
      const country = response?.data?.address?.country
      yield put({
        type: screenActions.GET_CITY_COUNTRY_FROM_COORDINATES_SUCCESS,
        payload: {city, country}
      });
        yield put({
          type: screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_REQUESTED,
          payload: city
        });
      }
     else {
      yield put({
        type: screenActions.GET_CITY_COUNTRY_FROM_COORDINATES_FAILED,
      });
    }
  } catch (error) {
    console.error('Saga error:', error);
    yield put({
      type: screenActions.GET_CITY_SEARCH_FAILED,
    });
  }
}

function* searchCityAndHotelListSaga(action: IActionInterface): any {  
  try {
    const response = yield call(
      screenServices.searchCityAndHotel,
      action.payload
    );
    if (response.status === 200) {
      if(response?.data === null || response?.data?.citySearchResponse?.responseList.length === 0){
        yield put({
          type: screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_FAILED,
          payload: response?.data,
        });
      }
      else{
        yield put({
          type: screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_SUCCESS,
          payload: response?.data,
        });
      //   if(action.payload === "Delhi") {
      //   yield put({
      //     type: screenActions.GET_CITY_COUNTRY_FROM_COORDINATES_SUCCESS,
      //     payload: {city:"Delhi", country: "India"},
      //   });
      // }
        }
    } else {
      yield put({
        type: screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_FAILED,
    });
  }
}

function* searchCityAndHotelListForSearchSaga(action: IActionInterface): any {  
  try {
    const response = yield call(
      screenServices.searchCityAndHotel,
      action.payload
    );
    if (response.status === 200) {
        yield put({
          type: screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_FOR_SEARCH_SUCCESS,
          payload: response?.data,
        });
    } else {
      yield put({
        type: screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_FOR_SEARCH_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_FOR_SEARCH_FAILED,
    });
  }
}

function* getHotelByIds(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getHotelByIds, action.payload);
    if (response.status === 200) {
      yield put({
        type: screenActions.GET_HOTEL_BY_IDS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: screenActions.GET_HOTEL_BY_IDS_FAILED,
      });
    }
  } catch (error) {
    console.error('Saga error:', error);
    yield put({
      type: screenActions.GET_HOTEL_BY_IDS_FAILED,
    });
  }
}

//VIATOR API CALL

function* getThingsToDoProductSearch(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getThingsToDoProductSearch, action.payload);
    if (response.status === 200) {
      yield put({
        type: screenActions.GET_THINGS_TO_DO_PRODUCT_SEARCH_SUCCESS,
        payload: response?.data,
      });
      // Call location API
      // yield put({
      //   type: screenActions.GET_THINGS_TO_DO_LOCATION_REQUESTED,
      //   payload: action?.payload?.filtering?.destination,
      // });
      // yield fork(fetchDestinations, response?.data?.products || []);
      yield put({
        type: screenActions.GET_THINGS_LOCATION_FROM_DESTINATION_REF_REQUESTED,
        payload: response?.data?.products || [],
      });
    } else {
      yield put({
        type: screenActions.GET_THINGS_TO_DO_PRODUCT_SEARCH_FAILED,
      });
    }
  }
  catch (error) {
    console.error('Saga error:', error);
    yield put({
      type: screenActions.GET_THINGS_TO_DO_PRODUCT_SEARCH_FAILED,
    });
  }
}

function* fetchDestinations(action: IActionInterface): any {
  const products = action?.payload  
  try {
    const destinationData = yield all(
      products.map((product) =>
        product?.destinations?.[0]?.ref
          ? call(screenServices.getThingsToDoDestination, product.destinations[0].ref)
          : null
      )
    );

    // Convert array of objects into a single object
    const updatedProducts = products.reduce((acc, product, index) => {
      const destinationResponse = destinationData[index];

      acc[product.productCode] =
        destinationResponse?.status === 200 ? destinationResponse.data || "Unknown" : "Unknown";

      return acc;
    }, {});

    yield put({
      type: screenActions.GET_THINGS_LOCATION_FROM_DESTINATION_REF_SUCCESS,
      payload: { products: updatedProducts }, // Now it's a single merged object
    });
  } catch (error) {
    console.error('Error fetching destinations:', error);
    yield put({
      type: screenActions.GET_THINGS_LOCATION_FROM_DESTINATION_REF_FAILED,
    });
  }
}




function* clearThingsToDoProductSearchSaga(): any {
  yield put({ type: screenActions.CLEAR_THINGS_TO_DO_PRODUCT_SEARCH_SUCCESS });
}

// function* getThingsToDoLocation(action: IActionInterface): any {
//   console.log('Saga triggered with action:', action);
//   try {
//     const response = yield call(screenServices.getThingsToDoLocation, action.payload);
//     if (response.status === 200) {
//       yield put({
//         type: screenActions.GET_THINGS_TO_DO_LOCATION_SUCCESS,
//         payload: response?.data,
//       });
//     } else {
//       yield put({
//         type: screenActions.GET_THINGS_TO_DO_LOCATION_FAILED,
//       });
//     }
//   }
//   catch (error) {
//     console.error('Saga error:', error);
//     yield put({
//       type: screenActions.GET_THINGS_TO_DO_LOCATION_FAILED,
//     });
//   }
// }

function* getThingsToDOActivitySearchSaga(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getThingsToDoActivitySearch, action.payload);
    if (response.status === 200) {
      yield put({
        type: screenActions.GET_THINGS_TO_DO_ACTIVITY_SEARCH_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: screenActions.GET_THINGS_TO_DO_ACTIVITY_SEARCH_FAILED,
      });
    }
  }
  catch (error) {
    console.error('Saga error:', error);
    yield put({
      type: screenActions.GET_THINGS_TO_DO_ACTIVITY_SEARCH_FAILED,
    });
  }
}

function* clearThingsToDoActivitySearchSaga(): any {
  yield put({ type: screenActions.CLEAR_THINGS_TO_DO_ACTIVITY_SEARCH_SUCCESS });
}

function* getThingsToDoDestinationListSaga(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getThingsToDoDestinationList, action.payload);
    if (response.status === 200) {
      yield put({
        type: screenActions.GET_THINGS_TO_DO_DESTINATION_LIST_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: screenActions.GET_THINGS_TO_DO_DESTINATION_LIST_FAILED,
      });
    }
  }
  catch (error) {
    console.error('Saga error:', error);
    yield put({
      type: screenActions.GET_THINGS_TO_DO_DESTINATION_LIST_FAILED,
    });
  }
}


function* getThingsToDoFreeTextSearchSaga(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getThingsToDoFreeTextSearch, action.payload);

    if (response.status === 200) {
      const products = response?.data?.products?.results || [];

      // Fetch destinations for each product
      const destinationData = yield all(
        products.map((product) =>
          call(screenServices.getThingsToDoDestination, product?.destinations?.[0]?.ref)
        )
      );

      // Merge products with their destination details
      const enrichedProducts = products.map((product, index) => ({
        ...product,
        destination: destinationData[index]?.data, // Add destination info
      }));

      // Keep the full response but replace `products` with enriched data
      const fullResponse = {
        ...response.data,
        products: {
          ...response.data.products,
          results: enrichedProducts,
        },
      };

      yield put({
        type: screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_SUCCESS,
        payload: fullResponse, // Send full response with updated products
      });
    } else {
      yield put({ type: screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FAILED });
    }
  } catch (error) {
    console.error('Saga error:', error);
    yield put({ type: screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FAILED });
  }
}

function* getThingsToDoFreeTextSearchForHomeSaga(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getThingsToDoFreeTextSearch, action.payload);

    if (response.status === 200) {
      yield put({
        type: screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FOR_HOME_SUCCESS,
        payload: response?.data, // Send full response with updated products
      });
      yield put({ type: screenActions.GET_THINGS_LOCATION_FROM_DESTINATION_REF_REQUESTED, payload: response?.data?.products?.results || [] });
      // yield fork(fetchDestinations, response?.data?.products?.results || []);
    } else {
      yield put({ type: screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FOR_HOME_FAILED });
    }
  } catch (error) {
    console.error('Saga error:', error);
    yield put({ type: screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FOR_HOME_FAILED });
  }
}

function* clearThingsToDoFreeTextSearchForHomeSaga(): any {
  yield put({ type: screenActions.CLEAR_THINGS_TO_DO_FREE_TEXT_FOR_HOME_SEARCH_SUCCESS });
}



// function* getThingsToDoDestinationSaga(action: IActionInterface): any {
//   console.log('Saga triggered with action:', action);
//   try {
//     const response = yield call(screenServices.getThingsToDoFreeTextSearch, action.payload);
//     if (response.status === 200) {
//       yield put({
//         type: screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_SUCCESS,
//         payload: response?.data,
//       });
//     } else {
//       yield put({
//         type: screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FAILED,
//       });
//     }
//   }
//   catch (error) {
//     console.error('Saga error:', error);
//     yield put({
//       type: screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FAILED,
//     });
//   }
// }

function* getThingsToDoProductDetailsSaga(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getThingsToDoProductDetails, action.payload);
    if (response.status === 200) {
      const destinations = response?.data?.destinations;
      const destinationRef = destinations?.find(dest => dest.primary)?.ref || destinations?.[0]?.ref;
      // if(destinationRef) {
      //   const destinationResponse = yield call(screenServices.getThingsToDoDestination, destinationRef);
      //   if(destinationResponse.status === 200) {
      //     response.data.locationFromRef = destinationResponse?.data;
      //   }
      // }
      yield put({
        type: screenActions.GET_THINGS_TO_DO_PRODUCT_DETAILS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: screenActions.GET_THINGS_TO_DO_PRODUCT_DETAILS_FAILED,
      });
    }
  }
  catch (error) {
    console.error('Saga error:', error);
    yield put({
      type: screenActions.GET_THINGS_TO_DO_PRODUCT_DETAILS_FAILED,
    });
  }
}

function* getthingsToDoSimilarExperienceList(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getThingsToDoProductSearch, action.payload);
    if (response.status === 200) {
      const oldProducts = response?.data?.products || [];
      const destinationData = yield all(
        oldProducts.map((product) =>
          call(screenServices.getThingsToDoDestination, product?.destinations?.[0]?.ref)
        )
      );
      // Merge products with their destination details
      const products = oldProducts.map((product, index) => ({
        ...product,
        destination: destinationData[index]?.data, // Add destination info
      }));
      
      yield put({
        type: screenActions.GET_THINGS_TO_DO_SIMILAR_EXPERIENCE_SUCCESS,
        payload: {products},
      });
    } else {
      yield put({
        type: screenActions.GET_THINGS_TO_DO_SIMILAR_EXPERIENCE_FAILED,
      });
    }
  }
  catch (error) {
    console.error('Saga error:', error);
    yield put({
      type: screenActions.GET_THINGS_TO_DO_SIMILAR_EXPERIENCE_FAILED,
    });
  }
}
function* getthingsToDoYouMayAlsoLikeList(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getThingsToDoProductSearch, action.payload);
    if (response.status === 200) {
      const oldProducts = response?.data?.products || [];
      const destinationData = yield all(
        oldProducts.map((product) =>
          call(screenServices.getThingsToDoDestination, product?.destinations?.[0]?.ref)
        )
      );
      // Merge products with their destination details
      const products = oldProducts.map((product, index) => ({
        ...product,
        destination: destinationData[index]?.data, // Add destination info
      }));
      
      yield put({
        type: screenActions.GET_THINGS_TO_DO_YOU_MAY_ALSO_LIKE_SUCCESS,
        payload: {products},
      });
    } else {
      yield put({
        type: screenActions.GET_THINGS_TO_DO_YOU_MAY_ALSO_LIKE_FAILED,
      });
    }
  }
  catch (error) {
    console.error('Saga error:', error);
    yield put({
      type: screenActions.GET_THINGS_TO_DO_YOU_MAY_ALSO_LIKE_FAILED,
    });
  }
}
function* getthingsToDoNearbyActivitiesList(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getThingsToDoProductSearch, action.payload);
    if (response.status === 200) {
      const oldProducts = response?.data?.products || [];
      const destinationData = yield all(
        oldProducts.map((product) =>
          call(screenServices.getThingsToDoDestination, product?.destinations?.[0]?.ref)
        )
      );
      // Merge products with their destination details
      const products = oldProducts.map((product, index) => ({
        ...product,
        destination: destinationData[index]?.data, // Add destination info
      }));
      
      yield put({
        type: screenActions.GET_THINGS_TO_DO_NEARBY_ACTIVITIES_SUCCESS,
        payload: {products},
      });
    } else {
      yield put({
        type: screenActions.GET_THINGS_TO_DO_NEARBY_ACTIVITIES_FAILED,
      });
    }
  }
  catch (error) {
    console.error('Saga error:', error);
    yield put({
      type: screenActions.GET_THINGS_TO_DO_NEARBY_ACTIVITIES_FAILED,
    });
  }
}
function* getthingsToDoNearbyThingsToDoList(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getThingsToDoProductSearch, action.payload);
    if (response.status === 200) {
      const oldProducts = response?.data?.products || [];
      const destinationData = yield all(
        oldProducts.map((product) =>
          call(screenServices.getThingsToDoDestination, product?.destinations?.[0]?.ref)
        )
      );
      // Merge products with their destination details
      const products = oldProducts.map((product, index) => ({
        ...product,
        destination: destinationData[index]?.data, // Add destination info
      }));
      
      yield put({
        type: screenActions.GET_THINGS_TO_DO_NEARBY_THINGS_TO_DO_SUCCESS,
        payload: {products},
      });
    } else {
      yield put({
        type: screenActions.GET_THINGS_TO_DO_NEARBY_THINGS_TO_DO_FAILED,
      });
    }
  }
  catch (error) {
    console.error('Saga error:', error);
    yield put({
      type: screenActions.GET_THINGS_TO_DO_NEARBY_THINGS_TO_DO_FAILED,
    });
  }
}

const sendFeedbackSaga = function* (action: IActionInterface): any {
  try {
    const response = yield call(screenServices.sendFeedback, action.payload);
    if (response.status === 201) {
      yield put({
        type: screenActions.SEND_FEEDBACK_SUCCESS,
        payload: response.data,
      });
      toast.success('Feedback sent successfully!');
    } else {
      yield put({
        type: screenActions.SEND_FEEDBACK_FAILED,
      });
      toast.error('Failed to send feedback. Please try again.');
    }
  } catch (error) {
    console.error('Saga error:', error);
    yield put({
      type: screenActions.SEND_FEEDBACK_FAILED,
    });
  }
}



function* screenSaga() {
  yield takeLatest(
    screenActions.GET_PUBLISHED_TRIP_REQUESTED,
    getPublishedTripSaga
  );

  yield takeEvery(screenActions.GET_ALL_IMAGES_REQUESTED, fetchImages);

  yield takeLatest(
    screenActions.GET_TRENDING_DESTINATIONS_REQUESTED,
    getTrendingDestinationsSaga
  );

  yield takeLatest(
    screenActions.GET_FEATURED_USERS_REQUESTED,
    getFeaturedUsers
  );

  yield takeLatest(
    screenActions.GET_ITINERARY_DETAILS_REQUESTED,
    getItineraryDetails
  );

  yield takeLatest(
    screenActions.GET_FEATURED_USER_DETAILS_REQUESTED,
    getFeaturedUserDetails
  );

  yield takeLatest(
    screenActions.GET_FEATURED_USER_PUBLISHED_TRIPS_REQUESTED,
    getFeaturedPublishedTrip
  );

  yield takeLatest(
    screenActions.GET_SEARCHED_ITINERARY_REQUESTED,
    getSearchedItinerary
  );

  yield takeLatest(
    screenActions.GET_SEARCHED_DESTINATIONS_REQUESTED,
    getSearchedDestinations
  );

  yield takeLatest(screenActions.GET_POPULAR_TRIPS_REQUESTED, getPopularTrips);

  yield takeLatest(
    screenActions.GET_WEEKENDS_GATEWAY_REQUESTED,
    getWeekendGateways
  );

  yield takeLatest(screenActions.GET_LONG_TRIPS_REQUESTED, getLongTrips);

  yield takeLatest(
    screenActions.GET_SORTED_ITINERARY_REQUESTED,
    getSortedItinerary
  );

  yield takeLatest(screenActions.GET_PLACE_DETAILS_REQUESTED, getPlaceDetails);

  yield takeLatest(screenActions.GET_COORDINATES_REQUESTED, getCoordinates);

  yield takeLatest(screenActions.SEARCH_USERS_REQUESTED, getSearchUsersSaga);

  yield takeLatest(
    screenActions.GET_USER_DETAILS_REQUESTED,
    getUserDetailsSaga
  );

  yield takeLatest(
    screenActions.UPDATE_USER_DETAILS_REQUESTED,
    updateUserDetailsSaga
  );

  yield takeLatest(
    screenActions.GET_MY_TRIPS_REQUESTED,
    getMyTrips
  );

  yield takeLatest(
    screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_REQUESTED,
    searchCityAndHotelListSaga
  );

  yield takeLatest(
    screenActions.GET_CITY_SEARCH_REQUESTED,
    getCitySearchSaga
  );

  yield takeLatest(
    screenActions.GET_HOTEL_DETAILS_REQUESTED,
    getHotelDetailsSaga
  );

  yield takeLatest(
    screenActions.GET_CITY_SEARCH_SIMILAR_EXPERIENCE_REQUESTED,
    getCitySearchSimilarExperience
  );

  yield takeLatest(
    screenActions.GET_CITY_SEARCH_NEARBY_PLACES_REQUESTED,
    getCitySearchNearbyPlaces
  );

  yield takeLatest(
    screenActions.GET_CITY_COUNTRY_FROM_COORDINATES_REQUESTED,
    getCurrentLocationSaga
  );

  yield takeLatest(
    screenActions.GET_SEARCH_CITY_AND_HOTEL_LIST_FOR_SEARCH_REQUESTED,
    searchCityAndHotelListForSearchSaga
  );
  yield takeLatest(
    screenActions.SEND_FEEDBACK_REQUESTED,
    sendFeedbackSaga
  );

  yield takeLatest(screenActions.GET_HOTEL_BY_IDS_REQUESTED, getHotelByIds);

  yield takeLatest(screenActions.GET_THINGS_TO_DO_PRODUCT_SEARCH_REQUESTED, getThingsToDoProductSearch);
  yield takeLatest(screenActions.GET_THINGS_LOCATION_FROM_DESTINATION_REF_REQUESTED, fetchDestinations);
  yield takeLatest(screenActions.CLEAR_THINGS_TO_DO_PRODUCT_SEARCH_REQUESTED, clearThingsToDoProductSearchSaga);
  yield takeLatest(screenActions.GET_THINGS_TO_DO_ACTIVITY_SEARCH_REQUESTED, getThingsToDOActivitySearchSaga);
  yield takeLatest(screenActions.CLEAR_THINGS_TO_DO_ACTIVITY_SEARCH_REQUESTED, clearThingsToDoActivitySearchSaga);
  yield takeLatest(screenActions.GET_THINGS_TO_DO_DESTINATION_LIST_REQUESTED, getThingsToDoDestinationListSaga);
  yield takeLatest(screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_REQUESTED, getThingsToDoFreeTextSearchSaga);
  yield takeLatest(screenActions.GET_THINGS_TO_DO_FREE_TEXT_SEARCH_FOR_HOME_REQUESTED, getThingsToDoFreeTextSearchForHomeSaga);
  yield takeLatest(screenActions.CLEAR_THINGS_TO_DO_FREE_TEXT_FOR_HOME_SEARCH_REQUESTED, clearThingsToDoFreeTextSearchForHomeSaga);
  yield takeLatest(screenActions.GET_THINGS_TO_DO_PRODUCT_DETAILS_REQUESTED, getThingsToDoProductDetailsSaga);
  // yield takeLatest(screenActions.GET_THINGS_TO_DO_DESTINATION_REQUESTED, getThingsToDoDestinationSaga);

  yield takeLatest(screenActions.GET_THINGS_TO_DO_SIMILAR_EXPERIENCE_REQUESTED, getthingsToDoSimilarExperienceList);
  yield takeLatest(screenActions.GET_THINGS_TO_DO_YOU_MAY_ALSO_LIKE_REQUESTED, getthingsToDoYouMayAlsoLikeList);
  yield takeLatest(screenActions.GET_THINGS_TO_DO_NEARBY_ACTIVITIES_REQUESTED, getthingsToDoNearbyActivitiesList);
  yield takeLatest(screenActions.GET_THINGS_TO_DO_NEARBY_THINGS_TO_DO_REQUESTED, getthingsToDoNearbyThingsToDoList);


}

export default screenSaga;
