import React, { useEffect, useState } from 'react'
import { CustomButton } from '../../../components/atom/CustomButton'
import { colors } from '../../../themes/colors'
import { Insta, stars, tellUs1, tellUs2, tellUs3, tellUs4, tellUs5, tellUsBanner, Youtube } from '../../../assests/exportImages'
import TellUsPopOver from './TellUsPopOver'
import { isLoggedIn } from '../../../utils/helpers'
import { useNavigate } from 'react-router-dom'
import { authRoutes } from '../../../constants/routeConstants'

const TellUs = () => {

    const [openWishlistPopUp, setopenWishlistPopUp] = useState(false)
    const navigate = useNavigate()
    const loggedIn = isLoggedIn()

    const handleClick = () => {
        if (loggedIn) {
            setopenWishlistPopUp(true)
        } else {
            navigate(authRoutes.LOGIN)
        }
    }

    useEffect(() => {
        const html = document.querySelector('html')
        if (openWishlistPopUp) {
            html.style.overflow = 'hidden'
        } else {
            html.style.overflow = 'auto'
        }

    }, [openWishlistPopUp])

    return (
        <div className='bg-[#6425C8] w-screen flex flex-col justify-center items-center pb-10 text-center px-5 overflow-hidden  '>
            <img src={stars} alt="stars" className='h-14 relative right-[40vw] top-[45vh] z-10 ' />
            <img src={stars} alt="stars" className='h-6 relative left-[40vw] top-[25vh] z-10 ' />
            <img src={Youtube} alt="Youtube" className='h-10 relative right-[22%] md:top-[40vh] top-[70vh] z-10 ' />
            <img src={Insta} alt="Insta" className='h-10 relative left-[49vw] top-[52vh] z-10 ' />
            <div className='-mt-[300px] relative'>
                <img src={tellUsBanner} alt="Upto $5K/ Month" className='h-[100px] relative md:left-[700px] md:top-[160px] z-10 xs:left-[52vw] xs:top-[160px] sm:left-[500px] sm:top-[160px]' />
                <p className='text-[72px] font-fugaz text-white font-normal opacity-10 relative top-[75px] hidden md:block'>TRAVEL. SHARE. EARN</p>
                <p className='text-[72px] font-fugaz text-white font-normal opacity-10 relative top-[75px] block md:hidden'>TRAVEL.</p>
                <p className='text-[72px] font-fugaz text-white font-normal'>TRAVEL. SHARE. EARN</p>
                <p className='text-[72px] font-fugaz text-white font-normal opacity-10 relative bottom-[75px] block md:hidden'>EARN</p>
                <p className='text-[72px] font-fugaz text-white font-normal opacity-10 relative bottom-[75px] hidden md:block'>TRAVEL. SHARE. EARN</p>
            </div>

            <p className='text-[16px] font-Mulish text-white font-normal -mt-20 text-center max-w-[670px] '>Become a Travel Creator and share your itineraries, inspire your followers, and get paid every time they book.</p>

            <CustomButton
                title={'Join Waitlist'}
                customStyle={{ borderRadius: '16px', marginY: '30px', paddingX: '30px' }}
                customTypography={{ color: colors.black }}
                bg={colors.primaryWhite}
                onClick={handleClick}
            />
            <div className="w-full -mt-[150px]">
                <div className="flex items-baseline justify-center flex-row gap-24 scale-50 -mb-20">
                    <img src={tellUs1} alt="Travel Image" className='hover:scale-110 duration-200' />
                    <img src={tellUs2} alt="Travel Image" className='hover:scale-110 duration-200' />
                    <img src={tellUs3} alt="Travel Image" className='hover:scale-110 duration-200' />
                    <img src={tellUs4} alt="Travel Image" className='hover:scale-110 duration-200' />
                    <img src={tellUs5} alt="Travel Image" className='hover:scale-110 duration-200' />
                </div>
            </div>
            <TellUsPopOver open={openWishlistPopUp} handleClose={() => { setopenWishlistPopUp(false) }} />
        </div>
    )
}

export default TellUs